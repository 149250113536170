import ColorHash from "color-hash";

const colorHash = new ColorHash();

export function assignTagColor(tag: string) {
  const normalizedTag = tag.toLowerCase();
  if (normalizedTag.includes("error") || normalizedTag.includes("fail")) {
    return [168, 50, 50];
  }
  if (normalizedTag.includes("success") || normalizedTag.includes("pass")) {
    return [117, 191, 64];
  }
  if (normalizedTag.includes("warn")) {
    return [219, 156, 20];
  } else {
    return colorHash.rgb(tag);
  }
}
