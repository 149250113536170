import { MenuItem, MenuItemProps } from "@mui/material";
import * as React from "react";

import { useAnalytics } from "@/providers";
import { EventName, EventProps } from "@/service/analytics";

export function RobotoMenuItem<C extends React.ElementType>(
  props: MenuItemProps<
    C,
    {
      eventName: EventName;
      component?: C;
      eventProperties?: EventProps<EventName>;
      onClick?: React.MouseEventHandler<C>;
    }
  >,
) {
  const { trackEvent } = useAnalytics();

  const { eventName, ...filteredProps } = props;

  return (
    <MenuItem
      onClick={(event: React.MouseEvent<C>) => {
        trackEvent(eventName, props.eventProperties);
        props.onClick?.(event);
      }}
      {...filteredProps}
    >
      {props.children}
    </MenuItem>
  );
}
