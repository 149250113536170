import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import {
  Box,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { ResizeCallbackData } from "react-resizable";

import { Column, DataGridSortingOrder } from "@/types";

import { ResizableHeaderCell } from "./ResizableHeaderCell";
import { RobotoCheckbox } from "./RobotoCheckbox";

interface RobotoTableHeaderProps {
  constantHeight: number;
  checkboxWidth: number;
  columns: Column[];
  sortableColumns?: Set<string>;
  maxCellWidth: number;
  minCellWidth: number;
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onResize: (index: number, data: ResizeCallbackData) => void;
  onSortStateChange: (
    sortingOrder: DataGridSortingOrder,
    columnIndex: number,
  ) => void;
  onAddColumnClick: () => void;
  sortingColumnIndex: number | null;
  sortOrder: DataGridSortingOrder;
  headerChecked: boolean;
  setHeaderChecked: (arg: boolean) => void;
  isRowSelectable: boolean;
  isRowExpandable: boolean;
}

export const RobotoTableHeader: React.FC<RobotoTableHeaderProps> = ({
  constantHeight,
  checkboxWidth,
  columns,
  sortableColumns,
  maxCellWidth,
  minCellWidth,
  onCheckboxChange,
  onResize,
  onSortStateChange,
  onAddColumnClick,
  sortingColumnIndex,
  sortOrder,
  headerChecked,
  setHeaderChecked,
  isRowSelectable = true,
  isRowExpandable = false,
}) => {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow sx={{ height: constantHeight + "px" }}>
        {isRowSelectable && (
          <TableCell
            sx={{
              height: constantHeight + "px",
              padding: "0px",
              width: checkboxWidth + "px",
              maxWidth: checkboxWidth + "px",
              backgroundColor: theme.palette.paper.main,
              minWidth: "20px",
            }}
          >
            <RobotoCheckbox
              sx={{
                ml: theme.spacing(1),
                display: {
                  xs: "none",
                  sm: "inline-flex",
                },
              }}
              checked={headerChecked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onCheckboxChange(e);
                setHeaderChecked(e.target.checked);
              }}
            />
          </TableCell>
        )}
        {isRowExpandable && (
          <TableCell
            sx={{
              height: constantHeight + "px",
              padding: "0px",
              width: checkboxWidth + "px",
              maxWidth: checkboxWidth + "px",
              backgroundColor: theme.palette.paper.main,
            }}
          >
            <Box
              sx={{
                ml: theme.spacing(1),
              }}
            >
              <IconButton
                disabled={true}
                sx={{
                  display: {
                    xs: "none",
                    sm: "inline-flex",
                  },
                  transform: "rotate(270deg)",
                }}
              >
                <ExpandCircleDownOutlinedIcon
                  color={"disabled"}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          </TableCell>
        )}
        {columns.map((column, index) => {
          // Default to true
          let isSortable = true;

          if (sortableColumns) {
            // If sortableColumns is defined, check if the column is sortable
            isSortable = sortableColumns.has(column.title) ?? false;
          }

          return (
            <ResizableHeaderCell
              key={column.title}
              title={column.title}
              width={columns[index].width}
              constantHeight={constantHeight}
              maxCellWidth={maxCellWidth}
              minCellWidth={minCellWidth}
              columnIndex={index}
              onResize={onResize}
              sortingState={sortingColumnIndex === index ? sortOrder : "none"}
              onSortStateChange={onSortStateChange}
              finalColumn={index === columns.length - 1}
              onAddColumn={onAddColumnClick}
              isSortable={isSortable}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
};
