import { PageRoute, RobotoEndpoint } from "@/types";

export enum Title {
  Admin = "Admin",
  Home = "Home",
  Search = "Search",
  Collections = "Collections",
  Actions = "Actions",
  ActionHub = "Action Hub",
  Settings = "Settings",
  Docs = "Docs",
  Account = "Account",
  Notifications = "Notifications",
  NewDataset = "New Dataset",
  Logout = "Log out",
}

export enum Alignment {
  Left = "left",
  Right = "right",
  Center = "center",
}

export interface RobotoNavigationProps {
  sideBarWidth: string;
  topBarHeight: string;
}

export interface NavItemData {
  title: Title;
  link: PageRoute | string;
  alignment: Alignment;
  target?: string;
  Icon: React.ElementType;
}

export const notificationsEndpoint: RobotoEndpoint = (pathParams) => {
  if (pathParams && pathParams.notificationId) {
    return `/notifications/${pathParams.notificationId}`;
  }

  return `/notifications`;
};

export const getCommentEndpoint: RobotoEndpoint = (pathParams) => {
  if (pathParams && pathParams.commentId) {
    return `/comments/${pathParams.commentId}`;
  }

  throw new Error("No commentId provided");
};
