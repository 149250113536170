import { VERSION } from "./v1";

export {
  type BoundingBox,
  type Segments,
  type Annotation,
  type Annotations,
  type AnnotationData,
  schema,
} from "./v1";

export const CURRENT_ANNOTATION_SCHEMA_VERSION = VERSION;
export { InvalidAnnotationsError } from "./exception";
