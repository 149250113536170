import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

export function useFileRecordTags(fileId: string) {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery<string[], Error>({
    queryKey: ["fileTags", fileId],
    queryFn: ({ signal }) => {
      return files
        .getFileRecord(fileId, {
          abortSignal: signal,
        })
        .then((record) => {
          return record.tags;
        });
    },
    staleTime: 1000 * 60 * 5,
  });
}
