import { useEffect } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";

import { PostHogService, EventName, EventProps } from "@/service/analytics";

import { useAuth } from "../auth";

import { AnalyticsContext } from "./AnalyticsContext";

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const authState = useAuth();

  useEffect(() => {
    PostHogService.trackEvent("$pageview", {
      $current_url: window.location.href,
    });
  }, [location]);

  useEffect(() => {
    if (authState.currentUser === null) {
      PostHogService.resetUser();
    } else {
      PostHogService.identifyUser(authState.currentUser);
    }
  }, [authState.currentUser]);

  useEffect(() => {
    if (authState.currentOrganization !== null) {
      PostHogService.setOrg(authState.currentOrganization);
    }
  }, [authState.currentOrganization]);

  const trackEvent = <Name extends EventName>(
    eventName: Name,
    ...props: EventProps<Name> extends undefined ? [] : [EventProps<Name>]
  ) => {
    PostHogService.trackEvent(eventName, ...(props as [EventProps<Name>]));
  };

  return (
    <AnalyticsContext.Provider
      value={{
        trackEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
