import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { FileRecord } from "@/domain/files";
import { DomainServicesContext } from "@/providers/DomainServices";

interface RenameFileProps {
  fileId: string;
  datasetId: string;
  currentPath: string;
  newName: string;
}

export function useRenameFile() {
  const { files } = React.useContext(DomainServicesContext);
  const queryClient = useQueryClient();

  return useMutation<FileRecord, Error, RenameFileProps>({
    mutationFn: ({ fileId, datasetId, currentPath, newName }) => {
      return files.renameFile(fileId, datasetId, currentPath, newName);
    },
    onSuccess: (file) => {
      queryClient.setQueryData(["fileRecord", file.file_id], file);
      return queryClient.invalidateQueries({ queryKey: ["directoryContents"] });
    },
  });
}
