import { useParams } from "react-router-dom";

import { NavigationPageWithRedirectProtection } from "@/components";
import { DatasetExplorerPage } from "@/features";

const DatasetExplorer: React.FC = () => {
  const { datasetPath: datasetId, "*": pathString } = useParams();

  let datasetPath: string[] = [];

  if (datasetId) {
    if (pathString) {
      datasetPath = [datasetId].concat(pathString.split("/"));
    } else {
      datasetPath = [datasetId];
    }
  }

  return (
    <NavigationPageWithRedirectProtection
      title={`Roboto - Dataset - ${datasetId}`}
    >
      <DatasetExplorerPage datasetId={datasetId} datasetPath={datasetPath} />
    </NavigationPageWithRedirectProtection>
  );
};

export default DatasetExplorer;
