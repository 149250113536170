import { HttpClient } from "@/http";

import { Limit, LimitDictionary } from "./LimitRecord";

export class LimitService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  async getLimitSummaryForOrg(
    orgId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<LimitDictionary> {
    const url = this.#httpClient.constructUrl(`v1/limits/orgs/id/${orgId}`);
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
    });

    const limits = await response.json<Limit[]>();

    return limits.reduce((dict, limit) => {
      return {
        ...dict,
        [limit.resource_name]: limit.quantity,
      };
    }, {} as LimitDictionary);
  }
}
