import { searchFields } from "./types";

export const searchFieldToType = (fieldName: string) => {
  for (let i = 0; i < searchFields.length; i++) {
    const searchField = searchFields[i];
    if (searchField.name === fieldName) {
      return searchField.type;
    }
  }
  return "other";
};
