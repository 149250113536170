import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import { SxProps, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { View } from "./types";

interface ActionsViewToggleProps {
  view: View;
  setView: (arg: View) => void;
  sx?: SxProps;
}

export default function ActionsViewToggle({
  view,
  setView,
  sx,
}: ActionsViewToggleProps) {
  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={(_, newView: View) => newView !== null && setView(newView)}
      size="small"
      sx={sx}
      value={view}
    >
      <ToggleButton value={View.Table} aria-label="table-view">
        <TableRowsRoundedIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={View.CardGrid} aria-label="grid-view">
        <GridViewRoundedIcon fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
