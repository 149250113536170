import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Divider, useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { RobotoStyledTab } from "@/components/RobotoStyledTabs";

import { SidebarTab, useWorkspaceSidebar } from "../sidebarContext";

import { EventList } from "./EventList";
import { FileAndTopicList } from "./FileAndTopicList";
import styles from "./TabbedSidebar.module.css";

interface TabbedSidebarProps {
  containerClassName?: classNames.Argument;
  containerStyle?: React.CSSProperties;
}

export function TabbedSidebar(props: TabbedSidebarProps) {
  const { containerClassName, containerStyle = {} } = props;
  const theme = useTheme();
  const sidebar = useWorkspaceSidebar();

  const onTabChange = React.useCallback(
    (_event: React.SyntheticEvent, value: string) => {
      const tab = SidebarTab[value as keyof typeof SidebarTab];
      sidebar.setActiveTab(tab);
    },
    [sidebar],
  );

  return (
    <TabContext value={sidebar.activeTab}>
      <section
        className={classNames(styles.container, containerClassName)}
        style={{ backgroundColor: theme.palette.code.main, ...containerStyle }}
      >
        <header>
          <TabList onChange={onTabChange} className={styles.tabList}>
            <RobotoStyledTab value={SidebarTab.Topics} label="Topics" />
            <RobotoStyledTab value={SidebarTab.Events} label="Events" />
          </TabList>
        </header>
        <Divider sx={{ borderColor: theme.border.color }} />
        <div className={styles.tabPanels}>
          <TabPanel className={styles.tabPanel} value={SidebarTab.Topics}>
            <FileAndTopicList />
          </TabPanel>
          <TabPanel className={styles.tabPanel} value={SidebarTab.Events}>
            <EventList />
          </TabPanel>
        </div>
      </section>
    </TabContext>
  );
}
