import { ToggleButton, ToggleButtonProps, TooltipProps } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "./RobotoTooltip";

export const TooltipToggleButton: React.FC<
  ToggleButtonProps & TooltipProps
> = ({ title, ...other }) => {
  return (
    <RobotoTooltip title={title || ""}>
      <ToggleButton {...other} />
    </RobotoTooltip>
  );
};
