import { Box, ListItem, ListItemText, useTheme } from "@mui/material";
import * as React from "react";

import { EditFieldButton } from "@/components";
import { UISimpleCondition } from "@/components/SearchBar/types";
import { TriggerRecord } from "@/domain/actions/TriggerRecord";
import { ClearFieldButton } from "@/features/actions/components/action/ClearFieldButton";
import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import {
  APIResponse,
  Condition,
  ConditionGroup,
  ConditionsOperator,
  RobotoAPICall,
  triggerEndpoint,
} from "@/types";

import { TriggerCondition } from "../TriggerCondition";

import { conditionsToUiSimpleConditions } from "./helpers";

interface EditableTriggerConditionProps {
  title: string;
  triggerName: string;
  value: Condition | undefined;
  setErrorText: (arg: string) => void;
  onUpdate: (arg: APIResponse<TriggerRecord>) => void;
}

export const EditableTriggerCondition: React.FC<
  EditableTriggerConditionProps
> = ({ title, triggerName, value, setErrorText, onUpdate }) => {
  const theme = useTheme();

  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const { currentOrganization } = useAuth();

  const [operator, setOperator] = React.useState<ConditionsOperator>("AND");
  const [conditions, setConditions] = React.useState<UISimpleCondition[]>([]);

  const { initiateRequest } = useLazyAPICall<APIResponse<TriggerRecord>>();

  React.useEffect(() => {
    if (value && "operator" in value) {
      setOperator(value.operator);
    }
    setConditions(conditionsToUiSimpleConditions(value));
  }, [value]);

  let group: ConditionGroup | null;
  if (conditions.length === 0) {
    group = null;
  } else {
    group = {
      conditions: conditions,
      operator: operator,
    };
  }

  const update = async (reset?: boolean) => {
    const condition = reset ? null : group;

    const updateCall: RobotoAPICall = {
      endpoint: triggerEndpoint,
      orgId: currentOrganization?.org_id,
      pathParams: { name: triggerName },
      method: "PUT",
      requestBody: JSON.stringify({
        condition: condition,
      }),
    };

    const { data, error } = await initiateRequest(updateCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data) {
      onUpdate(data);
    }

    setErrorText("");

    return true;
  };

  const onClick = async () => {
    if (editable) {
      const res = await update();
      if (res) {
        setEditable(false);
      }
    }
    setEditable(!editable);
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>
            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                void onClick();
              }}
            />

            {conditions && (
              <ClearFieldButton
                showIcon={showEditableIcon}
                onClick={() => {
                  void update(true);
                  setConditions([]);
                  setOperator("AND");
                }}
              />
            )}
          </>
        }
        secondaryTypographyProps={{
          component: "span",
        }}
        secondary={
          <>
            <Box>
              {conditions.length === 0 && "None"}
              <Box>
                <TriggerCondition
                  conditions={conditions}
                  setConditions={setConditions}
                  operator={operator}
                  setOperator={setOperator}
                  editable={editable}
                />
              </Box>
            </Box>
          </>
        }
      />
    </ListItem>
  );
};
