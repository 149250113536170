import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { LoadingButton } from "@mui/lab";
import { Box, List, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { RobotoLogo, RobotoTypography } from "@/components";
import { Experience, GettingStartedTrigger } from "@/domain/experiences";
import { useAnalytics } from "@/providers";
import { useDomainServices } from "@/providers/DomainServices";
import { LoggerService } from "@/service";

import { AuthCard } from "./AuthCard";
import { ExperienceToggle } from "./ExperienceToggle";
import { LegalFooter } from "./LegalFooter";

const buttonWidth = 215;

interface ConfigureExperiencesProps {
  isVisible: boolean;
  continueClicked: () => void;
  orgId?: string;
}

interface GettingStartedExperienceState {
  dataset: boolean;
  triggers: {
    enabled: boolean;
    displayName: string;
    description: string;
    name: GettingStartedTrigger;
  }[];
}

export const ConfigureExperiences: React.FC<ConfigureExperiencesProps> = ({
  isVisible,
  continueClicked,
  orgId,
}) => {
  const theme = useTheme();
  const { experienceService } = useDomainServices();

  const [experiences, setExperiences] = useState<GettingStartedExperienceState>(
    {
      dataset: true,
      triggers: [
        {
          enabled: true,
          displayName: "Ingest ROS logs",
          description:
            "A trigger will be created to ingest ROS logs automatically",
          name: GettingStartedTrigger.ROSLogIngestionTrigger,
        },
        {
          enabled: true,
          displayName: "Ingest PX4 logs",
          description:
            "A trigger will be created to ingest PX4 ULog files automatically",
          name: GettingStartedTrigger.PX4ULogIngestionTrigger,
        },
      ],
    },
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("Finish");

  const { trackEvent } = useAnalytics();

  if (!isVisible || !orgId) {
    return null;
  }

  const triggersToggles = experiences.triggers.map(
    ({ enabled, description, displayName, name }, index) => {
      const onChange = () => {
        const newTriggers = experiences.triggers.map((trigger, i) => {
          if (i === index) {
            return {
              ...trigger,
              enabled: !trigger.enabled,
            };
          }

          return trigger;
        });
        setExperiences({
          ...experiences,
          triggers: newTriggers,
        });
      };
      return (
        <ExperienceToggle
          key={name}
          displayText={displayName}
          description={description}
          enabled={enabled}
          onChange={onChange}
        />
      );
    },
  );

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <Box sx={{ textAlign: "center", mb: theme.spacing(3) }}>
          <SettingsSuggestIcon
            color="primary"
            sx={{
              fontSize: "5rem",
              display: {
                xs: "none",
                sm: "inline",
              },
            }}
          />

          <RobotoTypography
            variant="h5"
            textAlign={"center"}
            sx={{
              marginBottom: theme.spacing(2),
            }}
          >
            Getting Started
          </RobotoTypography>
          <RobotoTypography variant="body2" textAlign={"center"}>
            Configure default options in your account to streamline your
            experience. You can enable these later as well.
          </RobotoTypography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: theme.spacing(1),
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          <List>
            <ExperienceToggle
              displayText="Create initial dataset"
              description="A dataset with example log data will be created in your account"
              enabled={experiences.dataset}
              onChange={() => {
                setExperiences({
                  ...experiences,
                  dataset: !experiences.dataset,
                });
              }}
            />
            {triggersToggles}
          </List>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mt: theme.spacing(2),
            width: { xs: "100%", sm: buttonWidth },
          }}
        >
          <LoadingButton
            data-cy={"finish-button"}
            disableElevation
            loading={loading}
            loadingPosition="end"
            onClick={() => {
              setLoading(true);
              setButtonText("Finalizing setup...");

              trackEvent("FinishAuthFlowClicked");
              const applyExperiencesRequest = {
                experiences: [
                  {
                    experience_id: Experience.GettingStarted,
                    config: {
                      dataset: experiences.dataset,
                      triggers: experiences.triggers.map((trigger) => {
                        return {
                          name: trigger.name,
                          enabled: trigger.enabled,
                        };
                      }),
                    },
                  },
                ],
              };
              experienceService
                .applyExperiences(orgId, applyExperiencesRequest)
                .then(() => {
                  return new Promise<void>((resolve) => {
                    // Wait a few seconds to let the async experience operation kick off
                    setTimeout(() => {
                      setButtonText("All done!");
                      resolve();
                    }, 3000);
                  });
                })
                .catch((err) => {
                  setButtonText(
                    "Failed to apply the 'Getting Started' experience",
                  );
                  LoggerService.error("Failed to apply experiences", err);
                })
                .finally(() => {
                  setLoading(false);
                  continueClicked();
                });
            }}
            variant="contained"
            style={{
              textTransform: "none",
            }}
            sx={{
              marginBottom: theme.spacing(2),
              width: { xs: "100%", sm: buttonWidth },
            }}
          >
            <span>{buttonText}</span>
          </LoadingButton>
        </Box>
      </AuthCard>
      <LegalFooter />
    </>
  );
};
