import { Box } from "@mui/material";

import { TimestampedLocation } from "./geoTypes";
import styles from "./Map.module.css";

interface ActivePointIndicatorProps {
  activeLocation: TimestampedLocation | null;
}

export function ActivePointIndicator({
  activeLocation,
}: ActivePointIndicatorProps) {
  if (!activeLocation) {
    return null;
  }

  return (
    <Box
      className={styles.sidebar}
      sx={{
        display: {
          xs: "none",
          sm: "block",
        },
      }}
    >
      <>
        Current: {activeLocation.latitude.toFixed(6)} |{" "}
        {activeLocation.longitude.toFixed(6)}
      </>
    </Box>
  );
}
