import { IconButton, IconButtonProps } from "@mui/material";

import { useAnalytics } from "@/providers";
import { ComponentPropsWithEvent, EventName } from "@/service/analytics";

type RobotoButtonPropsWithEvent<T extends EventName> = ComponentPropsWithEvent<
  T,
  IconButtonProps
>;

// This function type overload provides better intellisense by telling the author
// what field is missing from the event properties. Without this, it'll just say
// "Your value for eventProperties does not match the expected type."
export function RobotoIconButton<T extends EventName>(
  props: RobotoButtonPropsWithEvent<T>,
): JSX.Element;

export function RobotoIconButton(
  props: Partial<RobotoButtonPropsWithEvent<EventName>>,
): JSX.Element {
  const { trackEvent } = useAnalytics();

  const { onClick, eventName, eventProperties, ...buttonProps } = props;

  return (
    <IconButton
      onClick={(event) => {
        if (eventName) {
          trackEvent(eventName, eventProperties);
        }
        if (onClick) {
          onClick(event);
        }
      }}
      {...buttonProps}
    >
      {props.children}
    </IconButton>
  );
}
