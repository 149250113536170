import { TableCell } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { RobotoTypography } from "../RobotoTypography";

import styles from "./Cell.module.css";

interface CellProps {
  className?: classNames.Argument;
}

export function Cell({
  children,
  className,
}: React.PropsWithChildren<CellProps>) {
  return (
    <TableCell className={styles.container}>
      <RobotoTypography
        className={classNames(className)}
        color="rgb(107, 114, 128)"
        variant="body2"
      >
        {children}
      </RobotoTypography>
    </TableCell>
  );
}
