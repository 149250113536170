import { LogPanelState } from "@/state/visualization";
import { MessagePayload } from "@/webworker";

import { Log } from "../log";
import { SearchParams } from "../search";

export enum LogCommand {
  Init = "init",
  GetFilteredTimestamps = "get-filtered-timestamps",
  GetLogs = "get-logs",
  SetState = "set-state",
}

export enum LogCommandResponse {
  LogsLoaded = "logs-loaded",
  TimestampsFiltered = "timestamps-filtered",
}

export interface LogCommandPayloadMap {
  [LogCommand.Init]: undefined;
  [LogCommand.SetState]: LogPanelState["data"];
  [LogCommand.GetFilteredTimestamps]: SearchParams;
  [LogCommand.GetLogs]: { startTime: bigint; endTime: bigint };
}

export interface LogCommandResponsePayloadMap {
  [LogCommandResponse.LogsLoaded]: Map<bigint, Log>;
  [LogCommandResponse.TimestampsFiltered]: ArrayBuffer;
}

export function isInitCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.Init]>
> {
  return event.data.type === LogCommand.Init;
}

export function isSetStateCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.SetState]>
> {
  return event.data.type === LogCommand.SetState;
}

export function isGetFilteredTimestampsCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<
    LogCommand,
    LogCommandPayloadMap[LogCommand.GetFilteredTimestamps]
  >
> {
  return event.data.type === LogCommand.GetFilteredTimestamps;
}

export function isGetLogsCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.GetLogs]>
> {
  return event.data.type === LogCommand.GetLogs;
}
