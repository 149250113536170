import { useQuery } from "@tanstack/react-query";

import { LogManager } from "./LogManager";
import { ColumnDefinition } from "./table/columnDefinition";

// Value chosen through trial and error
const overScanCount = 30;

export function useLogs(
  LogManager: LogManager | null,
  columnDefinitions: ColumnDefinition[],
  start: number,
  end: number,
  count: number,
  enabled: boolean,
) {
  const columns = columnDefinitions.map((column) => column.messagePathId);
  return useQuery({
    queryKey: ["logs", count, columns, start, end],
    queryFn: ({ signal }) => {
      const pageStart = Math.max(0, start - overScanCount);
      const pageEnd = Math.min(count - 1, end + overScanCount);
      return LogManager?.getLogs({ start: pageStart, end: pageEnd }, signal);
    },
    placeholderData: (previousData) => previousData,
    gcTime: 0,
    enabled,
  });
}
