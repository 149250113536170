import { LayoutItem, Layout } from "../schema/v1";

/**
 * Helper function to set the size of a list of children immutably
 */
export function setChildrenAxisSize(
  children: (Layout | LayoutItem)[],
  size: number,
): (Layout | LayoutItem)[] {
  if (size < 0 || size > 1) {
    throw new Error(`Invalid size: ${size}`);
  }

  if (children.length === 0) {
    return [];
  }

  return children.map((child) => ({
    ...child,
    relativeSize: size,
  }));
}
