import { Credentials } from "@aws-sdk/types";

import { LoggerService } from "@/service";

import { getFileUploadCredentials } from "./getFileUploadCredentials";

export class RobotoBucketCredentials implements Credentials {
  public accessKeyId: string;
  public secretAccessKey: string;
  public sessionToken: string;

  private datasetId: string;
  private orgId: string;
  private transactionId: string;

  constructor(
    initialAccessKeyId: string,
    initialSecretAccessKey: string,
    initialSessionToken: string,
    datasetId: string,
    orgId: string,
    transactionId: string,
  ) {
    this.accessKeyId = initialAccessKeyId;
    this.secretAccessKey = initialSecretAccessKey;
    this.sessionToken = initialSessionToken;
    this.datasetId = datasetId;
    this.orgId = orgId;
    this.transactionId = transactionId;
  }

  async refresh(): Promise<void> {
    const newCredentials = await this.fetchNewCredentials();

    this.accessKeyId = newCredentials.accessKeyId;
    this.secretAccessKey = newCredentials.secretAccessKey;
    this.sessionToken = newCredentials.sessionToken as string;
  }

  private async fetchNewCredentials(): Promise<{
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
  }> {
    const { response, error } = await getFileUploadCredentials(
      this.datasetId,
      this.orgId,
      this.transactionId,
    );

    if (error || !response?.data) {
      LoggerService.error(
        "Error getting credentials for dataset upload",
        error,
      );
      throw error;
    }

    const { access_key_id, secret_access_key, session_token } = response.data;

    return {
      accessKeyId: access_key_id,
      secretAccessKey: secret_access_key,
      sessionToken: session_token,
    };
  }
}
