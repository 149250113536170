import { NavigationPageWithRedirectProtection } from "@/components";
import { CreateDatasetPage } from "@/features/datasets";

const DatasetCreationPage: React.FC = () => {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Create Dataset"}>
      <CreateDatasetPage />
    </NavigationPageWithRedirectProtection>
  );
};

export default DatasetCreationPage;
