import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { IconButton, useTheme } from "@mui/material";
import classNames from "classnames";

import { actions, useVizDispatch } from "@/state/visualization";

import { default as sectionStyles } from "../SettingsSection.module.css";

import styles from "./RotateImage.module.css";

interface RotateImageProps {
  panelId: string;
  rotation: number;
}

export function RotateImage({ panelId, rotation }: RotateImageProps) {
  const theme = useTheme();
  const dispatch = useVizDispatch();

  const onRotateCounterClockwise = () => {
    const rot = rotation === 360 ? 0 : rotation;
    dispatch(actions.setImagePanelConfig(panelId, { rotation: rot + 90 }));
  };
  const onRotateClockwise = () => {
    const rot = rotation === 0 ? 360 : rotation;
    dispatch(actions.setImagePanelConfig(panelId, { rotation: rot - 90 }));
  };

  return (
    <div className={classNames(sectionStyles.setting, styles.container)}>
      Rotation ({`${rotation % 360}°`})
      <div className={styles.buttons}>
        <IconButton
          className={styles.rotateButton}
          onClick={onRotateCounterClockwise}
          size="small"
          title="Rotate 90° counter-clockwise"
          sx={{
            ":hover": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <RotateLeftIcon fontSize="small" />
        </IconButton>
        <IconButton
          className={styles.rotateButton}
          onClick={onRotateClockwise}
          size="small"
          title="Rotate 90° clockwise"
          sx={{
            ":hover": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <RotateRightIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}
