export enum ColView {
  Grid = "grid",
  Table = "table",
}

import { RobotoEndpoint } from "@/types";

export const collectionsAccessEndpoint: RobotoEndpoint = (pathParams) => {
  const collectionId = pathParams?.collectionId;

  return `/collections/${collectionId}/access`;
};
