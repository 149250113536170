import {
  type RobotoServiceErrorBody,
  RobotoDomainException,
  RobotoServiceException,
} from "./exceptions";

interface RobotoServiceSuccessResponse<T> {
  data: T;
}

interface RobotoServiceErrorResponse<T> {
  error: T;
}

interface JsonReviver {
  (key: string, value: unknown, context?: { source: string }): unknown;
}

export class HttpResponse {
  #response: Response;

  constructor(response: Response) {
    this.#response = response;
  }

  public get raw(): Response {
    return this.#response;
  }

  public get ok(): boolean {
    return this.#response.ok;
  }

  public get headers(): Headers {
    return this.#response.headers;
  }

  public get status(): number {
    return this.#response.status;
  }

  public arrayBuffer(): Promise<ArrayBuffer> {
    return this.#response.arrayBuffer();
  }

  public async json<ResponseBodyType>(
    reviver?: JsonReviver,
  ): Promise<ResponseBodyType> {
    let responseBody:
      | RobotoServiceSuccessResponse<ResponseBodyType>
      | RobotoServiceErrorResponse<RobotoServiceErrorBody>;
    if (reviver === undefined) {
      responseBody = (await this.#response.json()) as
        | RobotoServiceSuccessResponse<ResponseBodyType>
        | RobotoServiceErrorResponse<RobotoServiceErrorBody>;
    } else {
      const responseText = await this.#response.text();
      responseBody = JSON.parse(responseText, reviver) as
        | RobotoServiceSuccessResponse<ResponseBodyType>
        | RobotoServiceErrorResponse<RobotoServiceErrorBody>;
    }

    if ("data" in responseBody) {
      return responseBody.data;
    }

    if ("error" in responseBody) {
      throw RobotoDomainException.fromHttpResponse(responseBody.error);
    }

    throw new Error("Unexpected response body");
  }

  public text(): Promise<string> {
    return this.#response.text();
  }

  public async throwIfError(): Promise<void> {
    if (this.#response.ok === false) {
      await this.json<RobotoServiceException>();
    }
  }
}
