import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useVizDispatch } from "@/state/visualization";

import { Goto } from "./Goto";
import { NavigationContext } from "./NavigationContext";

interface NavigationProviderProps {
  children: React.ReactNode;
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const vizDispatch = useVizDispatch();

  const navigationContext = React.useMemo(() => {
    return {
      goto: new Goto(navigate, vizDispatch),
    };
  }, [navigate, vizDispatch]);

  return (
    <NavigationContext.Provider value={navigationContext}>
      {children}
    </NavigationContext.Provider>
  );
};
