/**
 * Partitions the array into two split by the {pivot} value
 *
 * @param arr
 * @param pivot Value to partition the array by
 * @returns [left, right] Items in left are less than or equal to pivot
 * and items in right are strictly greater than pivot
 */
export function partitionArray(
  arr: number[],
  pivot: number,
): [number[], number[]] {
  return arr.reduce<[number[], number[]]>(
    ([left, right], num: number) => {
      if (num <= pivot) {
        left.push(num);
      } else {
        right.push(num);
      }
      return [left, right];
    },
    [[], []],
  );
}
