import * as React from "react";

import { InvocationRecord } from "@/domain/actions";
import { OrgRecord } from "@/domain/orgs";
import { useLazyAPICall } from "@/service/apiHooks";

export default function useCancellationCallback(
  invocation?: InvocationRecord,
  org?: OrgRecord | null,
  onCancel?: () => void,
) {
  const { initiateRequest } = useLazyAPICall();
  return React.useCallback(async () => {
    if (!invocation || !org?.org_id) {
      return;
    }
    const { error } = await initiateRequest({
      endpoint: () => `/actions/invocations/${invocation.invocation_id}/cancel`,
      method: "POST",
      orgId: org.org_id,
    });

    if (!error && onCancel) {
      onCancel();
    }
  }, [invocation, org?.org_id, initiateRequest, onCancel]);
}
