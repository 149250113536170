import { Alert, Box, Divider, Typography, useTheme } from "@mui/material";

import { PageHeader } from "@/components";
import { Accessibility } from "@/domain/actions";
import { useAuth } from "@/providers";

import ActionsView from "../components/action/ActionsView";
import { Capability } from "../components/action/types";

const ACTION_VIEW_CAPABILITIES = [Capability.Invoke];

const dividerStyles = {
  margin: "1em 0",
};

export default function ActionHubPage() {
  const { currentOrganization } = useAuth();
  const theme = useTheme();

  return (
    <>
      <PageHeader
        display="inline-block"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          paddingRight: theme.spacing(1.25),
          marginRight: theme.spacing(1.25),
          borderRight: "1px solid #BBB",
        }}
      >
        Action Hub
      </PageHeader>

      <Typography display="inline-block" variant="subtitle1">
        Community Actions
      </Typography>

      <Divider sx={dividerStyles} />
      <Box marginTop={theme.spacing(3)} marginBottom={theme.spacing(3)}>
        <Alert severity="info">
          Actions in the Action Hub have been published by Roboto and other
          organizations and are available to invoke on your own datasets.
        </Alert>
      </Box>
      <Box>
        <ActionsView
          accessibility={Accessibility.ActionHub}
          capabilities={ACTION_VIEW_CAPABILITIES}
          org={currentOrganization}
        />
      </Box>
    </>
  );
}
