import { RobotoEndpoint } from "@/types";

export const datasetsAccessEndpoint: RobotoEndpoint = (pathParams) => {
  const datasetId = pathParams?.datasetId;

  return `/datasets/${datasetId}/access`;
};

export interface DatasetTableConfig {
  page: number;
  rowsPerPage: 10 | 25 | 50;
  pageTokens: { [key: number]: string | undefined };
}
