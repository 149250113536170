import { IFileService } from "@/domain/files";

import {
  ISequentialFileUploader,
  SequentialFileUploader,
} from "./SequentialFileUploader";
import {
  UploadItem,
  OnUploadItemProgress,
  MAX_FILES_PER_MANIFEST,
  OnUploadItemFirstBytes,
} from "./types";
import { UploadableFile } from "./UploadableFile";

export class UploadItemTask {
  /*
  Manages the upload of an entire item. Does not pass 
  more than 500 files to SequentialFileUploader
  */

  fileService: IFileService;
  uploadItem: UploadItem;

  private isCancelled = false;
  private sequentialFileUploader: ISequentialFileUploader;
  private transactionIds: string[] = [];

  private _onUploadItemFirstBytes: OnUploadItemFirstBytes;
  private _onUploadItemProgress: OnUploadItemProgress;

  set onUploadItemFirstBytes(fnc: OnUploadItemFirstBytes) {
    this._onUploadItemFirstBytes = fnc;

    this.sequentialFileUploader.onUploadItemFirstBytes =
      this._onUploadItemFirstBytes;

    this.sequentialFileUploader.onUploadItemFirstBytes =
      this._onUploadItemFirstBytes;
  }

  get onUploadItemFirstBytes() {
    return this._onUploadItemFirstBytes;
  }

  set onUploadItemProgress(fnc: OnUploadItemProgress) {
    this._onUploadItemProgress = fnc;
    this.sequentialFileUploader.onFileProgress = this._onUploadItemProgress;
  }

  get onUploadItemProgress() {
    return this._onUploadItemProgress;
  }

  constructor(
    fileService: IFileService,
    uploadItem: UploadItem,
    onUploadItemFirstBytes: OnUploadItemFirstBytes,
    onUploadItemProgress: OnUploadItemProgress,
    sequentialFileUploader?: ISequentialFileUploader,
  ) {
    this.fileService = fileService;
    this.uploadItem = uploadItem;
    this._onUploadItemFirstBytes = onUploadItemFirstBytes;
    this._onUploadItemProgress = onUploadItemProgress;
    this.sequentialFileUploader =
      sequentialFileUploader ??
      new SequentialFileUploader(
        uploadItem.id,
        onUploadItemProgress,
        (transactionId: string) => this.transactionIds.push(transactionId),
        onUploadItemFirstBytes,
      );
  }

  async cancelUploadTask() {
    this.isCancelled = true;
    await this.sequentialFileUploader.cancelUpload();
    await this.rollbackServerUploadState();
  }

  async executeUploadTask(): Promise<void> {
    let isFirstBatch = true;

    while (this.uploadItem.filesToUpload.length > 0) {
      const batch: UploadableFile[] = [];

      if (this.isCancelled) {
        return;
      }

      while (
        this.uploadItem.filesToUpload.length > 0 &&
        batch.length < MAX_FILES_PER_MANIFEST
      ) {
        const nextItem = this.uploadItem.filesToUpload.pop();
        if (nextItem) {
          batch.push(nextItem);
        }
      }

      await this.sequentialFileUploader.uploadFileBatch(
        isFirstBatch,
        batch,
        this.uploadItem.datasetId,
        this.uploadItem.orgId,
        this.uploadItem.prefix,
      );

      isFirstBatch = false;
    }
  }

  private async rollbackServerUploadState(): Promise<void> {
    for (let i = 0; i < this.transactionIds.length; i += 100) {
      await this.fileService.abortTransactions(
        this.transactionIds.slice(i, i + 100),
      );
    }
  }
}
