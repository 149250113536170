import { isImagePanelState, usePanelState } from "@/state/visualization";

import { EmptyPanelSettings } from "./EmptyPanelSettings";
import { ImagePanelSettings } from "./ImagePanelSettings";
import { NoSelectedPanelMessage } from "./NoSelectedPanelMessage";

interface PanelSettingsProps {
  panelId: string;
}

export function PanelSettings({ panelId }: PanelSettingsProps) {
  const selectedPanel = usePanelState(panelId);

  if (!selectedPanel) {
    return <NoSelectedPanelMessage />;
  }

  if (isImagePanelState(selectedPanel)) {
    return <ImagePanelSettings state={selectedPanel} />;
  }

  return <EmptyPanelSettings />;
}
