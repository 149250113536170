import { Layout, isLayoutItem, LayoutItem } from "../schema/v1";

/**
 * Helper function to update fields of the layout tree immutably
 */
export function updateLayout(
  root: Layout,
  id: string,
  newFields: Partial<Layout | LayoutItem>,
): Layout {
  function updateChildren(
    children: (Layout | LayoutItem)[],
  ): (Layout | LayoutItem)[] {
    return children.map((child) => {
      if (id === child.id) {
        return {
          ...child,
          ...newFields,
        };
      }

      if (isLayoutItem(child) === false) {
        return {
          ...child,
          children: updateChildren(child.children),
        };
      }

      return child;
    });
  }

  if (id === root.id) {
    return {
      ...root,
      ...newFields,
    };
  }

  return {
    ...root,
    children: updateChildren(root.children),
  };
}
