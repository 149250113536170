import * as React from "react";

export interface SidebarControls {
  isOpen: boolean;
  toggleOpen: () => void;
}

export const SidebarLayoutContext = React.createContext<SidebarControls>({
  isOpen: false,
  toggleOpen: () => {},
});

export const useSidebarControls = (): SidebarControls => {
  return React.useContext(SidebarLayoutContext);
};
