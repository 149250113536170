import {
  Box,
  LinearProgress,
  Paper,
  Typography,
  linearProgressClasses,
  styled,
  useTheme,
} from "@mui/material";
import * as React from "react";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgb(239 246 255)"
        : theme.palette.grey[800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? "rgb(59 130 246)" : "#308fe8",
  },
}));

export interface UsageLimitProps {
  title: string;
  currentValue: string;
  currentPercent: number;
  limitValue: string;
  monthly: boolean;
}

export const UsageLimit: React.FC<UsageLimitProps> = ({
  title,
  currentValue,
  currentPercent,
  limitValue,
  monthly,
}) => {
  const theme = useTheme();

  return (
    <Box
      component={Paper}
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "156px",
        width: {
          xs: "400px",
        },
        backgroundColor: theme.palette.foreground.main,
        p: "1.5rem",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: ".875rem",
          lineHeight: "1.25rem",
          color: "rgb(107 114 128)",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: "1.875rem",
          lineHeight: "2.25rem",
          fontWeight: 500,
        }}
      >
        {currentValue}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mt: "0.75rem",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: ".875rem",
            lineHeight: "1.25rem",
            color: "rgb(107 114 128)",
          }}
        >
          <b>{Math.round(currentPercent)}%</b> of{" "}
          {monthly ? "monthly" : "total"} limit
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: ".875rem",
            lineHeight: "1.25rem",
            color: "rgb(107 114 128)",
          }}
        >
          {limitValue}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "0.75rem",
        }}
      >
        <BorderLinearProgress variant="determinate" value={currentPercent} />
      </Box>
    </Box>
  );
};
