/*
Resource names that are used in metering or limits.
See backend references:
- packages/roboto_internal/src/roboto_internal/metering/metering_model.py
- packages/roboto_internal/src/roboto_internal/limiting/limiting_model.py
*/
export enum Resource {
  // Datasets
  DatasetsCreated = "datasets::created",
  DatasetCount = "datasets::total_count",

  // Actions
  ActionCount = "actions::total_count",
  ConcurrentInvocations = "actions::concurrent_invocations",
  FreeInvocationComputeCreditMillionths = "actions::free_invocation_compute_credit_millionths",
  FreeTierComputeSpec = "actions::free_tier_compute_spec",
  InvocationComputeCreditMillionths = "actions::invocation_compute_credit_millionths",
  InvocationVCpuMillis = "actions::invocation_vcpu_millis",
  InvocationRuntimeMillis = "actions::invocation_runtime_millis",
  InvocationRamMillis = "actions::invocation_ram_gb_millis",
  InvocationsScheduled = "actions::invocations_scheduled",
  MemoryMiBs = "actions::memory_mibs_per_invocation",
  VCPUs = "actions::vcpus_per_invocation",
  StorageGiBs = "actions::storage_gibs_per_invocation",
  GPUs = "actions::gpus_per_invocation",

  // Files
  FreeManagedDataDownloadBytes = "files::free_managed_data_download_bytes",
  FreeManagedDataStoredBytes = "files::free_managed_data_stored_bytes",
  ManagedDataInternalDownloadBytes = "files::managed_data_internal_download_bytes",
  ManagedDataDownloadBytes = "files::managed_data_download_bytes",
  ManagedDataStoredBytes = "files::managed_data_stored_bytes",
  ManagedDataStoredFiles = "files::managed_data_stored_files",
  ManagedDataInternalUploadBytes = "files::managed_data_internal_upload_bytes",
  ManagedDataUploadBytes = "files::managed_data_upload_bytes",

  // Orgs
  OrgMembers = "orgs::total_members",

  // Users
  OwnedOrgsPerUser = "users::total_owned_orgs",

  // Triggers
  TriggersActive = "triggers::active_count",
}
