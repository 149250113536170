import { NavigationPageWithRedirectProtection } from "@/components";
import { InvokeActionPage } from "@/features/actions/pages";

const ActionInvocationPage: React.FC = () => {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Invoke Action"}>
      <InvokeActionPage />
    </NavigationPageWithRedirectProtection>
  );
};

export default ActionInvocationPage;
