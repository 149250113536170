import * as React from "react";

import { HttpClientContext } from "@/providers/HttpClient";

import { AutocompleteType } from "./types";

const autocompleteTypeToUrl = {
  [AutocompleteType.ActionEnvVarKeys]: "",
  [AutocompleteType.ActionMetadataKeys]: "actions/metadata/keys",
  [AutocompleteType.ActionTags]: "actions/tags",
  [AutocompleteType.CollectionTags]: "collections/tags",
  [AutocompleteType.DatasetMetadataKeys]: "datasets/metadata/keys",
  [AutocompleteType.DatasetTags]: "datasets/tags",
  [AutocompleteType.EventMetadataKeys]: "events/metadata/keys",
  [AutocompleteType.EventNames]: "events/names",
  [AutocompleteType.EventTags]: "events/tags",
  [AutocompleteType.FileMetadataKeys]: "files/metadata/keys",
  [AutocompleteType.FileTags]: "files/tags",
  [AutocompleteType.TopicMetadataKeys]: "topics/metadata/keys",
};

interface ElementsForAutocompleteState {
  elements: string[];
  error: Error | null;
}

export const useElementsForAutocompleteType = (
  autocompleteType: AutocompleteType,
  existingElements: string[] = [],
): ElementsForAutocompleteState => {
  const [state, setState] = React.useState<ElementsForAutocompleteState>({
    elements: [],
    error: null,
  });

  const { httpClient } = React.useContext(HttpClientContext);

  const arrayAsString = existingElements.sort().join();

  React.useEffect(() => {
    const urlPath = autocompleteTypeToUrl[autocompleteType];

    if (urlPath === "") {
      setState({
        elements: [],
        error: null,
      });
      return;
    }

    const abortController = new AbortController();

    const fetchElements = async () => {
      try {
        const url = httpClient.constructUrl(`v1/${urlPath}`);
        const response = await httpClient.get(url, {
          signal: abortController.signal,
        });
        const elements = await response.json<string[]>();

        setState({
          elements: elements,
          error: null,
        });
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }

        setState({
          elements: [],
          error: new Error("Failed to fetch elements for autocomplete type", {
            cause: e,
          }),
        });
      }
    };

    void fetchElements();

    return () => {
      abortController.abort();
    };
  }, [httpClient, autocompleteType, arrayAsString]);

  return state;
};
