import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import {
  notUndefined,
  useVirtualizer,
  VirtualItem,
  Virtualizer,
} from "@tanstack/react-virtual";
import * as React from "react";

import { ErrorMonitoringService } from "@/service";
import { LayoutItem, LogPanelState } from "@/state/visualization";

import { formatMessagePath } from "../../message";
import { useWorkspaceTimer } from "../../WorkspaceCtx";
import { NoDataMessage } from "../NoDataMessage";
import { PanelLayout } from "../PanelLayout";
import { RenderingError } from "../RenderingError";

import { LogManager } from "./LogManager";
import styles from "./LogPanel.module.css";
import { LogEvent } from "./messaging";
import { LogSearchInput } from "./search/LogSearchInput";
import { ColumnDefinition, Row, TableHeader } from "./table";
import { useLogs } from "./useLogs";

function getDynamicColumns(data: LogPanelState["data"]): ColumnDefinition[] {
  return data.map((topic) => ({
    messagePathId: topic.messagePath.id,
    name: formatMessagePath(topic.messagePath.parts),
  }));
}

/**
 * Calculates the additional height [above, below] for the virtualized table to enable scrolling.
 */
function calculateTableHeight(
  virtualizer: Virtualizer<HTMLDivElement, Element>,
  items: VirtualItem[],
): [number, number] {
  // Implementation pulled from https://github.com/TanStack/virtual/issues/585#issuecomment-1716173260
  return items.length > 0
    ? [
        notUndefined(items[0]).start - virtualizer.options.scrollMargin,
        virtualizer.getTotalSize() - notUndefined(items[items.length - 1]).end,
      ]
    : [0, 0];
}

interface LogPanelProps {
  layout: LayoutItem;
  state: LogPanelState;
}

export function LogPanel({ layout, state }: LogPanelProps) {
  const timer = useWorkspaceTimer();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [logManager, setLogManager] = React.useState<LogManager | null>(null);
  const [selected, setSelected] = React.useState<number | undefined>();

  // We will initially show 20 logs loading but we will use the actual number of logs
  // once they have loaded.
  const [numLogs, setNumLogs] = React.useState(20);

  React.useEffect(function init() {
    const abortController = new AbortController();
    const logManager = new LogManager();

    logManager?.setEventListener(LogEvent.Initialized, () => {
      if (abortController.signal.aborted) {
        return;
      }

      setLogManager(logManager);
    });

    logManager?.setEventListener(LogEvent.Error, (err) => {
      if (abortController.signal.aborted || err.name === "AbortError") {
        return;
      }

      const error = new Error(err.message, { cause: err });
      ErrorMonitoringService.captureError(error);
      setError(error);
      setIsLoading(false);
      setLogManager(null);
    });

    logManager?.setEventListener(LogEvent.NumLogsChanged, ({ numLogs }) => {
      if (abortController.signal.aborted) {
        return;
      }

      setNumLogs(numLogs);
    });

    return function cleanUp() {
      abortController.abort();
      logManager?.dispose();
    };
  }, []);

  const timerHoldRef = React.useRef<symbol | null>(null);
  React.useEffect(
    function holdTimerWhileLoading() {
      if (logManager === null) {
        return;
      }

      const abortController = new AbortController();

      logManager.setEventListener(
        LogEvent.LoadingStateChange,
        ({ isLoading }) => {
          if (abortController.signal.aborted) {
            return;
          }

          if (isLoading && timerHoldRef.current === null) {
            timerHoldRef.current = timer.hold();
          }
          if (!isLoading && timerHoldRef.current !== null) {
            timer.holdRelease(timerHoldRef.current);
            timerHoldRef.current = null;
          }

          setIsLoading(isLoading);
        },
      );

      return function cleanUp() {
        abortController.abort();
        if (timerHoldRef.current !== null) {
          timer.holdRelease(timerHoldRef.current);
          timerHoldRef.current = null;
        }
      };
    },
    [logManager, timer],
  );

  React.useEffect(
    function setState() {
      if (logManager === null) {
        return;
      }

      logManager.setState(state.data);
    },
    [state.data, logManager],
  );

  const tableRef = React.useRef<HTMLDivElement>(null);
  const hasInitiatedTimerUpdateRef = React.useRef<boolean>(false);

  const staticColumns = ["time"];
  const dynamicColumns = getDynamicColumns(state.data);
  const numColumns = dynamicColumns.length + staticColumns.length;

  const virtualizer = useVirtualizer({
    count: state.data.length > 0 ? numLogs : 0,
    getScrollElement: () => tableRef.current,

    // Value chosen to match the loading row height
    estimateSize: () => 33,

    // Value chosen through trial and error
    overscan: 20,
  });

  const items = virtualizer.getVirtualItems();
  const start = items[0]?.index ?? 0;
  const end = items[items.length - 1]?.index ?? 0;

  const fetchingEnabled =
    logManager !== null && !isLoading && state.data.length > 0;

  const logsQuery = useLogs(
    logManager,
    dynamicColumns,
    start,
    end,
    numLogs,
    fetchingEnabled,
  );

  const scrollTable = React.useCallback(
    function scrollTable() {
      if (logManager === null) {
        return;
      }

      // Don't scroll when this panel has initiated a timer update
      if (hasInitiatedTimerUpdateRef.current) {
        hasInitiatedTimerUpdateRef.current = false;
        return;
      }

      const closestIndex = logManager.findLogIndexForTime(timer.currentTime);

      virtualizer.scrollToIndex(closestIndex, { align: "center" });
      setSelected(closestIndex);
    },
    [logManager, timer, virtualizer],
  );

  React.useEffect(
    function setTimerCallbacks() {
      const abortController = new AbortController();

      timer.addListener("tick", scrollTable, {
        signal: abortController.signal,
      });
      timer.addListener("seek", scrollTable, {
        signal: abortController.signal,
      });

      return function abort() {
        abortController.abort();
      };
    },
    [timer, scrollTable],
  );

  const [spaceAbove, spaceBelow] = calculateTableHeight(virtualizer, items);

  return (
    <PanelLayout state={state} layout={layout}>
      <NoDataMessage panelData={state.data} />
      <RenderingError error={error} />
      <div className={styles.container}>
        <LogSearchInput
          disabled={isLoading}
          onSearch={(query, mode) => {
            void logManager?.filterLogs({ query, mode });
          }}
        />
        <div className={styles.tableContainer} ref={tableRef}>
          <Table
            aria-label="sticky table"
            className={styles.table}
            size="small"
            stickyHeader
            // minWidth needs to be a function of the number of columns
            style={{ minWidth: `${numColumns * 12.5}rem` }}
          >
            <TableHeader
              columnDefinitions={dynamicColumns}
              panelId={state.id}
            />
            <TableBody>
              {spaceAbove > 0 && (
                <TableRow>
                  <TableCell style={{ height: spaceAbove }} />
                </TableRow>
              )}
              {items.map((virtualRow) => {
                const time = logManager?.getTimeForIndex(virtualRow.index);
                const log =
                  time !== undefined ? logsQuery.data?.get(time) : undefined;

                const columns = dynamicColumns.map((column) => ({
                  ...column,
                  isLoading:
                    // We're loading if the column doesn't exist in log and we're
                    // displaying the previous query while the new one is fetching.
                    column.messagePathId in (log?.data || {}) === false &&
                    logsQuery.isPlaceholderData,
                }));

                return (
                  <Row
                    columns={columns}
                    dataIndex={virtualRow.index}
                    key={virtualRow.index}
                    log={log}
                    onClick={(_time) => {
                      setSelected(virtualRow.index);
                      hasInitiatedTimerUpdateRef.current = true;
                      timer.seekTo(_time);
                    }}
                    ref={virtualizer.measureElement}
                    selected={selected === virtualRow.index}
                  />
                );
              })}
              {spaceBelow > 0 && (
                <TableRow>
                  <TableCell style={{ height: spaceBelow }} />
                </TableRow>
              )}
              {items.length === 0 && (
                <TableRow>
                  <TableCell colSpan={numColumns}>
                    No matching data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </PanelLayout>
  );
}
