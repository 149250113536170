import { Box, useTheme } from "@mui/material";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";
import remarkGfm from "remark-gfm";

interface ActionMarkdownProps {
  text: string;
}

export const ActionMarkdown: React.FC<ActionMarkdownProps> = ({ text }) => {
  const [loadedText, setLoadedText] = React.useState<string>("");

  const theme = useTheme();

  React.useEffect(() => {
    const renderText = async () => {
      function isValidHttpUrl(val: string) {
        let url;

        try {
          url = new URL(val);
        } catch (_) {
          return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
      }

      if (isValidHttpUrl(text)) {
        await fetch(text, {
          method: "GET",
        })
          .then(function (response) {
            return response.text();
          })
          .then(function (data) {
            setLoadedText(data);
          });
      } else {
        setLoadedText(text);
        return text;
      }
    };

    void renderText();
  }, [text]);

  return (
    <Box
      sx={{
        // These are necessary to style the markdown appropriately
        fontSize: "0.8rem",
        whiteSpace: "break-spaces",
        h1: {
          fontSize: "1.05rem",
        },
        h2: {
          fontSize: "0.95rem",
        },
        h3: {
          fontSize: "0.9rem",
        },
        h4: {
          fontSize: "0.88rem",
        },
        h5: {
          fontSize: "0.85rem",
        },
        h6: {
          fontSize: "0.8rem",
        },
        pre: {
          overflow: "auto",
          backgroundColor: theme.palette.code.main,
          padding: theme.spacing(1),
        },
        a: {
          color: theme.palette.primary.main,
          textDecoration: "none",
          ":hover": {
            textDecoration: "underline",
          },
        },
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}
      >
        {loadedText}
      </ReactMarkdown>
    </Box>
  );
};
