import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  DialogActions,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { RobotoStyledTabs, RobotoStyledTab } from "@/components";
import { useAuth } from "@/providers";
import { fetchAllItems, useLazyAPICall } from "@/service/apiHooks";
import { FileNode, RobotoAPICall, SearchQueryBody } from "@/types";

import { collectionEndpoint } from "../api";
import { Collection } from "../model";

import { CreateCollection } from "./CreateCollection";

interface TabPanelProps {
  index: number;
  value: number;
  children: React.ReactElement;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  index,
  value,
  children,
  ...props
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && (
        <Box sx={{ pl: 3, pr: 3, pt: 1, pb: 1 }}>{children}</Box>
      )}
    </div>
  );
};

interface AddToCollectionProps {
  selectedDatasets?: Set<string>;
  selectedFiles?: FileNode[];
  close: () => void;
}

export const AddToCollection: React.FC<AddToCollectionProps> = ({
  selectedDatasets,
  selectedFiles,
  close,
}) => {
  const theme = useTheme();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [collection, setCollection] = useState<Collection | null>();
  const [tabValue, setTabValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [addToExistingError, setAddToExistingError] =
    React.useState<Error | null>(null);
  const { currentOrganization } = useAuth();

  const { initiateRequest: updateCollectionReq } = useLazyAPICall<{
    data: Collection;
  }>();

  const updateCollection = async () => {
    if (!collection?.collection_id || !currentOrganization?.org_id) {
      return;
    }

    setIsLoading(true);

    const datasets =
      (selectedDatasets &&
        Array.from(selectedDatasets).map((dataset) => {
          return {
            resource_type: "dataset",
            resource_id: dataset,
          };
        })) ||
      [];

    const files =
      (selectedFiles &&
        selectedFiles.map((item) => {
          return {
            resource_type: "file",
            resource_id: item.file?.file_id || "",
          };
        })) ||
      [];

    // Update the Collection
    const { error, data } = await updateCollectionReq({
      method: "PUT",
      endpoint: collectionEndpoint,
      pathParams: { collectionId: collection?.collection_id },
      requestBody: JSON.stringify({
        add_resources: files.length ? files : datasets,
      }),
      orgId: currentOrganization?.org_id,
    });

    if (error) {
      setAddToExistingError(error);
    }

    setIsLoading(false);

    if (!error && data?.data) {
      close();
    }
  };

  React.useEffect(() => {
    async function fetchCollections() {
      setIsLoading(true);
      setAddToExistingError(null);

      const requestBody: SearchQueryBody = {
        sort_direction: "DESC",
        sort_by: "created",
      };

      const queryParams = new URLSearchParams();
      queryParams.set("content_mode", "summary_only");

      const collectionsQuery: RobotoAPICall = {
        endpoint: () => "/collections/search",
        method: "POST",
        orgId: currentOrganization?.org_id,
        requestBody: JSON.stringify(requestBody),
        queryParams: queryParams,
      };

      const collectionsQueryResponse =
        await fetchAllItems<Collection>(collectionsQuery);

      if (collectionsQueryResponse.error) {
        setAddToExistingError(collectionsQueryResponse.error);
        return;
      }

      setCollections(collectionsQueryResponse.items ?? []);

      setIsLoading(false);
    }

    void fetchCollections();
  }, [currentOrganization?.org_id]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  let errorDisplay = null;
  if (addToExistingError) {
    errorDisplay = (
      <Alert onClose={() => setAddToExistingError(null)} severity="error">
        {addToExistingError.message}
      </Alert>
    );
  }

  return (
    <Box sx={{ padding: "10px", minWidth: "325px" }} component="form">
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{ textAlign: "center" }}
      >
        Add to Collection
      </Typography>
      <Divider />
      <RobotoStyledTabs
        variant="fullWidth"
        value={tabValue}
        onChange={handleTabChange}
        aria-label="add-to-collection-tabs"
        sx={{
          mt: theme.spacing(2),
          "& .MuiTabs-indicatorSpan": {
            width: "80px",
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <RobotoStyledTab label={"Existing Collection"} />
        <RobotoStyledTab label={"New Collection"} />
      </RobotoStyledTabs>

      <TabPanel value={tabValue} index={0}>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0.5rem",
            width: "300px",
          }}
        >
          {errorDisplay}
          <Autocomplete
            id="available-collections"
            options={collections}
            getOptionLabel={(option) => option.name || option.collection_id}
            value={collection}
            size="small"
            loading={isLoading}
            onChange={(_, selection: Collection | null) => {
              setCollection(selection);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Collection" />
            )}
            componentsProps={{
              paper: {
                sx: {
                  fontSize: "0.875rem",
                },
              },
            }}
          />
          <DialogActions
            sx={{
              justifyContent: "center",
              mt: theme.spacing(1),
            }}
          >
            <LoadingButton
              loading={isLoading}
              type="submit"
              disabled={
                (selectedDatasets &&
                  selectedDatasets.size <= 0 &&
                  selectedFiles &&
                  selectedFiles.length <= 0) ||
                !collection
              }
              onClick={() => {
                void updateCollection();
              }}
              variant="outlined"
              style={{
                textTransform: "none",
              }}
              size="small"
            >
              Add to Collection (
              {selectedFiles && selectedFiles.length
                ? selectedFiles.length
                : selectedDatasets && selectedDatasets.size}
              )
            </LoadingButton>
          </DialogActions>
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CreateCollection
          selectedDatasets={selectedDatasets}
          selectedFiles={selectedFiles}
          close={close}
        />
      </TabPanel>
    </Box>
  );
};
