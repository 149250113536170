import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { alpha } from "@mui/material/styles";
import * as React from "react";

import {
  type TopicData,
  actions,
  usePlotSeries,
  VizStateDispatchContext,
} from "@/state/visualization";

import { ConfigSection } from "./ConfigSection";
import { MessagePath } from "./MessagePath";
import { MessagePathHelp } from "./MessagePathHelp";
import styles from "./PlotSeriesConfig.module.css";

interface PlotSeriesConfigProps {
  onClose?: () => void;
  panelId: string;
  seriesId?: string;
}

export function PlotSeriesConfig({
  onClose,
  panelId,
  seriesId,
}: PlotSeriesConfigProps) {
  const vizStateDispatch = React.useContext(VizStateDispatchContext);
  const plotSeries = usePlotSeries(panelId, seriesId);

  const onMessagePathUpdate = (messagePath: TopicData["messagePath"]) => {
    vizStateDispatch(
      actions.setMessagePath({
        messagePath,
        panelId,
        seriesId,
      }),
    );
  };

  if (plotSeries === undefined) {
    return null;
  }

  return (
    <Box
      className={styles.container}
      bgcolor={(theme) => alpha(theme.palette.paper.main, 0.8)}
      border={"1px solid #BBB"}
    >
      <div className={styles.header}>
        <h3>Series Config</h3>
        <IconButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>

      <ConfigSection
        title="Message Path"
        controls={<MessagePathHelp messagePath={plotSeries.data.messagePath} />}
      >
        <MessagePath
          messagePath={plotSeries.data.messagePath}
          onChange={onMessagePathUpdate}
        />
      </ConfigSection>
    </Box>
  );
}
