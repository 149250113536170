import { State } from "../schema";
import { Layout, isLayoutItem, LayoutItem } from "../schema/v1";

import { updateLayout } from "./updateLayout";

export function setIsLayoutResizing(
  state: State,
  layouts: (Layout | LayoutItem)[],
  isResizing: boolean,
): State {
  let nextState = state;

  for (const layout of layouts) {
    if (isLayoutItem(layout)) {
      nextState = {
        ...nextState,
        layout: updateLayout(nextState.layout, layout.id, { isResizing }),
      };
    } else {
      // Recursively sets all the children layoutItem's isResizing field
      nextState = setIsLayoutResizing(nextState, layout.children, isResizing);
    }
  }

  return nextState;
}
