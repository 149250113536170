import {
  type MessagePathPart,
  type MessagePathSlice,
  messagePathPartIsMessagePathAttr,
} from "@/state/visualization";

export function formatSlice(slice: MessagePathSlice): string {
  const end = slice.end ?? Infinity;
  if (end - slice.start === 1) {
    return `${slice.start}`;
  }
  const formattedEnd = end === Infinity ? "" : end;
  return `${slice.start}:${formattedEnd}`;
}

export function formatMessagePath(parts: MessagePathPart[]): string {
  return parts.reduce((accum, part) => {
    if (messagePathPartIsMessagePathAttr(part)) {
      return `${accum}${accum.length > 0 ? "." : ""}${part.attribute}`;
    }
    return `${accum}[${formatSlice(part)}]`;
  }, "");
}
