import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";

import { AlertDialog, RobotoIconButton, RobotoTooltip } from "@/components";
import { ActionRecord } from "@/domain/actions";
import { OrgRecord } from "@/domain/orgs";
import { useLazyAPICall } from "@/service/apiHooks";
import { actionEndpoint } from "@/types";

interface DeleteActionIconButtonProps {
  action: ActionRecord;
  onDelete: (action: ActionRecord) => void;
  org: OrgRecord;
}

/**
 * A trashcan icon button that deletes an action after confirmation when clicked.
 */
export default function DeleteActionIconButton({
  action,
  onDelete,
  org,
}: DeleteActionIconButtonProps) {
  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);

  const { initiateRequest: deleteActionRequest } = useLazyAPICall();

  const dialogAction = React.useCallback(
    async function deleteAction() {
      const { error } = await deleteActionRequest({
        endpoint: actionEndpoint,
        method: "DELETE",
        orgId: org?.org_id,
        pathParams: { name: action.name },
      });

      if (!error) {
        onDelete(action);
      }
    },
    [action, deleteActionRequest, onDelete, org?.org_id],
  );

  return (
    <>
      <RobotoTooltip title="Delete Action">
        <span>
          <RobotoIconButton
            eventName={"DeleteActionClicked"}
            eventProperties={{
              name: action.name,
            }}
            onClick={() => {
              setAlertDialogOpen(true);
            }}
          >
            <DeleteIcon color="secondary" />
          </RobotoIconButton>
        </span>
      </RobotoTooltip>
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle="Delete Action"
        dialogText={`Are you sure you want to delete ${action.name}?`}
        dialogAction={dialogAction}
      />
    </>
  );
}
