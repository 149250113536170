import { Box, Popover, useTheme } from "@mui/material";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { SelectionButton } from "@/components";
import { FilesTable } from "@/components/SearchResultViews";
import { FileRecord } from "@/domain/files";
import { AddToCollection } from "@/features/collections/components";
import { useAuth } from "@/providers";
import { FileNode } from "@/types";

import { FileGrid } from "./FileGrid";
import ResultsViewToggle from "./ResultsViewToggle";
import { ResultsView } from "./types";

const noop = () => {};

export interface FileResultsProps {
  loading: boolean;
  files?: FileRecord[];
  extraHeight: number;
}

export const FileResults: React.FC<FileResultsProps> = ({
  loading,
  files,
  extraHeight,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFiles, setSelectedFiles] = React.useState<FileNode[]>([]);

  const view = (searchParams.get("view") as ResultsView) || ResultsView.Table;

  const handleViewChange = (newView: ResultsView) => {
    searchParams.set("view", newView);
    setSearchParams(searchParams);
  };

  const maxTableHeight = `calc(100vh - ${
    theme.navTopBarHeight
  } - 56px - 35px - ${parseInt(theme.spacing(3))}px) - 49px - ${extraHeight}px - 36px - ${parseInt(theme.spacing(2))}px`;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleCollectionPopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    overrideAnchorEl?: HTMLElement | null,
  ) => {
    if (overrideAnchorEl) {
      setAnchorEl(overrideAnchorEl);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCollectionPopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          mt: theme.spacing(2),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#FFF",
        }}
      >
        <ResultsViewToggle view={view} setView={handleViewChange} />
        <SelectionButton
          selectedItems={selectedFiles}
          handleDeselectAll={() => {
            setSelectedFiles([]);
          }}
          currentOrganization={currentOrganization}
          handleCollectionPopoverOpen={handleCollectionPopoverOpen}
        />
      </Box>
      {view === ResultsView.Table && (
        <FilesTable
          files={files || []}
          loading={loading}
          // TODO: Enable selectedRows and setSelectedRows in the table by
          // aligning how the SelectionButton and RobotoDataGrid work.
          selectedRows={undefined}
          setSelectedRows={noop}
          disableSelection={true}
          onRowSingleClick={noop}
          containerStyle={{
            transition: "max-width 0.3s ease-in-out",
            maxHeight: maxTableHeight,
          }}
        />
      )}
      {view === ResultsView.Grid && (
        <Box mt={theme.spacing(2)}>
          <FileGrid
            files={files}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </Box>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCollectionPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        marginThreshold={5}
      >
        <AddToCollection
          selectedFiles={selectedFiles}
          close={handleCollectionPopoverClose}
        />
      </Popover>
    </>
  );
};
