export {
  isInitCommand,
  isSetStateCommand,
  LogCommand,
  LogCommandResponse,
  type LogCommandPayloadMap,
  type LogCommandResponsePayloadMap,
} from "./commands";

export {
  isAllLogsLoadedEvent,
  isErrorEvent,
  isInitializedEvent,
  LogEvent,
  type LogEventPayloadMap,
} from "./events";

export const SOURCE = "roboto-log-panel";
