import { Skeleton, TableCell, TableRow, Theme, useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { nanoSecToRosTimeStamp } from "@/utils/time";

import { Log, Severity } from "../log";

import { ColumnDefinition } from "./columnDefinition";
import styles from "./Row.module.css";

function toDisplayable(value: unknown): string {
  switch (typeof value) {
    case "undefined":
      return "Not set";
    case "string":
      return value;
    case "number":
      return value.toString();
    case "boolean":
      return value.toString();
    case "object":
      if (value === null) {
        return "null";
      }
      return JSON.stringify(value);
    default:
      return "Unknown";
  }
}

function severityToColor(severity: Severity, theme: Theme): string {
  switch (severity) {
    case Severity.Emergency:
    case Severity.Alert:
    case Severity.Critical:
    case Severity.Error:
      return theme.palette.tableRowBackground.error.main;
    case Severity.Warning:
      return theme.palette.tableRowBackground.warning.main;
    default:
      return "inherit";
  }
}

interface Column extends ColumnDefinition {
  isLoading: boolean;
}

interface TableRowProps {
  columns: Column[];
  dataIndex: number;
  log: Log | undefined;
  onClick: (time: bigint) => void;
  selected: boolean;
}

export const Row = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  function Row(props: TableRowProps, ref) {
    const { columns, log, onClick, selected, dataIndex } = props;

    const theme = useTheme();

    if (log === undefined) {
      return (
        <TableRow key={"loading-row"}>
          <TableCell className={classNames(styles.cell, styles.fixedWidth)}>
            <Skeleton height={20} />
          </TableCell>
          {columns.map((column) => {
            return (
              <TableCell
                key={column.messagePathId}
                className={classNames(styles.cell, styles.flexibleWidth)}
              >
                <Skeleton height={20} />
              </TableCell>
            );
          })}
        </TableRow>
      );
    }

    if (columns.some((column) => column.isLoading)) {
      return (
        <TableRow
          key={"loading-column"}
          sx={{
            backgroundColor: log.severity
              ? severityToColor(log.severity, theme)
              : "inherit",
          }}
        >
          <TableCell className={classNames(styles.cell, styles.fixedWidth)}>
            {nanoSecToRosTimeStamp(log.time)}
          </TableCell>
          {columns.map((column) => {
            return (
              <TableCell
                key={column.messagePathId}
                className={classNames(styles.cell, styles.flexibleWidth)}
              >
                {column.isLoading ? (
                  <Skeleton height={20} />
                ) : (
                  toDisplayable(log.data[column.messagePathId])
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }

    return (
      <TableRow
        hover
        key={dataIndex}
        selected={selected}
        onClick={() => {
          onClick(log.time);
        }}
        ref={ref}
        data-index={dataIndex}
        sx={{
          backgroundColor: log.severity
            ? severityToColor(log.severity, theme)
            : "inherit",
        }}
      >
        <TableCell className={classNames(styles.cell, styles.fixedWidth)}>
          {nanoSecToRosTimeStamp(log.time)}
        </TableCell>
        {columns.map((column) => {
          return (
            <TableCell
              key={column.messagePathId}
              className={classNames(styles.cell, styles.flexibleWidth)}
            >
              {toDisplayable(log.data[column.messagePathId])}
            </TableCell>
          );
        })}
      </TableRow>
    );
  },
);
