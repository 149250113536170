import { Message } from "@/mcap";

export const getNearestPoint = (
  messages: Message<Record<string, unknown>>[],
  currentTime: bigint,
): number => {
  if (messages.length === 0) {
    return 0;
  }

  let left = 0;
  let right = messages.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const timestampAsBigint = messages[mid].logTime;

    if (timestampAsBigint === currentTime) {
      return mid;
    } else if (timestampAsBigint < currentTime) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }
  return Math.max(0, left - 1);
};
