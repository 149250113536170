import { useQuery } from "@tanstack/react-query";

import { MessagePathRecord } from "@/domain/topics";
import { useDomainServices } from "@/providers/DomainServices";

export function useMessagePathById(messagePathId: string | undefined) {
  const { topics } = useDomainServices();

  return useQuery<MessagePathRecord, Error>({
    queryKey: ["messagePathRecordById", messagePathId],
    queryFn: ({ signal }) => {
      if (messagePathId === undefined) {
        return Promise.reject(new Error("Invalid messagePathId"));
      }

      return topics.getMessagePathById(messagePathId, { abortSignal: signal });
    },
    enabled: messagePathId !== undefined,

    // 1 minute until considered stale
    staleTime: 60000,
  });
}
