import { useCallback, useEffect, useRef, useState } from "react";

import { RobotoAPICall, APIServiceError, CallState } from "@/types";

import { initiateRequestFunc } from "./helper";

interface UpdatedCallParams {
  queryParams?: URLSearchParams;
  pathParams?: Record<string, string | number>;
  requestBody?: BodyInit;
  orgId?: string;
}

interface IUseAPIQuery<ResponseData> {
  loading: boolean;
  error: APIServiceError | null;
  data: ResponseData | null;
  refetch: (updatedParams?: UpdatedCallParams) => void;
  updateCache: (newData: ResponseData) => void;
}

export const useAPICall = <ResponseData>(
  apiCall: RobotoAPICall,
): IUseAPIQuery<ResponseData> => {
  //
  const [callState, setCallState] = useState<CallState<ResponseData>>({
    loading: false,
    error: null,
    data: null,
  });

  // keep track of whether the component is still mounted
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    void initiateRequestFunc<ResponseData>(apiCall, setCallState, isMountedRef);

    return () => {
      isMountedRef.current = false;
    };

    // Th original API call should only be called once. If the apiCall is dynamic, this hook probably shouldn't be used. Use useLazyAPICall instead.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetch = useCallback((updatedParams?: UpdatedCallParams) => {
    if (updatedParams) {
      const updatedApiCall: RobotoAPICall = {
        ...apiCall,
        ...(updatedParams.queryParams !== undefined && {
          queryParams: updatedParams.queryParams,
        }),
        ...(updatedParams.pathParams !== undefined && {
          pathParams: updatedParams.pathParams,
        }),
        ...(updatedParams.requestBody !== undefined && {
          requestBody: updatedParams.requestBody,
        }),
        ...(updatedParams.orgId !== undefined && {
          orgId: updatedParams.orgId,
        }),
      };

      void initiateRequestFunc<ResponseData>(
        updatedApiCall,
        setCallState,
        isMountedRef,
      );
    } else {
      void initiateRequestFunc<ResponseData>(
        apiCall,
        setCallState,
        isMountedRef,
      );
    }

    // The original API call should only be called once. If the apiCall changes, pass the new apiCall to the refetch function instead.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCache = useCallback((newData: ResponseData) => {
    setCallState((prevState) => {
      return {
        ...prevState,
        data: newData,
      };
    });
  }, []);

  return {
    loading: callState.loading,
    error: callState.error,
    data: callState.data,
    refetch,
    updateCache,
  };
};
