import { HttpClient, robotoHeaders } from "../../http";
import { Token } from "../tokens";

import { type Device } from "./Device";

interface Options {
  abortSignal: AbortSignal;
  resourceOwnerId: string;
  searchParams: URLSearchParams;
}

export class DeviceService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getDevice(
    deviceId: string,
    options?: Partial<Options>,
  ): Promise<Device> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/devices/id/${deviceId}`,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response.json<Device>();
  }

  public async getTokensForDevice(
    deviceId: string,
    options?: Partial<Options>,
  ): Promise<Token[]> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/devices/id/${deviceId}/tokens`,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response.json<Token[]>();
  }
}
