import { Box, Paper, useTheme } from "@mui/material";
import * as React from "react";

import { SidebarLayoutContext } from "./SidebarLayoutContext";

interface MainLayoutWithSidebarProps extends React.PropsWithChildren {
  sidebarContent: React.ReactElement | null;
  sidebarWidth?: string;
  sidebarControlOverrides?: {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
  };
}

export const MainLayoutWithSidebar: React.FC<MainLayoutWithSidebarProps> = ({
  children,
  sidebarContent,
  sidebarControlOverrides,
  sidebarWidth = "244px",
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [shouldAnimate, setShouldAnimate] = React.useState<boolean>(false);

  const canonicalIsOpen =
    sidebarControlOverrides === undefined
      ? isOpen
      : sidebarControlOverrides.isOpen;
  const canonicalSetIsOpen =
    sidebarControlOverrides === undefined
      ? setIsOpen
      : sidebarControlOverrides.setIsOpen;

  const toggleOpen = () => {
    canonicalSetIsOpen(!canonicalIsOpen);
  };

  const theme = useTheme();

  // Turn the animation on AFTER the display is set to block
  React.useEffect(() => {
    if (canonicalIsOpen) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  }, [canonicalIsOpen]);

  const topMargin =
    parseInt(theme.navTopBarHeight) + parseInt(theme.spacing(3));
  const sidebarHeight = `calc(100vh - ${topMargin}px - ${theme.spacing(2)})`;

  return (
    <SidebarLayoutContext.Provider
      value={{
        isOpen: canonicalIsOpen,
        toggleOpen: toggleOpen,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifySelf: "stretch",
        }}
      >
        <Box
          sx={{
            flex: 1,
            maxWidth: {
              xs: canonicalIsOpen
                ? `calc(100vw - ${sidebarWidth})`
                : `calc(100vw)`,
              sm: canonicalIsOpen
                ? `calc(100vw - ${sidebarWidth} - ${theme.navSideBarWidth})`
                : `calc(100vw - ${theme.navSideBarWidth})`,
            },
            transition: "max-width 0.3s ease-in-out",
          }}
        >
          {children}
        </Box>
        <Box
          component={Paper}
          elevation={1}
          sx={{
            zIndex: theme.zLayer(3),
            marginTop: `${topMargin}px`,
            width: sidebarWidth,
            maxWidth: sidebarWidth,
            overflowX: "hidden",
            overflowY: "auto",
            transform: shouldAnimate ? "translateX(0)" : "translateX(100%)",
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            position: "absolute",
            right: 0,
            top: 0,
            height: sidebarHeight,
            backgroundColor: theme.palette.foreground.main,
            p: theme.spacing(2),
            borderTopLeftRadius: theme.border.radius,
            borderBottomLeftRadius: theme.border.radius,
          }}
        >
          {sidebarContent}
        </Box>
      </Box>
    </SidebarLayoutContext.Provider>
  );
};
