import { Box, Dialog, DialogTitle, TextField, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoButton } from "@/components";
import { useRenameDirectory } from "@/domain/files/hooks/useRenameDirectoryMutation";
import { useRenameFile } from "@/domain/files/hooks/useRenameFileMutation";
import { FileSystemNode, isDirectory, isFile } from "@/types";

import styles from "./RenameNode.module.css";

interface RenameNodeProps {
  node: FileSystemNode;
  handleClose: () => void;
  open: boolean;
}

export const RenameNode: React.FC<RenameNodeProps> = ({
  node,
  handleClose,
  open,
}) => {
  const [newName, setNewName] = React.useState(node.name);

  const renameFileMutation = useRenameFile();
  const renameDirectoryMutation = useRenameDirectory();

  const theme = useTheme();

  const handleSave = () => {
    if (isFile(node)) {
      renameFileMutation
        .mutateAsync({
          fileId: node.file.file_id,
          datasetId: node.file.association_id,
          currentPath: node.file.relative_path,
          newName,
        })
        .then(handleClose)
        .catch(() => {
          //do nothing, error logged in hook
        });
    } else if (isDirectory(node)) {
      renameDirectoryMutation
        .mutateAsync({
          datasetId: node.directory.association_id,
          currentPath: node.directory.relative_path,
          newName,
        })
        .then(handleClose)
        .catch(() => {
          //do nothing, error logged in hook
        });
    }
  };

  const mutationLoading =
    renameFileMutation.isPending || renameDirectoryMutation.isPending;

  const errorMsg =
    renameFileMutation.error?.message || renameDirectoryMutation.error?.message;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          padding: 0,
          paddingLeft: theme.spacing(2),
          paddingTop: theme.spacing(2),
        }}
      >
        Rename {node.type === "file" ? "File" : "Directory"}
      </DialogTitle>
      <Box
        sx={{
          padding: theme.spacing(2),
          minWidth: "500px",
        }}
      >
        <TextField
          error={renameFileMutation.isError}
          helperText={
            renameFileMutation.isError || renameDirectoryMutation.isError
              ? errorMsg
              : undefined
          }
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          size={"small"}
          sx={{ marginBottom: theme.spacing(2), width: "100%" }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <RobotoButton
            eventName={"CancelSaveFileNodeClicked"}
            eventProperties={{
              name: node.name,
            }}
            onClick={() => {
              handleClose();
              setNewName(node.name);
            }}
            variant="outlined"
            className={styles.cancelNodeBtn}
            disabled={mutationLoading}
          >
            Cancel
          </RobotoButton>
          <RobotoButton
            loading={mutationLoading}
            eventName={"SaveFileNodeClicked"}
            eventProperties={{
              name: newName,
            }}
            onClick={handleSave}
            variant={mutationLoading ? "outlined" : "contained"}
            className={styles.saveNodeBtn}
          >
            Save
          </RobotoButton>
        </Box>
      </Box>
    </Dialog>
  );
};
