import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Alert, AlertTitle } from "@mui/material";
import classNames from "classnames";

import { Expandable } from "../Expandable";
import { Label } from "../Label";

export interface SelectableFile {
  fileId: string;
  relativePath?: string;
  version?: number;
}

interface FileSelectorProps {
  className?: classNames.Argument;
  files: SelectableFile[];
  onFileSelected: (file: SelectableFile) => void;
}

/**
 * List of files parsed from encoded VizConfig.
 * On file selection, the Sidebar will switch its content to show Topics extracted from the selected file.
 */
export function FileSelector(props: FileSelectorProps) {
  return (
    <>
      <div className={classNames(props.className)}>
        {props.files.map((file) => (
          <Expandable<SelectableFile>
            key={file.fileId}
            data={file}
            EndIcon={ChevronRightIcon}
            onClick={props.onFileSelected}
          >
            <Label title={file.relativePath}>{file.relativePath}</Label>
          </Expandable>
        ))}
        <Alert
          severity="warning"
          sx={{
            display: props.files.length === 0 ? "flex" : "none",
          }}
        >
          <AlertTitle>No files</AlertTitle>
          No files were loaded into the visualizer, so there aren&apos;t any
          topics to choose from.
        </Alert>
      </div>
    </>
  );
}
