import { useQuery } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";

import { type TriggerRecord } from "../TriggerRecord";

export function useTriggerById(triggerId: string) {
  const { triggerService } = useDomainServices();

  return useQuery<TriggerRecord, Error>({
    queryKey: ["triggerRecord", triggerId],
    queryFn: async ({ signal }) => {
      return await triggerService.getTriggerById(triggerId, {
        abortSignal: signal,
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
}
