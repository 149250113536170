import { useQuery } from "@tanstack/react-query";

import { type TriggerEvaluationRecord } from "@/domain/actions";
import { useDomainServices } from "@/providers/DomainServices";

interface useTriggerEvaluationsProps {
  filter: {
    datasetId?: string;
    triggerName?: string;
  };
}

export function useTriggerEvaluations({
  filter: { datasetId, triggerName },
}: useTriggerEvaluationsProps) {
  const { triggerService } = useDomainServices();

  return useQuery<TriggerEvaluationRecord[], Error>({
    queryKey: ["triggerEvaluations", datasetId, triggerName],
    queryFn({ signal }) {
      if (datasetId !== undefined && triggerName !== undefined) {
        throw new Error("Cannot specify both datasetId and triggerName");
      }
      if (datasetId !== undefined) {
        return triggerService.getTriggerEvaluationsForDataset(datasetId, {
          abortSignal: signal,
        });
      }
      if (triggerName !== undefined) {
        return triggerService.getTriggerEvaluationsForTrigger(triggerName, {
          abortSignal: signal,
        });
      }
      return Promise.resolve([]);
    },
  });
}
