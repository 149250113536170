import { Alert, AlertProps, AlertTitle, Link } from "@mui/material";
import * as React from "react";

interface NoFilesUploadedAlertProps extends AlertProps {
  datasetId: string;
}

export const NoFilesUploadedAlert: React.FC<NoFilesUploadedAlertProps> = ({
  datasetId,
  ...props
}) => {
  return (
    <Alert
      severity="info"
      style={{
        border: "1px dotted #C3CAD9",
      }}
      {...props}
    >
      <AlertTitle>Drag in files to upload</AlertTitle>
      You can drag them in here, or use the{" "}
      <Link
        underline="hover"
        target="_blank"
        href="https://docs.roboto.ai/getting-started/programmatic-access.html#python-sdk-and-cli"
      >
        Roboto CLI
      </Link>
      :
      <br />
      <br />
      <span
        style={{
          fontFamily: "monospace",
          fontSize: "0.8rem",
        }}
      >
        $ roboto datasets upload-files -d {datasetId} -p {"<path/to/files>"}
      </span>
    </Alert>
  );
};
