import { DirectoryRecord, FileRecord } from "@/domain/files";

export interface FileSystemNode {
  name: string;
  type: "file" | "directory";
}

export function isDirectory(node: FileSystemNode): node is DirectoryNode {
  return node.type === "directory";
}

export function isFile(node: FileSystemNode): node is FileNode {
  return node.type === "file";
}

export interface FileNode extends FileSystemNode {
  type: "file";
  file: FileRecord;
}

export interface DirectoryNode extends FileSystemNode {
  type: "directory";
  directory: DirectoryRecord;
}
