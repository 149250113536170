import { useMutation, UseMutationResult } from "@tanstack/react-query";
import * as React from "react";

import { FileRecord } from "@/domain/files";
import { DomainServicesContext } from "@/providers/DomainServices";

type DeleteFileMutationArgs = {
  file: FileRecord;
};

export function useDeleteFileMutation(): UseMutationResult<
  void,
  unknown,
  DeleteFileMutationArgs
> {
  const { files } = React.useContext(DomainServicesContext);
  return useMutation<void, unknown, DeleteFileMutationArgs>({
    mutationFn: async ({ file }) => {
      await files.deleteFile(file.file_id);
    },
  });
}
