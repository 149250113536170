import AndroidIcon from "@mui/icons-material/Android";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PersonIcon from "@mui/icons-material/Person";
import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { FileRecord } from "@/domain/files";

import { DeviceLink } from "../DeviceLink";
import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";
import { UserLink } from "../UserLink";

interface FileSourceProps {
  fileRecord: FileRecord;
}

export const FileSource: React.FC<FileSourceProps> = ({ fileRecord }) => {
  const theme = useTheme();
  let sourceComponent: JSX.Element | string = fileRecord.origination;
  const split = fileRecord.origination.split(" ");
  if (split && split[1] === "invocation:") {
    // Indicates this is created by an invocation
    const invocationId = split[2];
    sourceComponent = (
      <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
        <AutoAwesomeIcon fontSize="small" color="primary" />
        <RobotoLinkHoverUnderline
          variant="body2"
          to={`/invocations/${invocationId}`}
        >
          {invocationId}
        </RobotoLinkHoverUnderline>
      </Box>
    );
  } else if (fileRecord.device_id) {
    // Indicates this is created by a device
    sourceComponent = (
      <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
        <AndroidIcon fontSize="small" color="primary" />
        <DeviceLink deviceId={fileRecord.device_id} />
      </Box>
    );
  } else if (fileRecord) {
    sourceComponent = (
      <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
        <PersonIcon fontSize="small" color="primary" />
        <UserLink userId={fileRecord.created_by} />
      </Box>
    );
  }

  return sourceComponent;
};
