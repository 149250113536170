import * as React from "react";

import { useDomainServices } from "../DomainServices";

import {
  OnUploadItemBegin,
  OnUploadItemBeginCancellation,
  OnUploadItemCancelled,
  OnUploadItemComplete,
  OnUploadItemError,
  OnUploadItemFirstBytes,
  OnUploadItemProgress,
  UploadItem,
} from "./types";
import { UploadCoordinator } from "./UploadCoordinator";

export const useUploadCoordinator = (
  onUploadItemBegin: OnUploadItemBegin,
  onUploadItemBeginCancellation: OnUploadItemBeginCancellation,
  onUploadItemCancelled: OnUploadItemCancelled,
  onUploadItemProgress: OnUploadItemProgress,
  onUploadItemComplete: OnUploadItemComplete,
  onUploadItemError: OnUploadItemError,
  onUploadItemFirstBytes: OnUploadItemFirstBytes,
) => {
  const uploadCoordinatorRef = React.useRef<UploadCoordinator | null>(null);

  const { files } = useDomainServices();

  const coordinateUploadItems = async (items: UploadItem[]) => {
    await uploadCoordinatorRef.current?.uploadItems(items);
  };

  const cancelInProgressUploads = async () => {
    await uploadCoordinatorRef.current?.cancelInProgressUploads();
  };

  React.useEffect(() => {
    if (!uploadCoordinatorRef.current) {
      uploadCoordinatorRef.current = new UploadCoordinator(
        files,
        onUploadItemBegin,
        onUploadItemBeginCancellation,
        onUploadItemCancelled,
        onUploadItemComplete,
        onUploadItemError,
        onUploadItemFirstBytes,
        onUploadItemProgress,
      );
    } else {
      // Ensure the latest callbacks are always passed to the coordinator
      uploadCoordinatorRef.current.fileService = files;
      uploadCoordinatorRef.current.onUploadItemBegin = onUploadItemBegin;
      uploadCoordinatorRef.current.onUploadItemBeginCancellation =
        onUploadItemBeginCancellation;
      uploadCoordinatorRef.current.onUploadItemCancelled =
        onUploadItemCancelled;
      uploadCoordinatorRef.current.onUploadItemProgress = onUploadItemProgress;
      uploadCoordinatorRef.current.onUploadItemComplete = onUploadItemComplete;
      uploadCoordinatorRef.current.onUploadItemError = onUploadItemError;
      uploadCoordinatorRef.current.onUploadItemFirstBytes =
        onUploadItemFirstBytes;
    }
  }, [
    files,
    onUploadItemBegin,
    onUploadItemBeginCancellation,
    onUploadItemCancelled,
    onUploadItemProgress,
    onUploadItemComplete,
    onUploadItemError,
    onUploadItemFirstBytes,
  ]);

  return {
    cancelInProgressUploads,
    coordinateUploadItems,
  };
};
