import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import IconButton from "@mui/material/IconButton";

interface SelectRegionToolProps {
  selected?: boolean;
  onClick: () => void;
}

export function SelectRegionTool({
  onClick,
  selected = false,
}: SelectRegionToolProps) {
  return (
    <IconButton
      aria-label="select-region"
      onClick={onClick}
      size="small"
      title="Select a region"
    >
      <HighlightAltIcon
        color={selected ? "primary" : "inherit"}
        fontSize="small"
      />
    </IconButton>
  );
}
