import { Alert, AlertTitle } from "@mui/material";

export function NoSelectedPanelMessage() {
  return (
    <div>
      <Alert severity="info">
        <AlertTitle>No panel selected</AlertTitle>
        Click the settings icon on a panel
      </Alert>
    </div>
  );
}
