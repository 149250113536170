import { HttpClient, robotoHeaders } from "../../http";

import { type Token } from "./Token";

interface Options {
  abortSignal: AbortSignal;
  resourceOwnerId: string;
  searchParams: URLSearchParams;
}

export class TokenService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getToken(
    tokenId: string,
    options?: Partial<Options>,
  ): Promise<Token> {
    const requestUrl = this.#httpClient.constructUrl(`v1/tokens/id/${tokenId}`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response.json<Token>();
  }

  public async listTokens(options?: Partial<Options>): Promise<Token[]> {
    const requestUrl = this.#httpClient.constructUrl(`v1/tokens`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response.json<Token[]>();
  }

  public async createToken(
    expiry_days: number,
    name: string,
    description?: string,
    options?: Partial<Options>,
  ): Promise<Token> {
    const requestUrl = this.#httpClient.constructUrl(`v1/tokens`);

    const body = {
      expiry_days: expiry_days,
      name: name,
      description: description,
    };

    const response = await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(body),
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response.json<Token>();
  }

  public async deleteToken(
    tokenId: string,
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(`v1/tokens/id/${tokenId}`);

    await this.#httpClient.delete(requestUrl, {
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return;
  }

  public async enableToken(
    tokenId: string,
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/tokens/id/${tokenId}/enable`,
    );

    await this.#httpClient.post(requestUrl, {
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return;
  }

  public async disableToken(
    tokenId: string,
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/tokens/id/${tokenId}/disable`,
    );

    await this.#httpClient.post(requestUrl, {
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return;
  }
}
