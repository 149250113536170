import { getFileUploadCredentials } from "./getFileUploadCredentials";
import {
  beginManifestTransaction,
  completeManifestFileUpload,
  uploadManifestFile,
} from "./helpers";
import {
  MAX_FILES_PER_MANIFEST,
  OnUploadItemProgress,
  OnUploadItemFirstBytes,
} from "./types";
import { UploadableFile } from "./UploadableFile";

export interface ISequentialFileUploader {
  /** The ID of the upload item being processed */
  uploadItemId: string;

  /** Callback for tracking file upload progress */
  onFileProgress: OnUploadItemProgress;

  /** Callback triggered when the first bytes of the first file in a batch are uploaded */
  onUploadItemFirstBytes: OnUploadItemFirstBytes;

  /**
   * Cancels the ongoing upload.
   * Returns a promise that resolves when cancellation is complete.
   */
  cancelUpload(): Promise<void>;

  /**
   * Uploads a batch of files.
   * @param isFirstBatch - Indicates whether this is the first batch of the upload.
   * @param batch - The files to upload in this batch.
   * @param datasetId - The dataset ID associated with the files.
   * @param orgId - The organization ID associated with the upload.
   * @param prefix - Optional prefix for file paths.
   */
  uploadFileBatch(
    isFirstBatch: boolean,
    batch: UploadableFile[],
    datasetId: string,
    orgId: string,
    prefix?: string,
  ): Promise<void>;
}

export class SequentialFileUploader implements ISequentialFileUploader {
  /*
  This class uploads a batch of files, no more than 500 at a time.

  At the moment, it leverages APIService to make the HTTP calls. At some point, this should be refactored to make use 
  of the HttpClient that the rest of the app uses.

  This class also makes use of some update callbacks to update users of the class 
  on progress of an upload.

  For further optimization, this class could be refactored to make use of some sort of global 
  file pooling, ensuring that the pool is fully utilized and no more than X uploads are 
  currently in progress.
  */

  uploadItemId: string;

  private abortController: AbortController;
  private cancelPromise: Promise<void> | null = null;
  private hasStarted = false;
  private onTransactionCreated: (transactionId: string) => void;
  private resolveCancel: (() => void) | null = null;

  private _onFileProgress: OnUploadItemProgress;
  private _onUploadItemFirstBytes: OnUploadItemFirstBytes;

  constructor(
    uploadItemId: string,
    onFileProgress: OnUploadItemProgress,
    onTransactionCreated: (transactionId: string) => void,
    onUploadItemFirstBytes: OnUploadItemFirstBytes,
  ) {
    this.uploadItemId = uploadItemId;
    this._onFileProgress = onFileProgress;
    this.onTransactionCreated = onTransactionCreated;
    this._onUploadItemFirstBytes = onUploadItemFirstBytes;
    this.abortController = new AbortController();
  }

  set onFileProgress(fnc: OnUploadItemProgress) {
    this._onFileProgress = fnc;
  }

  get onFileProgress() {
    return this._onFileProgress;
  }

  set onUploadItemFirstBytes(fnc: OnUploadItemFirstBytes) {
    this._onUploadItemFirstBytes = fnc;
  }

  get onUploadItemFirstBytes() {
    return this._onUploadItemFirstBytes;
  }

  cancelUpload(): Promise<void> {
    if (!this.cancelPromise) {
      this.abortController.abort(); // Signal the cancellation
      if (!this.hasStarted) {
        return Promise.resolve();
      }
      this.cancelPromise = new Promise((resolve) => {
        this.resolveCancel = resolve;
      });
    }
    return this.cancelPromise;
  }

  async uploadFileBatch(
    isFirstBatch: boolean,
    batch: UploadableFile[],
    datasetId: string,
    orgId: string,
    prefix?: string,
  ): Promise<void> {
    this.hasStarted = true;

    if (batch.length > MAX_FILES_PER_MANIFEST) {
      throw new Error(
        `SequentialFileUploader cannot upload more than ${MAX_FILES_PER_MANIFEST} files per manifest`,
      );
    }

    const inProgressUploadItems = new Set<string>();

    const resourceManifest = Object.fromEntries(
      batch.map(({ relativePath, size, uploadItemKey }) => {
        let computedPath = relativePath;

        inProgressUploadItems.add(uploadItemKey);

        if (prefix) {
          computedPath = `${prefix}/${relativePath}`;
        }

        return [computedPath, size];
      }),
    );

    if (this.abortController.signal.aborted) {
      this.resolveCancel?.();
      return;
    }

    const {
      transaction_id,
      uploadMappings,
      error: beginTxnError,
    } = await beginManifestTransaction(datasetId, resourceManifest, orgId);

    if (transaction_id) {
      this.onTransactionCreated(transaction_id);
    }

    if (this.abortController.signal.aborted) {
      this.resolveCancel?.();
      return;
    }

    if (
      transaction_id === null ||
      uploadMappings === null ||
      beginTxnError !== null
    ) {
      const msg = beginTxnError
        ? beginTxnError.message
        : "System Error. Please refresh the page or try again later.";
      throw new Error(msg, {
        cause: beginTxnError ?? undefined,
      });
    }

    const { response: credentials, error } = await getFileUploadCredentials(
      datasetId,
      orgId,
      transaction_id,
    );

    if (this.abortController.signal.aborted) {
      this.resolveCancel?.();
      return;
    }

    if (!credentials || error) {
      const msg = error
        ? error.message
        : "System Error. Please refresh the page or try again later.";
      throw new Error(msg, {
        cause: error ?? undefined,
      });
    }

    for (let i = 0; i < batch.length; i++) {
      const file = batch[i];

      let filePath = file.relativePath;

      if (prefix) {
        filePath = `${prefix}/${file.relativePath}`;
      }

      if (i === 0 && isFirstBatch) {
        this.onUploadItemFirstBytes(this.uploadItemId);
      }

      if (this.abortController.signal.aborted) {
        this.resolveCancel?.();
        return;
      }

      await uploadManifestFile(
        file,
        uploadMappings[filePath],
        datasetId,
        orgId,
        (bytesUploaded: number) => {
          this.onFileProgress(this.uploadItemId, bytesUploaded);
        },
        credentials,
        transaction_id,
        { signal: this.abortController.signal },
      );

      if (this.abortController.signal.aborted) {
        this.resolveCancel?.();
        return;
      }
    }

    await completeManifestFileUpload(datasetId, orgId, transaction_id);
  }
}
