import { Typography } from "@mui/material";
import classNames from "classnames";

import styles from "./MessagePathLabel.module.css";

export interface MessagePathLabelProps {
  className?: classNames.Argument;
  dataType: string;
  label: string;
}

/**
 * Renders a label for a message path.
 */
export function MessagePathLabel(props: MessagePathLabelProps) {
  const { className, dataType, label } = props;

  return (
    <div className={classNames(className, styles.labelContainer)}>
      <p className={classNames(styles.hideOverflow, styles.label)}>
        {label}
        <Typography
          component={"span"}
          className={classNames(styles.dataTypeLabel, styles.hideOverflow)}
          color="text.secondary"
        >
          {dataType}
        </Typography>
      </p>
    </div>
  );
}
