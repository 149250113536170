import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

// Note: The component here must be Pure HTML to work with the Resizable component library.
// MUI styled components don't work.
export const AdjustmentHandle = forwardRef<HTMLDivElement>((props, ref) => {
  // Note: handleAxis is a prop from the Resizable component library. Typing is not great in this library.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { handleAxis, ...restProps } = props;

  return (
    <div
      ref={ref}
      className={`foo handle-${handleAxis}`}
      style={{
        minWidth: "8px",
        height: "100%",
        top: 0,
        right: 0,
        width: "8px",
        cursor: "col-resize",
        borderRadius: "0 2px 2px 0",
        opacity: 0.5,
        transition: "opacity 0.2s",
      }}
      {...restProps}
    >
      <HoverHandle />
    </div>
  );
});

AdjustmentHandle.displayName = "AdjustmentHandle";

const HoverHandle = styled("div")(({ theme }) => ({
  minWidth: "5px",
  height: "100%",
  backgroundColor: theme.palette.primary.main,
  width: "5px",
  cursor: "col-resize",
  borderRadius: "0 2px 2px 0",
  opacity: 0.05,
  ":hover": {
    opacity: 1,
  },
  transition: "opacity 0.2s",
}));
