import { DragOverlay } from "@dnd-kit/core";
import { Box } from "@mui/material";

import { DragItem } from "../../DnD";
import { MessagePathLabel, MessagePathNode } from "../../TopicTree";

import { DragOverlayProps } from "./base";
import styles from "./DragOverlays.module.css";

interface MessagePathDragOverlayProps extends DragOverlayProps {}

export function MessagePathDragOverlay({
  active,
}: MessagePathDragOverlayProps) {
  const isMessagePath = active?.type === DragItem.MessagePath;
  const draggedMessagePath = active?.data as MessagePathNode;

  return (
    <DragOverlay className={styles.dragOverlay} dropAnimation={null}>
      {isMessagePath && (
        <Box bgcolor={(theme) => theme.palette.paper.main} padding="0.25em">
          <MessagePathLabel
            dataType={draggedMessagePath.data.data_type}
            label={draggedMessagePath.label}
          />
        </Box>
      )}
    </DragOverlay>
  );
}
