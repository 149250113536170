import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Box, IconButton } from "@mui/material";

interface SuccessIconProps {
  isHovered: boolean;
}

export const SuccessIcon: React.FC<SuccessIconProps> = ({ isHovered }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isHovered ? (
        <IconButton sx={{ padding: 0 }}>
          <FolderOpenIcon color="primary" />
        </IconButton>
      ) : (
        <CheckCircleIcon color="success" />
      )}
    </Box>
  );
};
