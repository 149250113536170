export function isUrl(text: string): boolean {
  /*
   * This simple url Regex matcher matches any non-whitespace characters after "http(s)://"
   * Matches:
   *    "https://www.roboto.ai"
   *    "https://www.roboto"
   *
   * Does not match:
   *    "https://"
   *    "htps:/roboto.ai"
   */
  const urlRegex = /^https?:\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(text);
}
