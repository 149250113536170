import { TableBody } from "@mui/material";
import * as React from "react";

import { TableState } from "@/types";

import { RobotoTableRow } from "./RobotoTableRow";

interface TableBodyProps {
  table: TableState;
  constantHeight: number;
  checkboxWidth: number;
  onRowCheckboxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
  ) => void;
  onRowSingleClick: (rowId: string) => void;
  onRowRightClick?: (
    rowId: string,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  isRowSelectable: boolean;
  isRowExpandable: boolean;
  selectedRows: Set<string>;
  activeRowId?: string;
  secondaryRowId?: string;
  expandableContent?: (rowId: string) => React.ReactNode;
}

export const RobotoTableBody: React.FC<TableBodyProps> = ({
  table,
  constantHeight,
  checkboxWidth,
  onRowCheckboxChange,
  onRowSingleClick,
  onRowRightClick,
  isRowSelectable,
  isRowExpandable,
  selectedRows,
  activeRowId,
  secondaryRowId,
  expandableContent,
}) => {
  return (
    <TableBody>
      {table.rowState.map((row, idx) => {
        return (
          <RobotoTableRow
            key={row.id}
            table={table}
            row={row}
            rowIdx={idx}
            constantHeight={constantHeight}
            checkboxWidth={checkboxWidth}
            onRowCheckboxChange={onRowCheckboxChange}
            onRowSingleClick={onRowSingleClick}
            onRowRightClick={onRowRightClick}
            isRowSelectable={isRowSelectable}
            isRowExpandable={isRowExpandable}
            selectedRows={selectedRows}
            activeRowId={activeRowId}
            secondaryRowId={secondaryRowId}
            expandableContent={expandableContent}
          />
        );
      })}
    </TableBody>
  );
};
