import { Alert, Grid } from "@mui/material";

import { Dimensions, useMeasure } from "@/hooks";
import { PanelType } from "@/state/visualization";

import { PanelOption } from "./PanelOption";
import styles from "./PanelOptionsGrid.module.css";

const panels = [
  PanelType.Image,
  PanelType.Log,
  PanelType.Map,
  PanelType.Plot,
  PanelType.RawMessage,
];

function useColumns(dimensions: Dimensions) {
  // Uses breakpoints different from the standard mui breakpoints. These were
  // calculated via trial and error from the content of the panel option text.
  if (dimensions.width < 500) {
    return 1;
  }
  if (dimensions.width < 800) {
    return 2;
  }
  if (dimensions.width < 1200) {
    return 3;
  }
  return 4;
}

export function PanelOptionsGrid() {
  const [measure, dims] = useMeasure();
  const columns = useColumns(dims);
  return (
    <div className={styles.container}>
      <Alert
        severity="info"
        sx={{
          userSelect: "none",
        }}
      >
        Select a panel below to add it to the workspace or drag in a topic from
        the sidebar.
      </Alert>

      <div className={styles.gridContainer}>
        <Grid
          container
          columns={columns}
          spacing={2}
          ref={(node) => {
            if (node !== null) {
              measure(node);
            }
          }}
        >
          {panels.map((panelType) => {
            return (
              <Grid
                item
                key={panelType}
                sx={{
                  width: "325px",
                }}
              >
                <PanelOption type={panelType} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}
