import { Box, Typography, useTheme, SxProps, Theme } from "@mui/material";

interface AuthCardTitleProps {
  title: string;
  sx?: SxProps<Theme>;
}

export const AuthCardTitle: React.FC<AuthCardTitleProps> = ({ title, sx }) => {
  const theme = useTheme();

  const extraStyles = sx ? sx : {};

  return (
    <Box sx={{ marginBottom: theme.spacing(4) }}>
      <Typography variant="h4" sx={extraStyles}>
        {title}
      </Typography>
    </Box>
  );
};
