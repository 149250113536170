export interface ErrorDetail {
  message: string;
  name?: string;
  cause?: string;
  stack?: string;
}

export function isErrorDetail(value: unknown): value is ErrorDetail {
  return (
    typeof value === "object" &&
    value !== null &&
    typeof (value as ErrorDetail).message === "string"
  );
}

export interface MessagePayload<T = unknown, D = unknown> {
  correlationId: string;
  data: D;
  error?: ErrorDetail;
  signalPort: MessagePort;
  source: string;
  type: T;
}

export function isMessageEvent<T = unknown, D = unknown>(
  event: Event,
): event is MessageEvent<MessagePayload<T, D>> {
  return "data" in event;
}

export interface CommandMessage<T = unknown, D = unknown>
  extends Omit<MessagePayload, "correlationId" | "signalPort"> {
  correlationId?: string;
  data: D;
  signal?: AbortSignal;
  transferables?: Transferable[];
  type: T;
}

export interface DetachPortCommand {
  data: "detach";
}

export function isDetachPortCommand(
  event: Event,
): event is MessageEvent<DetachPortCommand> {
  return "data" in event && event.data === "detach";
}
