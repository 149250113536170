import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import {
  Box,
  Checkbox,
  Skeleton,
  SvgIcon,
  styled,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoIconButton, RobotoTooltip } from "@/components";
import { Annotation, AnnotationViewFilters } from "@/components/Annotations";
import { RobotoErrorAlertDialog } from "@/components/RobotoErrorAlert";
import { useAuth, useNavigation } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import { actions, useVizDispatch } from "@/state/visualization";
import {
  APIServiceError,
  FileSystemNode,
  FileSignedUrlResponse,
  fileSignedUrlEndpoint,
  isFile,
} from "@/types";
import {
  basename,
  dirname,
  isVisibleMedia,
  isImage,
  isText,
  isVideo,
  isMarkdown,
  isWebRenderable,
} from "@/utils";

import { GridItem } from "./GridItem";
import { DatasetImageItem } from "./viewers/DatasetImageItem";
import { DatasetTextItem } from "./viewers/DatasetTextItem";
import { DatasetVideoItem } from "./viewers/DatasetVideoItem";

const CheckboxIcon = styled("span")(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const CheckedIcon = styled(CheckboxIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

interface DatasetGridItemProps {
  item: FileSystemNode;
  datasetId?: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  annotations: Annotation[];
  annotationViewFilters: AnnotationViewFilters;
  isChecked: boolean;
  handleSelectRows: (rows: FileSystemNode[], isSelected: boolean) => void;
}

export const DatasetGridItem: React.FC<DatasetGridItemProps> = ({
  item,
  datasetId,
  onClick,
  annotations,
  annotationViewFilters,
  isChecked,
  handleSelectRows,
}) => {
  const theme = useTheme();

  const { currentOrganization } = useAuth();
  const [signedUrl, setSignedUrl] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();

  const { initiateRequest: initiateSignedUrlRequest } =
    useLazyAPICall<FileSignedUrlResponse>();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [lastError, setLastError] = React.useState<APIServiceError | undefined>(
    undefined,
  );

  const navigateToFile = () => {
    if (isFile(item)) {
      const fileId = item.file.file_id;
      goto.file({
        fileId,
        beforeNavigation: () => vizDispatch(actions.putFiles([{ fileId }])),
      });
    }
  };

  React.useEffect(() => {
    const getSignedUrl = async (item: FileSystemNode) => {
      if (!isFile(item)) {
        return;
      }

      const file = item.file;

      const { data, error } = await initiateSignedUrlRequest({
        endpoint: fileSignedUrlEndpoint,
        method: "GET",
        orgId: currentOrganization?.org_id,
        queryParams: new URLSearchParams({ redirect: "false" }),
        pathParams: { fileId: file.file_id },
      });

      if (!error && data?.data) {
        setSignedUrl(data.data.url);
        return data.data.url;
      }

      if (error) {
        setAlertOpen(true);
        setLastError(error);
      }
    };

    setLoading(true);
    if (isVisibleMedia(item.name)) {
      void getSignedUrl(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, currentOrganization?.org_id]);

  const infoButton = React.useMemo(() => {
    return (
      isFile(item) && (
        <Box
          sx={{
            opacity: isHovering ? 1 : 0,
            position: "absolute",
            left: "4px",
            top: "4px",
            zIndex: 100,
            transition: "opacity 0.75s",
            display: datasetId ? "none" : "block",
          }}
        >
          <RobotoTooltip title={item.file.association_id}>
            <Box sx={{ marginRight: "16px" }}>
              <RobotoIconButton
                eventName={"ViewDatasetForFileClicked"}
                eventProperties={{
                  fileId: item.file.file_id,
                  datasetId: item.file.association_id,
                }}
                size="small"
                onClick={() => {
                  if (item.file.association_id) {
                    const filePath = [item.file.association_id].concat(
                      dirname(item.file.relative_path).split("/"),
                    );
                    goto.dataset({
                      filePaths: filePath,
                    });
                  }
                }}
                sx={{
                  p: 0,
                }}
              >
                <SvgIcon
                  style={{
                    transform: "scale(0.93, 0.93) scale(-1, 1)",
                    fill: "currentColor",
                  }}
                  sx={{
                    color: "#BBB",
                  }}
                >
                  <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                    <rect
                      x="9.692"
                      y="6.302"
                      width="4.578"
                      height="11.652"
                      style={{
                        stroke: "rgb(0, 0, 0)",
                        fill: "rgb(255, 255, 255)",
                      }}
                    />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                  </svg>
                </SvgIcon>
              </RobotoIconButton>
            </Box>
          </RobotoTooltip>
        </Box>
      )
    );
  }, [isHovering, datasetId, goto, item]);

  return (
    <>
      <RobotoErrorAlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        error={lastError}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {isVisibleMedia(item.name) && !lastError ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "100%",
                height: loading ? "0px" : "100%",
              }}
            >
              <Box
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onClick={onClick}
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    background: "rgba(0,0,0,0.75)",
                    opacity:
                      isHovering && (isImage(item.name) || isVideo(item.name))
                        ? 1
                        : 0,
                    transition: "opacity 0.5s",
                    color: "#BBB",
                    padding: theme.spacing(0.5),
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    fontSize: "0.8rem",
                    bottom: 0,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {isImage(item.name) && (
                      <PhotoOutlinedIcon
                        fontSize="small"
                        sx={{ mr: theme.spacing(0.75) }}
                      />
                    )}
                    {isVideo(item.name) && (
                      <VideoCameraBackOutlinedIcon
                        fontSize="small"
                        sx={{ mr: theme.spacing(0.75) }}
                      />
                    )}
                    <span
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {basename(item.name)}
                    </span>
                  </Box>
                </Box>

                <Checkbox
                  size="small"
                  disableRipple
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckboxIcon />}
                  sx={{
                    color: "white",
                    opacity: isHovering || isChecked ? 1 : 0,
                    position: "absolute",
                    right: "4px",
                    top: "4px",
                    zIndex: 100,
                    transition: "opacity 0.75s",
                    p: theme.spacing(0.5),
                  }}
                  checked={isChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleSelectRows([item], e.target.checked);
                  }}
                  color="default"
                  inputProps={{ "aria-label": "checkbox" }}
                />

                {infoButton}

                {isImage(item.name) && (
                  <DatasetImageItem
                    signedUrl={signedUrl}
                    loading={loading}
                    setLoading={setLoading}
                    annotations={annotations}
                    annotationViewFilters={annotationViewFilters}
                    onClick={navigateToFile}
                  />
                )}
                {isVideo(item.name) && (
                  <DatasetVideoItem
                    signedUrl={signedUrl}
                    loading={loading}
                    setLoading={setLoading}
                    onClick={navigateToFile}
                  />
                )}
                {(isText(item.name) ||
                  isMarkdown(item.name) ||
                  isWebRenderable(item.name)) &&
                  isFile(item) && (
                    <DatasetTextItem
                      item={item}
                      signedUrl={signedUrl}
                      loading={loading}
                      setLoading={setLoading}
                      onClick={navigateToFile}
                    />
                  )}
              </Box>
            </Box>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "100px",
                  minHeight: "100px",
                  height: "100%",
                }}
              >
                <Skeleton variant="rounded" width={"100%"} height={"100%"} />
              </Box>
            )}
          </>
        ) : (
          <GridItem
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100px",
              minHeight: "100px",
              height: "100%",
              cursor: "pointer",
              position: "relative",
            }}
            variant="outlined"
            onDoubleClick={onClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            {item.type === "file" && (
              <>
                <Checkbox
                  size="small"
                  disableRipple
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckboxIcon />}
                  sx={{
                    color: "white",
                    opacity: isHovering || isChecked ? 1 : 0,
                    position: "absolute",
                    right: "4px",
                    top: "4px",
                    zIndex: 100,
                    transition: "opacity 0.75s",
                    p: theme.spacing(0.5),
                  }}
                  checked={isChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleSelectRows([item], e.target.checked);
                  }}
                  color="default"
                  inputProps={{ "aria-label": "checkbox" }}
                />

                {infoButton}
              </>
            )}
            <Box
              sx={{
                overflow: "hidden",
              }}
              onClick={item.type === "file" ? navigateToFile : undefined}
            >
              <Box>
                {item.type === "file" ? (
                  <InsertDriveFileOutlinedIcon fontSize="large" color="info" />
                ) : (
                  <FolderRoundedIcon fontSize="large" color="info" />
                )}
              </Box>
              <Box
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {basename(item.name)}
              </Box>
            </Box>
          </GridItem>
        )}
      </Box>
    </>
  );
};
