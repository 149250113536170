import { AddPanel } from "@/components/visualization/controls/AddPanel";
import { FileRecord } from "@/domain/files";

import { ShareWorkspace } from "./ShareWorkspace";

interface WorkspaceControlsProps {
  fileRecord: FileRecord;
  hide?: boolean;
}

export function WorkspaceControls({
  fileRecord,
  hide = false,
}: WorkspaceControlsProps) {
  if (hide) {
    return null;
  }

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <AddPanel />
      <ShareWorkspace fileId={fileRecord.file_id} />
    </div>
  );
}
