import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import * as React from "react";

const headerStyles = {
  fontWeight: 500,
  fontSize: "0.85rem",
};

const valueStyles = {
  fontSize: "0.85rem",
};

const ActionMetadataTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.foreground.main,
  padding: theme.spacing(1, 2),
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ActionMetadataTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ParamValuesTableProps {
  paramValues?: { [key: string]: unknown };
}

export const ParamValuesTable: React.FC<ParamValuesTableProps> = ({
  paramValues,
}) => {
  const theme = useTheme();

  return (
    <>
      {paramValues && Object.keys(paramValues).length > 0 ? (
        <TableContainer
          sx={{
            mt: theme.spacing(1),
            mb: theme.spacing(1),
          }}
        >
          <Table size="small" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <ActionMetadataTableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ...headerStyles,
                    }}
                  >
                    Name
                  </Typography>
                </ActionMetadataTableCell>
                <ActionMetadataTableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ...headerStyles,
                    }}
                  >
                    Value
                  </Typography>
                </ActionMetadataTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paramValues &&
                Object.keys(paramValues).map((param, idx) => {
                  return (
                    <ActionMetadataTableRow key={idx}>
                      <TableCell>
                        <Typography variant="caption" sx={{ ...valueStyles }}>
                          {param}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption" sx={{ ...valueStyles }}>
                          {paramValues[param] as string}
                        </Typography>
                      </TableCell>
                    </ActionMetadataTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            None
          </Typography>
        </>
      )}
    </>
  );
};
