import { Alert, Box, Divider, useTheme } from "@mui/material";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  RobotoStyledTab,
  RobotoStyledTabs,
  RobotoTabPanel,
  CommentsBox,
} from "@/components";
import { OrgRecord } from "@/domain/orgs/OrgRecord.ts";
import { Invocations } from "@/features/actions/components";
import { useAPICall } from "@/service/apiHooks";
import { GetDatasetResponse, individualDatasetEndpoint } from "@/types";

import { DatasetActionStatus } from "./DatasetActionStatus";
import { DatasetDetails } from "./DatasetDetails";
import { DatasetEvents } from "./DatasetEvents/DatasetEvents.tsx";
import { DatasetFileBrowser } from "./DatasetFileBrowser/DatasetFileBrowser.tsx";
import { DatasetHeader } from "./DatasetHeader";
import { DatasetPermissionDetails } from "./DatasetPermissionDetails.tsx";

interface DatasetTabsProps {
  datasetId: string;
  datasetPath: string[];
  currentOrg: OrgRecord | null;
}

export const DatasetTabs: FC<DatasetTabsProps> = ({
  datasetId,
  datasetPath,
  currentOrg,
}) => {
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const tabSearchParam = searchParams.get("tab");
  const tabValue = tabSearchParam !== null ? parseInt(tabSearchParam) : 0;

  const { data, refetch, updateCache, error } = useAPICall<GetDatasetResponse>({
    endpoint: individualDatasetEndpoint,
    method: "GET",
    orgId: currentOrg?.org_id,
    pathParams: { datasetId: datasetId },
  });

  useEffect(() => {
    refetch({
      orgId: currentOrg?.org_id,
      pathParams: { datasetId: datasetId },
    });
  }, [datasetId, refetch, currentOrg?.org_id]);

  return (
    <>
      {error ? (
        <Box>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "block",
            }}
          >
            <DatasetHeader
              datasetId={datasetId}
              dataset={data?.data}
              showTags={tabValue !== 2}
            />
            <Box
              sx={{
                paddingTop: theme.spacing(3),
              }}
            >
              <RobotoStyledTabs
                value={tabValue}
                onChange={(_event, newValue) => {
                  setSearchParams({ tab: String(newValue) });
                }}
                aria-label="dataset tabs"
                variant="scrollable"
              >
                <RobotoStyledTab label="Files" />
                <RobotoStyledTab
                  label="Actions"
                  icon={<DatasetActionStatus datasetId={datasetId} />}
                  iconPosition="end"
                />
                <RobotoStyledTab label="Metadata" />
                <RobotoStyledTab label="Events" />
                <RobotoStyledTab label="Permissions" />
              </RobotoStyledTabs>

              <Divider
                sx={{
                  borderColor: theme.border.color,
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              padding: theme.spacing(2, 0),
              paddingBottom: theme.spacing(1),
            }}
          >
            <RobotoTabPanel
              index={0}
              value={tabValue}
              sx={{
                padding: theme.spacing(1),
              }}
            >
              <DatasetFileBrowser
                dataset={data?.data}
                datasetId={datasetId}
                datasetPath={datasetPath}
              />
              <Box
                sx={{
                  mt: theme.spacing(1),
                }}
              >
                <CommentsBox entityType={"dataset"} entityId={datasetId} />
              </Box>
            </RobotoTabPanel>

            <RobotoTabPanel
              index={1}
              value={tabValue}
              sx={{
                padding: theme.spacing(1),
              }}
            >
              <Invocations datasetId={datasetId} currentOrg={currentOrg} />
            </RobotoTabPanel>

            <RobotoTabPanel
              index={2}
              value={tabValue}
              sx={{
                paddingBottom: theme.spacing(1),
              }}
            >
              {data?.data && (
                <DatasetDetails
                  datasetId={datasetId}
                  currentOrg={currentOrg}
                  dataset={data?.data}
                  updateCache={(record) => updateCache({ data: record })}
                />
              )}
            </RobotoTabPanel>

            <RobotoTabPanel
              index={3}
              value={tabValue}
              sx={{
                paddingBottom: theme.spacing(1),
              }}
            >
              <DatasetEvents datasetId={datasetId} />
            </RobotoTabPanel>

            <RobotoTabPanel
              index={4}
              value={tabValue}
              sx={{
                paddingBottom: theme.spacing(1),
              }}
            >
              <DatasetPermissionDetails datasetId={datasetId} />
            </RobotoTabPanel>
          </Box>
        </>
      )}
    </>
  );
};
