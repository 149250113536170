import { useCallback, useEffect, useRef, useState } from "react";

import {
  FromToken,
  RobotoAPICall,
  SearchQueryBody,
  APIServiceError,
  PaginatedAPIResponse,
} from "@/types";

import { APIService } from "../ApiService";
import { LoggerService } from "../LoggerService";

interface PaginatedCallState<ItemType> {
  loading: boolean;
  data: ItemType[];
  error: Error | null;
  nextPageToken: string | null;
  apiCall: RobotoAPICall | undefined;
  pageData: ItemType[];
  isNextUIPageAvailable: boolean;
}

interface IUsePaginatedAPICall<ItemType> {
  onRowsPerPageChange: (rowsPerPage: number) => Promise<ItemType[]>;
  fetchNextPage: (
    nextPage: number,
    rowsPerPage: number,
    nextTokenLocation?: "url" | "body",
  ) => Promise<ItemType[]>;
  fetchPreviousPage: (previousPage: number, rowsPerPage: number) => ItemType[];
  getFirstPage: (
    newAPICall: RobotoAPICall,
    rowsPerPage: number,
  ) => Promise<ItemType[]>;
  updateItemsInCache: (
    newItems: { [key: string]: ItemType },
    matchKey: string,
  ) => void;
  prependCache: (
    currentPage: number,
    rowsPerPage: number,
    newItems: ItemType[],
  ) => void;
  removeItemFromCache: <Key extends keyof ItemType>(
    itemId: string,
    matchKey: Key,
  ) => void;
  loading: boolean;
  error: APIServiceError | null;
  pageData: ItemType[];
  isNextPageAvailable: boolean;
  cacheLength: number;
  cache: ItemType[];
}

export const usePaginatedAPICall = <
  ItemType,
>(): IUsePaginatedAPICall<ItemType> => {
  //
  // keep track of whether the component is still mounted
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const [state, setState] = useState<PaginatedCallState<ItemType>>({
    loading: true,
    data: [],
    error: null,
    nextPageToken: null,
    apiCall: undefined,
    pageData: [],
    isNextUIPageAvailable: true,
  });

  const removeItemFromCache = useCallback(
    <Key extends keyof ItemType>(itemId: string, matchKey: Key) => {
      setState((prevState) => {
        const newData = prevState.data.filter((item) => {
          const currentItem = item;
          const currentId = currentItem[matchKey];
          return currentId !== itemId;
        });
        const newPageData = prevState.pageData.filter((item) => {
          const currentItem = item;
          const currentId = currentItem[matchKey];
          return currentId !== itemId;
        });

        return {
          ...prevState,
          data: newData,
          pageData: newPageData,
        };
      });
    },
    [],
  );

  const prependCache = useCallback(
    (currentPage: number, rowsPerPage: number, newItems: ItemType[]) => {
      setState((prevState) => {
        const newData = [...newItems, ...prevState.data];

        return {
          ...prevState,
          data: newData,
          pageData: newData.slice(
            currentPage * rowsPerPage,
            (currentPage + 1) * rowsPerPage,
          ),
        };
      });
    },
    [],
  );

  /**
   * Update the cache with the new items
   * Caller MUST ensure that the matchKey is a unique id string for the items
   * This method is useful for updating the local cache after a create, update, or delete without making an additional API call.
   * E.g. the user just added a tag to a dataset, so we can update the local cache without refreshing the data from Dynamo (which is eventually consistent, not strongly consistent)
   * @param newItems is an object of the format {itemId: Item}
   * @param matchKey is the key to match the items on
   */
  const updateItemsInCache = useCallback(
    (newItems: { [key: string]: ItemType }, matchKey: string) => {
      const pageData = [...state.pageData];

      try {
        // Update the page data if the item is in the page data (i.e. the item is on the current page)
        for (let i = 0; i < pageData.length; i++) {
          // This code only cares about the id, so we can use Record<string, string> here
          const currentItem = pageData[i] as Record<string, string>;

          const idToMatch = currentItem[matchKey];

          // Replace the current item with the new item if the id matches
          if (idToMatch in newItems) {
            pageData[i] = newItems[idToMatch];
          }
        }

        const currentCache = [...state.data];

        // Iterate over the cache. If the cache has the item update it. Otherwise do nothing
        for (let i = 0; i < currentCache.length; i++) {
          // This code only cares about the id, so we can use Record<string, string> here
          const currentItem = currentCache[i] as Record<string, string>;

          const idToMatch = currentItem[matchKey];

          // Replace the current item with the new item if the id matches
          if (idToMatch in newItems) {
            currentCache[i] = newItems[idToMatch];
          }
        }

        setState((prevState) => ({
          ...prevState,
          data: currentCache,
          pageData,
        }));
      } catch (error) {
        LoggerService.error(
          "Error updating items in cache. Be sure caller is passing a matchKey that is a unique id string:",
        );
        LoggerService.error(error);
      }
    },
    [state.data, state.pageData],
  );

  const getFirstPage = useCallback(
    async (
      newAPICall: RobotoAPICall,
      rowsPerPage: number,
    ): Promise<ItemType[]> => {
      if (isMountedRef.current) {
        setState((prevState) => ({ ...prevState, loading: true }));
      }

      const result = await APIService.authorizedRequest(newAPICall);
      const response: PaginatedAPIResponse<ItemType> =
        result.response as PaginatedAPIResponse<ItemType>;
      const error: APIServiceError | null = result.error;

      if (error) {
        if (isMountedRef.current) {
          setState((prevState) => ({
            ...prevState,
            error,
            nextPageToken: null,
          }));
        }
        return [];
      }

      const thisPage = response.data?.items?.slice(0, rowsPerPage) ?? [];

      if (isMountedRef.current) {
        setState((prevState) => {
          const newItems = response.data?.items;

          return {
            ...prevState,
            loading: false,
            data: newItems ?? [],
            nextPageToken: response.data?.next_token ?? null,
            error: null,
            apiCall: newAPICall,
            pageData: thisPage,
            isNextUIPageAvailable:
              rowsPerPage < (newItems?.length ?? 0) ||
              !!response.data?.next_token,
          };
        });
      }

      return thisPage;
    },

    [],
  );

  const fetchData = useCallback(
    async (
      pageNumber: number,
      rowsPerPage: number,
      apiCall: RobotoAPICall,
      nextTokenLocation: "url" | "body" = "url",
    ) => {
      //
      if (isMountedRef.current) {
        setState((prevState) => ({ ...prevState, loading: true }));
      }

      const newApiCall = attachNextTokenToApiCall(
        nextTokenLocation,
        apiCall,
        state.nextPageToken,
      );

      const result = await APIService.authorizedRequest(newApiCall);

      const response: PaginatedAPIResponse<ItemType> =
        result.response as PaginatedAPIResponse<ItemType>;
      const error: APIServiceError | null = result.error;

      if (error) {
        if (isMountedRef.current) {
          setState((prevState) => ({ ...prevState, error, loading: false }));
        }
        return [];
      }

      let newData = [] as ItemType[];
      newData = [...state.data, ...(response.data?.items ?? [])];

      const thisPage = newData.slice(
        pageNumber * rowsPerPage,
        (pageNumber + 1) * rowsPerPage,
      );

      if (isMountedRef.current) {
        setState((prevState) => ({
          ...prevState,
          apiCall: newApiCall,
          loading: false,
          data: newData,
          nextPageToken: response.data?.next_token ?? null,
          error: null,
          pageData: thisPage,
          isNextUIPageAvailable:
            rowsPerPage * (pageNumber + 1) < newData.length ||
            Boolean(response.data?.next_token),
        }));
      }

      return thisPage;
    },
    [state.data, state.nextPageToken],
  );

  const fetchNextPage = useCallback(
    async (
      nextPage: number,
      rowsPerPage: number,
      nextTokenLocation: "url" | "body" = "url",
    ): Promise<ItemType[]> => {
      // check the cache first
      // if the cache has the next page, return it

      if (!state.apiCall) {
        LoggerService.error(
          "API call is null or undefined. Cannot fetch next page",
        );

        setState((prevState) => ({
          ...prevState,
          pageData: [],
        }));

        return [];
      }

      const lengthNeeded = (nextPage + 1) * rowsPerPage;

      if (lengthNeeded < state.data.length) {
        const thisPage = state.data.slice(
          nextPage * rowsPerPage,
          (nextPage + 1) * rowsPerPage,
        );

        setState((prevState) => ({
          ...prevState,
          pageData: thisPage,
        }));

        return thisPage;
      }

      if (!state.nextPageToken) {
        LoggerService.log("Token is null or undefined. Cannot fetch next page");

        const thisPage = state.data.slice(
          nextPage * rowsPerPage,
          (nextPage + 1) * rowsPerPage,
        );

        setState((prevState) => ({
          ...prevState,
          pageData: thisPage,
          isNextUIPageAvailable: false,
        }));

        return thisPage;
      }

      return await fetchData(
        nextPage,
        rowsPerPage,
        state.apiCall,
        nextTokenLocation,
      );
    },
    [state.apiCall, state.data, state.nextPageToken, fetchData],
  );

  const fetchPreviousPage = useCallback(
    (previousPage: number, rowsPerPage: number) => {
      if (previousPage < 0) {
        LoggerService.error(
          "Previous page is less than 0. Cannot fetch previous page",
        );
        setState((prevState) => ({
          ...prevState,
          pageData: [],
          isNextUIPageAvailable: true,
        }));

        return [];
      }

      const startIndex = previousPage * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;

      const data = state.data.slice(startIndex, endIndex);

      setState((prevState) => ({
        ...prevState,
        pageData: data,
        isNextUIPageAvailable: true,
      }));

      return data;
    },

    [state.data],
  );

  const onRowsPerPageChange = useCallback(
    async (rowsPerPage: number) => {
      if (!state.apiCall) {
        LoggerService.error(
          "API call is null or undefined. Cannot fetch next page",
        );

        setState((prevState) => ({
          ...prevState,
          pageData: [],
        }));

        return [];
      }

      // Start the page at 0 when the rows per page changes
      return await getFirstPage(state.apiCall, rowsPerPage);
    },
    [state.apiCall, getFirstPage],
  );

  return {
    onRowsPerPageChange,
    fetchNextPage,
    fetchPreviousPage,
    getFirstPage: getFirstPage,
    updateItemsInCache,
    prependCache,
    removeItemFromCache,
    loading: state.loading,
    error: state.error,
    pageData: state.pageData,
    isNextPageAvailable: state.isNextUIPageAvailable,
    cacheLength: state.data.length,
    cache: state.data,
  };
};

const attachNextTokenToApiCall = (
  nextTokenLocation: "url" | "body",
  apiCall: RobotoAPICall,
  nextPageToken: FromToken,
): RobotoAPICall => {
  const newApiCall = { ...apiCall };

  if (nextTokenLocation === "url") {
    const newParams = newApiCall?.queryParams ?? new URLSearchParams();

    if (nextPageToken) {
      newParams.set("page_token", nextPageToken);
    } else {
      newParams.delete("page_token");
    }

    newApiCall.queryParams = newParams;

    return newApiCall;
  } else {
    // nextTokenLocation === "body"

    const jsonBody = apiCall?.requestBody;

    let objBody: SearchQueryBody = {};

    if (jsonBody) {
      objBody = JSON.parse(jsonBody as string) as SearchQueryBody;

      if (nextPageToken) {
        objBody.after = nextPageToken;
      } else {
        delete objBody.after;
      }

      apiCall.requestBody = JSON.stringify(objBody);
    }

    newApiCall.requestBody = JSON.stringify(objBody);

    return newApiCall;
  }
};
