import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";

export const useSharedWorkspace = (workspaceId: string | undefined) => {
  const { currentOrganization } = useAuth();
  const { workspaceService } = useContext(DomainServicesContext);

  return useQuery({
    queryKey: ["workspace", workspaceId],
    queryFn: async ({ signal }) => {
      if (currentOrganization === null) {
        return Promise.reject(new Error("CurrentOrganization is not set"));
      }

      return await workspaceService.getWorkspace(
        currentOrganization.org_id,
        signal,
        workspaceId,
      );
    },
    staleTime: Infinity,
  });
};
