/**
 * Shadow of types and enumerations from `roboto.association`
 */

export enum AssociationType {
  Dataset = "dataset",
  File = "file",
  Topic = "topic",
  MessagePath = "message_path",
}

export interface AssociationRecord {
  association_id: string;
  association_type: AssociationType;
  association_version?: number;
}

export class Association {
  private static URL_ENCODING_SEP = ":";

  #record: AssociationRecord;

  constructor(record: AssociationRecord) {
    this.#record = record;
  }

  public urlEncode(): string {
    const parts = [this.#record.association_type, this.#record.association_id];

    if (this.#record.association_version !== undefined) {
      parts.push(`${this.#record.association_version}`);
    }

    return parts.map(encodeURIComponent).join(Association.URL_ENCODING_SEP);
  }
}
