import { Alert, Box, Paper, Typography, useTheme } from "@mui/material";
import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartTooltip from "./ChartTooltip";
import { MeteringVals } from "./types";

export interface UsageBarChartProps {
  title: string;
  subtitle: string;
  data: MeteringVals[];
  dataKey: string;
  allowYDecimals?: boolean;
  fill?: string;
  yUnit?: string;
  limitY?: number;
}

export const UsageBarChart: React.FC<UsageBarChartProps> = ({
  title,
  subtitle,
  data,
  dataKey,
  allowYDecimals,
  fill,
  yUnit,
  limitY,
}) => {
  const theme = useTheme();

  return (
    <Box
      component={Paper}
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "350px",
        width: {
          xs: "400px",
        },
        backgroundColor: theme.palette.foreground.main,
        p: "1.5rem",
      }}
    >
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            fontSize: "1.05rem",
            lineHeight: "1.75rem",
            color: theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "0.9rem",
            lineHeight: "1.75rem",
            color: "rgb(156 163 175)",
          }}
        >
          {subtitle}
        </Typography>
      </Box>
      <Box sx={{ mt: theme.spacing(3), width: "100%", height: "100%" }}>
        {data.length > 0 ? (
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{ top: 5, right: 20, bottom: 0, left: 0 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                opacity={0.5}
                horizontal={true}
                vertical={false}
              />
              <XAxis
                dataKey="name"
                tick={{
                  fontSize: ".75rem",
                }}
                tickLine={false}
                axisLine={false}
                minTickGap={5}
                tickFormatter={(label: string) => label.slice(0, 3)}
              />
              <YAxis
                tick={{
                  fontSize: ".75rem",
                  color: theme.palette.text.secondary,
                }}
                axisLine={false}
                tickLine={false}
                allowDecimals={allowYDecimals ?? true}
                interval="preserveStartEnd"
                unit={yUnit}
              />
              <Tooltip
                wrapperStyle={{ outline: "none" }}
                cursor={{ fill: "#d1d5db", opacity: "0.15" }}
                content={({ active, payload, label }) => (
                  <ChartTooltip
                    active={active}
                    payload={payload}
                    label={label as string}
                    fill={fill ?? "#8884d8"}
                  />
                )}
                position={{ y: 0 }}
              />
              <Bar dataKey={dataKey} fill={fill ?? "#8884d8"} />
              {limitY !== undefined && (
                <ReferenceLine
                  y={limitY}
                  stroke="red"
                  strokeDasharray="3 3"
                  ifOverflow="extendDomain"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Alert severity="warning">No data yet, please check back soon.</Alert>
        )}
      </Box>
    </Box>
  );
};
