/**
 * An AbortController that can cascade its abort event to other AbortControllers.
 */
export class CascadingAbortController implements AbortController {
  #parentController: AbortController;
  #childControllerToAbortListenerMap: Map<AbortController, () => void>;

  constructor(abortController: AbortController) {
    this.#parentController = abortController;
    this.#childControllerToAbortListenerMap = new Map();
  }

  public get signal() {
    return this.#parentController.signal;
  }

  public abort() {
    this.#parentController.abort();
  }

  public add(child: AbortController) {
    const abortListener = () => {
      child.abort();
      this.#childControllerToAbortListenerMap.delete(child);
    };

    // Forward the parent's abort event to the child controller.
    this.#parentController.signal.addEventListener("abort", abortListener, {
      once: true, // Remove the listener after it's called.
      signal: child.signal, // Or when the child controller is aborted (e.g., by some external event)
    });

    // Remove the listener if the child controller is aborted.
    child.signal.addEventListener("abort", abortListener, {
      once: true, // Remove the listener after it's called.
      signal: this.#parentController.signal, // Or when the parent controller is aborted.
    });

    this.#childControllerToAbortListenerMap.set(child, abortListener);
  }

  public has(child: AbortController) {
    return this.#childControllerToAbortListenerMap.has(child);
  }

  public remove(child: AbortController) {
    const abortListener = this.#childControllerToAbortListenerMap.get(child);
    if (abortListener !== undefined) {
      this.#parentController.signal.removeEventListener("abort", abortListener);
      child.signal.removeEventListener("abort", abortListener);
    }
    this.#childControllerToAbortListenerMap.delete(child);
  }
}
