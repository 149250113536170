import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";

export function useDatasetMetadataKeys(orgId?: string) {
  const { currentOrganization } = useAuth();
  const canonicalOrgId = orgId || currentOrganization?.org_id;
  const { datasetService } = useContext(DomainServicesContext);

  return useQuery<string[], Error>({
    queryKey: ["orgDatasetMetadataKeys", canonicalOrgId],
    queryFn: ({ signal }) => {
      if (canonicalOrgId === undefined) {
        return Promise.reject(new Error("Invalid orgId"));
      }

      return datasetService.getMetadataKeysForOrg({
        abortSignal: signal,
        resourceOwnerId: canonicalOrgId,
      });
    },
    enabled: canonicalOrgId !== undefined,
    staleTime: 30000,
  });
}
