import { Alert, Box, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import { Collection } from "../model";

import CollectionCard from "./CollectionCard";

interface CollectionsGridProps {
  collections: Collection[];
  isLoading: boolean;
}

/**
 * A responsive grid of ActionCards.
 */
export default function CollectionsGrid({
  collections,
  isLoading,
}: CollectionsGridProps) {
  let content: JSX.Element[] | null = null;
  if (isLoading) {
    content = Array(3)
      .fill(0)
      .map((_, index) => {
        return (
          <Skeleton
            key={index}
            variant="rectangular"
            width={368}
            height={210}
            sx={{
              borderRadius: 1,
            }}
          />
        );
      });
  } else {
    content = collections.map((collection) => {
      return (
        <CollectionCard
          key={collection.collection_id}
          collection={collection}
        />
      );
    });
  }

  const theme = useTheme();

  return (
    <>
      {isLoading || collections.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fit, 1fr)", // applies to xtra small (mobile) screens
              sm: "repeat(auto-fit, 368px)", // applies to small screens
            },
            gridTemplateRows: "repeat(auto-fit, 1fr)",
            columnGap: theme.spacing(2),
            rowGap: theme.spacing(2),
          }}
        >
          {content}
        </Box>
      ) : (
        <>
          {!isLoading && (
            <Alert severity="warning">
              No collections found. Create one using the button above and then
              add datasets or files to it.
            </Alert>
          )}
        </>
      )}
    </>
  );
}
