import OpenWithIcon from "@mui/icons-material/OpenWith";
import IconButton from "@mui/material/IconButton";

interface MoveToolProps {
  selected?: boolean;
  onClick: () => void;
}

export function MoveTool({ onClick, selected = false }: MoveToolProps) {
  return (
    <IconButton
      aria-label="pan-chart"
      onClick={onClick}
      size="small"
      title="Move around the chart"
    >
      <OpenWithIcon color={selected ? "primary" : "inherit"} fontSize="small" />
    </IconButton>
  );
}
