import { useQuery } from "@tanstack/react-query";

import { DatasetRecord } from "@/domain/datasets/DatasetRecord";
import { useDomainServices } from "@/providers/DomainServices";

export function useDataset(datasetId: string | undefined) {
  const { datasetService } = useDomainServices();

  return useQuery<DatasetRecord, Error>({
    queryKey: ["datasetRecord", datasetId],
    queryFn({ signal }) {
      if (datasetId === undefined) {
        return Promise.reject(new Error("Invalid datasetId"));
      }

      return datasetService.getDataset(datasetId, {
        abortSignal: signal,
      });
    },
    enabled: datasetId !== undefined,
    // Our HttpClient already retries requests that need retrying
    retry: false,
    // 1 Minute until stale
    staleTime: 60000,
  });
}
