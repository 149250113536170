import { APIServiceError, PaginatedAPIResponse, RobotoAPICall } from "@/types";

import { APIService } from "../ApiService";

export const fetchAllItems = async <ItemType>(
  incomingAPICall: RobotoAPICall,
): Promise<{ items: ItemType[]; error: APIServiceError | null }> => {
  //

  let apiCall = { ...incomingAPICall };

  let nextToken: string | null = null;
  let items: ItemType[] = [];

  do {
    const { response, error } =
      await APIService.authorizedRequest<PaginatedAPIResponse<ItemType>>(
        apiCall,
      );

    if (error) {
      return {
        items: [],
        error,
      };
    }

    const currentItems = response?.data?.items;

    if (currentItems) {
      items = items.concat(currentItems);
    }

    const currentToken = response?.data?.next_token;

    if (currentToken) {
      const newParams = apiCall?.queryParams ?? new URLSearchParams();
      newParams.set("page_token", currentToken);
      apiCall = { ...apiCall, queryParams: newParams };
      nextToken = currentToken;
    } else {
      nextToken = null;
    }
  } while (nextToken !== null);

  return {
    items,
    error: null,
  };
};
