import { useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { LayoutItem, type PanelState } from "@/state/visualization";

import { Droppable, DropZone, Draggable, DragItem } from "../DnD";
import { useSelectedPanelId } from "../WorkspaceCtx";

import { LoadingIndicator } from "./LoadingIndicator";
import { PanelHeader } from "./PanelHeader";
import styles from "./PanelLayout.module.css";

interface PanelLayoutProps {
  className?: classNames.Argument;
  header?: React.ReactNode;
  isLoading?: boolean;
  layout: LayoutItem;
  state: PanelState;
  style?: React.CSSProperties;
}

export function PanelLayout(props: React.PropsWithChildren<PanelLayoutProps>) {
  const { children, className, header, isLoading, layout, state, style } =
    props;
  const theme = useTheme();
  const isSelected = useSelectedPanelId() === state.id;

  return (
    <Droppable
      className={classNames(
        styles.container,
        styles.shrinkBeyondContent,
        className,
        {
          [styles.disableUserInteraction]: layout.isResizing,
          [styles.selected]: isSelected,
        },
      )}
      data={state}
      defaultBackgroundColor={theme.palette.panel.main}
      droppableId={state.id}
      dropZone={DropZone.Panel}
      style={style}
    >
      <Draggable<LayoutItem>
        id={state.id}
        item={{ type: DragItem.Panel, data: layout }}
      >
        {header ? (
          header
        ) : (
          <PanelHeader className={styles.panelHeader} state={state} />
        )}
      </Draggable>
      <div
        className={classNames(styles.panelBody, {
          [styles.hideContent]: layout.isResizing,
        })}
      >
        {children}
        <LoadingIndicator
          className={styles.loadingOverlay}
          visible={isLoading === true}
        />
      </div>
      {layout.isResizing && (
        <div className={classNames(styles.fullScreen, styles.blur)} />
      )}
    </Droppable>
  );
}
