import { NavigationPageWithRedirectProtection } from "@/components";
import { ActionPage } from "@/features/actions/pages";

export default function ActionDetailPage() {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Action"}>
      <ActionPage />
    </NavigationPageWithRedirectProtection>
  );
}
