import { styled } from "@mui/material/styles";

interface StyledTopBarProps {
  topBarHeight: string;
}

export const StyledTopBar = styled("nav")<StyledTopBarProps>(
  ({ theme, topBarHeight }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    background: theme.palette.navMenu.main,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: topBarHeight,
    borderBottom: theme.border.thin,
    zIndex: theme.zLayer(3),
  }),
);
