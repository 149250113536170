import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { useFileUpload } from "@/providers/FileUpload";

import { RobotoTypography } from "../RobotoTypography";

interface DragAndDropFilesProps {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  datasetId: string;
  displayOverlayOnFileHover?: boolean;
  filePrefix?: string;
  onDropComplete: (error?: Error) => Promise<void>;
  onDragOver?: () => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  orgId: string;
}

export const DragAndDropFiles: React.FC<DragAndDropFilesProps> = ({
  children,
  containerStyle,
  datasetId,
  displayOverlayOnFileHover,
  filePrefix,
  onDropComplete,
  orgId,
}) => {
  const [fileHovering, setFileHovering] = React.useState(false);

  const theme = useTheme();

  const { beginFileUpload } = useFileUpload();

  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    setFileHovering(false);
    await beginFileUpload(event, orgId, datasetId, filePrefix);
    void onDropComplete();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: fileHovering
          ? theme.palette.primary.light
          : theme.palette.background.default,
        position: "relative",
        ...containerStyle,
      }}
      onDrop={(e) => {
        void onDrop(e);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setFileHovering(true);
      }}
      onDragEnter={(event) => {
        event.preventDefault();
        setFileHovering(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();

        // Check if leaving to a child
        if (event.currentTarget.contains(event.relatedTarget as Node)) {
          return;
        }

        setFileHovering(false);
      }}
    >
      {children}

      {displayOverlayOnFileHover && fileHovering ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.primary.light,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: theme.border.radius,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              maxWidth: "350px",
              padding: theme.spacing(2),
              borderRadius: theme.spacing(1),
              textAlign: "center",
            }}
          >
            <RobotoTypography variant={"h5"} color={"white"}>
              Drop files to upload them
            </RobotoTypography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
