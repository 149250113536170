import * as React from "react";

import { Timer, useTimerState } from "../../timer";

import { type Extents } from "./PlotRenderer";
import styles from "./TimelineIndicator.module.css";

interface TimelineIndicatorProps {
  chartExtents: Extents | null;
  hide?: boolean;
  timer: Timer;
}

export function TimelineIndicator(props: TimelineIndicatorProps) {
  const { chartExtents, hide, timer } = props;
  const { timeNs } = useTimerState(timer);

  const getPixelForTime = React.useCallback(
    (currentTimeNs: bigint) => {
      if (chartExtents === null) {
        return null;
      }

      const {
        data: {
          logTime: [min, max],
        },
        pixel: { left, width },
      } = chartExtents;
      if (currentTimeNs < min || currentTimeNs > max) {
        return null;
      }
      const pct = Number(currentTimeNs - min) / Number(max - min);
      if (!Number.isFinite(pct)) {
        // if min === max, pct will be the result of dividing by 0 (which in JS in NaN)
        return left;
      }
      return left + pct * width;
    },
    [chartExtents],
  );

  if (hide === true || chartExtents === null || timer.duration === 0) {
    return null;
  }

  const {
    pixel: { top, height },
  } = chartExtents;
  const left = getPixelForTime(timeNs);
  if (left === null) {
    return null;
  }

  return (
    <div
      className={styles.indicator}
      style={{
        top: `${top}px`,
        height: `${height}px`,
        left: `${left}px`,
      }}
    ></div>
  );
}
