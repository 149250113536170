import {
  Popover,
  Box,
  Divider,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoErrorAlertDialog } from "@/components/RobotoErrorAlert";
import { APIServiceError } from "@/types";

import { CreateDataset } from "../CreateDataset";

interface CreateDatasetPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleClose?: () => void;
}

export const CreateDatasetPopover: React.FC<CreateDatasetPopoverProps> = (
  props,
) => {
  const theme = useTheme();
  const [createDatasetAlertOpen, setCreateDatasetAlertOpen] =
    React.useState<boolean>(false);
  const [lastDatasetError, setLastDatasetError] = React.useState<
    APIServiceError | undefined
  >(undefined);

  function onCreateDatasetError(error: APIServiceError) {
    setLastDatasetError(error);
    setCreateDatasetAlertOpen(true);
  }

  return (
    <>
      <RobotoErrorAlertDialog
        open={createDatasetAlertOpen}
        setOpen={setCreateDatasetAlertOpen}
        error={lastDatasetError}
      />

      <Popover
        id={"popover-create-dataset"}
        open={Boolean(props.anchorEl)}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: "center",
        }}
        marginThreshold={5}
      >
        <Box
          component={Paper}
          elevation={24}
          sx={{
            width: "30rem",
            padding: theme.spacing(1.5),
          }}
        >
          <Typography variant="subtitle1">New Dataset</Typography>
          <Divider sx={{ mt: theme.spacing(1), mb: theme.spacing(1) }} />
          <CreateDataset
            handleClose={props.handleClose}
            onError={onCreateDatasetError}
          />
        </Box>
      </Popover>
    </>
  );
};
