import { Layout, LayoutItem } from "../schema/v1";

/**
 * Helper function to immutably scale the size of a list of children by a provided factor
 */
export function scaleChildrenProportionally(
  children: (Layout | LayoutItem)[],
  factor: number,
): (Layout | LayoutItem)[] {
  if (factor <= 0) {
    throw new Error(`Invalid factor: ${factor}`);
  }

  if (children.length === 0) {
    return [];
  }

  return children.map((child) => ({
    ...child,
    relativeSize: child.relativeSize * factor,
  }));
}
