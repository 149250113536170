import * as React from "react";

import { useAuth } from "@/providers";

import { DatasetTabs } from "../components";

interface DatasetExplorerPageProps {
  datasetId: string | undefined;
  datasetPath: string[];
}

export const DatasetExplorerPage: React.FC<DatasetExplorerPageProps> = ({
  datasetId,
  datasetPath,
}) => {
  const { currentOrganization } = useAuth();

  return (
    <>
      {datasetId && (
        <DatasetTabs
          currentOrg={currentOrganization}
          datasetId={datasetId}
          datasetPath={datasetPath}
        />
      )}
    </>
  );
};
