import { AssociationType } from "@/domain/association";
import { EventRecord } from "@/domain/events";
import { FileRecord } from "@/domain/files";
import { TopicRecord, MessagePathRecord } from "@/domain/topics";
import { CellType, Dataset, Row } from "@/types";
import { basename } from "@/utils";
import { nanoSecToDate } from "@/utils/time";

export function isDataset(object: unknown): object is Dataset {
  return (object as Dataset).dataset_id !== undefined;
}

export function isFile(object: unknown): object is FileRecord {
  return (object as FileRecord).file_id !== undefined;
}

function isMsgPath(object: unknown): object is MessagePathRecord {
  return (object as MessagePathRecord).message_path_id !== undefined;
}

export function isTopic(object: unknown): object is TopicRecord {
  return (object as TopicRecord).topic_id !== undefined && !isMsgPath(object);
}

export function isEvent(object: unknown): object is EventRecord {
  return (object as EventRecord).event_id !== undefined;
}

export const pushMetadataCell = (
  row: Row,
  column: string,
  item: Dataset | FileRecord | TopicRecord | MessagePathRecord | EventRecord,
) => {
  const cellId = row.id + "-" + column;
  const fieldName = column.includes("metadata.")
    ? column.split("metadata.")[1] // extract the actual field name
    : column;

  const value = item?.metadata?.[fieldName];
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    Array.isArray(value)
  ) {
    row.cells.push({
      type: "simpleText",
      data: value,
      id: cellId,
    });
  } else {
    row.cells.push({
      type: "simpleText",
      data: "",
      id: cellId,
    });
  }
};

// This function creates cell content from row values
// It also ensures that certain fields are formatted correctly
// where appropriate, e.g. IDs, users, tags etc.
export const pushStandardCell = (
  row: Row,
  column: string,
  item: Dataset | FileRecord | TopicRecord | MessagePathRecord | EventRecord,
) => {
  const cellId = `${row.id}-${column}`;

  if (isDataset(item)) {
    switch (column) {
      case "dataset_id":
        row.cells.push({
          type: "id",
          data: item[column] ?? "",
          id: cellId,
        });
        return;
      case "tags":
        row.cells.push({
          type: "chips",
          data: (item as Dataset | FileRecord)?.tags ?? [],
          id: cellId,
        });
        return;
      case "source":
        row.cells.push({
          type: item.device_id ? "device" : "email",
          data: item.device_id || item.created_by,
          id: cellId,
        });
        return;
    }
  }

  if (isFile(item)) {
    let sourceType: CellType = "email";
    let sourceData = item.created_by;

    const split = item.origination.split(" ");
    if (split && split[1] === "invocation:") {
      sourceType = "invocation";
      sourceData = split[2];
    } else if (item.device_id) {
      sourceType = "device";
      sourceData = item.device_id;
    }

    switch (column) {
      case "file_id":
        row.cells.push({
          type: "id",
          data: item[column] ?? "",
          id: cellId,
        });
        return;
      case "relative_path":
        row.cells.push({
          type: "id",
          data: basename(item[column]) ?? "",
          id: cellId,
          association: {
            association_id: item.file_id,
            association_type: AssociationType.File,
          },
        });
        return;
      case "association_id":
        row.cells.push({
          type: "id",
          data: item[column] ?? "",
          id: cellId,
        });
        return;
      case "tags":
        row.cells.push({
          type: "chips",
          data: (item as Dataset | FileRecord)?.tags ?? [],
          id: cellId,
        });
        return;
      case "source":
        row.cells.push({
          type: sourceType,
          data: sourceData,
          id: cellId,
        });
        return;
    }
  }

  if (isTopic(item)) {
    switch (column) {
      case "topic_id":
        row.cells.push({
          type: "id",
          data: item[column],
          id: cellId,
          association: item.association,
        });
        return;
    }
  }

  if (isMsgPath(item)) {
    switch (column) {
      case "message_path_id":
        row.cells.push({
          type: "id",
          data: item[column],
          id: cellId,
        });
        return;
    }
  }

  if (isEvent(item)) {
    switch (column) {
      case "event_id":
        row.cells.push({
          type: "id",
          data: item[column],
          id: cellId,
        });
        return;

      case "tags":
        row.cells.push({
          type: "chips",
          data: item.tags,
          id: cellId,
        });
        return;

      case "start_time":
      case "end_time": // FALLTHROUGH
        row.cells.push({
          type: "datetime",
          data: nanoSecToDate(item[column]).toLocaleString(),
          id: cellId,
        });
        return;
    }
  }

  // Applicable to a mixture of objects
  switch (column) {
    case "created":
    case "modified": // FALLTHROUGH
      row.cells.push({
        type: "datetime",
        data: item[column] ?? "",
        id: cellId,
      });
      return;
    case "created_by":
    case "modified_by": // FALLTHROUGH
      row.cells.push({
        type: "email",
        data: item[column] ?? "",
        id: cellId,
      });
      return;
    // FALLTHROUGH
    default: {
      row.cells.push({
        type: "simpleText",
        data:
          String(
            item[
              column as keyof (
                | Dataset
                | FileRecord
                | TopicRecord
                | MessagePathRecord
                | EventRecord
              )
            ],
          ) ?? "",
        id: cellId,
      });
    }
  }
};

export const retrieveMetadataColumns = (
  data:
    | Dataset[]
    | FileRecord[]
    | TopicRecord[]
    | MessagePathRecord[]
    | EventRecord[]
    | null,
): string[] | null => {
  if (data === null || data === undefined) {
    return null;
  }

  const columnSet = new Set<string>();

  for (let i = 0; i < data.length; i++) {
    const { metadata } = data[i];

    if (!metadata) {
      continue;
    }

    Object.keys(metadata).forEach((key) => columnSet.add(`metadata.${key}`));
  }

  return Array.from(columnSet);
};

export const createRows = (
  data:
    | Dataset[]
    | FileRecord[]
    | TopicRecord[]
    | MessagePathRecord[]
    | EventRecord[]
    | null,
  columns: string[],
  possibleMetadataColumns: string[],
): Row[] => {
  const rows: Row[] = [];

  if (data === null) {
    return [];
  }

  // create a row for each
  for (let i = 0; i < data.length; i++) {
    let row: Row;
    const rowData = data[i];

    if (isDataset(rowData)) {
      row = {
        id: rowData.dataset_id,
        cells: [],
      };
    } else if (isFile(rowData)) {
      row = {
        id: rowData.file_id,
        cells: [],
      };
    } else if (isTopic(rowData)) {
      row = {
        id: rowData.topic_id.toString(),
        cells: [],
      };
    } else if (isMsgPath(rowData)) {
      row = {
        id: rowData.message_path_id.toString(),
        cells: [],
      };
    } else if (isEvent(rowData)) {
      row = {
        id: rowData.event_id,
        cells: [],
      };
    } else {
      continue;
    }

    columns.forEach((column) => {
      if (possibleMetadataColumns.includes(column)) {
        pushMetadataCell(row, column, rowData);
      } else {
        pushStandardCell(row, column, rowData);
      }
    });

    rows.push(row);
  }

  return rows;
};
