import { EventRecord } from "@/domain/events";

import { DeleteEvent } from "./controls/DeleteEvent";

interface ToolbarProps {
  eventId: EventRecord["event_id"];
  onClose: () => void;
  onError: (error: Error) => void;
}

export function Toolbar({ eventId, onClose, onError }: ToolbarProps) {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <DeleteEvent eventId={eventId} onError={onError} onSuccess={onClose} />
    </div>
  );
}
