import { Box, SxProps, Theme, useTheme } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

interface RobotoIconProps {
  sx?: SxProps<Theme> | undefined;
}

export const RobotoIcon: React.FC<RobotoIconProps> = ({ sx }) => {
  const theme = useTheme();

  const imgURL =
    theme.palette.mode === "dark" ? "/iconBlack.svg" : "/iconWhite.svg";

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        width: "100%",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        ...sx,
      }}
    >
      <Link to="/">
        <img src={imgURL} width={50} height={50} alt="Logo" />
      </Link>
    </Box>
  );
};
