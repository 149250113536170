import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, IconButton, Stack, TextField, useTheme } from "@mui/material";
import * as React from "react";

interface ActionInputDataProps {
  inputData: string[];
  setInputData: (arg: string[]) => void;
  minRequired: number;
}

export const ActionInputData: React.FC<ActionInputDataProps> = ({
  inputData,
  setInputData,
  minRequired,
}) => {
  const theme = useTheme();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const newInputData = [...inputData];
    newInputData[index] = event.target.value;
    setInputData(newInputData);
  };

  return (
    <>
      <Stack
        sx={{
          maxWidth: "60ch",
        }}
        spacing={2}
      >
        {inputData &&
          inputData.map((_input, index: number) => (
            <Box
              key={"input-data-" + index}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                id="outlined-action-input-data"
                placeholder="Enter a file pattern, include all files with **/*"
                size="small"
                value={inputData[index]}
                sx={{ width: 400 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event, index);
                }}
              />
              {index === inputData.length - 1 && (
                <IconButton
                  aria-label="add-input-data"
                  size="small"
                  sx={{ ml: theme.spacing(0.5) }}
                  onClick={() => setInputData([...inputData, ""])}
                >
                  <AddCircleOutlineIcon color="primary" fontSize="small" />
                </IconButton>
              )}
              {inputData.length > minRequired && (
                <IconButton
                  aria-label="remove-input-data"
                  size="small"
                  sx={{ ml: theme.spacing(0.5) }}
                  onClick={() => {
                    const splicedData = inputData;
                    splicedData.splice(index, 1);
                    setInputData([...splicedData]);
                  }}
                >
                  <RemoveCircleOutlineIcon color="primary" fontSize="small" />
                </IconButton>
              )}
            </Box>
          ))}
      </Stack>
    </>
  );
};
