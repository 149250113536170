import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { CancelUploadDialog } from "./CancelUploadDialog";
import { FileUploadContext } from "./FileUploadContext";
import { getUploadItems } from "./getUploadItems";
import {
  FileUploadAction,
  UploadProviderState,
  UploadFileActionTypes,
  UIUploadItem,
  UploadItem,
} from "./types";
import { UploadProgressToast } from "./UploadProgressToast";
import { uploadReducer } from "./uploadReducer";
import { useUploadCoordinator } from "./useUploadCoordinator";

interface FileUploadProviderProps {
  children: React.ReactNode;
}

export const FileUploadProvider: React.FC<FileUploadProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer<
    React.Reducer<UploadProviderState, FileUploadAction>
  >(uploadReducer, {
    isCancelDialogOpen: false,
    isCancelUploadInProgress: false,
    isProcessingFiles: false,
    isToastVisible: false,
    processingError: null,
    uploadItems: {},
  });

  const queryClient = useQueryClient();

  let isUploading = false;

  Object.values(state.uploadItems).forEach((item) => {
    if (!item.isUploadCompleted) {
      isUploading = true;
    }
  });

  const handleUploadItemBegin = React.useCallback((itemId: string) => {
    dispatch({
      type: UploadFileActionTypes.UploadItemBegin,
      payload: {
        itemId,
      },
    });
  }, []);

  const handleUploadItemBeginCancellation = React.useCallback(
    (itemId: string) => {
      dispatch({
        type: UploadFileActionTypes.UploadItemBeginCancellation,
        payload: {
          itemId,
        },
      });
    },
    [],
  );

  const handleUploadItemCancelled = React.useCallback((itemId: string) => {
    dispatch({
      type: UploadFileActionTypes.UploadItemCancelled,
      payload: {
        itemId,
      },
    });
  }, []);

  const handleUploadItemProgress = React.useCallback(
    (itemId: string, bytesUploaded: number) => {
      dispatch({
        type: UploadFileActionTypes.UploadItemProgress,
        payload: {
          itemId,
          bytesUploaded,
        },
      });
    },
    [],
  );

  const handleUploadItemComplete = React.useCallback((itemId: string) => {
    dispatch({
      type: UploadFileActionTypes.UploadItemComplete,
      payload: {
        itemId,
      },
    });
  }, []);

  const handleUploadItemError = React.useCallback(
    (itemId: string, error: Error) => {
      dispatch({
        type: UploadFileActionTypes.UploadItemError,
        payload: {
          itemId,
          error,
        },
      });
    },
    [],
  );

  const handleUploadItemFirstBytes = React.useCallback((itemId: string) => {
    dispatch({
      type: UploadFileActionTypes.UploadItemFirstBytes,
      payload: {
        itemId,
      },
    });
  }, []);

  const { cancelInProgressUploads, coordinateUploadItems } =
    useUploadCoordinator(
      handleUploadItemBegin,
      handleUploadItemBeginCancellation,
      handleUploadItemCancelled,
      handleUploadItemProgress,
      handleUploadItemComplete,
      handleUploadItemError,
      handleUploadItemFirstBytes,
    );

  const beginFileUpload = async (
    event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
    orgId: string,
    datasetId: string,
    prefix?: string,
  ) => {
    const nativeEvent = event.nativeEvent;
    nativeEvent.preventDefault();

    dispatch({
      type: UploadFileActionTypes.BeginProcessing,
    });

    let uploadItems: UploadItem[] = [];
    let uiItems: UIUploadItem[] = [];

    try {
      uploadItems = await getUploadItems(nativeEvent, orgId, datasetId, prefix);

      uiItems = uploadItems.map((classItem) => {
        const item: UIUploadItem = {
          created: Date.now(),
          id: classItem.id,
          name: classItem.name,
          bytesUploaded: 0,
          totalBytes: classItem.totalBytes,
          isCancelling: false,
          isCancelled: false,
          isUploadCompleted: false,
          isUploadStarted: false,
          prefix: classItem.prefix,
          relative_path: classItem.path,
          dataset_id: classItem.datasetId,
          type: classItem.type,
        };

        return item;
      });
    } catch (reason) {
      if (reason instanceof Error) {
        dispatch({
          type: UploadFileActionTypes.ProcessingError,
          payload: {
            error: reason,
          },
        });
      } else {
        dispatch({
          type: UploadFileActionTypes.ProcessingError,
          payload: {
            error: new Error("Unknown error processing files"),
          },
        });
      }
    }

    dispatch({
      type: UploadFileActionTypes.EndProcessingBeginUpload,
      payload: {
        uploadItems: uiItems,
      },
    });

    await coordinateUploadItems(uploadItems);
  };

  const onExitToastClicked = () => {
    const inProgressUploads = Object.values(state.uploadItems).filter(
      (item) =>
        !item.isUploadCompleted &&
        item.isUploadStarted &&
        !item.isCancelling &&
        !item.isCancelled,
    );

    if (inProgressUploads.length > 0) {
      dispatch({ type: UploadFileActionTypes.ToggleCancelDialog });
    } else {
      dispatch({ type: UploadFileActionTypes.CloseToastDisplay });
    }
  };

  return (
    <FileUploadContext.Provider
      value={{
        beginFileUpload,
        isUploading,
      }}
    >
      <CancelUploadDialog
        open={state.isCancelDialogOpen}
        isCancelUploadInProgress={state.isCancelUploadInProgress}
        onCancelUpload={async () => {
          dispatch({ type: UploadFileActionTypes.BeginCancelUpload });
          await cancelInProgressUploads();
          await queryClient.refetchQueries({
            queryKey: ["directoryContents"],
          });
          dispatch({ type: UploadFileActionTypes.CloseToastDisplay });
        }}
        onContinueUpload={() => {
          dispatch({ type: UploadFileActionTypes.ToggleCancelDialog });
        }}
      />
      <UploadProgressToast
        isVisible={state.isToastVisible}
        isProcessing={state.isProcessingFiles}
        onExitClicked={onExitToastClicked}
        processingError={state.processingError}
        uploadItems={Object.values(state.uploadItems).sort((a, b) => {
          return a.created - b.created;
        })}
      />
      {children}
    </FileUploadContext.Provider>
  );
};
