import { Tooltip, TooltipProps, useTheme } from "@mui/material";
import * as React from "react";

export const RobotoTooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const theme = useTheme();
  return (
    <Tooltip
      placement={props.placement || "bottom"}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.secondary.main,
            "& .MuiTooltip-arrow": {
              color: theme.palette.secondary.main,
            },
          },
        },
      }}
      {...props}
    />
  );
};
