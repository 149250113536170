import Tag from "../Tag";
import { assignTagColor } from "../TagList/helpers";

interface TagsProps {
  // All elements in tags should be unique
  tags: string[];
  onClickTag?: (tag: string) => void;

  // If onDeleteTag is not provided, the tags will be readonly.
  onDeleteTag?: (tag: string) => void;
  truncateText?: boolean;
}

export function Tags({
  tags,
  onClickTag,
  onDeleteTag,
  truncateText = true,
}: TagsProps) {
  const sortedTags = [...tags].sort();

  return (
    <>
      {sortedTags.sort().map((tag) => {
        const tagColor = assignTagColor(tag);
        const onDelete = onDeleteTag && (() => onDeleteTag(tag));
        const onClick = onClickTag && (() => onClickTag(tag));

        return (
          <Tag
            key={tag}
            size="medium"
            sx={{
              fontSize: "0.75rem",
              backgroundColor: `rgba(${tagColor.toString()}, 0.35)`,
              borderRadius: "8px",
              ":hover": {
                backgroundColor: `rgba(${tagColor.toString()}, 0.55)`,
              },
            }}
            shrink={truncateText}
            value={tag}
            onDelete={onDelete}
            onTagClick={onClick}
          />
        );
      })}
    </>
  );
}
