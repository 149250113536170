import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material";
import * as React from "react";

import { Expandable } from "../Expandable";

import { MessagePath } from "./MessagePath";
import styles from "./Topic.module.css";
import { TopicLabel } from "./TopicLabel";
import { type TopicNode } from "./tree";

interface TopicProps {
  node: TopicNode;
  style?: React.CSSProperties;
}

/**
 * Render a Topic and its associated MessagePaths.
 */
export function Topic(props: TopicProps) {
  const { node } = props;
  const theme = useTheme();
  const [children, setChildren] = React.useState<JSX.Element | null>(null);

  const borderColor =
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.08)"
      : "rgba(0, 0, 0, 0.08)";

  const toggleExpanded = () => {
    if (node.children.length === 0) {
      return;
    }

    if (children !== null) {
      setChildren(null);
      return;
    }
    return setChildren(
      <div className={styles.messagePathContainer}>
        {node.children.map((child) => {
          return <MessagePath key={child.data.message_path_id} node={child} />;
        })}
      </div>,
    );
  };

  const baseLabel = (
    <TopicLabel dataType={node.data.schema_name || ""} label={node.label} />
  );

  const label = (
    <Expandable<TopicNode>
      data={node}
      StartIcon={children ? KeyboardArrowDownIcon : KeyboardArrowRightIcon}
      onClick={toggleExpanded}
      className={styles.topicIndent}
      iconClassName={styles.expandIcon}
    >
      {baseLabel}
    </Expandable>
  );

  return (
    <div
      style={{
        borderColor: borderColor,
      }}
      className={styles.container}
    >
      {label}
      {children}
    </div>
  );
}
