import { Duration } from "luxon";

/**
 *
 * This component formats the duration as days, hours, minutes, seconds, and milliseconds.
 *
 * The days should contain all the days and should not be capped at 2 digits.
 * Milliseconds should be capped at 3 digits and the rest should be capped at 2 digits.
 *
 * @param durationInSeconds
 * @returns
 */
export function formatDuration(durationInSeconds: number) {
  if (durationInSeconds < 0) {
    throw new Error("Duration is negative");
  }

  if (durationInSeconds === 0) {
    return "0d 0h 0m 0s 0ms";
  }

  const dur = Duration.fromObject({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: durationInSeconds,
    milliseconds: 0,
  });

  // Normalize spreads the seconds into the time units
  // explicitly described in the duration object
  const normalized = dur.normalize();

  const timeUnitsTemplateStrings = ["d'd'", "h'h'", "m'm'", "s's'", "S'ms'"];
  const timeUnits = [
    normalized.days,
    normalized.hours,
    normalized.minutes,
    normalized.seconds,
    normalized.milliseconds,
  ];

  const firstNonZeroIndex = timeUnits.findIndex((timeUnit) => timeUnit > 0);

  let lastNonZeroIndex = -1;
  for (let i = timeUnits.length - 1; i >= 0; i--) {
    if (timeUnits[i] > 0) {
      lastNonZeroIndex = i;
      break;
    }
  }

  const templateString = timeUnitsTemplateStrings
    .slice(firstNonZeroIndex, lastNonZeroIndex + 1)
    .join(" ");

  return dur.toFormat(templateString);
}
