import { HttpClient, robotoHeaders } from "../../http";

import { OrgRecord, OrganizationTier, RobotoRegion } from "./OrgRecord";

interface Options {
  abortSignal: AbortSignal;
  searchParams: URLSearchParams;
}

export class OrgService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getAvailableDataRegions(
    options?: Partial<Options>,
  ): Promise<RobotoRegion[]> {
    const requestUrl = this.#httpClient.constructUrl(`v1/integrations/regions`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
    });
    return await response.json<RobotoRegion[]>();
  }

  public async getOrg(
    orgId: string,
    options?: Partial<Options>,
  ): Promise<OrgRecord> {
    const requestUrl = this.#httpClient.constructUrl(`v1/orgs`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({
        orgId: orgId,
      }),
    });
    return await response.json<OrgRecord>();
  }

  public async updateOrgTier(
    orgId: string,
    tier: OrganizationTier,
    options?: Partial<Options>,
  ): Promise<OrgRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/orgs/id/${orgId}/tier`,
    );
    const response = await this.#httpClient.put(requestUrl, {
      idempotent: true,
      signal: options?.abortSignal,
      body: JSON.stringify({ tier: tier }),
    });
    return await response.json<OrgRecord>();
  }
}
