import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import * as React from "react";

dayjs.extend(utc);
dayjs.extend(timezone);

export const Localization: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DateTimePicker defaultValue={dayjs.utc('2022-04-17T15:30')} /> */}

      {children}
    </LocalizationProvider>
  );
};
