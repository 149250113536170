import { imageFileTypes, roboticsFileTypes, videoFileTypes } from "@/utils";

export const computeExtensionsArray = (
  serverExtensions: string[] | undefined,
) => {
  const fileExtensions = new Set<string>();

  if (serverExtensions) {
    serverExtensions.forEach(function (value) {
      fileExtensions.add(value);
    });
  }

  // Add useful image defaults
  imageFileTypes &&
    imageFileTypes.forEach(function (value) {
      fileExtensions.add(value);
    });

  // Add useful video defaults
  videoFileTypes &&
    videoFileTypes.forEach(function (value) {
      fileExtensions.add(value);
    });

  // Add useful robotics defaults
  roboticsFileTypes &&
    roboticsFileTypes.forEach(function (value) {
      fileExtensions.add(value);
    });

  return Array.from(fileExtensions.values()).sort();
};
