import { Extents } from "../PlotRenderer";

interface Dimensions {
  left: number;
  top: number;
  width: number;
  height: number;
}

/**
 * Maps the selected region's dimensions to a chart's time range.
 * @param region Dimensions are in pixels relative to the chart
 */
export function pixelRegionToTimeRange(
  region: Dimensions,
  chartExtents: Extents,
) {
  const {
    data: {
      logTime: [min, max],
    },
    pixel: { width },
  } = chartExtents;

  const right = region.left + region.width;

  const duration = Number(max - min);
  const pct = region.left / width;
  const startTime = min + BigInt(Math.round(duration * pct));

  const pct2 = right / width;
  const endTime = min + BigInt(Math.round(duration * pct2));

  return { startTime, endTime };
}
