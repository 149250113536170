import {
  AutocompleteType,
  TagAndMetadataAutocomplete,
} from "../TagAndMetadataAutocomplete";

interface TagInputProps {
  autocompleteType: AutocompleteType;
  onAddTag: (tag: string) => void;
  tags: string[];
  disabled?: boolean;
  size?: "small" | "medium";
  isLoading?: boolean;
}

export function TagInput({
  autocompleteType,
  onAddTag,
  tags,
  disabled = false,
  size = "medium",
  isLoading = false,
}: TagInputProps) {
  return (
    <TagAndMetadataAutocomplete
      autocompleteType={autocompleteType}
      disabled={disabled}
      existingTags={tags}
      onTagCreated={onAddTag}
      shrink={size === "small"}
      isLoading={isLoading}
    />
  );
}
