import { useCallback, useEffect, useRef, useState } from "react";

import { RobotoAPICall, APIServiceError, CallState } from "@/types";

import { initiateRequestFunc } from "./helper";

interface IUseLazyAPIQuery<Data> {
  loading: boolean;
  error: APIServiceError | null;
  data: Data | null;
  initiateRequest: (
    apiCall: RobotoAPICall,
  ) => Promise<{ error: APIServiceError | null; data: Data | null }>;
  updateCache: (newData: Data | null) => void;
}

export const useLazyAPICall = <
  ResponseData,
>(): IUseLazyAPIQuery<ResponseData> => {
  //
  const [callState, setCallState] = useState<CallState<ResponseData>>({
    loading: false,
    error: null,
    data: null,
  });

  // keep track of whether the component is still mounted
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const initiateRequest = useCallback(async (apiCall: RobotoAPICall) => {
    return await initiateRequestFunc<ResponseData>(
      apiCall,
      setCallState,
      isMountedRef,
    );
  }, []);

  const updateCache = useCallback((newData: ResponseData | null) => {
    setCallState((prevState) => {
      return {
        ...prevState,
        data: newData,
      };
    });
  }, []);

  return {
    loading: callState.loading,
    error: callState.error,
    data: callState.data,
    initiateRequest,
    updateCache,
  };
};
