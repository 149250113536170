import { LayoutOrientation } from "../actions";
import { Layout, LayoutItem } from "../schema/v1";

/**
 * Helper function to insert the new layout in the list of existing children either to the left
 * or right of its sibling depending on the orientation provided.
 */
export function insertLayoutAdjacentToSibling(
  existingLayouts: (Layout | LayoutItem)[],
  newLayout: Layout | LayoutItem,
  siblingLayout: Layout | LayoutItem,
  orientation: LayoutOrientation,
): (Layout | LayoutItem)[] {
  const siblingLayoutIndex = existingLayouts.findIndex(
    (child) => child.id === siblingLayout.id,
  );

  if (siblingLayoutIndex < 0) {
    throw new Error("siblingLayout not found in existingChildren");
  }

  if ([LayoutOrientation.TOP, LayoutOrientation.LEFT].includes(orientation)) {
    const newChildren = [...existingLayouts];
    newChildren.splice(siblingLayoutIndex, 0, newLayout);
    return newChildren;
  }

  // Otherwise add it after siblingLayout
  const newChildren = [...existingLayouts];
  newChildren.splice(siblingLayoutIndex + 1, 0, newLayout);
  return newChildren;
}

/**
 * Helper function to add a layout to the list of existing layout
 * either at the end of the list or beginning
 * depending on the orientation provided.
 */
export function addLayout(
  newLayout: Layout | LayoutItem,
  existingLayouts: (Layout | LayoutItem)[],
  orientation: LayoutOrientation,
): (Layout | LayoutItem)[] {
  if ([LayoutOrientation.TOP, LayoutOrientation.LEFT].includes(orientation)) {
    return [newLayout, ...existingLayouts];
  }
  return [...existingLayouts, newLayout];
}
