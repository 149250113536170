/**
 * Hook to measure the size of an element.
 * Adapted from https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
 */
import * as React from "react";

export type Dimensions = {
  width: number;
  height: number;
  top: number;
  left: number;
};
export type UseMeasureRef<E extends Element | null> = (element: E) => void;
export type UseMeasureResult<E extends Element | null> = [
  UseMeasureRef<E>,
  Dimensions,
];

const defaultState: Dimensions = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
};

export type OnDimensionsChange = (dims: Dimensions) => void;
interface UseMeasureOptions {
  onDimensionsChange?: OnDimensionsChange;
}

export function useMeasure<E extends Element>(
  options: UseMeasureOptions = {},
  defaults: Partial<Dimensions> = {},
): UseMeasureResult<E> {
  const [element, ref] = React.useState<E | null>(null);
  const [dims, setDims] = React.useState<Dimensions>(
    Object.assign({}, defaultState, defaults),
  );

  const { onDimensionsChange } = options;

  const observer = React.useMemo(
    function createResizeObserver() {
      return new ResizeObserver((entries) => {
        const entry = entries[0];
        if (!entry) {
          return;
        }

        const contentBox = entry.contentBoxSize[0];
        if (!contentBox) {
          return;
        }

        const { inlineSize, blockSize } = contentBox;
        const { top, left } = entry.target.getBoundingClientRect();
        const next = {
          width: inlineSize,
          height: blockSize,
          top,
          left,
        };
        setDims(next);
        if (onDimensionsChange) {
          onDimensionsChange(next);
        }
      });
    },
    [onDimensionsChange],
  );

  React.useLayoutEffect(
    function observe() {
      if (!element) {
        return;
      }
      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    },
    [element, observer],
  );

  return [ref, dims];
}
