import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";

import { type PanelState, isImagePanelState } from "@/state/visualization";

import {
  PanelSettingsSidebarProps,
  SidebarViewType,
  useWorkspaceSidebar,
} from "../../sidebarContext";
import { useEphemeralWorkspaceState } from "../../WorkspaceCtx";

interface OpenPanelSettingsProps {
  state: PanelState;
}

/**
 * A button that opens the settings for a panel.
 */
export function OpenPanelSettings({ state }: OpenPanelSettingsProps) {
  const workspace = useEphemeralWorkspaceState();
  const sidebar = useWorkspaceSidebar();

  const selectPanel = () => {
    workspace.selectPanel(state.id);
    sidebar.openView<PanelSettingsSidebarProps>({
      type: SidebarViewType.PanelSettings,
      data: {
        onClose: () => {
          workspace.clearSelectedPanel();
        },
        panelId: state.id,
      },
    });
  };

  // Enable this button for panel types for which we have settings.
  const enabled = isImagePanelState(state);
  const title = enabled
    ? "Open panel settings"
    : "No settings available for this panel";

  return (
    <IconButton
      aria-label="open-panel-settings"
      disabled={!enabled}
      onClick={selectPanel}
      size="small"
      title={title}
    >
      <SettingsIcon fontSize="small" />
    </IconButton>
  );
}
