import { useParams } from "react-router-dom";

import { NavigationPageWithRedirectProtection } from "@/components";
import { DevicePage } from "@/features/devices/pages/DevicePage";

export default function DeviceDetailPage() {
  const { deviceId } = useParams<{ deviceId: string | undefined }>();

  return (
    <NavigationPageWithRedirectProtection
      title={`Roboto - Device - ${deviceId}`}
    >
      <DevicePage deviceId={deviceId} />
    </NavigationPageWithRedirectProtection>
  );
}
