const ORG_OVERRIDE_HEADER = "X-Roboto-Org-Id";
const RESOURCE_OWNER_OVERRIDE_HEADER = "X-Roboto-Resource-Owner-Id";
const USER_OVERRIDE_HEADER = "X-Roboto-User-Id";

export function robotoHeaders(values: {
  orgId?: string;
  resourceOwnerId?: string;
  userId?: string;
}): Headers {
  const { orgId, resourceOwnerId, userId } = values;

  const headers = new Headers();

  if (orgId !== undefined) {
    headers.append(ORG_OVERRIDE_HEADER, orgId);
  }
  if (resourceOwnerId !== undefined) {
    headers.append(RESOURCE_OWNER_OVERRIDE_HEADER, resourceOwnerId);
  }
  if (userId !== undefined) {
    headers.append(USER_OVERRIDE_HEADER, userId);
  }

  return headers;
}
