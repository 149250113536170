import AddIcon from "@mui/icons-material/Add";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

interface DashboardButtonProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  bgColor: string;
  iconColor: string;
  href: string;
}

export const DashboardButton: React.FC<DashboardButtonProps> = ({
  title,
  description,
  icon,
  bgColor,
  iconColor,
  href,
}) => {
  const theme = useTheme();
  return (
    <Box
      component={Paper}
      variant="outlined"
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: theme.spacing(2),
        alignItems: "center",
        gap: theme.spacing(2),
        backgroundColor: theme.palette.paper.main,
        "&:hover": {
          boxShadow: 1,
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: theme.spacing(3),
          alignItems: "center",
          textDecoration: "none",
        }}
        component={RouterLink}
        to={href}
      >
        <Box
          sx={{
            backgroundColor: bgColor,
            borderRadius: theme.border.radius,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "45px",
            maxHeight: "45px",
            padding: theme.spacing(2.5),
            color: iconColor,
          }}
        >
          {icon}
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            <strong>{title}</strong>
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              fontSize: "0.8rem",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddIcon />
      </Box>
    </Box>
  );
};
