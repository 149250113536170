import { UserRecord } from "../users/UserRecord";

export enum OrganizationType {
  Team = "team",
  Individual = "individual",
}

export enum OrganizationTier {
  Free = "free",
  Premium = "premium",
}

export enum OrganizationStatus {
  Provisioning = "provisioning",
  Active = "active",
  Deprovisioning = "de-provisioning",
}

export enum RobotoRegion {
  UsEast = "us-east",
  UsWest = "us-west",
  UsGovWest = "us-gov-west",
  EuCentral = "eu-central",
}

export const humanReadableRobotoRegion = (region: RobotoRegion): string => {
  switch (region) {
    case RobotoRegion.UsEast:
      return "US East";

    case RobotoRegion.UsWest:
      return "US West";

    case RobotoRegion.UsGovWest:
      return "US Gov West";

    case RobotoRegion.EuCentral:
      return "EU Central";
  }
};

export interface OrgRecord {
  org_id: string;
  org_type: OrganizationType;
  name: string;
  tier: OrganizationTier;
  status: OrganizationStatus;
  created: string;
  created_by?: string;
}

export interface OrgInviteRecord {
  invite_id: string;
  user_id: string;
  invited_by: UserRecord;
  org: OrgRecord;
}
