import {
  Alert,
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoTableCell, RobotoTableRow } from "@/components";
import { useAuth } from "@/providers";
import { usePaginatedAPICall } from "@/service/apiHooks";
import { RobotoAPICall } from "@/types";

import { collectionEndpoint } from "../api";
import {
  Collection,
  CollectionChangeRecord,
  CollectionChangeSet,
} from "../model";

type RowsPerPage = 10 | 25 | 50;
const initialRowsPerPage = 10;

interface CollectionAuditProps {
  collection: Collection;
}

export const CollectionAudit: React.FC<CollectionAuditProps> = ({
  collection,
}) => {
  const theme = useTheme();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(initialRowsPerPage);

  const { currentOrganization } = useAuth();

  const {
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    onRowsPerPageChange,
    pageData,
    cacheLength,
    isNextPageAvailable,
    loading,
  } = usePaginatedAPICall<CollectionChangeRecord>();

  React.useEffect(() => {
    const apiCall: RobotoAPICall = {
      endpoint: collectionEndpoint,
      method: "GET",
      pathParams: {
        collectionId: collection.collection_id,
        changeset: 1,
      },
      orgId: currentOrganization?.org_id,
    };

    void getFirstPage(apiCall, initialRowsPerPage ?? 10);
  }, [currentOrganization?.org_id, collection.collection_id, getFirstPage]);

  function formatChangeSet(changeset: CollectionChangeSet) {
    const changeGroup = [] as React.ReactElement[];

    if (changeset.added_resources.length > 0) {
      for (let idx = 0; idx < changeset.added_resources.length; ++idx) {
        changeGroup.push(
          <p style={{ color: theme.palette.success.main }}>
            + {changeset.added_resources[idx].resource_id}
          </p>,
        );
      }
    }

    if (changeset.removed_resources.length > 0) {
      for (let idx = 0; idx < changeset.removed_resources.length; ++idx) {
        changeGroup.push(
          <p style={{ color: theme.palette.error.main }}>
            - {changeset.removed_resources[idx].resource_id}
          </p>,
        );
      }
    }

    if (changeset.added_tags.length > 0) {
      for (let idx = 0; idx < changeset.added_tags.length; ++idx) {
        changeGroup.push(
          <p style={{ color: theme.palette.success.main }}>
            + {changeset.added_tags[idx]}
          </p>,
        );
      }
    }

    if (changeset.removed_tags.length > 0) {
      for (let idx = 0; idx < changeset.removed_tags.length; ++idx) {
        changeGroup.push(
          <p style={{ color: theme.palette.error.main }}>
            - {changeset.removed_tags[idx]}
          </p>,
        );
      }
    }

    if (Object.keys(changeset.field_changes).length > 0) {
      const fields = Object.keys(changeset.field_changes);
      for (let idx = 0; idx < fields.length; ++idx) {
        changeGroup.push(
          <p style={{ color: theme.palette.success.main }}>
            + {fields[idx]}:{" "}
            {changeset.field_changes[fields[idx]] as unknown as string}
          </p>,
        );
      }
    }

    return changeGroup;
  }

  const onPageChange = async (newPage: number) => {
    //
    if (newPage > page) {
      setPage(page + 1);
      await fetchNextPage(newPage, rowsPerPage, "body");
    } else {
      setPage(page - 1);
      fetchPreviousPage(newPage, rowsPerPage);
    }
  };

  const onRowsPerPageChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const val = parseInt(e?.target?.value) ?? 10;
    setPage(0);
    setRowsPerPage(val);
    await onRowsPerPageChange(val as RowsPerPage);
  };

  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <RobotoTableCell>Version</RobotoTableCell>
              <RobotoTableCell>Created</RobotoTableCell>
              <RobotoTableCell>Created By</RobotoTableCell>
              <RobotoTableCell>Changeset</RobotoTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                {Array.from({ length: 3 }, (_, i) => (
                  <TableRow key={`row-${i}`}>
                    {Array.from({ length: 4 }, (_, j) => (
                      <RobotoTableCell key={`cell-${j}`}>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          height={30}
                          sx={{ mt: 1, mb: 1 }}
                        />
                      </RobotoTableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {pageData?.length > 0 ? (
                  <>
                    {pageData.map((changeset) => {
                      return (
                        <>
                          <RobotoTableRow>
                            <RobotoTableCell>
                              {changeset.to_version}
                            </RobotoTableCell>
                            <RobotoTableCell>
                              {new Date(
                                changeset.applied || "",
                              ).toLocaleString()}
                            </RobotoTableCell>
                            <RobotoTableCell>
                              {changeset.applied_by}
                            </RobotoTableCell>
                            <RobotoTableCell sx={{ maxWidth: 400 }}>
                              <pre
                                style={{
                                  whiteSpace: "pre-wrap",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {formatChangeSet(changeset.change_set)}
                              </pre>
                            </RobotoTableCell>
                          </RobotoTableRow>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <RobotoTableRow>
                    <RobotoTableCell
                      style={{
                        paddingBottom: theme.spacing(2),
                        paddingTop: theme.spacing(2),
                      }}
                      colSpan={4}
                    >
                      <Alert severity="info">
                        There haven&apos;t been any changes to this collection
                        yet.
                      </Alert>
                    </RobotoTableCell>
                  </RobotoTableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={isNextPageAvailable ? -1 : cacheLength}
        nextIconButtonProps={{
          disabled: !isNextPageAvailable,
        }}
        rowsPerPageOptions={[10, 25, 50]}
        page={page}
        onPageChange={(_e, newPage) => {
          void onPageChange(newPage);
        }}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          void onRowsPerPageChangeHandler(e);
        }}
        labelRowsPerPage={"Rows per page:"}
      />
    </Box>
  );
};
