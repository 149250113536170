import StarIcon from "@mui/icons-material/Star";
import { useContext } from "react";
import * as React from "react";

import { RobotoButton } from "@/components";
import { OrgRecord, OrganizationTier } from "@/domain/orgs";
import { DomainServicesContext } from "@/providers/DomainServices";

interface OrgUpdateTierButtonProps {
  orgId: string;
  onError?: () => void;
  onSuccess?: (updatedOrg: OrgRecord) => void;
  currentOrgTier: OrganizationTier;
}

export const OrgUpdateTierButton: React.FC<OrgUpdateTierButtonProps> = ({
  orgId,
  currentOrgTier,
  onSuccess,
  onError,
}) => {
  const { orgs } = useContext(DomainServicesContext);

  const differentTier: OrganizationTier =
    currentOrgTier === OrganizationTier.Free
      ? OrganizationTier.Premium
      : OrganizationTier.Free;
  const buttonText =
    currentOrgTier === OrganizationTier.Free
      ? "Upgrade to Premium"
      : "Downgrade to Free";

  const onClick = () => {
    async function onClickInner() {
      try {
        const updatedOrg = await orgs.updateOrgTier(orgId, differentTier);

        if (onSuccess) {
          onSuccess(updatedOrg);
        }
      } catch {
        if (onError) {
          onError();
        }
      }
    }

    void onClickInner();
  };

  return (
    <RobotoButton
      eventName={"UpdateOrgTierClicked"}
      eventProperties={{
        orgId,
        tier: differentTier,
      }}
      onClick={onClick}
      startIcon={<StarIcon fontSize="small" />}
      variant="outlined"
    >
      {buttonText}
    </RobotoButton>
  );
};
