import { type ErrorDetail, type MessagePayload } from "@/webworker";

import type { Extents } from "../PlotRenderer";

export enum PlotEvent {
  Error = "error",
  Initialized = "initialized",
  LoadingStateChange = "loading-state-change",
  Rendered = "rendered",
}

export interface RenderedAnnotation {
  id: string;
  type: "event";
  pixel: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  color: string;
}

export interface PlotEventPayloadMap {
  [PlotEvent.Error]: ErrorDetail | Error;
  [PlotEvent.Initialized]: undefined;
  [PlotEvent.LoadingStateChange]: { isLoading: boolean };
  [PlotEvent.Rendered]: {
    extents: Extents | null;
    annotations: RenderedAnnotation[];
  };
}

export function isErrorEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotEvent, PlotEventPayloadMap[PlotEvent.Error]>
> {
  return event.data.type === PlotEvent.Error;
}

export function isInitializedEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotEvent, PlotEventPayloadMap[PlotEvent.Initialized]>
> {
  return event.data.type === PlotEvent.Initialized;
}

export function isLoadingStateChangeEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotEvent, PlotEventPayloadMap[PlotEvent.LoadingStateChange]>
> {
  return event.data.type === PlotEvent.LoadingStateChange;
}

export function isRenderedEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotEvent, PlotEventPayloadMap[PlotEvent.Rendered]>
> {
  return event.data.type === PlotEvent.Rendered;
}
