import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { IconButton } from "@mui/material";
import * as React from "react";

export const RobotoCommunity: React.FC = () => {
  return (
    <>
      <IconButton
        href="https://github.com/roboto-ai"
        target="_blank"
        sx={{
          cursor: "pointer",
        }}
      >
        <GitHubIcon fontSize="small" />
      </IconButton>

      <IconButton
        href="https://www.linkedin.com/company/robotoai"
        target="_blank"
        sx={{
          cursor: "pointer",
        }}
      >
        <LinkedInIcon fontSize="small" />
      </IconButton>

      <IconButton
        href="https://twitter.com/roboto_ai"
        target="_blank"
        sx={{
          cursor: "pointer",
        }}
      >
        <TwitterIcon fontSize="small" />
      </IconButton>
    </>
  );
};
