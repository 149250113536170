import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Slide from "@mui/material/Slide";
import * as React from "react";

import { EventDataEntityType, EventSourceType } from "@/components/events";
import { RobotoButton } from "@/components/RobotoButton";
import { RobotoIconButton } from "@/components/RobotoIconButton";
import {
  CreateEventSidebarProps,
  SidebarViewType,
  useWorkspaceSidebar,
} from "@/components/visualization/sidebarContext";
import { EphemeralWorkspaceStateContext } from "@/components/visualization/WorkspaceCtx";

import styles from "./SelectedRegionActionsMenu.module.css";

export interface TimeRange {
  startTime: bigint;
  endTime: bigint;
}

interface SelectedRegionActionsMenuProps {
  onClose: () => void;
  panelId: string;
  timeRange: TimeRange;
}

export function SelectedRegionActionsMenu({
  onClose,
  panelId,
  timeRange,
}: SelectedRegionActionsMenuProps) {
  const theme = useTheme();
  const sidebar = useWorkspaceSidebar();

  const { addEventToWorkspace } = React.useContext(
    EphemeralWorkspaceStateContext,
  );

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <div
        className={styles.menu}
        style={{
          borderColor: theme.palette.action.active,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <div className={styles.buttonGroup}>
          <RobotoButton
            eventName={"CreateEventFromSelectionClicked"}
            variant="text"
            onClick={function openCreateEventForm() {
              sidebar.toggleDrawer<CreateEventSidebarProps>({
                type: SidebarViewType.CreateEvent,
                data: {
                  defaultFields: {
                    start_time: timeRange.startTime,
                    end_time: timeRange.endTime,
                    scope: {
                      dataEntity: { type: EventDataEntityType.AllMessagePaths },
                      source: {
                        type: EventSourceType.ThisPanel,
                        panelId: panelId,
                      },
                    },
                  },
                  onSuccess: (event) => {
                    addEventToWorkspace(event);
                    onClose();
                  },
                  onCancel: onClose,
                  sourceOptions: [
                    { type: EventSourceType.ThisPanel, panelId },
                    { type: EventSourceType.AllPanels },
                  ],
                },
              });
            }}
          >
            Create Event
          </RobotoButton>
        </div>
        <RobotoIconButton
          eventName="PlotPanelSelectedRegionMenuClosed"
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
        >
          <CloseIcon
            sx={{ color: theme.palette.action.active }}
            fontSize="small"
          />
        </RobotoIconButton>
      </div>
    </Slide>
  );
}
