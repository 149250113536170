import {
  ExponentialBackoff,
  handleWhenResult,
  retry,
  type RetryPolicy,
} from "cockatiel";

import { HttpResponse } from "./HttpResponse";

export enum HttpStatus {
  REQUEST_TIMEOUT = 408,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

type BearerTokenVendor = () => Promise<string | undefined>;

interface ConstructorArgs {
  baseHeaders: Headers;
  baseUrl: URL;
  bearerTokenVendor: BearerTokenVendor;
}

// Range bounds are inclusive and start at 0
// https://www.rfc-editor.org/rfc/rfc9110#section-14.1.2-2
interface Range {
  unit: "bytes";
  start?: number;
  end?: number;
}

interface RequestOptions extends Omit<RequestInit, "method"> {
  idempotent?: boolean;
  retryPolicy?: RetryPolicy;
  excludeAuth?: boolean;
}

interface GetRequestOptions extends RequestOptions {
  ranges?: Range[];
}

/**
 * Lightweight facade around `fetch`, with built-in support for:
 *  - Authorizing requests (bearer token auth)
 *  - retry
 */
export class HttpClient {
  #baseHeaders?: Headers;
  #baseUrl?: URL;
  #bearerTokenVendor?: BearerTokenVendor;

  constructor(options?: Partial<ConstructorArgs>) {
    this.#baseHeaders = options?.baseHeaders;
    this.#baseUrl = options?.baseUrl;
    this.#bearerTokenVendor = options?.bearerTokenVendor;
  }

  public constructUrl = (
    relativeUrl: string,
    searchParams?: URLSearchParams,
  ): URL => {
    // Note: searchParams.size doesn't work on Safari 16 (17+ is fine)
    // This left out page_token in URLs resulting in an infinite loop
    const hasSearchParams =
      searchParams !== undefined && Array.from(searchParams).length > 0;
    let normalizedRelativePath = hasSearchParams
      ? `${relativeUrl}?${searchParams.toString()}`
      : relativeUrl;

    // This exists for base URLs which don't just end in '/', specifically, API Gateway autogenerated stage URLs
    // which look like https://qa9bevzouk.execute-api.us-gov-west-1.amazonaws.com/prod
    // Without this, the "/prod" would get dropped from the path
    if (this.#baseUrl?.pathname && this.#baseUrl.pathname !== "/") {
      normalizedRelativePath = `${this.#baseUrl.pathname}/${normalizedRelativePath}`;
    }

    return new URL(normalizedRelativePath, this.#baseUrl);
  };

  public delete = (
    url: URL,
    options: RequestOptions = {},
  ): Promise<HttpResponse> => {
    return this.#request({
      method: "DELETE",
      url,
      options,
    });
  };

  public get = (
    url: URL,
    options: GetRequestOptions = {},
  ): Promise<HttpResponse> => {
    return this.#request({
      method: "GET",
      url,
      options,
    });
  };

  public post = (
    url: URL,
    options: RequestOptions = {},
  ): Promise<HttpResponse> => {
    return this.#request({
      method: "POST",
      url,
      options,
    });
  };

  public put = (
    url: URL,
    options: RequestOptions = {},
  ): Promise<HttpResponse> => {
    return this.#request({
      method: "PUT",
      url,
      options,
    });
  };

  #request = async ({
    method,
    url,
    options,
  }: {
    method: string;
    url: URL;
    options: RequestOptions & GetRequestOptions;
  }) => {
    const headers = this.#buildHeaders(options.headers);

    if (!options.excludeAuth) {
      const bearerToken = await this.#bearerTokenVendor?.();
      if (bearerToken) {
        const authHeaderValue = `Bearer ${bearerToken}`;
        headers.append("Authorization", authHeaderValue);
      }
    }

    if (options.ranges && options.ranges.length > 0) {
      const unit = options.ranges[0].unit;
      if (options.ranges.some((range) => range.unit !== unit)) {
        // All ranges must have the same unit
        throw new Error("All HTTP ranges must have the same unit");
      }
      if (
        options.ranges.some(
          (range) => range.start === undefined && range.end === undefined,
        )
      ) {
        throw new Error(
          "HTTP range must specify a start or end offset, or both",
        );
      }
      const ranges = options.ranges.map((range) => {
        // E.g. "0-1", "-500", "9500-"
        return `${range.start ?? ""}-${range.end ?? ""}`;
      });
      headers.append("Range", `${unit}=${ranges.join(", ")}`);
    }

    const request = new Request(new URL(url, this.#baseUrl), {
      body: options.body,
      credentials: options.credentials,
      headers: this.#mergeWithBaseHeaders(headers),
      integrity: options.integrity,
      keepalive: options.keepalive,
      method,
      mode: options.mode,
      redirect: options.redirect,
      referrer: options.referrer,
      referrerPolicy: options.referrerPolicy,
      signal: options.signal,
      window: options.window,
    });
    const retryPolicy =
      options.retryPolicy ??
      this.#retryPolicyFactory(options.idempotent ?? true);

    // Fetch only allows you to use a request once, so without calling clone, retry attempts will fail non-gracefully
    const responsePromise = (): Promise<Response> => {
      return fetch(request.clone());
    };

    const response = await retryPolicy.execute<Response>(
      responsePromise,
      request.signal,
    );

    return new HttpResponse(response);
  };

  #buildHeaders = (headersInit?: HeadersInit): Headers => {
    const headers = new Headers();
    if (headersInit === undefined) {
      return headers;
    }

    if (headersInit instanceof Headers) {
      headersInit.forEach((value, key) => {
        headers.set(key, value);
      });
    } else if (Array.isArray(headersInit)) {
      headersInit.forEach(([key, value]) => {
        headers.set(key, value);
      });
    } else if (headersInit !== undefined) {
      Object.entries(headersInit).forEach(([key, value]) => {
        headers.set(key, value);
      });
    }
    return headers;
  };

  #mergeWithBaseHeaders = (headers: Headers): Headers => {
    const merged = new Headers(this.#baseHeaders);
    headers.forEach((value, key) => {
      merged.set(key, value);
    });
    return merged;
  };

  #retryPolicyFactory = (idempotent: boolean): RetryPolicy => {
    const isTransientHttpError = (res: unknown): boolean => {
      if (!(res instanceof Response)) {
        return false;
      }

      if (!idempotent) {
        if (
          [
            HttpStatus.REQUEST_TIMEOUT,
            HttpStatus.INTERNAL_SERVER_ERROR,
            HttpStatus.BAD_GATEWAY,
          ].includes(res.status)
        ) {
          return false;
        }
      }

      return [
        HttpStatus.REQUEST_TIMEOUT,
        HttpStatus.INTERNAL_SERVER_ERROR,
        HttpStatus.TOO_MANY_REQUESTS,
        HttpStatus.BAD_GATEWAY,
        HttpStatus.SERVICE_UNAVAILABLE,
        HttpStatus.GATEWAY_TIMEOUT,
      ].includes(res.status);
    };

    return retry(handleWhenResult(isTransientHttpError), {
      backoff: new ExponentialBackoff({
        maxDelay: 10_000, // 10s in ms
      }),
      maxAttempts: 5,
    });
  };
}
