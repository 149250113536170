function getenv<T>(name: string, defaultValue?: T): T {
  const value = import.meta.env[name] as T;
  if (value === undefined) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error(`Required environment variable ${name} is not defined`);
  }
  return value;
}

export interface SSOSignInConfig {
  cognitoProviderId: string;
  displayName: string;
}

function getSsoSignInConfig(): SSOSignInConfig | null {
  const serialized = getenv<string | null>("VITE_SSO_SIGN_IN_CONFIG", null);
  if (!serialized) {
    return null;
  }

  try {
    return JSON.parse(serialized) as SSOSignInConfig;
  } catch (e) {
    return null;
  }
}

export class Env {
  public static readonly appEnv = getenv<string>("VITE_REACT_APP_ENV");
  public static readonly mixpanelToken = getenv<string>(
    "VITE_REACT_APP_MIXPANEL_TOKEN",
  );
  public static readonly robotoServiceBaseUrl = getenv<string>("VITE_API_URL");
  public static readonly robotoMapboxToken =
    getenv<string>("VITE_MAPBOX_TOKEN");

  public static readonly sentryDsn = getenv<string>("VITE_SENTRY_DSN");
  public static readonly PostHogPublicToken = getenv<string>(
    "VITE_POSTHOG_API_KEY",
  );
  public static readonly enableGoogleSignIn =
    getenv<string>("VITE_ENABLE_GOOGLE_SIGNIN", "true") === "true";
  public static readonly ssoSignInConfig: SSOSignInConfig | null =
    getSsoSignInConfig();
}
