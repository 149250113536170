import { Box } from "@mui/material";
import * as React from "react";

import { RobotoButton } from "@/components";
import { SystemUserOnly } from "@/components/SystemUserOnly";
import { NavigationContext } from "@/providers";

import { DatasetEventsTable } from "./DatasetEventsTable";

interface DatasetEventsProps {
  datasetId: string;
}

export function DatasetEvents({ datasetId }: DatasetEventsProps) {
  const { goto } = React.useContext(NavigationContext);
  return (
    <div>
      <SystemUserOnly>
        <Box sx={{ display: "flex", marginBottom: "1rem" }}>
          <RobotoButton
            eventName={"CreateDatasetEventClicked"}
            eventProperties={{ datasetId }}
            variant="outlined"
            onClick={() => goto.createDatasetEvent({ datasetId })}
          >
            Create Event
          </RobotoButton>
        </Box>
      </SystemUserOnly>
      <DatasetEventsTable datasetId={datasetId} />
    </div>
  );
}
