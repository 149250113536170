import { fetchAuthSession } from "aws-amplify/auth";
import * as React from "react";

import { Env } from "@/config";
import { HttpClient, robotoHeaders } from "@/http";

import { AuthContext } from "../auth";

import { HttpClientContext } from "./HttpClientContext";

export function HttpClientProvider({ children }: React.PropsWithChildren) {
  const authState = React.useContext(AuthContext);

  const httpClientContext = React.useMemo(() => {
    const currentOrg = authState.getCurrentOrganization();
    return {
      httpClient: new HttpClient({
        baseHeaders: robotoHeaders({
          resourceOwnerId: currentOrg?.org_id,
        }),
        baseUrl: new URL(Env.robotoServiceBaseUrl),
        bearerTokenVendor: async () => {
          const session = await fetchAuthSession();
          return session.tokens?.idToken?.toString();
        },
      }),
    };
  }, [authState]);

  return (
    <HttpClientContext.Provider value={httpClientContext}>
      {children}
    </HttpClientContext.Provider>
  );
}
