import { NavigationPageWithRedirectProtection } from "@/components";
import { DashboardPage } from "@/features/dashboard/pages/DashboardPage";

export default function IndexRoute() {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Dashboard"}>
      <DashboardPage />
    </NavigationPageWithRedirectProtection>
  );
}
