import DataObjectIcon from "@mui/icons-material/DataObject";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MapIcon from "@mui/icons-material/Map";
import TimelineIcon from "@mui/icons-material/Timeline";
import classNames from "classnames";

import { PanelType } from "@/state/visualization";

interface PanelIconProps {
  className?: classNames.Argument;
  panelType: PanelType;
}

export function PanelIcon({ className, panelType }: PanelIconProps) {
  if (panelType === PanelType.Image) {
    return <ImageIcon className={classNames(className)} />;
  }
  if (panelType === PanelType.Log) {
    return <ListAltIcon className={classNames(className)} />;
  }
  if (panelType === PanelType.Plot) {
    return <TimelineIcon className={classNames(className)} />;
  }
  if (panelType === PanelType.RawMessage) {
    return <DataObjectIcon className={classNames(className)} />;
  }
  if (panelType === PanelType.Map) {
    return <MapIcon className={classNames(className)} />;
  }
  return null;
}
