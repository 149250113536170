import { Duration } from "luxon";

/*
Takes an ISO 8601 duration format, specifically in the form of "P[n]DT[n]H[n]M[n]S" 
and converts it to a mechanical timestamp of the format "[dd:]hh:mm:ss"

In the ISO 8601 format, "P" represents the duration, "T" is the time separator, 
and the number of days (D), hours (H), minutes (M), and seconds (S).

e.g., "PT55.716471S" represents a duration of 55.716471 seconds.
*/

export function createMechanicalTimeStr(isoDuration: string): string {
  try {
    const duration = Duration.fromISO(isoDuration);

    let format = "";
    if (duration.days > 0) {
      format += "dd:";
    }
    format += "hh:mm:ss";

    const formatted = duration.toFormat(format);

    // If the formatted duration is 0.. something likely went wrong
    // or the data was backfilled for missing durations. Show a dash
    // instead to prevent confusion re: prior runs.
    if (formatted === "00:00:00") {
      return "-";
    }

    return formatted;
  } catch (error) {
    // Handle parsing error
    return "-";
  }
}
