import { Box, CircularProgress } from "@mui/material";
import * as React from "react";

import { useMeasure, type OnDimensionsChange } from "@/hooks";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { useDomainServices } from "@/providers/DomainServices";
import { ErrorMonitoringService } from "@/service";
import { LayoutItem, MapPanelState } from "@/state/visualization";

import { useWorkspaceTimer } from "../../WorkspaceCtx";
import { NoDataMessage } from "../NoDataMessage";
import { PanelLayout } from "../PanelLayout";
import { RenderingError } from "../RenderingError";

import { ActivePointIndicator } from "./ActivePointIndicator";
import { TimestampedLocation } from "./geoTypes";
import { Legend } from "./Legend";
import styles from "./Map.module.css";
import { MapService } from "./MapService";

interface MapPanelProps {
  layout: LayoutItem;
  state: MapPanelState;
}

export function MapPanel({ layout, state }: MapPanelProps) {
  const timer = useWorkspaceTimer();

  const { files: fileService } = useDomainServices();
  const mapContainer = React.useRef<HTMLDivElement | null>(null);
  const [mapService, setMapService] = React.useState<MapService | null>(null);
  const [error, setError] = React.useState<Error | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [singleActiveLocation, setSingleActiveLocation] =
    React.useState<TimestampedLocation | null>(null);

  React.useEffect(() => {
    const container = mapContainer.current;

    if (container === null || error !== null) {
      return;
    }

    const abortController = new AbortController();

    const _mapService = new MapService({
      abortController: abortController,
      mapContainer: container,
      fileService: fileService,
      setIsLoading: setIsLoading,
      timer: timer,
      setSingleActiveLocation: setSingleActiveLocation,
    });

    _mapService
      .initialize()
      .then(() => {
        if (abortController.signal.aborted) {
          return;
        }
        setMapService(_mapService);
      })
      .catch((err) => {
        if (abortController.signal.aborted) {
          return;
        }
        const error =
          err instanceof Error
            ? err
            : new Error("Failed to initialize MapPanel", { cause: err });
        setError(error);
        ErrorMonitoringService.captureError(error);
      });

    return () => {
      // Abort after disposing to ensure all clean-up actions are complete
      _mapService.dispose();
      abortController.abort();
    };
  }, [error, mapContainer, fileService, timer]);

  /**
   * Update the MapServices's reference to state as it changes
   */
  React.useEffect(
    function updateState() {
      if (mapService === null) {
        return;
      }

      const abortController = new AbortController();
      mapService.setState(state.data, abortController.signal).catch((err) => {
        if (abortController.signal.aborted) {
          return;
        }
        const error =
          err instanceof Error
            ? err
            : new Error("Failed to get latest message for MapPanel", {
                cause: err,
              });
        setIsLoading(false);
        ErrorMonitoringService.captureError(error);
        setError(error);
      });

      return function abort() {
        abortController.abort();
      };
    },
    [mapService, state.data],
  );

  /**
   * Redraw the image when the div is resized
   */
  const onRenderingSurfaceResize = React.useCallback(
    function onResize() {
      if (mapService === null) {
        return;
      }
      mapService?.resize();
    },
    [mapService],
  );

  const onRenderingSurfaceResizeDebounced =
    useDebouncedCallback<OnDimensionsChange>(onRenderingSurfaceResize, 100);

  const [measured] = useMeasure<HTMLDivElement>({
    onDimensionsChange: onRenderingSurfaceResizeDebounced,
  });

  return (
    <PanelLayout isLoading={isLoading} state={state} layout={layout}>
      <Box
        className={styles.loadingIndicator}
        sx={{
          display: isLoading ? "block" : "none",
        }}
      >
        <CircularProgress size="2rem" />
      </Box>

      {state.data.length === 1 && singleActiveLocation && (
        // Show active point indicator if there's only one path on the map
        <ActivePointIndicator activeLocation={singleActiveLocation} />
      )}

      {state.data.length > 1 && (
        // Show legend if there are multiple paths
        <Legend
          state={state}
          centerOnPath={(pathId) => mapService?.centerOnPath(pathId)}
        />
      )}

      <div
        id="map"
        ref={(node) => {
          if (node !== null) {
            measured(node);
          }
          mapContainer.current = node;
        }}
        style={{ width: "100%", height: "100%" }}
      />
      <RenderingError error={error} onClearError={() => setError(null)} />
      <NoDataMessage panelData={state.data} />
    </PanelLayout>
  );
}
