import { TextField } from "@mui/material";
import * as React from "react";

import { type MessagePathSlice } from "@/state/visualization";

import { formatSlice } from "../../../message";

import styles from "./MessagePathSliceEditor.module.css";

// E.g., 1, 1:3, 1:, :3, :
const VALIDATION_PATTERN =
  /^\s*((?<index>\d*)|\s*(?<start>\d*)\s*:\s*(?<end>\d*)?\s*)?\s*$/;

interface MessagePathSliceProps {
  onChange: (slice: MessagePathSlice) => void;
  slice: MessagePathSlice;
}

/**
 * Render an editor for a part of a MessagePath that represents a slice of an array.
 */
export function MessagePathSliceEditor({
  onChange,
  slice,
}: MessagePathSliceProps) {
  const [isValid, setIsValid] = React.useState(true);

  const validateSliceOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const valid = VALIDATION_PATTERN.test(event.target.value.trim());
    setIsValid(valid);
  };

  const commitChange = (value: string) => {
    if (!isValid) {
      return;
    }

    const trimmedValue = value.trim();
    const patternMatch = VALIDATION_PATTERN.exec(trimmedValue);
    if (patternMatch === null) {
      return;
    }

    const groups = patternMatch.groups || {};
    const nextSlice = {
      ...slice,
    };
    if ("index" in groups && groups.index !== undefined) {
      // A single index of the array is specified
      const parsedIndex = Number.parseInt(groups.index);
      if (!Number.isNaN(parsedIndex)) {
        nextSlice.start = parsedIndex;
        nextSlice.end = parsedIndex + 1;
      }
    } else {
      // A range of indices is specified, in "slice notation"
      if ("start" in groups && groups.start !== undefined) {
        const parsedStart = Number.parseInt(groups.start);
        if (Number.isNaN(parsedStart)) {
          nextSlice.start = 0;
        } else {
          nextSlice.start = parsedStart;
        }
      } else {
        nextSlice.start = 0;
      }
      if ("end" in groups && groups.end !== undefined) {
        const parsedEnd = Number.parseInt(groups.end);
        if (!Number.isNaN(parsedEnd)) {
          nextSlice.end = parsedEnd;
        } else {
          nextSlice.end = Infinity;
        }
      } else {
        nextSlice.end = Infinity;
      }
    }
    if (nextSlice.start === slice.start && nextSlice.end === slice.end) {
      return;
    }
    onChange(nextSlice as MessagePathSlice);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    commitChange(event.target.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const inputs = event.currentTarget.getElementsByTagName("input");
    if (inputs.length === 0) {
      return;
    }

    const value = inputs[0].value;
    if (value !== null && typeof value === "string") {
      commitChange(value);
    }
  };

  return (
    <span className={styles.slice}>
      [
      <form onSubmit={onSubmit}>
        <TextField
          aria-label="slice"
          className={styles.sliceEditor}
          defaultValue={formatSlice(slice)}
          error={!isValid}
          margin="none"
          name="slice"
          onChange={validateSliceOnChange}
          onBlur={onBlur}
          size="small"
          variant="filled"
        />
      </form>
      ]
    </span>
  );
}
