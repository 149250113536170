export enum PageRoute {
  Account = "/account",
  ActionHub = "/hub",
  Actions = "/actions",
  Admin = "/admin",
  Collections = "/collections",
  CreateAction = "/actions/create",
  CreateDataset = "/datasets/create",
  CreateTrigger = "/triggers/create",
  Datasets = "/datasets",
  Devices = "/devices",
  Files = "/files",
  Home = "/",
  Invite = "/invites",
  Invocations = "/invocations",
  InvokeAction = "/actions/invoke",
  Notifications = "/notifications",
  Orgs = "/orgs",
  Search = "/search",
  Settings = "/settings",
  SignIn = "/signin",
  SignUp = "/signup",
  Triggers = "/triggers",
  Visualize = "/visualize",
}
