import { useParams } from "react-router-dom";

import { NavigationPageWithRedirectProtection } from "@/components";
import { CollectionPage } from "@/features/collections/pages/CollectionPage";

export default function CollectionDetailPage() {
  const { collectionId } = useParams<{ collectionId: string | undefined }>();

  return (
    <NavigationPageWithRedirectProtection
      title={`Roboto - Collection - ${collectionId}`}
    >
      <CollectionPage collectionId={collectionId} />
    </NavigationPageWithRedirectProtection>
  );
}
