import * as React from "react";

import { NavigationPageWithRedirectProtection } from "@/components";
import { CreateActionPage } from "@/features/actions/pages";

const ActionCreationPage: React.FC = () => {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Create Action"}>
      <CreateActionPage />
    </NavigationPageWithRedirectProtection>
  );
};

export default ActionCreationPage;
