import { HttpClient, robotoHeaders, type PaginatedResponse } from "../../http";

import {
  type TriggerEvaluationRecord,
  type TriggerEvaluationSummary,
  type TriggerRecord,
} from "./TriggerRecord";

export class TriggerService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getTriggerById(
    triggerId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<TriggerRecord> {
    const url = this.#httpClient.constructUrl(`v1/triggers/id/${triggerId}`);
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return response.json<TriggerRecord>();
  }

  public async getTriggerEvaluationsForDataset(
    datasetId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      limit: number;
      resourceOwnerId: string;
    }>,
  ): Promise<TriggerEvaluationRecord[]> {
    const baseUrl = `v1/triggers/dataset/id/${datasetId}/evaluations`;
    const paginatedResponse = await this.getPagedTriggerEvaluations(
      baseUrl,
      options,
    );
    const evaluations = [...paginatedResponse.items];

    let nextPageToken = paginatedResponse.next_token;
    while (nextPageToken) {
      const nextPage = await this.getPagedTriggerEvaluations(baseUrl, {
        ...options,
        pageToken: nextPageToken,
      });
      evaluations.push(...nextPage.items);
      nextPageToken = nextPage.next_token;
    }

    return evaluations;
  }

  public async getTriggerEvaluationsForTrigger(
    triggerName: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      limit: number;
      resourceOwnerId: string;
    }>,
  ): Promise<TriggerEvaluationRecord[]> {
    const baseUrl = `v1/triggers/${triggerName}/evaluations`;
    const paginatedResponse = await this.getPagedTriggerEvaluations(
      baseUrl,
      options,
    );
    const evaluations = [...paginatedResponse.items];

    let nextPageToken = paginatedResponse.next_token;
    while (nextPageToken) {
      const nextPage = await this.getPagedTriggerEvaluations(baseUrl, {
        ...options,
        pageToken: nextPageToken,
      });
      evaluations.push(...nextPage.items);
      nextPageToken = nextPage.next_token;
    }

    return evaluations;
  }

  public async getTriggerEvaluationsSummaryForDataset(
    datasetId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<TriggerEvaluationSummary> {
    const url = this.#httpClient.constructUrl(
      `v1/triggers/dataset/id/${datasetId}/evaluations/summary`,
    );
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return response.json<TriggerEvaluationSummary>();
  }

  private async getPagedTriggerEvaluations(
    baseUrl: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      limit: number;
      pageToken: string;
      resourceOwnerId: string;
    }>,
  ): Promise<PaginatedResponse<TriggerEvaluationRecord>> {
    const { abortSignal, limit, pageToken, resourceOwnerId } = options ?? {};
    const searchParams = new URLSearchParams();
    if (limit !== undefined) {
      searchParams.append("limit", limit.toString());
    }
    if (pageToken) {
      searchParams.append("page_token", pageToken);
    }
    const url = this.#httpClient.constructUrl(baseUrl, searchParams);
    const response = await this.#httpClient.get(url, {
      signal: abortSignal,
      headers: robotoHeaders({ resourceOwnerId }),
    });
    return response.json<PaginatedResponse<TriggerEvaluationRecord>>();
  }
}
