import { NavigationPageWithRedirectProtection } from "@/components";
import { CreateTriggerPage } from "@/features/actions/pages";

const TriggerCreationPage: React.FC = () => {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Create Trigger"}>
      <CreateTriggerPage />
    </NavigationPageWithRedirectProtection>
  );
};

export default TriggerCreationPage;
