import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { ZLayer } from "./types";

const createRobotoTheme = (mode: "light" | "dark") => {
  const typography = {
    fontFamily: [
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  };

  const borderColor = mode === "light" ? "#DFE3EB" : "rgb(81, 78, 83)";

  const theme = createTheme({
    typography,
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#355EDB",
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.severity === "info" &&
              ownerState.variant === "standard" && {
                backgroundColor:
                  mode === "light" ? "#E5F3FB" : "rgb(39, 39, 43)",
                color: mode === "light" ? "#004B6F" : "rgb(184, 231, 251)",
              }),
          }),
        },
      },
    },
    palette: {
      mode: mode,
      text: {
        primary: mode === "light" ? "#333333" : "#DDDDDD",
        secondary:
          mode === "light" ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.7)",
      },
      primary: {
        main: "rgb(2, 136, 209)", // blue
        light: "#355EDB20",
        dark: "#355EDB80",
      },
      secondary: {
        main: "#DF6888", // magenta
      },
      background: {
        default: mode === "light" ? "#F7F8FA" : "#202020",
      },
      toastHeader: {
        main: mode === "light" ? "#F7F8FA" : "rgb(39, 39, 43)",
      },
      foreground: {
        main: mode === "light" ? "#FFFFFF" : "rgb(39, 39, 43)",
      },
      navMenu: {
        main: mode === "light" ? "#fff" : "rgb(39, 39, 43)",
      },
      buttonHeader: {
        main: mode === "light" ? "#DF6888CC" : "#DF6888CC",
        light: mode === "light" ? "#DF6888" : "#DF6888",
      },
      paper: {
        main: mode === "light" ? "#fff" : "rgb(39, 39, 43)",
      },
      code: {
        main: mode === "light" ? "rgb(246, 248, 250)" : "rgb(41 42 43)",
      },
      tableHover: {
        main: mode === "light" ? "#eeeeee" : "rgb(22, 27, 34)",
        light: mode === "light" ? "#fcfcfc" : "rgb(22, 27, 34)",
      },
      panel: {
        main: mode === "light" ? "#fff" : "#323236",
      },
      hoverBackground: {
        main:
          mode === "light"
            ? "rgba(0, 0, 0, 0.04)"
            : "rgba(255, 255, 255, 0.08)",
      },
      tableRowBackground: {
        error: {
          main: mode === "light" ? "#fff3f0" : "#370400",
        },
        warning: {
          main: mode === "light" ? "#fffef0" : "#423700",
        },
      },
    },
    border: {
      thin: `1px solid ${borderColor}`,
      thick: `2px solid ${borderColor}`,
      radius: "4px",
      color: `${borderColor}`,
    },
    zLayer: (layer: ZLayer) => {
      switch (layer) {
        case 1:
          return 100;
        case 2:
          return 200;
        case 3:
          return 300;
        case 4:
          return 400;
        case 5:
          return 500;
        case 6:
          return 600;
        case 7:
          return 700;
        case 8:
          return 800;
        case 9:
          return 900;
        case 10:
          return 999;
      }
    },
    navTopBarHeight: "55px",
    navSideBarWidth: "55px",
    actionButtonHeight: "30.75px",
  });

  return theme;
};

export const lightTheme = responsiveFontSizes(createRobotoTheme("light"));

export const darkTheme = responsiveFontSizes(createRobotoTheme("dark"));
