import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import * as React from "react";

const headerStyles = {
  fontWeight: 500,
  fontSize: "0.85rem",
};

const ActionMetadataTableCell = styled(TableCell)(({ theme }) => ({
  minWidth: "120px",
  fontSize: "0.8rem",
  padding: theme.spacing(1, 2),
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ActionMetadataTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ActionContainerParamsTableProps {
  workdir: string | undefined;
  command: string | undefined;
  entrypoint: string | undefined;
  envVars: { [key: string]: unknown };
}

export const ActionContainerParamsTable: React.FC<
  ActionContainerParamsTableProps
> = ({ workdir, command, entrypoint, envVars }) => {
  const theme = useTheme();
  return (
    <>
      <TableContainer>
        <Table size="small" sx={{ width: "auto" }}>
          <TableBody>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell
                sx={{
                  verticalAlign: "top",
                }}
              >
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  Entry Point
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {entrypoint}
                </pre>
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell
                sx={{
                  verticalAlign: "top",
                }}
              >
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  Workdir
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {workdir}
                </pre>
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell
                sx={{
                  verticalAlign: "top",
                }}
              >
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  Command
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {command}
                </pre>
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell
                sx={{
                  verticalAlign: "top",
                }}
              >
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  Env Vars
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                {envVars && Object.keys(envVars).length > 0 ? (
                  <TableContainer>
                    <Table size="small" sx={{ width: "auto" }}>
                      <TableBody>
                        {envVars &&
                          Object.keys(envVars)?.map((envVar, idx) => {
                            return (
                              <TableRow key={idx}>
                                <ActionMetadataTableCell
                                  sx={{
                                    border: 0,
                                    p: 0,
                                    pb:
                                      idx + 1 !== Object.keys(envVars).length
                                        ? theme.spacing(1)
                                        : 0,
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <pre style={{ whiteSpace: "pre-wrap" }}>
                                    {envVar}
                                  </pre>
                                </ActionMetadataTableCell>
                                <ActionMetadataTableCell
                                  sx={{
                                    border: 0,
                                    p: 0,
                                    pb:
                                      idx + 1 !== Object.keys(envVars).length
                                        ? theme.spacing(1)
                                        : 0,
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <pre style={{ whiteSpace: "pre-wrap" }}>
                                    {envVars[envVar] as string}
                                  </pre>
                                </ActionMetadataTableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    <pre style={{ whiteSpace: "pre-wrap" }}>null</pre>
                  </Typography>
                )}
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
