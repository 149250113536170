import {
  Alert,
  Box,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoTableCell, RobotoTableRow } from "@/components";
import { OrgRecord } from "@/domain/orgs";
import { usePaginatedAPICall } from "@/service/apiHooks";
import { Repository, RobotoAPICall, listRepositoriesEndpoint } from "@/types";

import { RepoRow } from "./RepoRow";

type RowsPerPage = 10 | 25 | 50;
const initialRowsPerPage = 10;

interface RepoTableProps {
  currentOrg: OrgRecord | null;
  setAlertDialogText: (arg: string) => void;
  setAlertDialogAction: (
    value: React.SetStateAction<(() => Promise<void>) | undefined>,
  ) => void;
  setAlertDialogOpen: (arg: boolean) => void;
}

export const RepoTable: React.FC<RepoTableProps> = ({
  currentOrg,
  setAlertDialogText,
  setAlertDialogAction,
  setAlertDialogOpen,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const theme = useTheme();

  const [reloadToggle, setReloadToggle] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>("");

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(initialRowsPerPage);

  const {
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    onRowsPerPageChange,
    pageData,
    isNextPageAvailable,
    cacheLength,
  } = usePaginatedAPICall<Repository>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!currentOrg?.org_id) {
        return;
      }

      setIsLoading(true);

      const apiCall: RobotoAPICall = {
        endpoint: listRepositoriesEndpoint,
        method: "GET",
        orgId: currentOrg?.org_id,
      };

      await getFirstPage(apiCall, initialRowsPerPage);

      setIsLoading(false);
    };

    void fetchData();
  }, [getFirstPage, currentOrg?.org_id, reloadToggle]);

  const onPageChangeHandler = async (newPage: number) => {
    //
    if (newPage > page) {
      setPage(page + 1);
      await fetchNextPage(newPage, rowsPerPage);
    } else {
      setPage(page - 1);
      fetchPreviousPage(newPage, rowsPerPage);
    }
  };

  const onRowsPerPageChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const val = parseInt(e?.target?.value) ?? 10;
    setPage(0);
    setRowsPerPage(val);
    await onRowsPerPageChange(val as RowsPerPage);
  };

  return (
    <>
      {errorText && (
        <Box sx={{ mb: theme.spacing(3) }}>
          <Alert severity="error">{errorText}</Alert>
        </Box>
      )}
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <RobotoTableCell>Repository</RobotoTableCell>
              <RobotoTableCell>Images</RobotoTableCell>
              <RobotoTableCell>Operations</RobotoTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                {Array.from({ length: 3 }, (_, i) => (
                  <TableRow key={`row-${i}`}>
                    {Array.from({ length: 3 }, (_, j) => (
                      <RobotoTableCell key={`cell-${j}`}>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          height={30}
                          sx={{ mt: 1, mb: 1 }}
                        />
                      </RobotoTableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {pageData?.length > 0 ? (
                  <>
                    {pageData?.map((repo, idx) => {
                      return (
                        <RepoRow
                          key={idx}
                          repo={repo}
                          currentOrg={currentOrg}
                          reloadToggle={reloadToggle}
                          setReloadToggle={setReloadToggle}
                          setAlertDialogText={setAlertDialogText}
                          setAlertDialogAction={setAlertDialogAction}
                          setAlertDialogOpen={setAlertDialogOpen}
                          setErrorText={setErrorText}
                        />
                      );
                    })}
                  </>
                ) : (
                  <RobotoTableRow>
                    <RobotoTableCell
                      style={{
                        paddingBottom: theme.spacing(2),
                        paddingTop: theme.spacing(2),
                      }}
                      colSpan={6}
                    >
                      <Alert severity="warning">
                        No images have been pushed yet. You can push one with
                        the{" "}
                        <Link
                          underline="hover"
                          target="_blank"
                          href="https://docs.roboto.ai/getting-started/programmatic-access.html#python-sdk-and-cli"
                        >
                          Roboto CLI
                        </Link>
                        :
                        <br />
                        <br />
                        <span
                          style={{
                            fontFamily: "monospace",
                            fontSize: "0.8rem",
                          }}
                        >
                          {"$ roboto images push <image-name:1.2.3>"}
                        </span>
                      </Alert>
                    </RobotoTableCell>
                  </RobotoTableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={isNextPageAvailable ? -1 : cacheLength}
        nextIconButtonProps={{
          disabled: !isNextPageAvailable,
        }}
        rowsPerPageOptions={[10, 25, 50]}
        page={page}
        onPageChange={(_e, newPage) => {
          void onPageChangeHandler(newPage);
        }}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          void onRowsPerPageChangeHandler(e);
        }}
        labelRowsPerPage={"Repos per page:"}
        sx={{}}
      />
    </>
  );
};
