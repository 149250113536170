import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { EventRecord } from "@/domain/events";
import { PaginatedResponse } from "@/http";
import { DomainServicesContext } from "@/providers/DomainServices";

export function useDatasetEvents(datasetId: string) {
  const { datasetService } = useContext(DomainServicesContext);

  return useQuery<EventRecord[], Error>({
    queryKey: ["datasetEvents", datasetId],
    queryFn: async ({ signal }) => {
      let items: EventRecord[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const data: PaginatedResponse<EventRecord> =
          await datasetService.getEventsForDataset(datasetId, {
            pageToken: nextToken,
            limit: 1000,
            abortSignal: signal,
          });

        items = items.concat(data.items);
        nextToken = data.next_token;
      } while (nextToken);

      return items;
    },
  });
}
