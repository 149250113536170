import { Alert } from "@mui/material";

export interface Notification {
  id: string;
  message: string;
  severity: "info" | "success" | "warning" | "error";
}

interface NotificationsProps {
  notifications: Notification[];
  onDismiss: (notification: Notification) => void;
}

/**
 * Notifications are any workspace-wide messages that are not associated with a specific panel.
 */
export function Notifications({
  notifications,
  onDismiss,
}: NotificationsProps) {
  if (notifications.length === 0) {
    return null;
  }

  return (
    <div>
      {notifications.map((notification) => (
        <Alert
          key={notification.id}
          onClose={() => onDismiss(notification)}
          severity={notification.severity}
          sx={{
            marginTop: "1em",
          }}
        >
          {notification.message}
        </Alert>
      ))}
    </div>
  );
}
