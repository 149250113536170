import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { ReactNode } from "react";

import { isUrl } from "@/utils/urls";

import CopyToClipboardButton from "../CopyToClipboardButton";
import { RobotoTypography } from "../RobotoTypography";

interface ValueProps {
  value: unknown;
}

/**
 * Component that displays an arbitrary value gracefully
 */
export function Value({ value }: ValueProps) {
  switch (typeof value) {
    case "object": {
      if (value === null) {
        return (
          <RobotoTypography
            sx={{
              wordBreak: "break-word",
              fontSize: "0.85rem",
            }}
          >
            null
          </RobotoTypography>
        );
      }
      return (
        <>
          <RobotoTypography
            sx={{
              wordBreak: "break-word",
              fontSize: "0.85rem",
            }}
            component={"span"}
          >
            {Array.isArray(value) ? "Array" : "Object"}
            <CopyToClipboardButton
              text={JSON.stringify(value)}
              sx={{ marginLeft: "0.4rem" }}
            />
          </RobotoTypography>
        </>
      );
    }
    case "string": {
      if (isUrl(value)) {
        return (
          <Link target="_blank" rel="noopener noreferrer" href={value}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.85rem",
                lineHeight: 1.5,
              }}
              component="span"
            >
              Link
              <OpenInNewIcon
                sx={{
                  fontSize: 14,
                  marginLeft: "0.15rem",
                }}
              />
            </Box>
          </Link>
        );
      }
      return (
        <RobotoTypography
          sx={{
            wordBreak: "break-word",
            fontSize: "0.85rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <abbr title={value} style={{ textDecoration: "none" }}>
            {value}
          </abbr>
        </RobotoTypography>
      );
    }
    case "boolean": {
      return (
        <RobotoTypography
          sx={{
            wordBreak: "break-word",
            fontSize: "0.85rem",
          }}
        >{`${value}`}</RobotoTypography>
      );
    }
    case "undefined": {
      return (
        <RobotoTypography
          sx={{
            wordBreak: "break-word",
            fontSize: "0.85rem",
          }}
        >
          Not set
        </RobotoTypography>
      );
    }
    default:
      return (
        <RobotoTypography
          sx={{
            wordBreak: "break-word",
            fontSize: "0.85rem",
          }}
        >
          {value as ReactNode}
        </RobotoTypography>
      );
  }
}
