import { OrgInviteRecord, OrgRecord } from "@/domain/orgs";
import { RobotoRegion } from "@/domain/orgs/OrgRecord";

export type CurrentSignUpForm =
  | "configureDataRegion"
  | "configureExperiences"
  | "configureOrganization"
  | "howDoYouWork"
  | "interruptedVerificationCode"
  | "inviteTeamMembers"
  | "pendingInvites"
  | "selectOrCreateOrganization"
  | "signUp"
  | "verificationCode";

export type CurrentSignInForm =
  | "configureDataRegion"
  | "configureExperiences"
  | "configureOrganization"
  | "forgotPassword"
  | "howDoYouWork"
  | "inviteTeamMembers"
  | "loadOrgs"
  | "pendingInvites"
  | "resetPassword"
  | "selectOrCreateOrganization"
  | "signIn";

type WorkMode = "individual" | "team";

export interface SignUpFormState {
  currentSignUpForm: CurrentSignUpForm;
  emailAddress: string;
  individualAccountIdentifier: string;
  password: string;
  currentOrganizations: OrgRecord[] | null;
  workMode: WorkMode;
  organizationName?: string;
  allowEmailDomainToJoinOrg?: boolean;
  teamMemberEmails?: string[];
  pendingInvites: OrgInviteRecord[] | null;
  robotoRegion: RobotoRegion;
}

export interface SignInFormState {
  currentSignInForm: CurrentSignInForm;
  emailAddress: string;
  individualAccountIdentifier: string;
  workMode: WorkMode;
  currentOrganizations: OrgRecord[] | null;
  organizationName?: string;
  allowEmailDomainToJoinOrg?: boolean;
  teamMemberEmails?: string[];
  pendingInvites: OrgInviteRecord[] | null;
  robotoRegion: RobotoRegion;
}

export enum AmplifyAuthError {
  UserAlreadyAuthenticatedException = "UserAlreadyAuthenticatedException",
}
