/**
 * A Typescript implementation of Python's Event synchronization primitive.
 *
 * See reference documentation...:
 * https://docs.python.org/3/library/asyncio-sync.html#asyncio.Event
 *
 * ...and implementation:
 * https://github.com/python/cpython/blob/main/Lib/asyncio/locks.py
 *
 * Used to signal to one or many waiters that an event has occurred.
 */
export class AsyncEvent {
  #isSet: boolean = false;
  #waiters: ((value: boolean) => void)[] = [];

  public get isSet(): boolean {
    return this.#isSet;
  }

  clear(): void {
    this.#isSet = false;
  }

  set(): void {
    if (!this.#isSet) {
      this.#isSet = true;
      for (const resolve of this.#waiters) {
        resolve(true);
      }
      this.#waiters.length = 0;
    }
  }

  async wait(signal?: AbortSignal): Promise<boolean> {
    if (this.#isSet) {
      return Promise.resolve(true);
    } else {
      return new Promise<boolean>((resolve) => {
        if (signal) {
          if (signal.aborted) {
            // Fail fast if the signal is already aborted
            resolve(false);
            return;
          }

          const abortWaiter = () => {
            this.#waiters = this.#waiters.filter((p) => p !== resolve);

            resolve(false);
          };

          signal.addEventListener("abort", abortWaiter, { once: true });

          const resolver = (value: boolean | PromiseLike<boolean>) => {
            signal.removeEventListener("abort", abortWaiter);
            resolve(value);
          };
          this.#waiters.push(resolver);
          return;
        }
        this.#waiters.push(resolve);
      });
    }
  }
}
