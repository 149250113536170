import { useDroppable } from "@dnd-kit/core";
import { Box, type BoxProps } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { DropZone } from "./constants";
import styles from "./Droppable.module.css";

interface DroppableProps<T> extends BoxProps {
  data?: T;
  // The background color to use when the droppable is not being hovered by a draggable.
  defaultBackgroundColor?: string;
  droppableId: string;
  dropZone: DropZone;
  disabled?: boolean;
}

export interface DroppableData<T> {
  data?: T;
  dropZone: DropZone;
}

/**
 * A wrapper that makes its children drop targets within a DnD context.
 */
export function Droppable<T>(
  props: React.PropsWithChildren<DroppableProps<T>>,
) {
  const {
    children,
    data,
    defaultBackgroundColor = "transparent",
    droppableId,
    dropZone,
    style,
    ...rest
  } = props;

  const droppableData: DroppableData<T> = {
    data,
    dropZone,
  };
  const { isOver, setNodeRef } = useDroppable({
    id: droppableId,
    data: droppableData,
  });

  return (
    <Box
      {...rest}
      style={{
        ...style,
        backgroundColor: defaultBackgroundColor,
      }}
      ref={setNodeRef}
      className={classNames(
        {
          [styles.droppable]: isOver,
        },
        rest.className,
      )}
    >
      {children}
    </Box>
  );
}
