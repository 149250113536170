import ColorHash from "color-hash";

import {
  Action,
  ActionType,
  LayoutOrientation,
  isAddPathToMapPanelAction,
  isAddSeriesToPlotPanelAction,
  isCreatePanelAction,
  isMovePanelAction,
  isPutImagePanelDataAction,
  isPutFilesAction,
  isPutRawMessagePanelDataAction,
  isRemoveAllPanelsAction,
  isRemovePanelAction,
  isRemovePathFromMapPanelAction,
  isRemoveSeriesFromPlotPanelAction,
  isReplaceStateAction,
  isResizeLayoutsAction,
  isSetAllLayoutsResizingAction,
  isSetEventVisibilityAction,
  isSetVisibilityForAllEventsAction,
  isSetImagePanelConfigAction,
  isSetLayoutResizingAction,
  isSetMessagePathAction,
  isSetPathStyleAction,
  isSetPathVisibilityAction,
  isSetSeriesStyleAction,
  isSetSeriesVisibilityAction,
  isAddDataToLogPanelAction,
  isRemoveMessagePathFromLogPanelAction,
} from "../actions";
import { INITIAL_STATE, DEFAULT_IMAGE_PANEL_CONFIG } from "../context";
import { selectLayoutById } from "../hooks";
import {
  type Layout,
  type LayoutItem,
  isImagePanelState,
  isLayoutItem,
  isLogPanelState,
  isMapPanelState,
  isPlotPanelState,
  isRawMessagePanelState,
  LayoutType,
  PanelType,
  PlotSeries,
  State,
  TopicData,
} from "../schema";
import { MapPath } from "../schema/v1";

import { addItemToLayout } from "./addItemToLayout";
import { removeItemFromLayout } from "./removeItemFromLayout";
import { setIsLayoutResizing } from "./setIsLayoutResizing";
import { updateLayout } from "./updateLayout";

const colorHash = new ColorHash({ lightness: 0.5, saturation: 0.5 });

function idForSeries(data: TopicData): string {
  return `${data.representation.id}:${data.topic.name}:${data.messagePath.dotPath}`;
}

function idForMap(data: TopicData[]): string {
  if (data.length > 0) {
    return `${data[0].representation.id}:${data[0].topic.name}:${data[0].messagePath.dotPath}`;
  } else {
    return "Map";
  }
}

function titleForPlot(data: PlotSeries[]): string {
  if (data.length === 0) {
    return "";
  }

  if (data.length === 1) {
    const series = data[0];
    return `${series.data.topic.name}.${series.data.messagePath.dotPath}`;
  }

  return "Multiple topics";
}

function titleForMap(data: MapPath[]): string {
  if (data.length === 0) {
    return "";
  }

  // If there's 1 path
  if (data.length === 1 && data[0].data.length > 0) {
    const path = data[0];
    return `${path.data[0].topic.name}`;
  }

  // If there are multiple paths
  return "Multiple paths";
}

function titleForLogPanel(data: TopicData[]): string {
  if (data.length === 0) {
    return "";
  }
  return data[0].topic.name;
}

const defaultMapPathColor = "#007cbf";

export function reducer<T>(state: State, action: Action<T>): State {
  switch (action.type) {
    case ActionType.AddDataToLogPanel: {
      if (!isAddDataToLogPanelAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isLogPanelState(panel)) {
        return state;
      }

      const nextData = [...panel.data, action.payload.data];
      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          data: nextData,
          title: titleForLogPanel(nextData),
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.AddPathToMapPanel: {
      if (!isAddPathToMapPanelAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isMapPanelState(panel)) {
        return state;
      }

      const id = idForMap(action.payload.data);

      if (panel.data.some((path) => path.id === id)) {
        return state;
      }

      let color = defaultMapPathColor;
      if (panel.data.length > 0) {
        color = colorHash.hex(id);
      }

      const path = {
        data: action.payload.data,
        id,
        visible: true,
        style: {
          lineColor: color,
        },
      };

      const nextData = [...panel.data, path];
      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          data: nextData,
          title: titleForMap(nextData),
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.AddSeriesToPlotPanel: {
      if (!isAddSeriesToPlotPanelAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isPlotPanelState(panel)) {
        return state;
      }

      const id = idForSeries(action.payload.data);
      if (panel.data.some((series) => series.id === id)) {
        return state;
      }

      const series = {
        data: action.payload.data,
        id,
        visible: true,
      };

      const nextData = [...panel.data, series];
      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          data: nextData,
          title: titleForPlot(nextData),
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.CreatePanel: {
      if (!isCreatePanelAction(action)) {
        return state;
      }

      const base = { id: crypto.randomUUID() };

      const updatedPanels = {
        ...state.panels,
      };

      let payloadData = null;

      if (action.payload.data && action.payload.data.length === 1) {
        payloadData = action.payload.data[0];
      }

      if (action.payload.type === PanelType.Plot) {
        if (payloadData === null) {
          const panel = {
            ...base,
            type: action.payload.type,
            data: [],
            title: "",
          };
          updatedPanels[panel.id] = panel;
        } else {
          const series = {
            data: payloadData,
            id: idForSeries(payloadData),
            visible: true,
          };
          const panel = {
            ...base,
            type: action.payload.type,
            data: [series],
            title: titleForPlot([series]),
          };
          updatedPanels[panel.id] = panel;
        }
      } else if (action.payload.type === PanelType.Image) {
        const panel = {
          ...base,
          type: PanelType.Image,
          config: DEFAULT_IMAGE_PANEL_CONFIG,
          data: payloadData,
          title: payloadData?.topic.name ?? "",
        };
        updatedPanels[panel.id] = panel;
      } else if (action.payload.type === PanelType.Log) {
        if (payloadData === null) {
          const panel = {
            ...base,
            type: action.payload.type,
            data: [],
            title: "",
          };
          updatedPanels[panel.id] = panel;
        } else {
          const panel = {
            ...base,
            type: action.payload.type,
            data: [payloadData],
            title: payloadData.topic.name,
          };
          updatedPanels[panel.id] = panel;
        }
      } else if (action.payload.type === PanelType.Map) {
        if (action.payload.data === null) {
          const panel = {
            ...base,
            type: action.payload.type,
            data: [],
            title: "",
          };
          updatedPanels[panel.id] = panel;
        } else {
          const path = {
            data: action.payload.data,
            id: idForMap(action.payload.data),
            visible: true,
            style: {
              lineColor: defaultMapPathColor,
            },
          };
          const panel = {
            ...base,
            type: action.payload.type,
            data: [path],
            title: titleForMap([path]),
          };
          updatedPanels[panel.id] = panel;
        }
      } else {
        const panel = {
          ...base,
          type: action.payload.type,
          data: payloadData,
          title: payloadData?.topic.name ?? "",
        };
        updatedPanels[panel.id] = panel;
      }

      const nextState: State = {
        ...state,
        panels: updatedPanels,
      };

      // If placement not provided we add the new panel to the left of the root layout
      const placement = action.payload.placement ?? {
        siblingLayout: state.layout,
        orientation: LayoutOrientation.LEFT,
      };

      const layoutItem: LayoutItem = {
        id: base.id,
        isResizing: false,
        relativeSize: 1,
        type: LayoutType.Panel,
      };

      return addItemToLayout(nextState, layoutItem, placement);
    }
    case ActionType.MovePanel: {
      if (!isMovePanelAction(action)) {
        return state;
      }

      const nextState = removeItemFromLayout(state, action.payload.panelLayout);

      // Special case:
      // We currently have the ability to specify a layout as the drop target.
      // As a result, there is a possibility that when we move a panel out of a layout that contains
      // just two children, one of which is the panel we're moving, we delete that layout and merge its
      // remaining child up the tree.
      // So to handle that we set the siblingLayout to be that layout's child if the layout no longer exists
      let { siblingLayout } = action.payload.placement;

      const layout = selectLayoutById(nextState, siblingLayout.id);

      if (layout === undefined) {
        if (isLayoutItem(siblingLayout) === false) {
          if (siblingLayout.children.length === 2) {
            const newSiblingLayout = siblingLayout.children.find(
              (child) => child.id !== action.payload.panelLayout.id,
            );
            if (newSiblingLayout !== undefined) {
              siblingLayout = newSiblingLayout;
            }
          }
        }
      } else {
        // Update siblingLayout with the new size from removing the old panel
        siblingLayout = layout;
      }

      return addItemToLayout(nextState, action.payload.panelLayout, {
        ...action.payload.placement,
        siblingLayout,
      });
    }
    case ActionType.PutImagePanelData: {
      if (!isPutImagePanelDataAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isImagePanelState(panel)) {
        return state;
      }

      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          // Reset config to defaults if data changes.
          // If previous image was captured upside down or needed to be stretched to fit the canvas,
          // this one may not be.
          config: DEFAULT_IMAGE_PANEL_CONFIG,
          data: action.payload.data,
          title: action.payload.data.topic.name,
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.PutFiles: {
      if (!isPutFilesAction(action)) {
        return state;
      }

      // Only clear panels and layout if files have been removed
      const filesRemoved =
        state.files.filter(
          (file) => !action.payload.files.some((f) => f.fileId === file.fileId),
        ).length > 0;
      const panels = filesRemoved ? {} : state.panels;
      const layout = filesRemoved
        ? ({
            id: "root",
            relativeSize: 1,
            axis: "x",
            children: [],
          } as Layout)
        : state.layout;

      return {
        ...state,
        files: action.payload.files,
        // Clear panels and layout when files change
        panels,
        layout,
      };
    }
    case ActionType.PutRawMessagePanelData: {
      if (!isPutRawMessagePanelDataAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isRawMessagePanelState(panel)) {
        return state;
      }

      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          data: action.payload.data,
          title: action.payload.data.topic.name,
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.RemoveAllPanels: {
      if (!isRemoveAllPanelsAction(action)) {
        return state;
      }

      return {
        ...state,
        panels: { ...INITIAL_STATE.panels },
        layout: {
          ...INITIAL_STATE.layout,
          children: [],
        },
      };
    }
    case ActionType.RemoveMessagePathFromLogPanel: {
      if (!isRemoveMessagePathFromLogPanelAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isLogPanelState(panel)) {
        return state;
      }

      const nextData = panel.data.filter(
        (data) => data.messagePath.id !== action.payload.messagePathId,
      );

      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          data: nextData,
          title: titleForLogPanel(nextData),
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.RemovePanel: {
      if (!isRemovePanelAction(action)) {
        return state;
      }

      // Remove panel
      const existing = state.panels ?? {};
      const updatedPanels = { ...existing };
      const toBeDeleted = updatedPanels[action.payload.panelId];
      delete updatedPanels[toBeDeleted.id];

      const nextState: State = {
        ...state,
        panels: updatedPanels,
      };

      // Find layout for panel id
      const panelLayout = selectLayoutById(nextState, action.payload.panelId);

      if (panelLayout === undefined || !isLayoutItem(panelLayout)) {
        // Should never happen. A corresponding layout should exist for a panel in state
        return nextState;
      }

      return removeItemFromLayout(nextState, panelLayout);
    }
    case ActionType.RemovePathFromMapPanel: {
      if (!isRemovePathFromMapPanelAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isMapPanelState(panel)) {
        return state;
      }

      const nextData = panel.data.filter(
        (path) => path.id !== action.payload.pathId,
      );
      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          data: nextData,
          title: titleForMap(nextData),
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.RemoveSeriesFromPlotPanel: {
      if (!isRemoveSeriesFromPlotPanelAction(action)) {
        return state;
      }
      const panel = (state.panels ?? {})[action.payload.panelId];
      if (!panel || !isPlotPanelState(panel)) {
        return state;
      }

      const nextData = panel.data.filter(
        (series) => series.id !== action.payload.seriesId,
      );
      const updatedPanels = {
        ...state.panels,
        [panel.id]: {
          ...panel,
          data: nextData,
          title: titleForPlot(nextData),
        },
      };

      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.ReplaceState: {
      if (!isReplaceStateAction(action)) {
        return state;
      }
      return action.payload.state;
    }
    case ActionType.ResizeLayouts: {
      if (!isResizeLayoutsAction(action)) {
        return state;
      }

      const { layout1, layout2 } = action.payload;

      const nextState = {
        ...state,
        layout: updateLayout(state.layout, layout1.id, {
          relativeSize: layout1.relativeSize,
        }),
      };

      return {
        ...nextState,
        layout: updateLayout(nextState.layout, layout2.id, {
          relativeSize: layout2.relativeSize,
        }),
      };
    }
    case ActionType.SetAllLayoutsResizing: {
      if (!isSetAllLayoutsResizingAction(action)) {
        return state;
      }
      const { isResizing } = action.payload;
      return setIsLayoutResizing(state, state.layout.children, isResizing);
    }
    case ActionType.SetEventVisibility: {
      if (!isSetEventVisibilityAction(action)) {
        return state;
      }
      const { eventId, isVisible } = action.payload;
      const existingEventConfig = (state.events ?? {})[eventId];

      const updatedEvents = {
        ...state.events,
        [eventId]: {
          ...existingEventConfig,
          isVisible,
        },
      };
      return {
        ...state,
        events: updatedEvents,
      };
    }
    case ActionType.SetEventsVisibility: {
      if (!isSetVisibilityForAllEventsAction(action)) {
        return state;
      }
      const { isVisible, eventIds } = action.payload;
      const updatedEvents = {
        ...state.events,
      };
      eventIds.forEach((eventId) => {
        updatedEvents[eventId] = {
          ...updatedEvents[eventId],
          isVisible,
        };
      });
      return {
        ...state,
        events: updatedEvents,
      };
    }
    case ActionType.SetImagePanelConfig: {
      if (!isSetImagePanelConfigAction(action)) {
        return state;
      }
      const { panelId, config } = action.payload;
      const panel = (state.panels ?? {})[panelId];
      if (!panel || !isImagePanelState(panel)) {
        return state;
      }
      const updatedPanel = {
        ...panel,
        config: {
          ...panel.config,
          ...config,
        },
      };
      const updatedPanels = {
        ...state.panels,
        [panel.id]: updatedPanel,
      };
      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.SetLayoutResizing: {
      if (!isSetLayoutResizingAction(action)) {
        return state;
      }
      const { layoutIds, isResizing } = action.payload;

      const layouts = layoutIds.map((id) => {
        const layout = selectLayoutById(state, id);
        if (layout === undefined) {
          throw new Error(`Invalid layoutId: ${id}`);
        }
        return layout;
      });

      return setIsLayoutResizing(state, layouts, isResizing);
    }
    case ActionType.SetMessagePath: {
      if (!isSetMessagePathAction(action)) {
        return state;
      }
      const { messagePath, panelId, seriesId } = action.payload;
      const panel = (state.panels ?? {})[panelId];
      if (!panel || (isPlotPanelState(panel) && seriesId === undefined)) {
        return state;
      }
      let updatedPanel = panel;
      if (isPlotPanelState(panel)) {
        updatedPanel = {
          ...panel,
          data: panel.data.map((series) => {
            if (series.id === seriesId) {
              return {
                ...series,
                data: {
                  ...series.data,
                  messagePath,
                },
              };
            }
            return series;
          }),
        };
      } else if (isLogPanelState(panel) || isMapPanelState(panel)) {
        // Not supported by Log or Map panel
        updatedPanel = panel;
      } else {
        if (panel.data !== null) {
          updatedPanel = {
            ...panel,
            data: {
              ...panel.data,
              messagePath,
            },
          };
        }
      }
      const updatedPanels = {
        ...state.panels,
        [panel.id]: updatedPanel,
      };
      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.SetSeriesStyle: {
      if (!isSetSeriesStyleAction(action)) {
        return state;
      }

      const { panelId, seriesId, style } = action.payload;
      const panel = (state.panels ?? {})[panelId];

      if (!panel || !isPlotPanelState(panel)) {
        return state;
      }
      const updatedPanel = {
        ...panel,
        data: panel.data.map((series) => {
          if (series.id === seriesId) {
            return {
              ...series,
              style: {
                ...series.style,
                ...style,
              },
            };
          }
          return series;
        }),
      };
      const updatedPanels = {
        ...state.panels,
        [panel.id]: updatedPanel,
      };
      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.SetSeriesVisibility: {
      if (!isSetSeriesVisibilityAction(action)) {
        return state;
      }
      const { panelId, seriesId, visible } = action.payload;
      const panel = (state.panels ?? {})[panelId];
      if (!panel || !isPlotPanelState(panel)) {
        return state;
      }
      const updatedPanel = {
        ...panel,
        data: panel.data.map((series) => {
          if (series.id === seriesId) {
            return {
              ...series,
              visible,
            };
          }
          return series;
        }),
      };
      const updatedPanels = {
        ...state.panels,
        [panel.id]: updatedPanel,
      };
      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.SetPathStyle: {
      if (!isSetPathStyleAction(action)) {
        return state;
      }

      const { panelId, pathId, style } = action.payload;
      const panel = (state.panels ?? {})[panelId];

      if (!panel || !isMapPanelState(panel)) {
        return state;
      }
      const updatedPanel = {
        ...panel,
        data: panel.data.map((path) => {
          if (path.id === pathId) {
            return {
              ...path,
              style: {
                ...path.style,
                ...style,
              },
            };
          }
          return path;
        }),
      };
      const updatedPanels = {
        ...state.panels,
        [panel.id]: updatedPanel,
      };
      return {
        ...state,
        panels: updatedPanels,
      };
    }
    case ActionType.SetPathVisibility: {
      if (!isSetPathVisibilityAction(action)) {
        return state;
      }
      const { panelId, pathId, visible } = action.payload;
      const panel = (state.panels ?? {})[panelId];
      if (!panel || !isMapPanelState(panel)) {
        return state;
      }
      const updatedPanel = {
        ...panel,
        data: panel.data.map((path) => {
          if (path.id === pathId) {
            return {
              ...path,
              visible,
            };
          }
          return path;
        }),
      };
      const updatedPanels = {
        ...state.panels,
        [panel.id]: updatedPanel,
      };
      return {
        ...state,
        panels: updatedPanels,
      };
    }
    default:
      return state;
  }
}
