import ColorHash from "color-hash";
import * as React from "react";

import { Annotation, BoundingBox } from "./schema";
import { AnnotationViewFilters } from "./types";

const colorHash = new ColorHash();

function labelAtTop(box: BoundingBox) {
  if (box.y1 * 100 > 20) {
    return true;
  }
}

interface ImageAnnotationProps {
  annotation: Annotation;
  annotationViewFilters: AnnotationViewFilters;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ImageAnnotation: React.FC<ImageAnnotationProps> = ({
  annotation,
  annotationViewFilters,
  onClick,
}) => {
  const label = annotation.name.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
  const labelColor = colorHash.rgb(label || "");
  const labelColorStr = "rgba(" + labelColor.toString() + ",0.9)";
  const confidenceStr =
    annotation.confidence !== undefined
      ? ` ${Math.round(annotation.confidence * 100)}%`
      : "";
  const [isHovering, setIsHovering] = React.useState(false);

  const showLabels = annotationViewFilters.labels.show;
  const showConfidence = annotationViewFilters.confidenceThreshold.show;

  if (annotation.box === undefined) {
    return null;
  }

  return (
    <div
      style={{
        border: annotationViewFilters.boundingBox.show
          ? "1px solid " + labelColorStr
          : "1px solid rgba(1,1,1,0)",
        position: "absolute",
        left: (annotation.box.x1 * 100).toString() + "%",
        top: (annotation.box.y1 * 100).toString() + "%",
        width: ((annotation.box.x2 - annotation.box.x1) * 100).toString() + "%",
        height:
          ((annotation.box.y2 - annotation.box.y1) * 100).toString() + "%",
        zIndex: 100,
      }}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      onClick={onClick}
    >
      {(showLabels || showConfidence || isHovering) && (
        <div
          style={{
            padding: "2px",
            color: "#FFFFFF",
            fontWeight: "bold",
            fontSize: "0.7em",
            position: "absolute",
            background: labelColorStr,
            transition: "opacity 0.5s",
            opacity: 1,
            transform: labelAtTop(annotation.box)
              ? "translateY(-100%)"
              : "translateY(100%)",
            bottom: labelAtTop(annotation.box) ? "" : 0,
            left: "-1px",
            width: "max-content",
            zIndex: 105,
          }}
        >
          {(showLabels || isHovering) && label}
          {(showConfidence || (!showLabels && isHovering)) && confidenceStr}
        </div>
      )}
    </div>
  );
};
