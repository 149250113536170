import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Collapse, useTheme } from "@mui/material";
import * as React from "react";

import {
  RobotoIconButton,
  RobotoTableCell,
  RobotoTableRow,
  RobotoTooltip,
  RobotoButton,
} from "@/components";
import { OrgRecord } from "@/domain/orgs";
import { useLazyAPICall } from "@/service/apiHooks";
import { Repository, repositoryEndpoint } from "@/types";

import { ImageTable } from "./ImageTable";

interface RepoRowProps {
  repo: Repository;
  currentOrg: OrgRecord | null;
  reloadToggle: boolean;
  setReloadToggle: (arg: boolean) => void;
  setAlertDialogText: (arg: string) => void;
  setAlertDialogAction: (
    value: React.SetStateAction<(() => Promise<void>) | undefined>,
  ) => void;
  setAlertDialogOpen: (arg: boolean) => void;
  setErrorText: (arg: string) => void;
}

export const RepoRow: React.FC<RepoRowProps> = ({
  repo,
  currentOrg,
  reloadToggle,
  setReloadToggle,
  setAlertDialogText,
  setAlertDialogAction,
  setAlertDialogOpen,
  setErrorText,
}) => {
  const theme = useTheme();

  const { initiateRequest: deleteRepositoryRequest } = useLazyAPICall();

  const [listImages, setListImages] = React.useState<boolean>(false);

  function wrapActionWithDialog(text: string, action: () => Promise<void>) {
    setAlertDialogText(text);
    setAlertDialogAction(() => action);
    setAlertDialogOpen(true);
  }

  return (
    <React.Fragment>
      <RobotoTableRow>
        <RobotoTableCell
          sx={{
            borderBottom: "unset",
          }}
        >
          {repo.repository_name}
        </RobotoTableCell>
        <RobotoTableCell
          sx={{
            borderBottom: "unset",
          }}
        >
          <RobotoButton
            aria-label="list images"
            eventName={"RepositoryImagesViewToggled"}
            eventProperties={{
              expanded: listImages,
              repoName: repo.repository_name,
              repoUri: repo.repository_uri,
            }}
            disableElevation
            endIcon={listImages ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            size="small"
            variant={listImages ? "contained" : "outlined"}
            onClick={() => {
              setListImages(!listImages);
            }}
          >
            Show
          </RobotoButton>
        </RobotoTableCell>
        <RobotoTableCell
          sx={{
            borderBottom: "unset",
          }}
        >
          <RobotoTooltip title="Delete Repository">
            <span>
              <RobotoIconButton
                eventName={"DeleteRepositoryClicked"}
                eventProperties={{
                  repoName: repo.repository_name,
                  repoUri: repo.repository_uri,
                }}
                onClick={() =>
                  wrapActionWithDialog(
                    `Are you sure you want to delete ${repo.repository_name}?`,
                    async function () {
                      const { error } = await deleteRepositoryRequest({
                        endpoint: repositoryEndpoint,
                        method: "DELETE",
                        orgId: currentOrg?.org_id,
                        requestBody: JSON.stringify({
                          repository_name: repo.repository_name,
                        }),
                      });

                      if (!error) {
                        setReloadToggle(!reloadToggle);
                      } else {
                        setErrorText(error.message);
                      }
                    },
                  )
                }
              >
                <DeleteIcon
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                />
              </RobotoIconButton>
            </span>
          </RobotoTooltip>
        </RobotoTableCell>
      </RobotoTableRow>
      <RobotoTableRow>
        <RobotoTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={3}
        >
          <Collapse in={listImages} timeout="auto" unmountOnExit>
            <Box
              sx={{
                maxWidth: "80vw",
                mt: 2,
                mb: 3,
              }}
            >
              <ImageTable
                repo={repo}
                currentOrg={currentOrg}
                listImages={listImages}
                setAlertDialogText={setAlertDialogText}
                setAlertDialogAction={setAlertDialogAction}
                setAlertDialogOpen={setAlertDialogOpen}
                setErrorText={setErrorText}
              />
            </Box>
          </Collapse>
        </RobotoTableCell>
      </RobotoTableRow>
    </React.Fragment>
  );
};
