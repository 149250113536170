/**
 * Shadow of types and enums from `roboto.query.api`
 */

import { ISO_8601_DATETIME } from "@/types";
import { SearchQueryBody } from "@/types/search";

export enum QueryTarget {
  Collections = "collections",
  Datasets = "datasets",
  Events = "events",
  Files = "files",
  Topics = "topics",
  TopicMessagePaths = "topic_message_paths",
}

export enum QueryStatus {
  Completed = "completed",
  Failed = "failed",
  Scheduled = "scheduled",
}

export enum QueryScheme {
  QuerySpecV1 = "query_spec_v1",
}

export interface QueryRecord {
  modified: ISO_8601_DATETIME;
  org_id: string;
  query_id: string;
  query_ctx: {
    query_scheme: QueryScheme;
    query: Record<string, unknown>;
  };
  status: QueryStatus;
  submitted: ISO_8601_DATETIME;
  target: QueryTarget;
}

export interface SubmitStructuredQueryRequest {
  query: SearchQueryBody;
  target: QueryTarget;
}

export interface SubmitRoboqlQueryRequest {
  query: string;
  target: QueryTarget;
}

export interface SubmitTermQueryRequest {
  term: string;
  target: QueryTarget;
}
