import { Editor } from "@monaco-editor/react";
import { Box, useTheme } from "@mui/material";
import { editor } from "monaco-editor";
import * as React from "react";
import { useCallback } from "react";

import { QueryTarget } from "@/domain/query";

import {
  singletonCompletionProvider,
  roboqlEditorBeforeMount,
  RoboqlExternalCtx,
} from "./monaco";

export interface RoboqlEditorProps {
  initialValue?: string;
  triggerCompletionOnEmptyInitialValue?: boolean;
  onChange?: (value: string | undefined) => void;
  target?: QueryTarget;
  roboqlExternalCtx: RoboqlExternalCtx;
}

export const RoboqlEditor: React.FC<RoboqlEditorProps> = ({
  initialValue = "",
  onChange = () => {},
  target = QueryTarget.Datasets,
  triggerCompletionOnEmptyInitialValue = true,
  roboqlExternalCtx,
}) => {
  const theme = useTheme();

  React.useEffect(() => {
    singletonCompletionProvider().externalCtx = roboqlExternalCtx;
  }, [roboqlExternalCtx]);

  const editorRef = React.useRef<editor.IStandaloneCodeEditor>();
  const maybeTriggerCompletion = useCallback(() => {
    if (
      editorRef.current &&
      initialValue.trim() === "" &&
      triggerCompletionOnEmptyInitialValue
    ) {
      editorRef.current.trigger("", "editor.action.triggerSuggest", null);
    }
  }, [editorRef, initialValue, triggerCompletionOnEmptyInitialValue]);

  const innerOnChange = (
    value: string | undefined,
    event: editor.IModelContentChangedEvent,
  ) => {
    onChange(value);

    // If the last completion added a '[', we want to trigger autocomplete. This makes it so completing
    // msgpaths[] will drop a user in the middle of the '[]' and also provide any existing message paths as
    // suggestions. We need to add a small amount of delay in order for this to work smoothly.
    const triggerForMessagePath = event.changes.some(
      (change: editor.IModelContentChange) => {
        return (
          change.text.includes("msgpaths[") || change.text.includes("fields[")
        );
      },
    );

    if (editorRef.current && triggerForMessagePath) {
      setTimeout(() => {
        editorRef.current &&
          editorRef.current.trigger("", "editor.action.triggerSuggest", null);
      }, 50);
    }
  };

  const onMount = (mountedEditor: editor.IStandaloneCodeEditor) => {
    editorRef.current = mountedEditor;
    maybeTriggerCompletion();
  };

  React.useEffect(() => {
    if (editorRef.current) {
      // Retrigger the suggestion widget as new data might be available
      editorRef.current.trigger("editor", "hideSuggestWidget", []);
      editorRef.current.trigger("", "editor.action.triggerSuggest", null);
    }

    maybeTriggerCompletion();
  }, [
    maybeTriggerCompletion,
    target,
    roboqlExternalCtx.datasets?.metadataKeys?.isSuccess,
    roboqlExternalCtx.datasets?.tags?.isSuccess,
    roboqlExternalCtx.files?.metadataKeys?.isSuccess,
    roboqlExternalCtx.files?.tags?.isSuccess,
    roboqlExternalCtx.topics?.isSuccess,
  ]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: theme.spacing(0.75),
      }}
      data-cy={"roboqlEditor"}
    >
      <Editor
        beforeMount={roboqlEditorBeforeMount}
        onMount={onMount}
        height={"100%"}
        defaultLanguage={"roboql"}
        theme={theme.palette.mode === "dark" ? "vs-dark" : "light"}
        options={{
          // Another option is "interval", which only shows your current line.
          lineNumbers: "on",
          scrollBeyondLastLine: false,
          readOnly: false,

          // If unset, this defaults to 5, and pads line numbers, so they'll look uniform up to 5 digits of line number.
          // That uses a ton of horizontal space for basically a huge margin, so we want to disable it.
          lineNumbersMinChars: 2,

          // This stops Monaco from intercepting the right-click menu with its own command menu, and defaults to
          // whatever the browser was normally doing.
          contextmenu: false,

          // This gets rid of the thing that shows a really tiny version of your whole codebase
          minimap: {
            enabled: false,
          },

          // This gets rid of persistent scrollbars
          scrollbar: {
            horizontal: "hidden",
            vertical: "hidden",
            useShadows: false,
          },

          // This gets rid of an outline of where the scroll bar used to be
          overviewRulerBorder: false,

          // This gets rid of a little grey line above the outline
          overviewRulerLanes: 0,

          fontSize: theme.typography.fontSize,

          renderLineHighlight: "none",

          wordWrap: "on",
        }}
        value={initialValue}
        onChange={innerOnChange}
      />
    </Box>
  );
};
