import AddChartOutlined from "@mui/icons-material/AddchartOutlined";
import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { Menu } from "@/components/Menu";
import { RobotoButton } from "@/components/RobotoButton";
import { usePopupState } from "@/hooks";
import { actions, PanelType, useVizDispatch } from "@/state/visualization";

import { PanelIcon } from "../../Panel/PanelIcon";
import { panelTypeToDetails } from "../../PanelBoard";

export function AddPanel() {
  const theme = useTheme();
  const dispatch = useVizDispatch();
  const popup = usePopupState();

  return (
    <>
      <RobotoButton
        eventName={"AddPanelOptionsViewed"}
        variant="contained"
        disableElevation
        startIcon={<AddChartOutlined />}
        size="small"
        onClick={function openAddPanelMenu(
          event: React.MouseEvent<HTMLButtonElement>,
        ) {
          popup.open(event.currentTarget);
        }}
      >
        Add Panel
      </RobotoButton>
      <Menu
        anchorEl={popup.anchor}
        open={popup.isOpen}
        onClose={popup.close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {Object.entries(panelTypeToDetails).map(([panelType, panelDetails]) => (
          <MenuItem
            key={panelDetails.title}
            disableRipple
            onClick={() => {
              dispatch(actions.createPanel(null, panelType as PanelType));
              popup.close();
            }}
            style={{
              paddingTop: theme.spacing(1.5),
              paddingBottom: theme.spacing(1.5),
            }}
          >
            <Box>
              <PanelIcon panelType={panelType as PanelType} />
            </Box>
            <Box>
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {panelDetails.title} panel
              </Typography>
              <Typography
                variant="body2"
                style={{ fontSize: "0.83rem", color: "#777" }}
              >
                {panelDetails.description}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
