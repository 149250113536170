import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { DirectoryRecord } from "@/domain/files";
import { DomainServicesContext } from "@/providers/DomainServices";

interface RenameDirectoryProps {
  datasetId: string;
  currentPath: string;
  newName: string;
}

export function useRenameDirectory() {
  const { files } = React.useContext(DomainServicesContext);
  const queryClient = useQueryClient();

  return useMutation<DirectoryRecord, Error, RenameDirectoryProps>({
    mutationFn: async ({ datasetId, currentPath, newName }) => {
      return await files.renameDirectory(datasetId, currentPath, newName);
    },
    onSuccess: (directory) => {
      queryClient.setQueryData(["directoryRecord", directory.name], directory);
      return queryClient.invalidateQueries({ queryKey: ["directoryContents"] });
    },
  });
}
