import { Box, SxProps, Theme, useTheme } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

interface RobotoLogoProps {
  sx?: SxProps<Theme> | undefined;
}

export const RobotoLogo: React.FC<RobotoLogoProps> = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <Link to="/">
        <img
          alt="logo"
          style={{ maxWidth: "100%" }}
          src={
            theme.palette.mode === "dark"
              ? "/logoWhiteText.svg"
              : "/logoDarkText.svg"
          }
        />
      </Link>
    </Box>
  );
};
