import { selectParentLayoutByChildId } from "../hooks";
import { LayoutItem, State } from "../schema";

import { mergeChildrenWithParent } from "./mergeChildrenWithParent";
import { scaleChildrenProportionally } from "./scaleChildrenProportionally";
import { setChildrenAxisSize } from "./setChildrenAxisSize";
import { updateLayout } from "./updateLayout";

/**
 * Helper function to remove a LayoutItem from the layout tree
 */
export function removeItemFromLayout(
  state: State,
  itemToBeRemoved: LayoutItem,
): State {
  const parent = selectParentLayoutByChildId(state, itemToBeRemoved.id);

  if (parent === undefined) {
    // Parent wasn't found. Shouldn't reach here
    return state;
  }

  let nextLayout = {
    ...parent,
    children: parent.children.filter(
      (child) => child.id !== itemToBeRemoved.id,
    ),
  };

  // Special case where all panels have been removed from rootLayout
  if (parent.id === state.layout.id && nextLayout.children.length === 0) {
    return {
      ...state,
      layout: updateLayout(state.layout, parent.id, {
        children: nextLayout.children,
      }),
    };
  }

  // Rescale all remaining siblings to take the new found space
  if (itemToBeRemoved.relativeSize >= 1) {
    // Edge case: If we only have panels with size = 0 remaining, we should re-distribute the layout
    const nextChildren = setChildrenAxisSize(
      nextLayout.children,
      1 / nextLayout.children.length,
    );
    nextLayout = {
      ...nextLayout,
      children: nextChildren,
    };
  } else {
    // Otherwise, we should rescale the remaining panels by multiplying by the factor required to fit the new space
    // e.g. if we're removing panel1 with size 3/10, then we scale the other panels by 10/7.
    const nextChildren = scaleChildrenProportionally(
      nextLayout.children,
      1 / (1 - itemToBeRemoved.relativeSize),
    );
    nextLayout = {
      ...nextLayout,
      children: nextChildren,
    };
  }

  let nextState = {
    ...state,
    layout: updateLayout(state.layout, parent.id, {
      children: nextLayout.children,
    }),
  };

  // Special case where a nested layout has one child left
  if (nextLayout.id !== "root" && nextLayout.children.length === 1) {
    nextState = mergeChildrenWithParent(nextState, nextLayout);
  }

  return nextState;
}
