import * as React from "react";

export function useUpdateOnce<T>(defaultValue: T) {
  const [value, setValue] = React.useState<T>(defaultValue);
  const hasRunOnce = React.useRef(false);

  const reset = React.useCallback(
    function reset() {
      hasRunOnce.current = false;
      setValue(defaultValue);
    },
    [defaultValue],
  );

  const updateOnce = React.useCallback(function updateOnce(newValue: T) {
    if (hasRunOnce.current) {
      return;
    }
    hasRunOnce.current = true;
    setValue(newValue);
  }, []);

  return {
    value,
    reset,
    updateOnce,
  };
}
