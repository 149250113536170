import { NavigationPageWithRedirectProtection } from "@/components";
import { CollectionsPage } from "@/features/collections";

export default function Collections() {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Collections"}>
      <CollectionsPage />
    </NavigationPageWithRedirectProtection>
  );
}
