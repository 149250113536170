import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import {
  Alert,
  Box,
  Collapse,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import {
  handleTimeoutOnChangeState,
  isActionTimeoutValid,
} from "./actionUtils";

interface ActionTimeoutFieldProps {
  isGated: boolean;
  showAlert: boolean;
  actionTimeout: number | string | undefined;
  setActionTimeout: (arg: number | string | undefined) => void;
}

export function ActionTimeoutField({
  isGated,
  showAlert,
  actionTimeout,
  setActionTimeout,
}: ActionTimeoutFieldProps) {
  const theme = useTheme();
  const [openTimeout, setOpenTimeout] = React.useState<boolean>(false);

  return (
    <Box>
      <Box sx={{ mb: theme.spacing(2) }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2">Timeout (optional)</Typography>
          <IconButton
            aria-label="timeout"
            size="small"
            sx={{
              ml: theme.spacing(0.25),
              transform: openTimeout ? "rotate(180deg)" : "none",
            }}
            onClick={() => setOpenTimeout(!openTimeout)}
          >
            <ExpandCircleDownOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
        </Box>
        {isGated ? (
          <Typography variant="caption">
            Set a timeout to limit the action&apos;s maximum runtime. <br />
            Upgrade to premium to configure timeout values of up to 12 hours.
          </Typography>
        ) : (
          <Typography variant="caption">
            Set a timeout to limit the action&apos;s maximum runtime.
          </Typography>
        )}
      </Box>
      {isGated ? (
        <Collapse in={openTimeout} timeout="auto" unmountOnExit>
          {showAlert && (
            <Alert
              severity="warning"
              sx={{
                marginBottom: theme.spacing(2),
              }}
            >
              {
                "Your organization is currently in Roboto's free tier and so this action's maximum runtime will be constrained to 30 minutes."
              }
            </Alert>
          )}
          <TextField
            id="outlined-action-timeout"
            placeholder="30"
            size="small"
            minRows={1}
            maxRows={1}
            disabled={isGated}
            error={!isActionTimeoutValid(actionTimeout)}
            fullWidth
            value={actionTimeout}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setActionTimeout(parseInt(event.target.value));
            }}
          />
        </Collapse>
      ) : (
        <Collapse in={openTimeout} timeout="auto" unmountOnExit>
          <TextField
            id="outlined-action-timeout"
            placeholder="Enter an action timeout in minutes"
            size="small"
            minRows={1}
            maxRows={1}
            disabled={isGated}
            helperText={
              actionTimeout === undefined ||
              (actionTimeout && isActionTimeoutValid(actionTimeout))
                ? ""
                : "Action timeouts must be between 1 and 720 minutes"
            }
            error={!isActionTimeoutValid(actionTimeout)}
            fullWidth
            value={actionTimeout}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleTimeoutOnChangeState(event, setActionTimeout);
            }}
          />
        </Collapse>
      )}
    </Box>
  );
}
