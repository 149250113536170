import { type NavigateFunction } from "react-router-dom";

import { LoggerService } from "@/service";
import { actions, Dispatch } from "@/state/visualization";
import { PageRoute } from "@/types";

import {
  ActionRouteParams,
  ActionsRouteParam,
  CollectionRouteParams,
  DatasetRouteParams,
  DeviceRouteParams,
  FileRouteParams,
  InviteRouteParams,
  InvocationRouteParams,
  InvokeActionRouteParams,
  SignInRouteParams,
  SignUpRouteParams,
  TriggerRouteParams,
  VisualizerRouteParams,
} from "./types";

export class Goto {
  #navigate: NavigateFunction;
  #vizDispatch: Dispatch;

  constructor(navigator: NavigateFunction, vizDispatch: Dispatch) {
    this.#navigate = navigator;
    this.#vizDispatch = vizDispatch;
  }

  public home() {
    this.#navigate(PageRoute.Home);
  }

  public signIn(params: SignInRouteParams = {}) {
    const { inviteId, chooseOrg } = params;

    if (inviteId && chooseOrg) {
      LoggerService.warn(
        "Both inviteId and chooseOrg are set. Ignoring chooseOrg",
      );
    }

    if (inviteId) {
      this.#navigate(`${PageRoute.SignIn}?inviteId=${inviteId}`);
      return;
    }

    if (chooseOrg) {
      this.#navigate(`${PageRoute.SignIn}?chooseorg=true`);

      return;
    }

    this.#navigate(PageRoute.SignIn);
  }

  public signUp(params: SignUpRouteParams = {}) {
    const { interruptedVerificationCode } = params;

    if (interruptedVerificationCode) {
      this.#navigate(`${PageRoute.SignUp}?interruptedVerificationCode=true`);
    } else {
      this.#navigate(PageRoute.SignUp);
    }
  }

  public invite(params: InviteRouteParams = {}) {
    const { inviteId } = params;
    if (inviteId) {
      this.#navigate(`${PageRoute.Invite}/${inviteId}`);
    } else {
      this.#navigate(PageRoute.Invite);
    }
  }

  public search(urlParams?: URLSearchParams) {
    if (urlParams) {
      this.#navigate(`${PageRoute.Search}?${urlParams.toString()}`);
      return;
    }
    this.#navigate(PageRoute.Search);
  }

  public dataset({ datasetId, filePaths }: DatasetRouteParams) {
    if (datasetId) {
      this.#navigate(`${PageRoute.Datasets}/${datasetId}`);
    }

    if (filePaths) {
      this.#navigate(
        `${PageRoute.Datasets}/${filePaths.map(encodeURIComponent).join("/")}`,
      );
    }
  }

  public createDataset() {
    this.#navigate(`${PageRoute.CreateDataset}`);
  }

  public createDatasetEvent({ datasetId }: DatasetRouteParams) {
    this.#navigate(`${PageRoute.Datasets}/${datasetId}/events/create`);
  }

  public actions(params: ActionsRouteParam = {}) {
    const { tab } = params;
    if (tab) {
      this.#navigate(`${PageRoute.Actions}?tab=${tab}`);
    } else {
      this.#navigate(PageRoute.Actions);
    }
  }

  public action({ action }: ActionRouteParams) {
    const urlParts: string[] = [PageRoute.Actions];
    if (action.owner) {
      urlParts.push(action.owner);
    }
    if (action.digest) {
      urlParts.push(`${action.name}?digest=${action.digest}`);
    } else {
      urlParts.push(action.name);
    }
    this.#navigate(urlParts.join("/"));
  }

  public createAction() {
    this.#navigate(PageRoute.CreateAction);
  }

  public invocation({ invocationId, tab }: InvocationRouteParams) {
    if (tab) {
      this.#navigate(`${PageRoute.Invocations}/${invocationId}?tab=${tab}`);
    } else {
      this.#navigate(`${PageRoute.Invocations}/${invocationId}`);
    }
  }

  public invokeAction(params: InvokeActionRouteParams = {}) {
    const { action, datasetId, baseInvocation, inputFiles } = params;
    const urlParams = new URLSearchParams();

    if (action) {
      // Add "action_digest" to query params when we want to
      // enable invoking a specific version of an action
      urlParams.append("action_name", action.name);
      if (action.owner) {
        urlParams.append("action_owner", action.owner);
      }
    }
    if (datasetId) {
      urlParams.append("dataset_id", datasetId);
    }
    if (baseInvocation) {
      urlParams.append("base_invocation", baseInvocation);
    }
    if (inputFiles) {
      const encodedInputFiles = inputFiles
        .map((item) => encodeURIComponent(item))
        .join(",");
      urlParams.append("input_files", encodedInputFiles);
    }

    const queryString = urlParams.toString();
    const href = queryString
      ? `${PageRoute.InvokeAction}?${urlParams.toString()}`
      : `${PageRoute.InvokeAction}`;

    this.#navigate(href);
  }

  public trigger({ triggerId }: TriggerRouteParams) {
    this.#navigate(`${PageRoute.Triggers}/${triggerId}`);
  }

  public createTrigger() {
    this.#navigate(PageRoute.CreateTrigger);
  }

  public collections() {
    this.#navigate(PageRoute.Collections);
  }

  public collection({ collectionId }: CollectionRouteParams) {
    this.#navigate(`${PageRoute.Collections}/${collectionId}`);
  }

  public device({ deviceId }: DeviceRouteParams) {
    this.#navigate(`${PageRoute.Devices}/${deviceId}`);
  }

  public file({ fileId, beforeNavigation }: FileRouteParams) {
    beforeNavigation && beforeNavigation();
    window.scrollTo(0, 0); // Ensure the page doesn't load scrolled down
    this.#navigate(`${PageRoute.Files}/${fileId}`);
  }

  public visualize({ files }: VisualizerRouteParams) {
    this.#vizDispatch(actions.putFiles(files));
    window.scrollTo(0, 0); // Ensure the page doesn't load scrolled down
    this.#navigate(PageRoute.Visualize);
  }
}
