import { LayoutOrientation } from "../actions";

/**
 * Helper function to check if a layout's axis aligns with the orientation of a layout item
 */
export function isAxisAlignedWithOrientation(
  axis: "x" | "y",
  orientation: LayoutOrientation,
): boolean {
  if (axis === "y") {
    return [LayoutOrientation.TOP, LayoutOrientation.BOTTOM].includes(
      orientation,
    );
  }

  return [LayoutOrientation.LEFT, LayoutOrientation.RIGHT].includes(
    orientation,
  );
}
