import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoCheckbox, RobotoTableCell, RobotoTableRow } from "@/components";
import { useNavigation } from "@/providers";
import { actions, useVizDispatch } from "@/state/visualization";
import { FileSystemNode, isDirectory, isFile } from "@/types";

import { DatasetFileRow } from "./DatasetFileRow";
import { NoFilesFoundAlert } from "./NoFilesFoundAlert";

interface DatasetFileTableProps {
  currentPage: FileSystemNode[] | null;
  handleDeleteRow: (row: FileSystemNode) => void;
  handleRowDoubleClick: (row: FileSystemNode) => void;
  handleActionConfirmation: (
    title: string,
    text: string,
    action: () => Promise<void>,
  ) => void;
  selectedItems: FileSystemNode[];
  handleSelectRows: (rows: FileSystemNode[], isSelected: boolean) => void;
}

const isChecked = (selectedItems: FileSystemNode[], row: FileSystemNode) => {
  return selectedItems.some((item) => {
    if (isDirectory(row) && isDirectory(item)) {
      return item.name === row.name;
    } else if (isFile(row) && isFile(item)) {
      return item.file.file_id === row.file.file_id;
    }
    return false;
  });
};

export const DatasetFileTable: React.FC<DatasetFileTableProps> = ({
  currentPage,
  handleDeleteRow,
  handleRowDoubleClick,
  handleActionConfirmation,
  selectedItems,
  handleSelectRows,
}) => {
  const theme = useTheme();
  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();

  let tableRowContent = null;

  const headerChecked =
    selectedItems.length === currentPage?.length &&
    selectedItems.every((item) => {
      return currentPage?.some((row) => {
        if (isDirectory(row) && isDirectory(item)) {
          return item.name === row.name;
        } else if (isFile(row) && isFile(item)) {
          return item.file.file_id === row.file.file_id;
        }
        return false;
      });
    });

  if (currentPage && currentPage.length > 0) {
    tableRowContent = (
      <>
        {currentPage.map((row, idx) => {
          return (
            <DatasetFileRow
              key={idx}
              row={row}
              handleDeleteRow={handleDeleteRow}
              handleRowDoubleClick={handleRowDoubleClick}
              handleActionConfirmation={handleActionConfirmation}
              isChecked={isChecked(selectedItems, row)}
              handleSelectRows={handleSelectRows}
              goToFile={() => {
                if (!isFile(row)) {
                  return;
                }

                goto.file({
                  fileId: row.file.file_id,
                  beforeNavigation: () =>
                    vizDispatch(
                      actions.putFiles([{ fileId: row.file.file_id }]),
                    ),
                });
              }}
            />
          );
        })}
      </>
    );
  } else if (currentPage && currentPage.length === 0) {
    tableRowContent = (
      <RobotoTableRow>
        <RobotoTableCell
          style={{
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
          }}
          colSpan={6}
        >
          <NoFilesFoundAlert />
        </RobotoTableCell>
      </RobotoTableRow>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "50px",
      }}
    >
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" sx={{ minWidth: 650 }} aria-label="files-table">
          <TableHead>
            <TableRow>
              <RobotoTableCell
                sx={{
                  padding: "0px",
                  width: "42.5px",
                  maxWidth: "42.5px",
                }}
              >
                <RobotoCheckbox
                  sx={{
                    ml: theme.spacing(1),
                    display: {
                      xs: "none",
                      sm: "inline-flex",
                    },
                  }}
                  checked={headerChecked}
                  onChange={(event) => {
                    if (currentPage) {
                      if (event.target.checked) {
                        handleSelectRows(currentPage, true);
                      } else {
                        handleSelectRows(currentPage, false);
                      }
                    }
                  }}
                  color="default"
                  inputProps={{ "aria-label": "checkbox" }}
                />
              </RobotoTableCell>

              <RobotoTableCell>Name</RobotoTableCell>
              <RobotoTableCell>Last Modified</RobotoTableCell>
              <RobotoTableCell>Source</RobotoTableCell>
              <RobotoTableCell>Size</RobotoTableCell>
              <RobotoTableCell>Operations</RobotoTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRowContent}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
