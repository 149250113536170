import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import * as React from "react";

const headerStyles = {
  fontWeight: 500,
  fontSize: "0.85rem",
};

const ActionMetadataTableCell = styled(TableCell)(({ theme }) => ({
  minWidth: "120px",
  fontSize: "0.8rem",
  padding: theme.spacing(1, 2),
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ActionMetadataTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ActionComputeReqsTableProps {
  cpu: string | undefined;
  memory: string | undefined;
  gpu: string | undefined;
  storage: number | undefined;
}

export const ActionComputeReqsTable: React.FC<ActionComputeReqsTableProps> = ({
  cpu,
  memory,
  gpu,
  storage,
}) => {
  return (
    <>
      <TableContainer>
        <Table size="small" sx={{ width: "auto" }}>
          <TableBody>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell>
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  CPU
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {cpu ? (
                    <>
                      {cpu} ({cpu && parseInt(cpu) / 1024} vCPU)
                    </>
                  ) : (
                    <>null</>
                  )}
                </pre>
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell>
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  Memory
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {memory ? <>{memory} (MiB)</> : <>null</>}
                </pre>
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell>
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  GPU
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {gpu ? <>{gpu}</> : <>null</>}
                </pre>
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
            <ActionMetadataTableRow>
              <ActionMetadataTableCell>
                <Typography variant="subtitle2" sx={{ ...headerStyles }}>
                  Storage
                </Typography>
              </ActionMetadataTableCell>
              <ActionMetadataTableCell>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {storage ? <>{storage} (GiB)</> : <>null</>}
                </pre>
              </ActionMetadataTableCell>
            </ActionMetadataTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
