import { UISimpleCondition } from "@/components/SearchBar/types";
import { Condition } from "@/types";

import { searchFieldToType } from "../util";

const conditionToUiSimpleCondition = (condition: Condition, idx: number) => {
  const uiSimple = {} as UISimpleCondition;
  if ("comparator" in condition) {
    uiSimple.comparator = condition.comparator;
  }
  if ("field" in condition) {
    uiSimple.field = condition.field;
    uiSimple.searchFieldType = searchFieldToType(condition.field);
    uiSimple.id = idx.toString();
  }
  if ("value" in condition) {
    uiSimple.value = condition.value;
  }
  return uiSimple;
};

export const conditionsToUiSimpleConditions = (
  value: Condition | undefined,
) => {
  const simpleConditions = [] as UISimpleCondition[];

  if (value && "conditions" in value) {
    value.conditions.forEach((condition, idx) => {
      simpleConditions.push(conditionToUiSimpleCondition(condition, idx));
    });
  } else if (value && "field" in value) {
    simpleConditions.push(conditionToUiSimpleCondition(value, 0));
  }

  return simpleConditions;
};
