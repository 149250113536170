import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import {
  AnnotationData,
  CURRENT_ANNOTATION_SCHEMA_VERSION,
  Annotations,
  InvalidAnnotationsError,
} from "@/components/Annotations/schema";
import { FileRecord } from "@/domain/files";
import { DomainServicesContext } from "@/providers/DomainServices";
import { validator } from "@/validators";

export function useAnnotationData(fileRecord: FileRecord | undefined) {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery<Annotations, Error>({
    queryKey: ["annotationData", fileRecord?.file_id],
    queryFn: async ({ signal }) => {
      if (fileRecord === undefined) {
        return Promise.reject(new Error("Invalid fileRecord"));
      }

      const data = await files.getJsonFileContents<Annotations>(
        fileRecord.file_id,
        {
          abortSignal: signal,
        },
      );

      const areValidAnnotations = validator.validate<AnnotationData>(
        CURRENT_ANNOTATION_SCHEMA_VERSION,
        data,
      );

      if (!areValidAnnotations) {
        const message = `Invalid annotations for file: "${fileRecord.relative_path}" with validation errors: "${JSON.stringify(validator.errors)}"`;
        throw new InvalidAnnotationsError(message);
      }

      return data;
    },
    enabled: fileRecord !== undefined,
  });
}
