import RefreshIcon from "@mui/icons-material/Refresh";
import { Alert, AlertTitle, Button } from "@mui/material";

import styles from "./RenderingError.module.css";

interface RenderingErrorProps {
  error: Error | null;
  onClearError?: () => void;
}

export function RenderingError({ error, onClearError }: RenderingErrorProps) {
  if (error === null) {
    return null;
  }

  const action = onClearError ? (
    <Button
      className={styles.reload}
      endIcon={<RefreshIcon fontSize="medium" />}
      onClick={onClearError}
      size="small"
    >
      Reload
    </Button>
  ) : null;

  return (
    <Alert action={action} className={styles.error} severity="error">
      <AlertTitle>Error rendering panel</AlertTitle>
      {error?.message}
    </Alert>
  );
}
