import * as React from "react";

import { RobotoTableCell, RobotoTableRow, TagList } from "@/components";
import { UserLink } from "@/components/UserLink";
import { ActionRecord } from "@/domain/actions";
import { OrgRecord } from "@/domain/orgs";

import { ActionLink } from "./ActionLink";
import DeleteActionIconButton from "./DeleteActionIconButton";
import InvokeActionIconButton from "./InvokeActionIconButton";

interface ActionRowProps {
  action: ActionRecord;
  onDelete?: (action: ActionRecord) => void;
  org: OrgRecord | null;
}

const tdStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const ActionRow: React.FC<ActionRowProps> = ({
  action,
  onDelete,
  org,
}) => {
  const imageParts = action.uri?.split("/") ?? [];
  const repo = imageParts.length ? imageParts[imageParts.length - 1] : null;

  const operations = [<InvokeActionIconButton key="invoke" action={action} />];
  if (onDelete !== undefined && action.org_id === org?.org_id) {
    operations.push(
      <DeleteActionIconButton
        key="delete"
        action={action}
        onDelete={onDelete}
        org={org}
      />,
    );
  }

  return (
    <RobotoTableRow>
      <RobotoTableCell
        sx={{
          ...tdStyle,
        }}
        title={action.name}
      >
        <ActionLink action={action} />
      </RobotoTableCell>
      <RobotoTableCell
        sx={{
          ...tdStyle,
          maxWidth: "200px",
        }}
        title={repo || undefined}
      >
        {repo || <>None</>}
      </RobotoTableCell>
      <RobotoTableCell
        sx={{
          ...tdStyle,
        }}
        title={new Date(action.modified).toLocaleString()}
      >
        {new Date(action.modified).toLocaleString()}
      </RobotoTableCell>
      <RobotoTableCell
        sx={{
          ...tdStyle,
        }}
        title={action.modified_by || undefined}
      >
        {action.modified_by.includes("@") ? (
          <UserLink userId={action.modified_by} />
        ) : (
          action.modified_by
        )}
      </RobotoTableCell>
      <RobotoTableCell sx={{ maxWidth: "400px" }}>
        <TagList maxDisplayCount={4} tags={action.tags} />
      </RobotoTableCell>
      <RobotoTableCell sx={tdStyle}>{operations}</RobotoTableCell>
    </RobotoTableRow>
  );
};
