import { FileRecord } from "@/domain/files";
import { TopicRecord } from "@/domain/topics";
import { Dataset, DataGridSortingOrder, SearchQueryBody } from "@/types";

export interface TablePageState {
  modalOpen: boolean;
  rightClickedRow: Dataset | FileRecord | TopicRecord | null;
  sidebar: {
    isOpen: boolean;
    dataset: Dataset | null;
  };
  columns: string[];
  tableConfig: {
    page: number;
    rowsPerPage: 10 | 25 | 50;
  };
  snackbar: {
    isOpen: boolean;
    message: string;
  };
  sortColumnIndex: number | null;
  sortOrder: DataGridSortingOrder;
  lastSearchQuery: SearchQueryBody;
  selectedRows: Set<string>;
}

export type TablePageAction =
  | {
      type: "SET_MODAL_OPEN";
      payload: boolean;
    }
  | {
      type: "SET_RIGHT_CLICKED_ROW";
      payload: Dataset | null;
    }
  | {
      type: "SET_SIDEBAR_OPEN";
      payload: {
        isOpen: boolean;
        dataset: Dataset | null;
      };
    }
  | {
      type: "SAVE_COLUMNS";
      payload: string[];
    }
  | {
      type: "SET_TABLE_CONFIG";
      payload: {
        page: number;
        rowsPerPage: 10 | 25 | 50;
      };
    }
  | {
      type: "SET_SNACKBAR";
      payload: {
        isOpen: boolean;
        message: string;
      };
    }
  | {
      type: "SET_SORTING";
      payload: {
        page: number;
        sortColumnIndex: number | null;
        sortOrder: DataGridSortingOrder;
        lastSearchQuery: SearchQueryBody;
      };
    }
  | {
      type: "SET_LAST_SEARCH_QUERY";
      payload: {
        lastQuery: SearchQueryBody;
        sortColumnIndex: number | null;
        sortOrder: DataGridSortingOrder;
      };
    }
  | {
      type: "START_SEARCH_OVER";
      payload: {
        lastSearchQuery: SearchQueryBody;
      };
    }
  | {
      type: "SET_SELECTED_ROWS";
      payload: {
        selectedRows: Set<string>;
      };
    };

export const tablePageReducer = (
  state: TablePageState,
  action: TablePageAction,
): TablePageState => {
  switch (action.type) {
    case "SET_MODAL_OPEN":
      return {
        ...state,
        modalOpen: action.payload,
      };

    case "SET_RIGHT_CLICKED_ROW":
      return {
        ...state,
        rightClickedRow: action.payload,
      };

    case "SET_SIDEBAR_OPEN":
      return {
        ...state,
        sidebar: {
          isOpen: action.payload.isOpen,
          dataset: action.payload.dataset,
        },
      };

    case "SAVE_COLUMNS":
      return {
        ...state,
        columns: action.payload,
        modalOpen: false,
      };

    case "SET_TABLE_CONFIG":
      return {
        ...state,
        tableConfig: {
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      };

    case "SET_SNACKBAR":
      return {
        ...state,
        snackbar: {
          isOpen: action.payload.isOpen,
          message: action.payload.message,
        },
      };

    case "SET_SORTING":
      return {
        ...state,
        tableConfig: {
          page: action.payload.page,
          rowsPerPage: state.tableConfig.rowsPerPage,
        },
        sortColumnIndex: action.payload.sortColumnIndex,
        sortOrder: action.payload.sortOrder,
        lastSearchQuery: action.payload.lastSearchQuery,
      };

    case "SET_LAST_SEARCH_QUERY":
      return {
        ...state,
        lastSearchQuery: action.payload.lastQuery,
        tableConfig: {
          page: 0,
          rowsPerPage: state.tableConfig.rowsPerPage,
        },
        sortColumnIndex: action.payload.sortColumnIndex,
        sortOrder: action.payload.sortOrder,
      };

    case "START_SEARCH_OVER":
      return {
        ...state,
        lastSearchQuery: action.payload.lastSearchQuery,
        tableConfig: {
          page: 0,
          rowsPerPage: state.tableConfig.rowsPerPage,
        },
        sortColumnIndex: null,
        sortOrder: "none",
      };

    case "SET_SELECTED_ROWS":
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
      };
  }
};
