import { Alert, AlertTitle } from "@mui/material";
import * as React from "react";

export const NoFilesFoundAlert: React.FC = () => {
  return (
    <Alert severity="warning">
      <AlertTitle>No matching files were found</AlertTitle>
      Try changing filters, search terms or directory.
    </Alert>
  );
};
