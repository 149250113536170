import { Box, useTheme, CircularProgress } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "@/components";
import { InvocationStatus } from "@/domain/actions";
import { useActiveInvocations } from "@/domain/actions/useActiveInvocations";

interface DatasetActionStatusProps {
  datasetId: string;
}

export const DatasetActionStatus: React.FC<DatasetActionStatusProps> = ({
  datasetId,
}) => {
  const theme = useTheme();

  const invocationsQuery = useActiveInvocations(datasetId);

  const counts = invocationsQuery.data?.items.reduce(
    (acc, record) => {
      const status = record.status[record.status.length - 1].status;

      if (
        [InvocationStatus.Queued, InvocationStatus.Scheduled].includes(status)
      ) {
        acc.pendingCount++;
      } else if (
        [
          InvocationStatus.Downloading,
          InvocationStatus.Processing,
          InvocationStatus.Uploading,
        ].includes(status)
      ) {
        acc.runningCount++;
      }

      return acc;
    },
    { pendingCount: 0, runningCount: 0 },
  );

  let activityString = "";

  if (counts) {
    const { pendingCount, runningCount } = counts;

    const pendingString = pendingCount >= 9 ? "9+" : `${pendingCount}`;
    const runningString = runningCount >= 9 ? "9+" : `${runningCount}`;

    if (pendingCount > 0 && runningCount > 0) {
      activityString = `${pendingString} Pending, ${runningString} Running`;
    } else if (pendingCount > 0) {
      activityString = `${pendingString} Pending`;
    } else if (runningCount > 0) {
      activityString = `${runningString} Running`;
    }
  }

  return (
    <Box
      sx={{
        ml: theme.spacing(1),
        display: counts?.pendingCount || counts?.runningCount ? "flex" : "none",
        alignItems: "center",
      }}
    >
      <RobotoTooltip title={activityString} placement="top">
        <CircularProgress size={"1rem"} />
      </RobotoTooltip>
    </Box>
  );
};
