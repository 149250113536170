import { Page } from "@/components/Page";
import { SignInPage } from "@/features";

const SignIn: React.FC = () => {
  return (
    <Page title={"Roboto - Sign In"}>
      <SignInPage />
    </Page>
  );
};

export default SignIn;
