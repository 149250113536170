import { SvgIcon } from "@mui/material";
import * as React from "react";

export const FeedbackIcon: React.FC = () => {
  return (
    <SvgIcon
      style={{
        transform: "scale(0.93, 0.93) scale(-1, 1)",
        fill: "currentColor",
      }}
    >
      <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
        <path d="M12 4c4.41 0 8 3.59 8 8s-3.59 8-8 8c-1.18 0-2.34-.26-3.43-.78-.27-.13-.56-.19-.86-.19-.19 0-.38.03-.56.08l-3.2.94.94-3.2c.14-.47.1-.98-.11-1.42C4.26 14.34 4 13.18 4 12c0-4.41 3.59-8 8-8m0-2C6.48 2 2 6.48 2 12c0 1.54.36 2.98.97 4.29L1 23l6.71-1.97c1.31.61 2.75.97 4.29.97 5.52 0 10-4.48 10-10S17.52 2 12 2z"></path>
      </svg>
    </SvgIcon>
  );
};
