import { Button, ButtonProps, CircularProgress } from "@mui/material";
import * as React from "react";

import { useAnalytics } from "@/providers";
import { EventName, ComponentPropsWithEvent } from "@/service/analytics";

type RobotoButtonProps = ButtonProps & {
  loading?: boolean;
};

type RobotoButtonPropsWithEvent<T extends EventName> = ComponentPropsWithEvent<
  T,
  RobotoButtonProps
>;

// This function type overload provides better intellisense by telling the author
// what field is missing from the event properties. Without this, it'll just say
// "Your value for eventProperties does not match the expected type."
export function RobotoButton<T extends EventName>(
  props: RobotoButtonPropsWithEvent<T>,
): JSX.Element;

export function RobotoButton(
  props: Partial<RobotoButtonPropsWithEvent<EventName>>,
): JSX.Element {
  const { trackEvent } = useAnalytics();
  const { onClick, eventName, eventProperties, loading, ...buttonProps } =
    props;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (eventName) {
      trackEvent(eventName, eventProperties);
    }
    onClick?.(event);
  };

  return (
    <Button
      disableElevation
      onClick={handleClick}
      style={{ textTransform: "none" }}
      endIcon={loading ? <CircularProgress size={"1rem"} /> : null}
      {...buttonProps}
    >
      {props.children}
    </Button>
  );
}
