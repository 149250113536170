import { useMutation } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";

import { EventRecord, UpdateEventRequest } from "../EventRecord";

export function useUpdateEvent() {
  const { events } = useDomainServices();

  return useMutation<
    EventRecord,
    Error,
    { id: string; requestBody: UpdateEventRequest }
  >({
    mutationFn: ({ id, requestBody }) => {
      return events.updateEvent(id, requestBody);
    },
  });
}
