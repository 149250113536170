import { Divider } from "@mui/material";
import * as React from "react";

import styles from "./SettingsSection.module.css";

interface SettingsSectionProps {
  title: string;
}

export function SettingsSection({
  children,
  title,
}: React.PropsWithChildren<SettingsSectionProps>) {
  return (
    <section className={styles.section}>
      <Divider className={styles.divider} component="div" role="presentation">
        <span className={styles.title}>{title}</span>
      </Divider>
      {children}
    </section>
  );
}
