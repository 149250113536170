import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { Link, useRouteError } from "react-router-dom";

import { RobotoTypography } from "@/components";
import { ErrorMonitoringService } from "@/service/ErrorMonitoringService";
import { PageRoute } from "@/types";

export function ErrorPage() {
  const error = useRouteError();

  useEffect(() => {
    ErrorMonitoringService.captureError(error);
  }, [error]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      textAlign="center"
    >
      <RobotoTypography variant="h2" gutterBottom>
        Oops! Something went wrong.
      </RobotoTypography>
      <RobotoTypography variant="body1" paragraph>
        We apologize for the inconvenience. Our team has been notified and is
        working to fix the issue.
      </RobotoTypography>
      <Box>
        <Link to={PageRoute.Home}>
          <Button variant="contained" color="primary">
            Go to Homepage
          </Button>
        </Link>
      </Box>
    </Box>
  );
}
