import { Typography } from "@mui/material";
import classNames from "classnames";

import styles from "./TopicLabel.module.css";

export interface TopicLabelProps {
  className?: classNames.Argument;
  dataType: string;
  label: string;
}

/**
 * Renders a label for a topic.
 */
export function TopicLabel(props: TopicLabelProps) {
  const { className, dataType, label } = props;

  return (
    <div className={classNames(className, styles.labelContainer)}>
      <p className={classNames(styles.hideOverflow, styles.label)}>{label}</p>

      <Typography
        className={classNames(
          styles.dataTypeLabel,
          styles.hideOverflow,
          styles.label,
        )}
        color="text.secondary"
      >
        {dataType}
      </Typography>
    </div>
  );
}
