import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Skeleton, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoMarkdown } from "@/components/RobotoMarkdown";
import { EventRecord } from "@/domain/events";

import { RobotoTypography } from "../../RobotoTypography";
import { useWorkspaceEvent } from "../WorkspaceCtx";

import styles from "./EventDescription.module.css";
import { EventDescriptionForm } from "./EventDescriptionForm";

interface EventDescriptionProps {
  eventId: EventRecord["event_id"];
}

export function EventDescription({ eventId }: EventDescriptionProps) {
  const eventQuery = useWorkspaceEvent(eventId);
  const theme = useTheme();

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  if (eventQuery.isLoading) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Description
        </RobotoTypography>
        <div className={styles.sectionPadding}>
          <Skeleton variant="rounded" height="40px" />
        </div>
      </section>
    );
  }

  if (eventQuery.isError) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Description
        </RobotoTypography>
        <div className={styles.errorContainer}>
          <ErrorOutlineIcon color="error" />
          <RobotoTypography>Failed to load event description</RobotoTypography>
        </div>
      </section>
    );
  }

  const { description } = eventQuery.data;

  if (isEditing) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Description
        </RobotoTypography>
        <div>
          <EventDescriptionForm
            id={eventId}
            description={description}
            onSubmit={() => setIsEditing(false)}
          />
        </div>
      </section>
    );
  }

  return (
    <section>
      <RobotoTypography component={"h3"} className={styles.title}>
        Description
      </RobotoTypography>
      <Box
        onClick={() => setIsEditing(true)}
        sx={{
          ":hover": {
            backgroundColor: theme.palette.hoverBackground.main,
            borderRadius: "0.25rem",
            cursor: "pointer",
          },
        }}
      >
        {description ? (
          <RobotoMarkdown
            content={description}
            className={styles.description}
          />
        ) : (
          <RobotoTypography component="p" className={styles.description}>
            {"Add a description..."}
          </RobotoTypography>
        )}
      </Box>
    </section>
  );
}
