export enum DropZone {
  Panel = "panel",
  PanelBoard = "panel-board",
  Layout = "layout",
}

export enum DragItem {
  MessagePath = "message-path",
  Panel = "panel",
}
