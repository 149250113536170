import { createContext } from "react";

import { OrgRecord } from "@/domain/orgs";
import { UserRecord } from "@/domain/users";
import { OrganizationRole } from "@/service/ApiService";
import { UserIdToken } from "@/types";

import { LoggerService } from "../../service";

export type Auth = {
  isAuthenticated: boolean;
  isLoading: boolean;
  currentUser: UserRecord | null;
  currentOrganization: OrgRecord | null;
  availableOrganizations: OrgRecord[] | null;
  orgRoles: OrganizationRole[] | null;
  signUp: (emailAddress: string, password: string) => Promise<string | null>;
  ssoSignIn: (inviteId: string | null) => Promise<string | null>;
  submitVerificationCode: (
    username: string,
    code: string,
  ) => Promise<string | null>;
  signIn: (emailAddress: string, password: string) => Promise<string | null>;
  signOut: () => Promise<string | null>;
  forgotPasswordInitiate: (emailAddress: string) => Promise<string | null>;
  forgotPasswordComplete: (
    emailAddress: string,
    code: string,
    newPassword: string,
  ) => Promise<string | null>;
  getCurrentUser: () => Promise<UserRecord | null>;
  getCurrentOrganization: () => OrgRecord | null;
  setCurrentOrganization: (org: OrgRecord) => void;
  deleteCurrentOrganization: () => void;
  getUserIdToken: () => Promise<UserIdToken | null>;
  refreshOrgList: () => Promise<void>;
  resendVerificationCode: (emailAddress: string) => Promise<string | null>;
};

export const AuthContext = createContext<Auth>({
  isAuthenticated: false,
  isLoading: false,
  currentUser: null,
  currentOrganization: null,
  availableOrganizations: null,
  orgRoles: null,
  signUp: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  submitVerificationCode: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  ssoSignIn: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  signIn: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  signOut: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  forgotPasswordInitiate: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  forgotPasswordComplete: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  getCurrentUser: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  getCurrentOrganization: () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return null;
  },
  setCurrentOrganization: () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
  },
  getUserIdToken: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  refreshOrgList: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve();
  },
  resendVerificationCode: async () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
    return Promise.resolve(null);
  },
  deleteCurrentOrganization: () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
  },
});
