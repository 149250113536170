import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { McapReader } from "./McapReader";
import { RemoteFile } from "./RemoteFile";

interface Params {
  fileId: string | undefined;
  onError: (error: Error) => void;
  onLoading: (isLoading: boolean) => void;
}

export function useMcapReader(params: Params) {
  const { fileId, onError, onLoading } = params;

  const { files: fileService } = React.useContext(DomainServicesContext);
  const [reader, setReader] = React.useState<McapReader | null>(null);

  React.useEffect(() => {
    const abortController = new AbortController();

    async function instantiate() {
      if (fileId === undefined) {
        return null;
      }
      const representationRecord = await fileService.getFileRecord(fileId, {
        abortSignal: abortController.signal,
      });
      const file = new RemoteFile(representationRecord, fileService);
      return await McapReader.forFile(file, {
        abortSignal: abortController.signal,
      });
    }

    onLoading(true);
    instantiate()
      .then((reader) => {
        if (abortController.signal.aborted) {
          return;
        }
        setReader(reader);
      })
      .catch((err) => {
        if (abortController.signal.aborted) {
          return;
        }
        const error =
          err instanceof Error
            ? err
            : new Error(
                "Failed to instantiate data source for RawMessagePanel",
                { cause: err },
              );
        onError(error);
      })
      .finally(() => {
        if (abortController.signal.aborted) {
          return;
        }
        onLoading(false);
      });
    return () => {
      abortController.abort();
    };
  }, [fileService, fileId, onError, onLoading]);

  return reader;
}
