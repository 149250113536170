import * as React from "react";

/*
 * Only execute callback delay_ms time after the most recent attempt.
 * i.e. callback is executed "after the dust has settled".
 */
export function useDebouncedCallback<
  T extends (...args: Parameters<T>) => ReturnType<T>,
>(
  fn: T,
  delay_ms: number = 0,
): (...args: Parameters<T>) => ReturnType<T> | undefined {
  const timeout = React.useRef<number>();

  return React.useCallback(
    (...args: Parameters<T>) => {
      let result: ReturnType<T> | undefined = undefined;

      const later = () => {
        window.clearTimeout(timeout.current);
        result = fn(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = window.setTimeout(later, delay_ms);

      return result;
    },
    [fn, delay_ms],
  );
}
