import { Box, useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { ResizeSeparatorProps } from "@/hooks";

import styles from "./WorkspaceResizeHandle.module.css";

interface WorkspaceResizeHandleProps {
  isDragging: boolean;
  separatorProps: ResizeSeparatorProps;
}

export const WorkspaceResizeHandle = React.forwardRef<
  HTMLElement,
  WorkspaceResizeHandleProps
>(function WorkspaceResizeHandle(props, ref) {
  const { isDragging, separatorProps } = props;
  const theme = useTheme();

  return (
    <Box
      ref={ref}
      className={classNames(styles.dragHandle, {
        [styles.dragBgColorLight]: isDragging && theme.palette.mode === "light",
        [styles.dragBgColorDark]: isDragging && theme.palette.mode === "dark",
        [styles.dragHandleDark]: theme.palette.mode === "dark",
        [styles.dragHandleLight]: theme.palette.mode === "light",
      })}
      {...separatorProps}
    />
  );
});
