import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, OutlinedInput } from "@mui/material";

import styles from "./EventFilterInput.module.css";

interface EventFilterInputProps {
  onFilter: (filterTerm: string) => void;
}

export function EventFilterInput({ onFilter }: EventFilterInputProps) {
  return (
    <OutlinedInput
      className={styles.searchInput}
      inputProps={{
        className: styles.searchInputChildren,
      }}
      onChange={(event) => {
        onFilter(event.target.value);
      }}
      placeholder="Filter events by name"
      size="small"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon className={styles.icon} />
        </InputAdornment>
      }
      sx={(theme) => ({
        backgroundColor: theme.palette.paper.main,
      })}
    />
  );
}
