import { Alert, Box, Skeleton, useTheme } from "@mui/material";
import * as React from "react";

import { AutocompleteType } from "@/components/TagAndMetadataAutocomplete";
import { useFileRecordTags } from "@/domain/files/hooks/useFileRecordTags";
import { useMutateFileTags } from "@/domain/files/hooks/useMutateFileTags";

import { TagInput, Tags, TagsGroup } from "../tags";

interface UpdateFileTagsProps {
  fileId: string;
}

const TagsSkeleton: React.FC = () => {
  const theme = useTheme();

  const tagsJsx = [];

  for (let i = 0; i <= 2; i++) {
    tagsJsx.push(
      <Skeleton
        width={100}
        height={50}
        sx={{ marginRight: theme.spacing(1) }}
        key={i}
      />,
    );
  }

  return <Box sx={{ display: "flex", flexDirection: "row" }}>{tagsJsx}</Box>;
};

export const UpdateFileTags: React.FC<UpdateFileTagsProps> = ({ fileId }) => {
  const theme = useTheme();
  const fileTagsQuery = useFileRecordTags(fileId);
  const fileTagsMutation = useMutateFileTags();

  const tagAdded = (tag: string) => {
    fileTagsMutation.mutate({
      fileId,
      putTags: [tag],
    });
  };

  const tagRemoved = (tag: string) => {
    fileTagsMutation.mutate({
      fileId,
      removeTags: [tag],
    });
  };

  return (
    <>
      <TagsGroup>
        {fileTagsQuery.isPending ? (
          <TagsSkeleton />
        ) : (
          <Tags tags={fileTagsQuery.data || []} onDeleteTag={tagRemoved} />
        )}

        <TagInput
          autocompleteType={AutocompleteType.FileTags}
          onAddTag={tagAdded}
          tags={fileTagsQuery.data || []}
          size="small"
          isLoading={fileTagsMutation.isPending}
        />
      </TagsGroup>

      {fileTagsQuery.isError && (
        <Alert severity="error" sx={{ marginTop: theme.spacing(1) }}>
          {fileTagsQuery.error.message}
        </Alert>
      )}

      {fileTagsMutation.isError && (
        <Alert
          onClose={() => fileTagsMutation.reset()}
          severity="error"
          sx={{ marginTop: theme.spacing(1) }}
        >
          {fileTagsMutation.error.message}
        </Alert>
      )}
    </>
  );
};
