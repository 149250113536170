import { Box, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { AnnotationViewFilters, Annotations } from "@/components/Annotations";
import { FileRecord, MAX_FILE_SIZE_PREVIEW } from "@/domain/files/FileRecord";
import { useSignedUrl } from "@/domain/files/hooks/useFile";
import {
  isImage,
  isText,
  isMarkdown,
  isVideo,
  isWebRenderable,
  canHaveTopics,
} from "@/utils";

import { IframeContent } from "./views/IframeContent";
import { ImageContent } from "./views/ImageContent";
import { TextContent } from "./views/TextContent";
import { VideoContent } from "./views/VideoContent";

interface FileFocalContentProps {
  fileRecord: FileRecord;
  signedUrl?: URL;
  annotationData?: Annotations;
  annotationViewFilters?: AnnotationViewFilters;
}

export const FileFocalContent: React.FC<FileFocalContentProps> = ({
  fileRecord,
  annotationData,
  annotationViewFilters,
}) => {
  const theme = useTheme();

  const signedUrlQuery = useSignedUrl(fileRecord);

  const msgContent = (message: React.ReactElement) => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            maxWidth: "500px",
            padding: theme.spacing(3),
            backgroundColor: theme.palette.code.main,
          }}
        >
          {message}
        </Typography>
      </Box>
    );
  };

  let contentType;

  if (isImage(fileRecord.relative_path)) {
    contentType = (
      <ImageContent
        fileRecord={fileRecord}
        signedUrl={signedUrlQuery.data}
        annotationData={annotationData}
        annotationViewFilters={annotationViewFilters}
      />
    );
  } else if (isVideo(fileRecord.relative_path)) {
    contentType = (
      <VideoContent fileRecord={fileRecord} signedUrl={signedUrlQuery.data} />
    );
  } else if (
    isText(fileRecord.relative_path) ||
    isMarkdown(fileRecord.relative_path)
  ) {
    if (fileRecord.size > MAX_FILE_SIZE_PREVIEW) {
      contentType = msgContent(
        <>
          File size is over 50 MB, skipping preview <br />
          <span
            style={{
              fontSize: "0.9rem",
              color: theme.palette.text.secondary,
            }}
          >
            Open or Download the file using the buttons above
          </span>
        </>,
      );
    } else {
      contentType = (
        <TextContent fileRecord={fileRecord} signedUrl={signedUrlQuery.data} />
      );
    }
  } else if (isWebRenderable(fileRecord.relative_path)) {
    contentType = <IframeContent signedUrl={signedUrlQuery.data} />;
  } else if (canHaveTopics(fileRecord.relative_path)) {
    contentType = msgContent(
      <>
        File not ingested yet
        <br />
        <span
          style={{
            fontSize: "0.9rem",
            color: theme.palette.text.secondary,
          }}
        >
          This file hasn&apos;t been ingested, so there aren&apos;t any topics
          available for visualization. Check if any ingestion actions are
          currently running on its dataset, or invoke one manually.
        </span>
      </>,
    );
  } else {
    contentType = msgContent(
      <>
        No preview available <br />
        <span
          style={{
            fontSize: "0.9rem",
            color: theme.palette.text.secondary,
          }}
        >
          Open or Download the file using the buttons above
        </span>
      </>,
    );
  }

  return contentType;
};
