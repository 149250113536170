import { CircularProgress, Paper, useTheme } from "@mui/material";
import * as React from "react";

export const LoadingView: React.FC<{ loading: boolean }> = ({ loading }) => {
  const theme = useTheme();

  if (!loading) {
    return null;
  }

  return (
    <Paper
      sx={{
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
        height: "100%",
        minHeight: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.paper.main,
        zIndex: theme.zLayer(1),
      }}
      elevation={1}
    >
      <CircularProgress size="2rem" />
    </Paper>
  );
};
