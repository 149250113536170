import { Alert, Box, CircularProgress, useTheme } from "@mui/material";
import * as React from "react";

import {
  DatasetsTable,
  TopicsTable,
  TopicMessagePathsTable,
  EventResultsTable,
} from "@/components/SearchResultViews";
import { EventRecord } from "@/domain/events";
import { FileRecord } from "@/domain/files";
import { QueryTarget } from "@/domain/query";
import { useQueryRecord, useQueryResults } from "@/domain/query/hooks";
import { MessagePathRecord, TopicRecord } from "@/domain/topics";
import { RobotoDomainException } from "@/http";
import { Dataset } from "@/types";

import { FileResults } from "./FileResults";
import { OnResultInteraction, SearchResultInteraction } from "./interaction";

const noop = () => {};

export interface SearchResultsProps {
  limit?: number;
  pageToken?: string;
  queryId?: string;
  lastError?: RobotoDomainException | Error;
  onError?: (error: RobotoDomainException | Error) => void;
  onLoaded?: () => void;
  onResultInteraction?: OnResultInteraction;
  selectedDatasets?: Set<string>;
  setSelectedDatasets?: (arg: Set<string>) => void;
  extraHeight?: number;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  queryId,
  pageToken = "0",
  limit,
  lastError,
  onError = noop,
  onLoaded = noop,
  onResultInteraction,
  selectedDatasets,
  setSelectedDatasets,
  extraHeight = 0,
}) => {
  const theme = useTheme();
  const queryRecordQuery = useQueryRecord(queryId);
  const queryRecord = queryRecordQuery.data;
  const resultsQuery = useQueryResults(queryRecord?.query_id, pageToken, limit);
  const results = resultsQuery.data;

  const loading = queryRecordQuery.isLoading || resultsQuery.isLoading;
  const queryError = queryRecordQuery.error || resultsQuery.error;

  // 100vh - navTopBarHeight - searchBarHeight - any margins/padding - pagination
  const maxTableHeight = `calc(100vh - ${
    theme.navTopBarHeight
  } - 56px - 35px - ${parseInt(theme.spacing(3))}px) - 49px - ${extraHeight}px`;

  React.useEffect(() => {
    if (queryRecordQuery.error || resultsQuery.error) {
      if (queryRecordQuery.error) {
        onError(queryRecordQuery.error);
      } else if (resultsQuery.error) {
        onError(resultsQuery.error);
      }
    }
  }, [onError, queryRecordQuery, resultsQuery]);

  React.useEffect(() => {
    if (!loading) {
      onLoaded();
    }
  }, [onLoaded, loading]);

  if (!loading && queryRecord && queryRecord.target === QueryTarget.Datasets) {
    const onDatasetSingleClick = (dataset: Dataset) => {
      if (onResultInteraction) {
        onResultInteraction(
          dataset,
          QueryTarget.Datasets,
          SearchResultInteraction.SingleClick,
        );
      }
    };

    return (
      <DatasetsTable
        datasets={(results?.items as Dataset[]) || []}
        loading={loading}
        selectedDatasets={selectedDatasets}
        setSelectedDatasets={setSelectedDatasets}
        onDatasetSingleClick={onDatasetSingleClick}
        containerStyle={{
          transition: "max-width 0.3s ease-in-out",
          maxHeight: maxTableHeight,
        }}
      />
    );
  } else if (
    !loading &&
    queryRecord &&
    queryRecord.target === QueryTarget.Files
  ) {
    {
      return (
        <FileResults
          loading={loading}
          files={results?.items as FileRecord[]}
          extraHeight={extraHeight}
        />
      );
    }
  } else if (
    !loading &&
    queryRecord &&
    queryRecord.target === QueryTarget.Topics
  ) {
    {
      return (
        <TopicsTable
          topicRecords={(results?.items as TopicRecord[]) || []}
          loading={loading}
          selectedRows={undefined}
          setSelectedRows={noop}
          onRowSingleClick={noop}
          containerStyle={{
            transition: "max-width 0.3s ease-in-out",
            maxHeight: maxTableHeight,
          }}
        />
      );
    }
  } else if (
    !loading &&
    queryRecord &&
    queryRecord.target === QueryTarget.TopicMessagePaths
  ) {
    return (
      <TopicMessagePathsTable
        msgPaths={(results?.items as MessagePathRecord[]) || []}
        loading={loading}
        selectedRows={undefined}
        setSelectedRows={noop}
        onRowSingleClick={noop}
        containerStyle={{
          transition: "max-width 0.3s ease-in-out",
          maxHeight: maxTableHeight,
        }}
      />
    );
  } else if (
    !loading &&
    queryRecord &&
    queryRecord.target === QueryTarget.Events
  ) {
    return (
      <EventResultsTable
        events={(results?.items as EventRecord[]) || []}
        loading={loading}
        containerStyle={{
          transition: "max-width 0.3s ease-in-out",
          maxHeight: maxTableHeight,
        }}
      />
    );
  } else if (!loading && queryRecord && queryRecord.target) {
    return (
      <Box mt={theme.spacing(2)}>
        <Alert severity="error">
          Search results cannot be displayed. Please contact support@roboto.ai
          if this problem persists.
        </Alert>
      </Box>
    );
  } else if (!queryError && !lastError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: theme.spacing(3),
        }}
      >
        <CircularProgress size="2rem" />
      </Box>
    );
  }
};
