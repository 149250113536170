import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { CommentsBox } from "@/components";

interface RecentCommentsProps {
  count: number;
}

export const RecentComments: React.FC<RecentCommentsProps> = ({ count }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mt: theme.spacing(1.5),
      }}
    >
      <CommentsBox summaryView={true} initialCount={count} />
    </Box>
  );
};
