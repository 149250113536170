import { useQuery, UseQueryResult } from "@tanstack/react-query";
import * as React from "react";

import { PaginatedResponse } from "@/http";
import { DomainServicesContext } from "@/providers/DomainServices";

export function useQueryResults<T>(
  queryId: string | undefined,
  pageToken: string = "0",
  limit: number = 1000,
  ttlMillis: number = 1000,
): UseQueryResult<PaginatedResponse<T>, Error> {
  const { queryService } = React.useContext(DomainServicesContext);

  return useQuery<PaginatedResponse<T>, Error>({
    queryKey: ["queryResults", queryId, pageToken, limit],
    queryFn: ({ signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryResults(queryId, {
        abortSignal: signal,
        pageToken: pageToken,
        limit: limit,
      });
    },
    enabled: queryId !== undefined,
    staleTime: ttlMillis || 1000,
  });
}
