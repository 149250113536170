import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Avatar,
  Box,
  CircularProgress,
  Skeleton,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import {
  NotificationRecord,
  NotificationType,
  ReadStatus,
} from "@/domain/notifications";
import { useAuth } from "@/providers";
import { APIService } from "@/service";
import { useLazyAPICall } from "@/service/apiHooks";
import { APIResponse, Comment, EntityType, RobotoAPICall } from "@/types";
import { formatComment } from "@/utils";

import { RobotoTypography } from "../../RobotoTypography";
import { getCommentEndpoint, notificationsEndpoint } from "../types";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface NotificationCellProps {
  createdTime: string;
  notifierId: string;
  notificationId: string;
  notificationType: NotificationType;
  readStatus: ReadStatus;
  handleNotificationUpdate: (notification: NotificationRecord) => void;
  commentRef: React.RefObject<{ [key: string]: Comment }>;
  avatarMap: { [key: string]: string };
}

export const NotificationCell: React.FC<NotificationCellProps> = ({
  createdTime,
  notifierId,
  notificationId,
  readStatus,
  handleNotificationUpdate,
  commentRef,
  avatarMap,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const navigate = useNavigate();

  const unread = readStatus === ReadStatus.Unread;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [navigating, setNavigating] = React.useState<boolean>(false);

  useEffect(() => {
    const getContent = async () => {
      if (commentRef?.current?.[notifierId]) {
        //use the cached comment text
      } else {
        if (!commentRef.current) {
          return;
        }

        setLoading(true);

        const { response, error } = await APIService.authorizedRequest<
          APIResponse<Comment>
        >({
          endpoint: getCommentEndpoint,
          method: "GET",
          orgId: currentOrganization?.org_id,
          pathParams: { commentId: notifierId },
        });

        if (!error && response?.data) {
          commentRef.current[notifierId] = response.data;
        }

        setLoading(false);
      }
    };

    void getContent();
  }, [commentRef, currentOrganization?.org_id, notifierId]);

  const { initiateRequest } = useLazyAPICall<APIResponse<NotificationRecord>>();

  const time = new Date(createdTime);

  const humanTime = time
    ? dayjs.duration(dayjs(time).diff(dayjs())).humanize(true)
    : "Never";

  function trimComment(comment: string) {
    const formattedComment = formatComment(comment);

    if (formattedComment.length > 50) {
      return formattedComment.slice(0, 50) + "...";
    } else {
      return formattedComment;
    }
  }

  const onClick = async () => {
    setNavigating(true);

    const apiCall: RobotoAPICall = {
      method: "PUT",
      endpoint: notificationsEndpoint,
      pathParams: {
        notificationId: notificationId,
      },
      requestBody: JSON.stringify({
        notification_id: notificationId,
        read_status: "read",
      }),
      orgId: currentOrganization?.org_id ?? "",
    };

    const { data: response, error } = await initiateRequest(apiCall);

    const entityType = commentRef?.current?.[notifierId]?.entity_type;
    const entityId = commentRef?.current?.[notifierId]?.entity_id;

    if (!entityType || !entityId) {
      // no op
      return;
    }

    const entityPath = mapCommentEntityTypeToPath(entityType, entityId);

    navigate(entityPath);

    if (!error && response?.data) {
      handleNotificationUpdate(response.data);
    }

    setNavigating(false);
  };

  const createdBy =
    commentRef?.current && commentRef?.current[notifierId]?.created_by;

  const avatarUrl = createdBy && avatarMap[createdBy];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          cursor: "pointer",
          "&:hover": { backgroundColor: theme.palette.primary.light },
          mt: theme.spacing(0.5),
        }}
        onClick={() => {
          void onClick();
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              height={"50px"}
              width={"100%"}
              sx={{
                mt: theme.spacing(0.5),
                mb: theme.spacing(0.5),
              }}
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                src={avatarUrl ?? ""}
                imgProps={{ referrerPolicy: "no-referrer" }}
                sx={{ width: 38, height: 38, m: 1 }}
              />
            </Box>
            <Box sx={{ flex: 1, p: 1 }}>
              <RobotoTypography
                variant="body2"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {trimComment(
                  commentRef?.current?.[notifierId]?.comment_text ?? "...",
                )}
              </RobotoTypography>
              <RobotoTypography variant={"caption"}>
                <span
                  style={{ color: "#888" }}
                  title={time.toLocaleString() + " (" + humanTime + ")"}
                >
                  {time.toLocaleDateString(undefined, {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  at{" "}
                  {time.toLocaleTimeString(undefined, {
                    hour: "numeric",
                    minute: "numeric",
                  })}{" "}
                  ({humanTime})
                </span>
              </RobotoTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: theme.spacing(1.5),
              }}
            >
              {navigating ? (
                <CircularProgress size="1rem" />
              ) : (
                <>
                  {unread && (
                    <FiberManualRecordIcon
                      color={"primary"}
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    />
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const mapCommentEntityTypeToPath = (
  entityType: EntityType,
  entity_id: string,
) => {
  switch (entityType) {
    case "dataset":
      return `/datasets/${entity_id}`;
    case "action":
      return `/actions/${entity_id}`;
    case "trigger":
      return `/triggers/${entity_id}`;
    case "invocation":
      return `/invocations/${entity_id}`;
    case "file":
      return `/files/${entity_id}`;
    case "collection":
      return `/collections/${entity_id}`;
  }
};
