import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import { SxProps, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { ResultsView } from "./types";

interface ResultsViewToggleProps {
  view: ResultsView;
  setView: (arg: ResultsView) => void;
  sx?: SxProps;
}

export default function ResultsViewToggle({
  view,
  setView,
  sx,
}: ResultsViewToggleProps) {
  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={(_, newView: ResultsView) =>
        newView !== null && setView(newView)
      }
      size="small"
      sx={sx}
      value={view}
    >
      <ToggleButton value={ResultsView.Table} aria-label="table-view">
        <TableRowsRoundedIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={ResultsView.Grid} aria-label="grid-view">
        <GridViewRoundedIcon fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
