import BlurOnIcon from "@mui/icons-material/BlurOn";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { ToggleButtonGroup, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoSlider } from "@/components/RobotoSlider";
import { TooltipToggleButton } from "@/components/ToggleTooltipButton";

import { AnnotationViewFilters } from "./types";

const sliderValueFormat = (value: number) => {
  return `Confidence: ${value}%`;
};

interface AnnotationOperationsProps {
  annotationViewFilters: AnnotationViewFilters;
  onViewFiltersChanged(filters: AnnotationViewFilters): void;
}

export const AnnotationOperations: React.FC<AnnotationOperationsProps> = ({
  annotationViewFilters,
  onViewFiltersChanged,
}) => {
  const theme = useTheme();
  const [annotations, setAnnotations] = React.useState(() => [
    "boxes",
    "labels",
  ]);

  const handleGridSliderChange = (
    _event: Event,
    newValue: number | number[],
  ) => {
    if (typeof newValue === "number") {
      onViewFiltersChanged({
        confidenceThreshold: {
          percent: newValue,
          show: annotationViewFilters.confidenceThreshold.show,
        },
        labels: annotationViewFilters.labels,
        boundingBox: annotationViewFilters.boundingBox,
        segmentation: annotationViewFilters.segmentation,
      });
    }
  };

  return (
    <>
      <ToggleButtonGroup
        aria-label="image-features"
        size="small"
        sx={{
          height: "32px",
        }}
        value={annotations}
        onChange={(_, newValue: string[]) => {
          setAnnotations(newValue);

          onViewFiltersChanged({
            confidenceThreshold: {
              percent: annotationViewFilters.confidenceThreshold.percent,
              show: newValue.includes("confidence"),
            },
            labels: { show: newValue.includes("labels") },
            boundingBox: { show: newValue.includes("boxes") },
            segmentation: { show: newValue.includes("segmentation") },
          });
        }}
      >
        <TooltipToggleButton
          title="Bounding Boxes"
          value="boxes"
          aria-label="boxes"
          color="primary"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <HighlightAltIcon fontSize="small" />
        </TooltipToggleButton>
        <TooltipToggleButton
          title="Segmentation"
          value="segmentation"
          aria-label="segmentation"
          color="primary"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <BlurOnIcon fontSize="small" />
        </TooltipToggleButton>
        <TooltipToggleButton
          title="Labels"
          value="labels"
          aria-label="labels"
          color="primary"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <LabelOutlinedIcon fontSize="small" />
        </TooltipToggleButton>
        <TooltipToggleButton
          title="Confidence"
          value="confidence"
          aria-label="confidence"
          color="primary"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <PercentOutlinedIcon fontSize="small" />
        </TooltipToggleButton>
      </ToggleButtonGroup>

      {annotations.includes("confidence") && (
        <RobotoSlider
          value={annotationViewFilters.confidenceThreshold.percent}
          min={0}
          step={1}
          max={100}
          onChange={handleGridSliderChange}
          valueLabelDisplay="auto"
          valueLabelFormat={sliderValueFormat}
          aria-labelledby="confidence-slider"
          sx={{
            width: "75px",
            height: "5px",
            ml: theme.spacing(1),
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
          }}
        />
      )}
    </>
  );
};
