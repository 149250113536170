import { Button } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import styles from "./Expandable.module.css";

interface ExpandableProps<T> {
  data: T;
  StartIcon?: JSX.ElementType;
  EndIcon?: JSX.ElementType;
  onClick?: (arg0: T) => void;
  className?: classNames.Argument;
  iconClassName?: classNames.Argument;
}

const noop = () => {};

/**
 * A subtle, textual button (optionally with an icon) that can be expanded to reveal more content.
 */
export function Expandable<T>(
  props: React.PropsWithChildren<ExpandableProps<T>>,
) {
  const { children, data, StartIcon, EndIcon, onClick = noop } = props;
  return (
    <Button
      className={classNames(props.className, styles.button)}
      startIcon={
        StartIcon !== undefined ? (
          <StartIcon className={props.iconClassName} />
        ) : undefined
      }
      endIcon={
        EndIcon !== undefined ? (
          <EndIcon className={props.iconClassName} />
        ) : undefined
      }
      onClick={() => onClick(data)}
      variant="text"
    >
      {children}
    </Button>
  );
}
