import { useCallback, useContext, useEffect, useState } from "react";

import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";

import { LimitDictionary } from "..";

export default function useLimits() {
  const { currentOrganization } = useAuth();
  const { limitSummary } = useContext(DomainServicesContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [limits, setLimits] = useState<LimitDictionary | null>(null);

  const getLimits = useCallback(
    async (signal: AbortSignal) => {
      try {
        if (!currentOrganization || !currentOrganization.org_id) {
          throw new Error("Organization not set");
        }

        const limits = await limitSummary.getLimitSummaryForOrg(
          currentOrganization.org_id,
          { abortSignal: signal },
        );

        setLimits(limits);
        setError(null);
      } catch (error) {
        if (signal.aborted) {
          return; // Prevent state update if aborted
        }

        if (error instanceof Error) {
          if (error.name === "AbortError") {
            return;
          }

          setError(error);
        } else {
          setError(new Error(String(error), { cause: error }));
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
        }
      }
    },
    [currentOrganization, limitSummary],
  );

  useEffect(() => {
    const abortController = new AbortController();
    void getLimits(abortController.signal);
    return () => abortController.abort();
  }, [getLimits]);

  return {
    limits,
    loading,
    error,
  };
}
