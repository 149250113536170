import { type Readable } from "./ReadableFile";

export class InMemoryFile implements Readable {
  #bytes: Uint8Array;

  constructor(bytes: Uint8Array) {
    this.#bytes = bytes;
  }

  public read(offset: bigint, size: bigint): Promise<Uint8Array> {
    return Promise.resolve(
      this.#bytes.slice(Number(offset), Number(offset + size)),
    );
  }

  public setAbortSignal(): void {
    // No-op
  }

  public size(): Promise<bigint> {
    return Promise.resolve(BigInt(this.#bytes.length));
  }
}
