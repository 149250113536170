import { CanonicalDataType } from "@/domain/topics";
import { ErrorMonitoringService } from "@/service";
import {
  type Dispatch,
  type MessagePathPart,
  type PanelState,
  type TopicData,
  actions,
  isLogPanelState,
  LayoutOrientation,
  MessagePathPartType,
  PanelType,
  Placement,
  LayoutItem,
  Layout,
  isMapPanelState,
  MapPanelState,
} from "@/state/visualization";

import { DroppableData, DropZone } from "../DnD";
import {
  getCorrespondingGeoType,
  isLatitudeType,
  isLongitudeType,
} from "../Panel/MapPanel";
import {
  type MessagePathNode,
  type TopicNode,
  isTopicNode,
  topicAndRepresentationForMessagePath,
  walkMessagePathToTopic,
} from "../TopicTree/tree";

interface LayoutData {
  layout: Layout | LayoutItem;
  orientation: LayoutOrientation;
}

export function isViewableAsImage(messagePath: MessagePathNode) {
  return messagePath.data.canonical_data_type === CanonicalDataType.Image;
}

export function isViewableAsPlot(messagePath: MessagePathNode) {
  return (
    messagePath.data.canonical_data_type === CanonicalDataType.Number ||
    messagePath.data.canonical_data_type === CanonicalDataType.Boolean ||
    isLatitudeType(messagePath.data.canonical_data_type) ||
    isLongitudeType(messagePath.data.canonical_data_type) ||
    // The PlotPanel will attempt pick values out of arrays and coerce them to numbers.
    // If it is unable to coerce a value to a number, it will raise an exception.
    messagePath.data.canonical_data_type === CanonicalDataType.Array
  );
}

export function isViewableAsMap(messagePath: MessagePathNode) {
  return (
    isLatitudeType(messagePath.data.canonical_data_type) ||
    isLongitudeType(messagePath.data.canonical_data_type)
  );
}

export function isViewableAsRawMessage(messagePath: MessagePathNode) {
  return !isViewableAsImage(messagePath);
}

export function isViewableAsConsoleLog(messagePath: MessagePathNode) {
  return !isViewableAsImage(messagePath);
}

function canAddToLogPanel(
  panel: PanelState,
  messagePath: MessagePathNode,
): {
  isValid: boolean;
  errors: string[];
} {
  if (!isLogPanelState(panel)) {
    return {
      isValid: false,
      errors: ["Cannot add data to non-log panel"],
    };
  }

  // Can always add if the panel is empty
  if (panel.data.length === 0) {
    return { isValid: true, errors: [] };
  }

  const errors: string[] = [];

  const topic = walkMessagePathToTopic(messagePath);

  // Cannot add if the message path is for a different topic
  if (panel.data[0].topic.id !== topic.data.topic_id) {
    errors.push("Cannot add data from a different topic to a log panel");
  }

  // Cannot add if the message path is already in the panel
  if (
    panel.data.some(
      (data) => data.messagePath.id === messagePath.data.message_path_id,
    )
  ) {
    errors.push(
      `Message path "${messagePath.data.message_path}" is already in the log panel`,
    );
  }

  return { isValid: errors.length === 0, errors };
}

function panelAcceptsMessagePath(
  panel: PanelState,
  messagePath: MessagePathNode,
): boolean {
  const panelType = panel.type;
  switch (panelType) {
    case PanelType.Plot:
      return isViewableAsPlot(messagePath);
    case PanelType.RawMessage:
      return isViewableAsRawMessage(messagePath);
    case PanelType.Image:
      return isViewableAsImage(messagePath);
    case PanelType.Map:
      return isViewableAsMap(messagePath);
    case PanelType.Log:
      return isViewableAsConsoleLog(messagePath);
    default:
      return false;
  }
}

function canAddToPanel(panel: PanelState, messagePath: MessagePathNode) {
  const panelType = panel.type;
  switch (panelType) {
    case PanelType.Log:
      return canAddToLogPanel(panel, messagePath);
    default:
      return { isValid: true, errors: [] };
  }
}

/**
 * Make a default decision for how to visualize a data type.
 * E.g., a numeric type should be visualized as a plot,
 * a string should be visualized as a "raw message," etc.
 */
export function determineDefaultPanelType(
  messagePath: MessagePathNode,
): PanelType {
  switch (messagePath.data.canonical_data_type) {
    case CanonicalDataType.Number:
    case CanonicalDataType.Boolean: // Fall through
      return PanelType.Plot;
    case CanonicalDataType.Image:
      return PanelType.Image;
    case CanonicalDataType.String:
      return PanelType.Log;
    case CanonicalDataType.LatDegFloat:
    case CanonicalDataType.LonDegFloat:
    case CanonicalDataType.LatDegInt:
    case CanonicalDataType.LonDegInt:
      return PanelType.Map;
    default:
      return PanelType.RawMessage;
  }
}

export function messagePathParts(
  messagePath: MessagePathNode,
): MessagePathPart[] {
  const parts: MessagePathPart[] = [];
  let current: MessagePathNode | TopicNode = messagePath;
  while ("parent" in current) {
    if (isTopicNode(current)) {
      break;
    }
    const { data } = current;
    const part = data.message_path.split(".").pop();
    if (data.canonical_data_type === CanonicalDataType.Array) {
      parts.unshift({
        type: MessagePathPartType.Slice,
        start: 0,
        end: Infinity,
      });
    }
    parts.unshift({
      type: MessagePathPartType.Attr,
      attribute: part ?? data.message_path,
      dataType: data.canonical_data_type,
    });
    current = current.parent;
  }
  return parts;
}

function messagePathToTopicData(messagePath: MessagePathNode): TopicData {
  const { topic, representation } =
    topicAndRepresentationForMessagePath(messagePath);

  return {
    topic: {
      id: topic.data.topic_id,
      name: topic.data.topic_name,
      association: topic.data.association,
      startTime: topic.data.start_time?.toString(),
      endTime: topic.data.end_time?.toString(),
      messageCount: topic.data.message_count ?? undefined,
    },
    messagePath: {
      id: messagePath.data.message_path_id,
      dotPath: messagePath.data.message_path,
      metadata: messagePath.data.metadata,
      parts: messagePathParts(messagePath),
    },
    representation: {
      association: representation.association,
      id: representation.representation_id,
      format: representation.storage_format,
    },
  };
}

function getCorrespondingMessagePath(messagePath: MessagePathNode) {
  const canonicalType = messagePath.data.canonical_data_type;

  if (isLatitudeType(canonicalType) || isLongitudeType(canonicalType)) {
    const correspondingType = getCorrespondingGeoType(canonicalType);
    const siblingMessagePath = messagePath.parent?.children?.find(
      (siblingNode) =>
        siblingNode.data.canonical_data_type === correspondingType,
    );

    return siblingMessagePath;
  }

  return undefined;
}

/**
 * On drop of a message path into the panel board,
 * dispatch an action to create a new panel appropriate for that message path.
 */
export function constructPanelForMessagePath(
  dispatch: Dispatch,
  messagePath: MessagePathNode,
  panelType: PanelType,
  placement?: Placement,
) {
  let data = [messagePathToTopicData(messagePath)];

  if (panelType === PanelType.Map) {
    const correspondingMessagePath = getCorrespondingMessagePath(messagePath);

    if (correspondingMessagePath) {
      const correspondingData = messagePathToTopicData(
        correspondingMessagePath,
      );
      data = [...data, correspondingData];
    }
  }

  dispatch(actions.createPanel(data, panelType, placement));
}

export function addDataToPanel(
  dispatch: Dispatch,
  panelState: PanelState,
  messagePath: MessagePathNode,
  onError: (errorMsg: string) => void,
) {
  const data = messagePathToTopicData(messagePath);

  if (panelState.type === PanelType.Plot) {
    dispatch(actions.addSeriesToPlotPanel(panelState.id, data));
    return;
  }

  if (panelState.type === PanelType.RawMessage) {
    dispatch(actions.putRawMessagePanelData(panelState.id, data));
    return;
  }

  if (panelState.type === PanelType.Image) {
    dispatch(actions.putImagePanelData(panelState.id, data));
    return;
  }

  if (panelState.type === PanelType.Log) {
    dispatch(actions.addDataToLogPanel(panelState.id, data));
    return;
  }

  if (panelState.type === PanelType.Map && isMapPanelState(panelState)) {
    // Only add the message path to the map if it's not already in use
    // This prevents duplicate paths from being added
    if (!messagePathInMapState(panelState, messagePath)) {
      const correspondingMessagePath = getCorrespondingMessagePath(messagePath);
      const dataArr = correspondingMessagePath
        ? [data, messagePathToTopicData(correspondingMessagePath)]
        : [data];

      dispatch(actions.addPathToMapPanel(panelState.id, dataArr));
    } else {
      onError(
        `Message path '${messagePath.data.message_path}' is already in use on the panel.`,
      );
    }
    return;
  }
}

function messagePathInMapState(
  panelState: MapPanelState,
  messagePath: MessagePathNode,
): boolean {
  // Get a list of topic message path ids that are currently in use
  const activeMessagePathIds = panelState.data.flatMap((path) =>
    path.data.map((geoItem) => geoItem.messagePath.id),
  );

  // Check if the provided message path id is already in the list
  if (activeMessagePathIds.includes(messagePath.data.message_path_id)) {
    return true;
  }

  // Message path is not currently in map state
  return false;
}

export function dropMessagePath(
  dispatch: Dispatch,
  draggedMessagePath: MessagePathNode,
  droppable: DroppableData<unknown>,
  onError: (errorMsg: string) => void,
) {
  const { dropZone } = droppable;

  if (dropZone === DropZone.PanelBoard) {
    const panelType = determineDefaultPanelType(draggedMessagePath);
    constructPanelForMessagePath(dispatch, draggedMessagePath, panelType);
  }
  if (dropZone === DropZone.Layout) {
    const { data } = droppable as DroppableData<LayoutData>;
    const panelType = determineDefaultPanelType(draggedMessagePath);
    if (data === undefined) {
      // This would be a programming error.
      ErrorMonitoringService.captureError(
        new Error(
          "DropMessagePath's droppable data is undefined instead of being of type LayoutData",
        ),
      );
      onError("An error occurred dropping data onto the board.");
      return;
    }

    constructPanelForMessagePath(dispatch, draggedMessagePath, panelType, {
      siblingLayout: data.layout,
      orientation: data.orientation,
    });
  }
  if (dropZone === DropZone.Panel) {
    const { data: currentPanel } = droppable as DroppableData<PanelState>;
    if (currentPanel === undefined) {
      // This would be a programming error.
      ErrorMonitoringService.captureError(
        new Error(
          "DropMessagePath's droppable data is undefined instead of being of type PanelState",
        ),
      );
      onError("An error occurred dropping data on the panel.");
      return;
    }

    if (!panelAcceptsMessagePath(currentPanel, draggedMessagePath)) {
      onError(
        `Cannot drop data of type "${draggedMessagePath.data.data_type}" onto a panel of type "${currentPanel.type}"`,
      );
      return;
    }

    const addCommand = canAddToPanel(currentPanel, draggedMessagePath);
    if (!addCommand.isValid) {
      onError(addCommand.errors.join("\n"));
      return;
    }

    addDataToPanel(dispatch, currentPanel, draggedMessagePath, onError);
  }
}

export function dropPanel(
  dispatch: Dispatch,
  draggedPanel: LayoutItem,
  droppable: DroppableData<unknown>,
  onError: (errorMsg: string) => void,
) {
  const dropZone = droppable.dropZone;

  if (dropZone === DropZone.Layout) {
    const { data } = droppable as DroppableData<LayoutData>;

    if (data === undefined) {
      // This would be a programming error.
      ErrorMonitoringService.captureError(
        new Error(
          "DropPanel's droppable data is undefined instead of being of type LayoutData",
        ),
      );
      onError("An error occurred dropping this panel.");
      return;
    }

    dispatch(
      actions.movePanel(draggedPanel, {
        siblingLayout: data.layout,
        orientation: data.orientation,
      }),
    );
  }
}
