import { type JSONSchemaType } from "ajv";

export const VERSION = "annotations_v1";

interface BaseSchema {
  version: string;
}

export interface BoundingBox {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

const boxSchema: JSONSchemaType<BoundingBox> = {
  type: "object",
  properties: {
    x1: { type: "number" },
    x2: { type: "number" },
    y1: { type: "number" },
    y2: { type: "number" },
  },
  required: ["x1", "x2", "y1", "y2"],
};

export interface Segments {
  x: number[];
  y: number[];
}

const segmentSchema: JSONSchemaType<Segments> = {
  type: "object",
  properties: {
    x: {
      type: "array",
      items: { type: "number" },
    },
    y: {
      type: "array",
      items: { type: "number" },
    },
  },
  required: ["x", "y"],
};

export interface Annotation {
  name: string;
  box?: BoundingBox;
  class?: number;
  confidence?: number;
  segments?: Segments | null;
}

const imageAnnotationsSchema: JSONSchemaType<Annotation> = {
  type: "object",
  properties: {
    box: {
      ...boxSchema,
      nullable: true,
    },
    class: { type: "number", nullable: true },
    confidence: { type: "number", nullable: true },
    name: { type: "string" },
    segments: {
      ...segmentSchema,
      nullable: true,
    },
  },
  required: ["name"],
  additionalProperties: true,
};

type ImagesDictionary = Record<string, Annotation[]>;

const imagesSchema: JSONSchemaType<ImagesDictionary> = {
  type: "object",
  patternProperties: {
    ".*": {
      type: "array",
      items: imageAnnotationsSchema,
    },
  },
  required: [],
};

interface Metadata {
  model_name: string;
  date: string;
}

const metadataSchema: JSONSchemaType<Metadata> = {
  type: "object",
  properties: {
    model_name: { type: "string" },
    date: { type: "string" },
  },
  required: [],
  additionalProperties: true,
};

export interface Annotations {
  images: { [key: string]: Annotation[] };
  metadata: {
    model_name: string;
    date: string;
  };
}

interface ImageAnnotations extends BaseSchema, Annotations {}

export const schema: JSONSchemaType<ImageAnnotations> = {
  $id: VERSION,
  type: "object",
  properties: {
    version: { type: "string", const: VERSION },
    images: imagesSchema,
    metadata: metadataSchema,
  },
  required: ["images"],
  additionalProperties: true,
};

// In case we add more annotation types later
export type AnnotationData = ImageAnnotations;
