import { MenuItem, Stack, TextField } from "@mui/material";
import * as React from "react";

import useLimits from "@/domain/limits/hooks/useLimits";
import { Resource } from "@/types/resources";
import { partitionArray } from "@/utils/arrays";

import {
  actionStorageError,
  actionStorageMin,
  determineActionMemoryOptions,
  isActionStorageValid,
  vCpuOptions,
} from "./actionUtils";

interface ActionComputeReqsProps {
  cpu: string | undefined;
  setCpu: (arg: string | undefined) => void;
  memory: string | undefined;
  setMemory: (arg: string | undefined) => void;
  gpu: string | undefined;
  setGpu: (arg: string | undefined) => void;
  storage: number | undefined;
  setStorage: (arg: number | undefined) => void;
  setHasComputeFormErrors: (hasError: boolean) => void;
}

const StorageHelperText = ({
  actionStorageMax,
  storage,
}: {
  actionStorageMax: number;
  storage?: number;
}) => {
  const showStorageHelperText =
    storage && !isActionStorageValid(storage, actionStorageMax);

  if (showStorageHelperText) {
    return (
      "Storage must be between " +
      actionStorageMin +
      " GiB and " +
      actionStorageMax +
      " GiB. Contact support@roboto.ai for higher amounts."
    );
  }

  return "";
};

function useComputeLimits(cpu?: string) {
  const { limits, loading, error } = useLimits();

  if (loading || !limits) {
    return {
      vCpuOptionsEligible: [],
      vCpuOptionsIneligible: [],
      memoryOptionsEligible: [],
      memoryOptionsIneligible: [],
      actionStorageMax: actionStorageMin,
      error,
    };
  }

  const [vCpuOptionsEligible, vCpuOptionsIneligible] = partitionArray(
    vCpuOptions,
    limits[Resource.VCPUs],
  );

  const [memoryOptionsEligible, memoryOptionsIneligible] = partitionArray(
    determineActionMemoryOptions(cpu || "0"),
    limits[Resource.MemoryMiBs],
  );

  const actionStorageMax = limits[Resource.StorageGiBs];

  return {
    vCpuOptionsEligible,
    vCpuOptionsIneligible: vCpuOptionsIneligible.slice(-1),
    memoryOptionsEligible,
    memoryOptionsIneligible: memoryOptionsIneligible.slice(-1),
    actionStorageMax,
    error,
  };
}

export const ActionComputeReqs: React.FC<ActionComputeReqsProps> = ({
  cpu,
  setCpu,
  memory,
  setMemory,
  gpu,
  setGpu,
  storage,
  setStorage,
  setHasComputeFormErrors,
}) => {
  const {
    vCpuOptionsEligible,
    vCpuOptionsIneligible,
    memoryOptionsEligible,
    memoryOptionsIneligible,
    actionStorageMax,
  } = useComputeLimits(cpu);

  const actionStoragePlaceholder =
    actionStorageMin >= actionStorageMax
      ? `${actionStorageMax} GiB`
      : `(Storage ${actionStorageMin} GiB to ${actionStorageMax} GiB)`;

  return (
    <Stack spacing={2}>
      <TextField
        id="select-action-vcpu"
        select
        label="vCPU"
        size={"small"}
        value={cpu ?? ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const cpu = event.target.value;
          const memory = determineActionMemoryOptions(cpu)[0];

          setCpu(cpu);

          if (memory) {
            setMemory(`${memory}`);
          }
        }}
        fullWidth
      >
        {vCpuOptionsEligible.map((option) => (
          <MenuItem key={option} value={option}>
            {option} ({option / 1024} vCPU)
          </MenuItem>
        ))}
        {vCpuOptionsIneligible.map((option) => (
          <MenuItem disabled key={option} value={option}>
            {option} ({option / 1024} vCPU) [Premium]
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="select-action-memory"
        select
        label="Memory (MiB)"
        size={"small"}
        value={memory ?? ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setMemory(event.target.value);
        }}
        fullWidth
      >
        {cpu &&
          memoryOptionsEligible.map((option) => (
            <MenuItem key={option} value={option}>
              {option} ({option / 1024} GiB)
            </MenuItem>
          ))}
        {cpu &&
          memoryOptionsIneligible.map((option) => (
            <MenuItem disabled key={option} value={option}>
              {option} ({option / 1024} GiB) [Premium]
            </MenuItem>
          ))}
      </TextField>
      <TextField
        id="select-action-storage"
        placeholder={actionStoragePlaceholder}
        size="small"
        fullWidth
        value={storage ?? actionStorageMin}
        label="Storage (GiB)"
        type="number"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const storage = parseInt(event.target.value);
          setStorage(storage);
          setHasComputeFormErrors(
            actionStorageError(storage, actionStorageMax),
          );
        }}
        error={actionStorageError(storage, actionStorageMax)}
        helperText={
          <StorageHelperText
            storage={storage}
            actionStorageMax={actionStorageMax}
          />
        }
        inputProps={{
          autoComplete: "off",
        }}
      />
      <TextField
        id="select-action-gpu"
        select
        label="GPU"
        size={"small"}
        value={gpu ?? "false"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setGpu(event.target.value);
        }}
        fullWidth
      >
        <MenuItem value={"false"}>No (Coming Soon)</MenuItem>
      </TextField>
    </Stack>
  );
};
