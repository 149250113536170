import { useParams } from "react-router-dom";

import {
  NavigationPageWithRedirectProtection,
  RobotoTypography,
} from "@/components";
import { AssociationType } from "@/domain/association";
import { CreateDatasetEventForm } from "@/features/dataset/components/CreateDatasetEventForm";
import { useNavigation } from "@/providers";

export const CreateDatasetEvent = () => {
  const { datasetPath } = useParams();
  const { goto } = useNavigation();
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Create Event"}>
      <RobotoTypography variant="h5">Create Event</RobotoTypography>
      <CreateDatasetEventForm
        associationRecord={{
          association_id: datasetPath ?? "",
          association_type: AssociationType.Dataset,
        }}
        onCancel={() => {
          if (datasetPath) {
            goto.dataset({ datasetId: datasetPath });
          }
        }}
        onSuccess={() => {
          if (datasetPath) {
            goto.dataset({ datasetId: datasetPath });
          }
        }}
      />
    </NavigationPageWithRedirectProtection>
  );
};
