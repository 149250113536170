import * as React from "react";

import { FileFocal } from "../components";

interface FileFocalPageProps {
  fileId: string | undefined;
}

export const FileFocalPage: React.FC<FileFocalPageProps> = ({ fileId }) => {
  return fileId && <FileFocal fileId={fileId} />;
};
