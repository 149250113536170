import { snakeToSpacedCamelCase } from "./snakeToSpacedCamelCase";

export const formatFieldName = (name: string) => {
  if (name.includes("metadata.")) {
    // Simply capitalize first letter, don't transform the field name
    return name.charAt(0).toUpperCase() + name.slice(1);
  } else {
    // Fully transform the field name to improve readability
    return snakeToSpacedCamelCase(name);
  }
};
