import ClearIcon from "@mui/icons-material/Clear";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import * as React from "react";

import {
  RobotoButton,
  RobotoIconButton,
  RobotoLogo,
  RobotoTypography,
} from "@/components";

import { AuthCard } from "./AuthCard";
import { LegalFooter } from "./LegalFooter";

interface InviteTeamMembersFormProps {
  isVisible: boolean;
  settingsPage?: boolean;
  inviteAndCompletePressed: (emailAddresses: string[]) => void;
  skipForNowPressed: () => void;
  organizationName: string | undefined;
}

const buttonWidth = "215px";

export const InviteTeamMembersForm: React.FC<InviteTeamMembersFormProps> = ({
  isVisible,
  settingsPage,
  inviteAndCompletePressed,
  skipForNowPressed,
  organizationName,
}) => {
  const theme = useTheme();

  const [emailAddresses, setEmailAddresses] = React.useState<string[]>([""]);
  const [loading, setLoading] = React.useState<boolean>(false);

  if (!isVisible) {
    return null;
  }

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  let allEmailsValid = true;

  for (let i = 0; i < emailAddresses.length; i++) {
    const isValid = emailRegex.test(emailAddresses[i]);

    if (!isValid) {
      allEmailsValid = false;
      break;
    }
  }

  return (
    <>
      {!settingsPage && (
        <RobotoLogo
          sx={{ maxWidth: "250px", marginBottom: theme.spacing(2) }}
        />
      )}

      <AuthCard settingsPage={settingsPage}>
        {!settingsPage && (
          <Box
            sx={{
              maxWidth: "575px",
              textAlign: settingsPage ? "left" : "center",
            }}
          >
            {!settingsPage && (
              <GroupRoundedIcon
                color="primary"
                sx={{
                  fontSize: "5rem",
                  display: {
                    xs: "none",
                    sm: "inline",
                  },
                }}
              />
            )}

            <RobotoTypography
              variant="h5"
              sx={{ marginBottom: theme.spacing(2) }}
            >
              Invite team members to {organizationName}
            </RobotoTypography>
          </Box>
        )}

        <RobotoTypography
          variant={"body2"}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          Add team members to collaborate
        </RobotoTypography>

        {emailAddresses.map((_emailAddress, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: settingsPage ? "start" : "center",
                width: "100%",
                marginBottom: theme.spacing(2),
              }}
            >
              <TextField
                label={"Email address"}
                placeholder="name@domain.com"
                sx={{ width: "70%" }}
                value={emailAddresses[index]}
                onChange={(e) => {
                  const newEmailAddresses = [...emailAddresses];
                  newEmailAddresses[index] = e.target.value;
                  setEmailAddresses(newEmailAddresses);
                }}
                size="small"
                inputProps={{
                  autoCapitalize: "none",
                }}
              />

              <RobotoIconButton
                eventName={"InviteFieldRemoved"}
                sx={{ marginLeft: theme.spacing(1) }}
                onClick={() => {
                  const newEmailAddresses = [...emailAddresses];
                  newEmailAddresses.splice(index, 1);
                  setEmailAddresses(newEmailAddresses);
                }}
              >
                <ClearIcon fontSize="small" />
              </RobotoIconButton>
            </Box>
          );
        })}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: settingsPage ? "start" : "center",
          }}
        >
          <RobotoButton
            eventName={"InviteFieldAdded"}
            sx={{ marginLeft: "-5px" }}
            onClick={() => {
              const newEmailAddresses = [...emailAddresses];
              newEmailAddresses.push("");
              setEmailAddresses(newEmailAddresses);
            }}
          >
            + Add another person
          </RobotoButton>
        </Box>

        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size="1.75rem" />
          </Box>
        ) : (
          <>
            <RobotoButton
              eventName={"GoToNextStep"}
              eventProperties={{ currentStep: "InviteTeamMembers" }}
              disabled={!allEmailsValid || emailAddresses.length === 0}
              variant={"contained"}
              sx={{
                width: buttonWidth,
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
              onClick={() => {
                inviteAndCompletePressed(emailAddresses);
                setEmailAddresses([""]);
              }}
            >
              Invite
            </RobotoButton>

            {!settingsPage && (
              <RobotoButton
                data-cy={"skip-for-now"}
                eventName="StepSkipped"
                eventProperties={{ currentStep: "InviteTeamMembers" }}
                variant={"outlined"}
                sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
                onClick={() => {
                  setLoading(true);
                  skipForNowPressed();
                }}
              >
                Skip for now
              </RobotoButton>
            )}
          </>
        )}
      </AuthCard>
      {!settingsPage && <LegalFooter />}
    </>
  );
};
