import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import * as React from "react";

import { actions, useVizDispatch } from "@/state/visualization";

interface RemovePanelProps {
  panelId: string;
}

/**
 * A button that removes a panel from the panel board.
 */
export function RemovePanel({ panelId }: RemovePanelProps) {
  const dispatch = useVizDispatch();

  const onRemovePanel = React.useCallback(() => {
    dispatch(actions.removePanel(panelId));
  }, [dispatch, panelId]);

  return (
    <IconButton
      aria-label="remove panel"
      onClick={onRemovePanel}
      size="small"
      title="Remove panel"
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}
