import { Alert } from "@mui/material";
import * as React from "react";

import { EventDescription } from "./EventDescription";
import styles from "./EventDetails.module.css";
import { EventId } from "./EventId";
import { EventMetadata } from "./EventMetadata";
import { EventName } from "./EventName";
import { EventScope } from "./EventScope";
import { EventTags } from "./EventTags";
import { EventTime } from "./EventTime";

interface EventDetailsProps {
  eventId: string;
}

export function EventDetails({ eventId }: EventDetailsProps) {
  const [error, setError] = React.useState<Error | null>(null);
  return (
    <div className={styles.container}>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error.message}
        </Alert>
      )}
      <EventId eventId={eventId} />
      <EventName eventId={eventId} />
      <EventTime eventId={eventId} />
      <EventScope eventId={eventId} />
      <EventDescription eventId={eventId} />
      <EventTags eventId={eventId} onError={setError} />
      <EventMetadata eventId={eventId} onError={setError} />
    </div>
  );
}
