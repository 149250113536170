import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Paper, useTheme, IconButton, Typography } from "@mui/material";
import * as React from "react";

import { UIUploadItem } from "../types";

import { UploadRow } from "./UploadRow";

interface UploadProgressToastProps {
  isVisible: boolean;
  isProcessing: boolean;
  onExitClicked: () => void;
  processingError: Error | null;
  uploadItems: UIUploadItem[];
}

export const UploadProgressToast: React.FC<UploadProgressToastProps> = ({
  isVisible,
  isProcessing,
  onExitClicked,
  processingError,
  uploadItems,
}) => {
  const theme = useTheme();

  const [collapsed, setCollapsed] = React.useState(false);

  const onCollapseClicked = () => {
    setCollapsed(!collapsed);
  };

  if (!isVisible) {
    return null;
  }

  let inCompleteUploads = 0;
  let completedUploads = 0;

  uploadItems.forEach((item) => {
    if (!item.isUploadCompleted) {
      inCompleteUploads += 1;
    } else {
      completedUploads += 1;
    }
  });

  let title = "";
  if (isProcessing) {
    title = "Processing...";
  } else if (inCompleteUploads > 0) {
    title = `${inCompleteUploads} upload${inCompleteUploads > 1 ? "s" : ""} in progress`;
  } else if (completedUploads > 0) {
    title = `${completedUploads} upload${completedUploads > 1 ? "s" : ""} complete`;
  } else if (processingError) {
    title = "Upload Error";
  }

  return (
    <Paper
      elevation={2}
      sx={{
        position: "fixed",
        right: theme.spacing(2),
        bottom: 0,
        zIndex: 10000,
        width: "350px",
        maxWidth: "350px",
        borderTopRightRadius: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: theme.spacing(0.5),
          paddingLeft: theme.spacing(2),
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: theme.palette.toastHeader.main,
          borderTopRightRadius: theme.spacing(1),
          borderTopLeftRadius: theme.spacing(1),
          position: "sticky",
        }}
      >
        <Typography variant={"subtitle1"} sx={{ fontWeight: 500 }}>
          {title}
        </Typography>

        <Box>
          <IconButton
            onClick={onCollapseClicked}
            sx={{ marginRight: theme.spacing(0.25) }}
          >
            {collapsed ? (
              <ExpandLessIcon fontSize={"medium"} />
            ) : (
              <ExpandMoreIcon fontSize={"medium"} />
            )}
          </IconButton>
          <IconButton onClick={onExitClicked}>
            <CloseIcon fontSize={"medium"} />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          maxHeight: "275px",
          overflowY: "scroll",
          display: collapsed ? "none" : "block",
          marginBottom: theme.spacing(1),
        }}
      >
        {processingError && (
          <Box
            sx={{
              padding: theme.spacing(1, 2),
              backgroundColor: "rgb(182 40 40)",
            }}
          >
            <Typography
              variant={"body2"}
              sx={{ color: "white", wordBreak: "break-word" }}
            >
              {processingError.message}
            </Typography>
          </Box>
        )}
        {uploadItems.map((uploadItem, i) => {
          return <UploadRow key={i} item={uploadItem} />;
        })}
      </Box>
    </Paper>
  );
};
