import Ajv from "ajv";

import {
  CURRENT_ANNOTATION_SCHEMA_VERSION,
  schema as annotationSchema,
} from "@/components/Annotations/schema";
import {
  CURRENT_VIZ_SCHEMA_VERSION,
  vizSchema,
} from "@/state/visualization/schema/";

export const validator = new Ajv();

validator.addSchema(annotationSchema, CURRENT_ANNOTATION_SCHEMA_VERSION);
validator.addSchema(vizSchema, CURRENT_VIZ_SCHEMA_VERSION);
