import { Outlet } from "react-router-dom";

/**
 * Top-level layout component for visualization routes.
 *
 * `Outlet` will be replaced with either `UnderConstruction` or `Workspace` based on the route.
 */
export function VisualizationPageLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
