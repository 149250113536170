import { SelectAnnotations } from "./SelectAnnotations";

interface FileVisualizationControlsProps {
  isFileAnImage: boolean;
  handlePopover: (
    component: "annotations" | null,
    element: HTMLButtonElement | null,
  ) => void;
  hide?: boolean;
}

export function FileVisualizationControls(
  props: FileVisualizationControlsProps,
) {
  const { hide = false, ...rest } = props;

  if (hide) {
    return null;
  }

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <SelectAnnotations {...rest} />
    </div>
  );
}
