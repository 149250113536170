import { useContext, useState } from "react";

import { OrgInviteRecord, OrgRecord } from "@/domain/orgs";
import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";

export const useSubmitVerificationAndSignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  const { submitVerificationCode, signIn } = useAuth();
  const { users } = useContext(DomainServicesContext);

  const handleVerificationCodeSubmission = async (
    emailAddress: string,
    code: string,
  ): Promise<string | null> => {
    setLoading(true);
    setErrMsg(null);

    const submitErr = await submitVerificationCode(emailAddress, code);
    if (submitErr) {
      setErrMsg(submitErr);
      setLoading(false);
    }
    return submitErr;
  };

  const handleSignIn = async (
    emailAddress: string,
    password: string,
  ): Promise<string | null> => {
    const signInErr = await signIn(emailAddress, password);
    if (signInErr) {
      setErrMsg(signInErr);
      setLoading(false);
    }
    return signInErr;
  };

  const fetchOrganizationsAndInvites = async (): Promise<{
    organizations: OrgRecord[];
    invites: OrgInviteRecord[];
    errMsg: string | null;
  }> => {
    try {
      const [orgs, invites] = await Promise.all([
        users.getOrgsForCurrentUser(),
        users.getInvitesForCurrentUser(),
      ]);
      setLoading(false);
      return { organizations: orgs, invites: invites, errMsg: null };
    } catch (e: unknown) {
      if (e instanceof Error) {
        setErrMsg(e.message);
        setLoading(false);
        return { organizations: [], invites: [], errMsg: e.message };
      }
      return {
        organizations: [],
        invites: [],
        errMsg: "Something went wrong. Please try again.",
      };
    }
  };

  const submitVerificationAndSignIn = async (
    emailAddress: string,
    code: string,
    password: string,
  ): Promise<{
    organizations: OrgRecord[];
    invites: OrgInviteRecord[];
    errMsg: string | null;
  }> => {
    const submitErr = await handleVerificationCodeSubmission(
      emailAddress,
      code,
    );
    if (submitErr) {
      return { organizations: [], invites: [], errMsg: submitErr };
    }

    const signInErr = await handleSignIn(emailAddress, password);
    if (signInErr) {
      return { organizations: [], invites: [], errMsg: signInErr };
    }

    return await fetchOrganizationsAndInvites();
  };

  const clearErrMsg = () => {
    setErrMsg(null);
  };

  return { loading, errMsg, submitVerificationAndSignIn, clearErrMsg };
};
