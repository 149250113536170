import { Alert } from "@mui/material";
import * as React from "react";

import { MetadataForm, MetadataView } from "@/components/Metadata";
import { useMutateFileMetadata } from "@/domain/files/hooks/useMutateFileMetadata";

import { Metadata } from "../Metadata/types";
import { AutocompleteType } from "../TagAndMetadataAutocomplete";

interface FileMetadataProps {
  fileId: string;
  metadata: Record<string, string>;
}

export const FileMetadata: React.FC<FileMetadataProps> = ({
  fileId,
  metadata,
}) => {
  const updateFileMetadataMutation = useMutateFileMetadata();

  const onRemoveMetadata = (data: Metadata, keyDeleted?: string) => {
    if (data && keyDeleted) {
      updateFileMetadataMutation.mutate({
        fileId: fileId,
        removeFields: [keyDeleted],
      });
    }
  };

  const onAddMetadata = (key: string, value: string) => {
    if (key && value) {
      const metadataClone = {
        ...metadata,
        [key]: value,
      };

      updateFileMetadataMutation.mutate({
        fileId: fileId,
        putFields: metadataClone,
      });
    }
  };

  return (
    <>
      <MetadataView
        metadata={metadata || {}}
        onChange={onRemoveMetadata}
        disabled={false}
      />
      <MetadataForm
        metadata={metadata || {}}
        autocompleteType={AutocompleteType.FileMetadataKeys}
        onAddMetadata={onAddMetadata}
        disabled={false}
        isLoading={updateFileMetadataMutation.isPending}
      />
      {updateFileMetadataMutation.isError && (
        <Alert
          onClose={() => updateFileMetadataMutation.reset()}
          severity="error"
          sx={(theme) => ({ marginTop: theme.spacing(1) })}
        >
          {updateFileMetadataMutation.error.message}
        </Alert>
      )}
    </>
  );
};
