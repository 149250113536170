import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button } from "@mui/material";
import classNames from "classnames";

import styles from "./Header.module.css";

interface HeaderProps {
  className?: classNames.Argument;
  onBack?: () => void;
}

const noop = () => {};

/**
 * Header for the TopicTree Sidebar.
 */
export function Header(props: HeaderProps) {
  const { className, onBack = noop } = props;

  return (
    <div className={classNames(className)}>
      <Button
        onClick={onBack}
        startIcon={<ChevronLeftIcon />}
        variant="contained"
        disableElevation
        className={styles.button}
        fullWidth
      >
        Select file
      </Button>
    </div>
  );
}
