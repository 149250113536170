import { UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { editor, IRange, languages, Position } from "monaco-editor";

import { QueryTarget } from "@/domain/query";
import { AllTopicKeysRecord } from "@/domain/topics/TopicRecord";

import { RoboqlFieldDescription, roboqlFields } from "../roboqlFields";

import { languageDef } from "./language";

enum CurrentlyEditing {
  BlankEditor = "blank_editor",
  Dataset = "dataset",
  DatasetTags = "datasetTags",
  DatasetMetadata = "datasetMetadata",
  File = "file",
  FileTags = "fileTags",
  FileMetadata = "fileMetadata",
  MessagePathSelection = "messagePathSelection",
  MessagePath = "messagePath",
  MessagePathMetadata = "messagePathMetadata",
  TopLevelField = "topLevelField",
  Topic = "topic",
  TopicName = "topicName",
  Event = "event",
  EventName = "eventName",
  EventTags = "eventTags",
  EventMetadata = "eventMetadata",
  Unspecified = "unspecified",
}

interface RoboqlQueryCtx {
  currentlyEditing: CurrentlyEditing;
  currentVariable?: string;
  replaceRange: IRange;
  variables: {
    datasetFiles?: string[];
    datasetTopics?: string[];
    fileTopics?: string[];
  };
}

export interface RoboqlExternalCtx {
  target: QueryTarget;
  datasets?: {
    tags?: UseQueryResult<string[], Error>;
    metadataKeys?: UseQueryResult<string[], Error>;
  };
  files?: {
    tags?: UseQueryResult<string[], Error>;
    metadataKeys?: UseQueryResult<string[], Error>;
  };
  topics?: UseQueryResult<AllTopicKeysRecord, Error>;
  events?: {
    names?: UseQueryResult<string[], Error>;
    tags?: UseQueryResult<string[], Error>;
    metadataKeys?: UseQueryResult<string[], Error>;
  };
}

const FILE_VAR_REGEX = /files\[[^]+]/;
const TOPIC_VAR_REGEX = /topics\[[^]+]/;
const MESSAGE_PATH_SELECTION_REGEX = /(msgpaths|fields|message_paths)\[(.*)$/;
// Literally the same, but with a closed square brace
const MESSAGE_PATH_VAR_REGEX = /(msgpaths|fields|message_paths)\[.+]/;

const DATASET_SORTABLE_FIELDS = roboqlFields.datasets
  .filter((field) => {
    return field.is_sortable;
  })
  .map((field) => field.label);

const FILE_SORTABLE_FIELDS = roboqlFields.files
  .filter((field) => {
    return field.is_sortable;
  })
  .map((field) => field.label);

const TOPIC_SORTABLE_FIELDS = roboqlFields.topics
  .filter((field) => {
    return field.is_sortable;
  })
  .map((field) => field.label);

const TOPIC_MESSAGE_PATH_SORTABLE_FIELDS = roboqlFields.topic_message_paths
  .filter((field) => {
    return field.is_sortable;
  })
  .map((field) => field.label);

const EVENT_SORTABLE_FIELDS = roboqlFields.events
  .filter((field) => {
    return field.is_sortable;
  })
  .map((field) => field.label);

export class RoboqlCompletionProvider
  implements
    languages.CompletionItemProvider /* prettier-ignore */ {
  #externalCtx: RoboqlExternalCtx;

  public set externalCtx(theExternalCtx: RoboqlExternalCtx) {
    this.#externalCtx = theExternalCtx;
  }

  public get externalCtx(): RoboqlExternalCtx {
    return this.#externalCtx;
  }

  public constructor(externalCtx?: RoboqlExternalCtx) {
    this.#externalCtx = externalCtx || {
      target: QueryTarget.Datasets,
    };
  }

  public readonly triggerCharacters = [".", " "];

  private operatorSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {

    const operators: string[] = [
      ...(languageDef.keywords as string[]),
      ...(languageDef.string_operators as string[]),
    ].filter(
        (operator) => !["by", "sort", "limit"].includes(operator.toLowerCase()),
    );

    const proposals: languages.CompletionItem[] = operators.map(
        (operator: string) => {
          return {
            label: operator.toUpperCase(),
            kind: languages.CompletionItemKind.Operator,
            insertText: operator.toUpperCase(),
            range: queryCtx.replaceRange,
          };
        },
    );

    let sortableFields: string[];
    switch (this.externalCtx.target) {
      case QueryTarget.Datasets:
        sortableFields = DATASET_SORTABLE_FIELDS;
        break;
      case QueryTarget.Files:
        sortableFields = FILE_SORTABLE_FIELDS;
        break;
      case QueryTarget.Topics:
        sortableFields = TOPIC_SORTABLE_FIELDS;
        break;
      case QueryTarget.TopicMessagePaths:
        sortableFields = TOPIC_MESSAGE_PATH_SORTABLE_FIELDS;
        break;
      case QueryTarget.Events:
        sortableFields = EVENT_SORTABLE_FIELDS;
        break;
      default:
        sortableFields = ["created"];
        break;
    }

    const sortByText = `SORT BY \${1|${sortableFields.join(",")}|} \${2|DESC,ASC|}`;
    const limitText = `LIMIT \${3|25,50,100,250,500|}`;

    proposals.push({
      label: "SORT BY (No Limit)",
      kind: languages.CompletionItemKind.Snippet,
      insertText: sortByText,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: queryCtx.replaceRange,
    });

    proposals.push({
      label: "SORT BY (With Limit)",
      kind: languages.CompletionItemKind.Snippet,
      insertText: `${sortByText} ${limitText}`,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: queryCtx.replaceRange,
    });

    proposals.push({
      label: "LIMIT",
      kind: languages.CompletionItemKind.Snippet,
      insertText: limitText,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: queryCtx.replaceRange,
    });

    return proposals;
  }

  private normalizeComparators(comparators: string[]): string[] {
    const normalizingMap = new Map<string, string>([
      ["equals", "="],
      ["not_equals", "!="],
      ["greater_than", ">"],
      ["greater_than_or_equal", ">="],
      ["less_than", "<"],
      ["less_than_or_equal", "<="],
    ])

    return comparators.map((comparator) => {
      return normalizingMap.get(comparator.toLowerCase()) || comparator
    })
  }

  private fieldSnippetSuggestions(field: RoboqlFieldDescription, queryCtx: RoboqlQueryCtx): languages.CompletionItem[] {
    const extraProps: Partial<languages.CompletionItem> = {}

    let insertTextEnd: string
    let insertAsSnippet: boolean;

    if (!field.comparators || field.label === "tags" || field.label === "metadata") {
      insertTextEnd = ""
      insertAsSnippet = false
    } else {
      const comparatorChoices = this.normalizeComparators(field.comparators).join(",")
      insertTextEnd = ` $\{1|${comparatorChoices}|}`
      insertAsSnippet = true

      if (field.label === "created" || field.label === "modified" || field.label === "start_time" || field.label === "end_time") {
        const now = DateTime.utc();
        const nowUtcTime = `"${now.toISO()}"`
        const nowUtcDate = `"${now.toISODate()}"`
        const nowLocalTime = `"${now.toLocal().toISO()}"`

        insertTextEnd += ` $\{2|${nowUtcDate},${nowUtcTime},${nowLocalTime}|}`
      } else {
        insertTextEnd += " $0"
      }
    }


    if (insertAsSnippet) {
      extraProps.insertTextRules = languages.CompletionItemInsertTextRule.InsertAsSnippet
    }

    const suggestions: languages.CompletionItem[] = [{
      ...extraProps,
      kind: languages.CompletionItemKind.Field,
      range: queryCtx.replaceRange,
      detail: field.detail || undefined,
      label: field.label,
      insertText: field.label + insertTextEnd,
      documentation: field.documentation || undefined
    }]

    field.aliases.forEach((alias) => {
      suggestions.push({
        ...extraProps,
        kind: languages.CompletionItemKind.Field,
        range: queryCtx.replaceRange,
        detail: `Alias for ${field.label}. ${(field.detail || "")}`.trim(),
        documentation: field.documentation || undefined,
        label: alias,
        insertText: alias + insertTextEnd
      })
    })

    return suggestions
  }

  private filePropertySuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = []

    roboqlFields.files.forEach((field) => {
      proposals.push(...this.fieldSnippetSuggestions(field, queryCtx))
    })

    if (this.externalCtx.target === QueryTarget.Files) {
      proposals.push({
        label: "dataset",
        kind: languages.CompletionItemKind.Field,
        insertText: "dataset",
        range: queryCtx.replaceRange,
        detail: "The dataset this file belongs to",
      });

      let newFileTopicIdx = 0;
      queryCtx.variables.fileTopics?.forEach((fileTopic) => {
        proposals.push({
          label: `${fileTopic}`,
          kind: languages.CompletionItemKind.Field,
          insertText: `${fileTopic}`,
          detail: "Reference a specific topic",
          range: queryCtx.replaceRange,
        });

        if (fileTopic === `topics[${newFileTopicIdx}]`) {
          newFileTopicIdx += 1;
        }
      });

      proposals.push({
        label: `topics[${newFileTopicIdx}] (New Reference)`,
        kind: languages.CompletionItemKind.Field,
        insertText: `topics[${newFileTopicIdx}]`,
        detail: "A reference to a new topic condition set",
        range: queryCtx.replaceRange,
      });
    }

    return proposals;
  }

  private fileTagSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.files?.tags?.isLoading) {
      proposals.push({
        label: `Loading tags...`,
        kind: languages.CompletionItemKind.Variable,
        insertText: "",
        range: queryCtx.replaceRange,
      });
    } else {
      this.externalCtx.files?.tags?.data?.forEach((tag) => {
        proposals.push({
          label: `${tag}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: ` CONTAINS "${tag}"`,
          range: queryCtx.replaceRange,
          // Delete the leading .
          additionalTextEdits: [
            {
              range: {
                startColumn: queryCtx.replaceRange.startColumn - 1,
                endColumn: queryCtx.replaceRange.startColumn,
                startLineNumber: queryCtx.replaceRange.startLineNumber,
                endLineNumber: queryCtx.replaceRange.endLineNumber,
              },
              text: null,
            },
          ],
        });
      });
    }

    return proposals;
  }

  private fileMetadataSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.files?.metadataKeys?.isLoading) {
      proposals.push({
        label: `Loading metadata...`,
        kind: languages.CompletionItemKind.Variable,
        insertText: "",
        range: queryCtx.replaceRange,
      });
    } else {
      this.externalCtx.files?.metadataKeys?.data?.forEach((metadataKey) => {
        proposals.push({
          label: `${metadataKey}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: `${metadataKey}`,
          range: queryCtx.replaceRange,
        });
      });
    }

    return proposals;
  }

  private datasetPropertyProposals(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = []

    roboqlFields.datasets.forEach((field) => {
      proposals.push(...this.fieldSnippetSuggestions(field, queryCtx))
    })

    if (this.externalCtx.target === QueryTarget.Datasets) {
      let newDatasetFileIdx = 0;
      queryCtx.variables.datasetFiles?.forEach((datasetFile) => {
        proposals.push({
          label: `${datasetFile}`,
          kind: languages.CompletionItemKind.Field,
          insertText: `${datasetFile}`,
          detail: "Reference a specific file",
          range: queryCtx.replaceRange,
        });

        if (datasetFile === `files[${newDatasetFileIdx}]`) {
          newDatasetFileIdx += 1;
        }
      });

      proposals.push({
        label: `files[${newDatasetFileIdx}] (New Reference)`,
        kind: languages.CompletionItemKind.Field,
        insertText: `files[${newDatasetFileIdx}]`,
        detail: "A reference to a new file condition set",
        range: queryCtx.replaceRange,
      });

      let newDatasetTopicIdx = 0;
      queryCtx.variables.datasetTopics?.forEach((datasetTopic) => {
        proposals.push({
          label: `${datasetTopic}`,
          kind: languages.CompletionItemKind.Field,
          insertText: `${datasetTopic}`,
          detail: "Reference a specific topic",
          range: queryCtx.replaceRange,
        });

        if (datasetTopic === `topics[${newDatasetTopicIdx}]`) {
          newDatasetTopicIdx += 1;
        }
      });

      proposals.push({
        label: `topics[${newDatasetTopicIdx}] (New Reference)`,
        kind: languages.CompletionItemKind.Field,
        insertText: `topics[${newDatasetTopicIdx}]`,
        detail: "A reference to a new topic condition set",
        range: queryCtx.replaceRange,
      });
    }

    return proposals;
  }

  private datasetTagSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.datasets?.tags?.isLoading) {
      proposals.push({
        label: `Loading tags...`,
        kind: languages.CompletionItemKind.Variable,
        insertText: "",
        range: queryCtx.replaceRange,
      });
    } else {
      this.externalCtx.datasets?.tags?.data?.forEach((tag) => {
        proposals.push({
          label: `${tag}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: ` CONTAINS "${tag}"`,
          range: queryCtx.replaceRange,
          // Delete the leading .
          additionalTextEdits: [
            {
              range: {
                startColumn: queryCtx.replaceRange.startColumn - 1,
                endColumn: queryCtx.replaceRange.startColumn,
                startLineNumber: queryCtx.replaceRange.startLineNumber,
                endLineNumber: queryCtx.replaceRange.endLineNumber,
              },
              text: null,
            },
          ],
        });
      });
    }
  
    return proposals;
  }

  private datasetMetadataSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.datasets?.metadataKeys?.isLoading) {
      proposals.push({
        label: `Loading metadata...`,
        kind: languages.CompletionItemKind.Variable,
        insertText: "",
        range: queryCtx.replaceRange,
      });
    } else {
      this.externalCtx.datasets?.metadataKeys?.data?.forEach((metadataKey) => {
        proposals.push({
          label: `${metadataKey}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: `${metadataKey}`,
          range: queryCtx.replaceRange,
        });
      });
    }

    return proposals;
  }

  private topicPropertySuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = []

    roboqlFields.topics.forEach((field) => {
      if (field.label === "msgpaths") {
        const baseProposal: languages.CompletionItem = {
          kind: languages.CompletionItemKind.Field,
          range: queryCtx.replaceRange,
          detail: field.detail || undefined,
          label: field.label,
          insertText: field.label + "[$0]",
          insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: field.documentation || undefined
        }

        proposals.push(baseProposal)
        field.aliases.forEach((alias) => {
          proposals.push({
            ...baseProposal,
            label: alias,
            detail: `Alias for ${field.label}. ${(baseProposal.detail || "")}`.trim(),
          })
        })
      } else {
        proposals.push(...this.fieldSnippetSuggestions(field, queryCtx))
      }
    })

    proposals.push({
      label: "file",
      kind: languages.CompletionItemKind.Field,
      insertText: "file",
      range: queryCtx.replaceRange,
      detail: "The file this topic belongs to",
    });

    proposals.push({
      label: "dataset",
      kind: languages.CompletionItemKind.Field,
      insertText: "dataset",
      range: queryCtx.replaceRange,
      detail: "The dataset this topic belongs to",
    });

    return proposals
  }

  private topicNameSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.topics?.isLoading) {
      proposals.push({
        label: "Loading topics...",
        kind: languages.CompletionItemKind.Value,
        insertText: "",
        range: queryCtx.replaceRange,
      });
    } else {
    this.externalCtx.topics?.data?.topic_names?.forEach((topicName) => {
      proposals.push({
        label: topicName,
        kind: languages.CompletionItemKind.Value,
        insertText: `"${topicName}"`,
        range: queryCtx.replaceRange,
        detail: "A topic in your org",
      });
    });
  }

    return proposals;
  }

  private messagePathSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    roboqlFields.topic_message_paths.forEach((field) => {
      // See below comment, but it would be confusing to have both `metadata.max` and `max` be auto-completable, so
      // we'll just not suggest metadata at all. If someone decides to type it anyway, it will still be valid parsable
      // RoboQL from the backend's perspective
      if (field.label !== "metadata") {
        proposals.push(...this.fieldSnippetSuggestions(field, queryCtx))
      }
    })

    // ROBO-920: In order to keep syntax succinct, users are allowed to drop the literal `metadata` in queries against
    // message paths.
    proposals.push(...this.messagePathMetadataSuggestions(queryCtx));

    proposals.push({
      label: "topic",
      kind: languages.CompletionItemKind.Field,
      insertText: "topic",
      range: queryCtx.replaceRange,
      detail: "The topic within which this message path applies.",
    });

    proposals.push({
      label: "file",
      kind: languages.CompletionItemKind.Field,
      insertText: "file",
      range: queryCtx.replaceRange,
      detail: "The source file from which this message path was extracted.",
    });

    proposals.push({
      label: "dataset",
      kind: languages.CompletionItemKind.Field,
      insertText: "dataset",
      range: queryCtx.replaceRange,
      detail: "The dataset from which this message path was extracted.",
    });

    return proposals
  }

  private messagePathMetadataSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.topics?.isLoading) {
      proposals.push({
        label: "Loading metadata...",
        kind: languages.CompletionItemKind.Value,
        insertText: "",
        range: queryCtx.replaceRange,
      });
    } else {
      this.externalCtx.topics?.data?.message_path_metadata_keys?.forEach((metadataKey) => {
        proposals.push({
          label: `${metadataKey}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: `${metadataKey}`,
          range: queryCtx.replaceRange,
        });
      });
    }

    return proposals;
  }

  private messagePathSelectionSuggestions(
      queryCtx: RoboqlQueryCtx,
  ): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.topics?.isLoading) {
      proposals.push({
        label: "Loading paths...",
        kind: languages.CompletionItemKind.Value,
        insertText: "",
        range: queryCtx.replaceRange,
      });
    } else {
      this.externalCtx.topics?.data?.message_paths.forEach((metadataKey) => {
        proposals.push({
          label: `${metadataKey}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: `${metadataKey}`,
          range: queryCtx.replaceRange,
        });
      });
    }

    return proposals
  }

  private eventPropertySuggestions(roboQueryCtx: RoboqlQueryCtx): languages.CompletionItem[] {
    const suggestions: languages.CompletionItem[] = []

    roboqlFields.events.forEach((field) => {
      suggestions.push(...this.fieldSnippetSuggestions(field, roboQueryCtx))
    })
    
    return suggestions;
  }

  private eventNameSuggestions(roboQueryCtx: RoboqlQueryCtx): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.events?.names?.isLoading) {
      proposals.push({
        label: "Loading event names...",
        kind: languages.CompletionItemKind.Value,
        insertText: "",
        range: roboQueryCtx.replaceRange,
      });
    } else {
      this.externalCtx.events?.names?.data?.forEach((eventName) => {
        proposals.push({
          label: eventName,
          kind: languages.CompletionItemKind.Value,
          insertText: `"${eventName}"`,
          range: roboQueryCtx.replaceRange,
          detail: "An event in your org",
        });
      });
    }

    return proposals;
  }

  private eventTagSuggestions(roboQueryCtx: RoboqlQueryCtx): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.events?.tags?.isLoading) {
      proposals.push({
        label: `Loading tags...`,
        kind: languages.CompletionItemKind.Variable,
        insertText: "",
        range: roboQueryCtx.replaceRange,
      });
    } else {
      this.externalCtx.events?.tags?.data?.forEach((tag) => {
        proposals.push({
          label: `${tag}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: ` CONTAINS "${tag}"`,
          range: roboQueryCtx.replaceRange,
          // Delete the leading .
          additionalTextEdits: [
            {
              range: {
                startColumn: roboQueryCtx.replaceRange.startColumn - 1,
                endColumn: roboQueryCtx.replaceRange.startColumn,
                startLineNumber: roboQueryCtx.replaceRange.startLineNumber,
                endLineNumber: roboQueryCtx.replaceRange.endLineNumber,
              },
              text: null,
            },
          ],
        });
      });
    }

    return proposals;
  }

  private eventMetadataSuggestions(roboQueryCtx: RoboqlQueryCtx): languages.CompletionItem[] {
    const proposals: languages.CompletionItem[] = [];

    if (this.externalCtx.events?.metadataKeys?.isLoading) {
      proposals.push({
        label: `Loading metadata...`,
        kind: languages.CompletionItemKind.Variable,
        insertText: "",
        range: roboQueryCtx.replaceRange,
      });
    } else {
      this.externalCtx.events?.metadataKeys?.data?.forEach((metadataKey) => {
        proposals.push({
          label: `${metadataKey}`,
          kind: languages.CompletionItemKind.Variable,
          insertText: `${metadataKey}`,
          range: roboQueryCtx.replaceRange,
        });
      });
    }

    return proposals;
  }

  private currentlyEditingFromPartialExpr(
      field: string,
      operator: string,
  ): CurrentlyEditing | undefined {
    if (
        field.endsWith("name") &&
        ["=", "!=", "contains"].includes(operator.toLowerCase())
    ) {
      switch (this.externalCtx.target) {
        case QueryTarget.Events:
          return CurrentlyEditing.EventName;
        default:
          return CurrentlyEditing.TopicName;
      }
    }

    return undefined;
  }

  // This is ChatGPT generated code that I inspected and vetted, if it breaks blame ChatGPT.
  private splitQueryFieldRespectingBrackets(query: string): string[] {
    const result: string[] = [];
    let bracketCount = 0;
    let currentSegment = '';

    for (const char of query) {
      if (char === '[') {
        bracketCount++;
      } else if (char === ']') {
        bracketCount--;
      }

      if (char === '.' && bracketCount === 0) {
        // If a period is found, and we are not within brackets, push the segment
        result.push(currentSegment);
        currentSegment = '';
      } else {
        currentSegment += char;
      }
    }

    // Always push the last segment, even if it's empty (handles trailing period)
    result.push(currentSegment);

    return result;
  }

  private extractRoboQueryCtx(
      model: editor.ITextModel,
      position: Position,
  ): RoboqlQueryCtx {
    const fullText = model.getValue();

    if (fullText.length === 0) {
      return {
        currentlyEditing: CurrentlyEditing.BlankEditor,
        replaceRange: {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: position.column,
          endColumn: position.column,
        },
        variables: {}
      }
    }

    const valueToCursor = model.getValueInRange({
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: 0,
      endColumn: position.column,
    });
    const lineSplitOnWs = valueToCursor.split(" ");
    const nonWhitespaceSegment = lineSplitOnWs[lineSplitOnWs.length - 1];
    const parenTrimmed = nonWhitespaceSegment.substring(
        nonWhitespaceSegment.search(/[^(]/),
    );

    const splits = this.splitQueryFieldRespectingBrackets(parenTrimmed)
    let currentlyEditing: CurrentlyEditing = CurrentlyEditing.Unspecified;
    let currentVariable: string | undefined = undefined;

    if (splits.length >= 1) {
      currentlyEditing = CurrentlyEditing.TopLevelField;

      if (
          lineSplitOnWs.length >= 3 &&
          [
            ...(languageDef.operators as string[]),
            ...(languageDef.string_operators as string[]),
          ].includes(lineSplitOnWs[lineSplitOnWs.length - 2].toLowerCase())
      ) {
        currentlyEditing =
            this.currentlyEditingFromPartialExpr(
                lineSplitOnWs[lineSplitOnWs.length - 3],
                lineSplitOnWs[lineSplitOnWs.length - 2],
            ) || currentlyEditing;
      }
    }

    if (splits.length >= 1) {
      if (splits[0].match(TOPIC_VAR_REGEX) || splits[0].match(FILE_VAR_REGEX)) {
        currentVariable = splits[0];
      } else if (
          splits.length > 2 &&
          (splits[1].match(TOPIC_VAR_REGEX) || splits[1].match(FILE_VAR_REGEX))
      ) {
        currentVariable = splits[1];
      }

      for (let idx = 0; idx < splits.length - 1; idx++) {
        const segment = splits[idx];

        // Nested ifs vs. switch, so we can break out of the loop if needed
        if (currentlyEditing === CurrentlyEditing.TopLevelField) {
          if (segment === "dataset" || segment === "datasets") {
            currentlyEditing = CurrentlyEditing.Dataset;
          } else if (segment === "file" || segment === "files") {
            currentlyEditing = CurrentlyEditing.File;
          } else if (segment === "topic" || segment === "topics") {
            currentlyEditing = CurrentlyEditing.Topic;
          } else if (segment === "event" || segment === "events") {
            currentlyEditing = CurrentlyEditing.Event;
          } else if (segment === "metadata") {
            if (this.externalCtx.target === QueryTarget.Datasets) {
              currentlyEditing = CurrentlyEditing.DatasetMetadata;
            } else if (this.externalCtx.target === QueryTarget.Files) {
              currentlyEditing = CurrentlyEditing.FileMetadata;
            } else if (this.externalCtx.target === QueryTarget.Events) {
              currentlyEditing = CurrentlyEditing.EventMetadata;
            } else {
              currentlyEditing = CurrentlyEditing.Unspecified;
            }
          } else if (segment === "tags") {
            if (this.externalCtx.target === QueryTarget.Datasets) {
              currentlyEditing = CurrentlyEditing.DatasetTags;
            } else if (this.externalCtx.target === QueryTarget.Files) {
              currentlyEditing = CurrentlyEditing.FileTags;
            } else if (this.externalCtx.target === QueryTarget.Events) {
              currentlyEditing = CurrentlyEditing.EventTags;
            } else {
              currentlyEditing = CurrentlyEditing.Unspecified;
            }
          } else if (segment.match(TOPIC_VAR_REGEX)) {
            currentlyEditing = CurrentlyEditing.Topic;
          } else if (segment.match(MESSAGE_PATH_VAR_REGEX)) {
            currentlyEditing = CurrentlyEditing.MessagePath;
          } else if (segment.match(MESSAGE_PATH_SELECTION_REGEX)) {
            currentlyEditing = CurrentlyEditing.MessagePathSelection;
          } else if (segment.match(FILE_VAR_REGEX)) {
            currentlyEditing = CurrentlyEditing.File;
          } else {
            currentlyEditing = CurrentlyEditing.Unspecified;
          }
        } else if (currentlyEditing === CurrentlyEditing.Dataset) {
          if (segment === "metadata") {
            currentlyEditing = CurrentlyEditing.DatasetMetadata;
          } else if (segment === "tags") {
            currentlyEditing = CurrentlyEditing.DatasetTags;
          } else if (segment.match(TOPIC_VAR_REGEX)) {
            currentlyEditing = CurrentlyEditing.Topic;
          } else if (segment.match(FILE_VAR_REGEX)) {
            currentlyEditing = CurrentlyEditing.File;
          } else {
            currentlyEditing = CurrentlyEditing.Unspecified;
          }
        } else if (currentlyEditing === CurrentlyEditing.File) {
          if (segment === "metadata") {
            currentlyEditing = CurrentlyEditing.FileMetadata;
          } else if (segment === "tags") {
            currentlyEditing = CurrentlyEditing.FileTags;
          } else if (segment === "dataset") {
            currentlyEditing = CurrentlyEditing.Dataset;
          } else if (segment.match(TOPIC_VAR_REGEX)) {
            currentlyEditing = CurrentlyEditing.Topic;
          } else {
            currentlyEditing = CurrentlyEditing.Unspecified;
          }
        } else if (currentlyEditing === CurrentlyEditing.Topic) {
          if (segment.match(MESSAGE_PATH_VAR_REGEX)) {
            currentlyEditing = CurrentlyEditing.MessagePath;
          } else if (segment.match(MESSAGE_PATH_SELECTION_REGEX)) {
            currentlyEditing = CurrentlyEditing.MessagePathSelection;
          } else {
            currentlyEditing = CurrentlyEditing.Unspecified
          }
        } else if (currentlyEditing === CurrentlyEditing.MessagePath) {
          if (segment === "metadata") {
            currentlyEditing = CurrentlyEditing.MessagePathMetadata
          } else {
            currentlyEditing = CurrentlyEditing.Unspecified
          }
        } else if (currentlyEditing === CurrentlyEditing.Event) {
          if (segment === "metadata") {
            currentlyEditing = CurrentlyEditing.EventMetadata;
          } else if (segment === "tags") {
            currentlyEditing = CurrentlyEditing.EventTags;
          } else if (segment.endsWith("name")) {
            currentlyEditing = CurrentlyEditing.EventName;
          } else {
            currentlyEditing = CurrentlyEditing.Unspecified;
          }
        } else {
          // We've now gone too far into some '.' delimited thing and have no idea what we're doing
          currentlyEditing = CurrentlyEditing.Unspecified;
          break;
        }
      }
    }

    let currentlyEditingSegmentLength = splits[splits.length - 1].length;

    // Incomplete segment logic based on the last segment
    const lastSegment = splits[splits.length - 1]
    if (currentlyEditing === CurrentlyEditing.Topic || (currentlyEditing === CurrentlyEditing.TopLevelField && this.externalCtx.target === QueryTarget.Topics)) {
      const messagePathSegmentMatch = lastSegment.match(MESSAGE_PATH_SELECTION_REGEX)
      if (messagePathSegmentMatch && messagePathSegmentMatch.length === 3) {
        currentlyEditing = CurrentlyEditing.MessagePathSelection
        currentlyEditingSegmentLength = messagePathSegmentMatch[2].length
      }
    }

    return {
      currentlyEditing: currentlyEditing,
      currentVariable: currentVariable,
      replaceRange: {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: position.column - currentlyEditingSegmentLength,
        endColumn: position.column,
      },
      variables: {
        datasetFiles:
            this.externalCtx.target === QueryTarget.Datasets
                ? [...new Set<string>(fullText.match(/files\[[^\]]\]/g))]
                : [],
        datasetTopics:
            this.externalCtx.target === QueryTarget.Datasets
                ? [...new Set<string>(fullText.match(/topics\[[^\]]\]/g))]
                : [],
        fileTopics:
            this.externalCtx.target === QueryTarget.Files
                ? [...new Set<string>(fullText.match(/topics\[[^\]]\]/g))]
                : [],
      },
    };
  }

  public provideCompletionItems(
      model: editor.ITextModel,
      position: Position,
  ): languages.ProviderResult<languages.CompletionList> {
    const roboQueryCtx = this.extractRoboQueryCtx(model, position);

    const suggestions: languages.CompletionItem[] = [];

    const populateTargetPropertySuggestions = () => {
      if (this.externalCtx.target === QueryTarget.Datasets) {
        suggestions.push(...this.datasetPropertyProposals(roboQueryCtx));
      } else if (this.externalCtx.target === QueryTarget.Files) {
        suggestions.push(...this.filePropertySuggestions(roboQueryCtx));
      } else if (this.externalCtx.target === QueryTarget.Topics) {
        suggestions.push(...this.topicPropertySuggestions(roboQueryCtx));
      } else if (this.externalCtx.target === QueryTarget.TopicMessagePaths) {
        suggestions.push(...this.messagePathSuggestions(roboQueryCtx));
      } else if (this.externalCtx.target === QueryTarget.Events) {
        suggestions.push(...this.eventPropertySuggestions(roboQueryCtx));
      }
    }

    switch (roboQueryCtx.currentlyEditing) {
      case CurrentlyEditing.BlankEditor:
        // We don't suggest operators for a Blank Editor, but we do for a normal top-level field
        populateTargetPropertySuggestions();
        break;
      case CurrentlyEditing.TopLevelField:
        suggestions.push(...this.operatorSuggestions(roboQueryCtx));
        populateTargetPropertySuggestions();
        break;
      case CurrentlyEditing.File:
        suggestions.push(...this.filePropertySuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.FileMetadata:
        suggestions.push(...this.fileMetadataSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.FileTags:
        suggestions.push(...this.fileTagSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.Dataset:
        suggestions.push(...this.datasetPropertyProposals(roboQueryCtx));
        break;
      case CurrentlyEditing.DatasetMetadata:
        suggestions.push(...this.datasetMetadataSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.DatasetTags:
        suggestions.push(...this.datasetTagSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.MessagePath:
        suggestions.push(...this.messagePathSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.MessagePathMetadata:
        suggestions.push(...this.messagePathMetadataSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.MessagePathSelection:
        suggestions.push(...this.messagePathSelectionSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.Topic:
        suggestions.push(...this.topicPropertySuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.TopicName:
        suggestions.push(...this.topicNameSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.Event:
        suggestions.push(...this.eventPropertySuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.EventName:
        suggestions.push(...this.eventNameSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.EventTags:
        suggestions.push(...this.eventTagSuggestions(roboQueryCtx));
        break;
      case CurrentlyEditing.EventMetadata:
        suggestions.push(...this.eventMetadataSuggestions(roboQueryCtx));
        break;
    }

    return {
      suggestions: suggestions,
    };
  }
}
