import { FileRecord } from "@/domain/files";

import { Resource } from "./resources";

export type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE" | "HEAD" | "OPTIONS";

/*

Problems with using strings for API Routes:

1. No type safety. If you change the route, you have to change it everywhere.
2. No autocomplete. You have to remember the route.
3. Code is very error prone. If you misspell the route, or the route changes, you won't know until runtime.
4. API routes are changing very quickly as we develop the API. Routes can change out from underneath us as we go.
We need to be able to change the routes in one place, and have the changes reflected everywhere.

*/

export type RobotoEndpoint = (
  params?: Record<string, string | number>,
) => string;

export const usersOrgsEndpoint: RobotoEndpoint = () => `/users/orgs`;

export const orgsEndpoint: RobotoEndpoint = () => `/orgs`;

export const orgsRolesEndpoint: RobotoEndpoint = () => `/orgs/roles`;

export const orgsUsersEndpoint: RobotoEndpoint = () => `/orgs/users`;

export const orgsInvitesEndpoint: RobotoEndpoint = () => `/orgs/invites`;

export const actionsEndpoint: RobotoEndpoint = () => `/actions`;

export const triggersEndpoint: RobotoEndpoint = () => `/triggers`;

export const meteringReportsEndpoint: RobotoEndpoint = () =>
  `/metering/reports`;
export const limitSummaryEndpoint: RobotoEndpoint = () => `/limits/summary`;
export const deepHealthCheckEndpoint: RobotoEndpoint = () =>
  `/health/ping/deep`;

export const individualDatasetEndpoint: RobotoEndpoint = (pathParams) => {
  const datasetId = pathParams?.datasetId;

  if (!datasetId) {
    throw Error("Individual dataset endpoint requires a datasetId");
  }

  return `/datasets/${datasetId}`;
};

export const datasetsEndpoint: RobotoEndpoint = () => {
  return `/datasets`;
};

export const datasetsFilesEndpoint: RobotoEndpoint = (pathParams) => {
  const datasetId = pathParams?.datasetId;

  return `/datasets/${datasetId}/files`;
};

export const datasetsQueryFilesEndpoint: RobotoEndpoint = (pathParams) => {
  const datasetId = pathParams?.datasetId;

  return `/datasets/${datasetId}/files/query`;
};

export const datasetsCredentialsEndpoint: RobotoEndpoint = (pathParams) => {
  const datasetId = pathParams?.datasetId;

  if (!datasetId) {
    throw Error("Dataset credentials endpoint requires a datasetId");
  }

  return `/datasets/${datasetId}/credentials`;
};

export const datasetsQueryEndpoint: RobotoEndpoint = () => {
  return `/datasets/query`;
};

export const datasetsManifestTransactionBeginEndpoint: RobotoEndpoint = (
  pathParams,
) => {
  const datasetId = pathParams?.datasetId;

  if (!datasetId) {
    throw Error(
      "Dataset manifest transaction begin endpoint requires a datasetId",
    );
  }

  return `/datasets/${datasetId}/batch_uploads`;
};

export const datasetsManifestTransactionCompleteEndpoint: RobotoEndpoint = (
  pathParams,
) => {
  const datasetId = pathParams?.datasetId;
  const uploadId = pathParams?.uploadId;

  if (!datasetId || !uploadId) {
    throw Error(
      "Dataset manifest transaction complete endpoint requires a datasetId and uploadId",
    );
  }

  return `/datasets/${datasetId}/batch_uploads/${uploadId}/complete`;
};

export const feedbackEndpoint: RobotoEndpoint = () => {
  return "/feedback";
};

export const fileSignedUrlEndpoint: RobotoEndpoint = (pathParams) => {
  const fileId = pathParams?.fileId;

  return `/files/${fileId}/signed-url`;
};

export const deleteFileEndpoint: RobotoEndpoint = (pathParams): string => {
  const fileId = pathParams?.fileId;

  return `/files/${fileId}`;
};

export const invocationLogsEndpoint: RobotoEndpoint = (pathParams) => {
  const invocationId = pathParams?.invocationId;

  if (!invocationId) {
    throw Error("Invocation logs endpoint requires an invocationId");
  }

  return `/actions/invocations/${invocationId}/logs`;
};

export const searchInvocationsEndpoint: RobotoEndpoint = () => {
  return `/actions/invocations/query`;
};

export const invocationEndpoint: RobotoEndpoint = (params) => {
  const invocationId = params?.invocationId;

  if (!invocationId) {
    throw Error("Invocation endpoints require an invocationId");
  }

  return `/actions/invocations/${invocationId}`;
};

export const invokeActionEndpoint: RobotoEndpoint = (params) => {
  const actionName = params?.actionName;

  if (!actionName) {
    throw Error("Invoke endpoint requires an actionName");
  }

  return `/actions/${actionName}/invoke`;
};

export const searchActionsEndpoint: RobotoEndpoint = () => {
  return `/actions/query`;
};

export const actionEndpoint: RobotoEndpoint = (params) => {
  const actionName = params?.name;

  if (!actionName) {
    throw Error("Action endpoints require an actionName");
  }

  return `/actions/${actionName}`;
};

export const actionAccessibilityEndpoint: RobotoEndpoint = (params) => {
  const actionName = params?.actionName;

  if (!actionName) {
    throw Error("Action endpoints require an actionName");
  }

  return `/actions/${actionName}/accessibility`;
};

export const searchTriggersEndpoint: RobotoEndpoint = () => {
  return `/triggers/query`;
};

export const triggerEndpoint: RobotoEndpoint = (params) => {
  const triggerName = params?.name;

  if (!triggerName) {
    throw Error("Trigger endpoints require a triggerName");
  }

  return `/triggers/${triggerName}`;
};

export const tokensEndpoint: RobotoEndpoint = () => `/tokens`;

export const tokenByIdEndpoint: RobotoEndpoint = (pathParams) => {
  const tokenId = pathParams?.tokenId;

  if (!tokenId) {
    throw Error("Token by ID endpoint requires a tokenId");
  }

  return `/tokens/id/${tokenId}`;
};

export const inviteEndpoint: RobotoEndpoint = (params) => {
  const inviteId = params?.inviteId;

  if (!inviteId) {
    throw Error("Invite endpoints require an inviteId");
  }

  return `/orgs/invites/${inviteId}`;
};

export const acceptInviteEndpoint: RobotoEndpoint = (params) => {
  const inviteId = params?.inviteId;

  if (!inviteId) {
    throw Error("Invite endpoints require an inviteId");
  }

  return `/orgs/invites/${inviteId}/accept`;
};

export const declineInviteEndpoint: RobotoEndpoint = (params) => {
  const inviteId = params?.inviteId;

  if (!inviteId) {
    throw Error("Invite endpoints require an inviteId");
  }

  return `/orgs/invites/${inviteId}/decline`;
};

export const usersEndpoint: RobotoEndpoint = () => `/users`;

export const getUserEndpoint: RobotoEndpoint = (pathParams) => {
  const userId = pathParams?.userId;

  if (!userId) {
    throw Error("Missing path param 'userId'");
  }

  return `/users/id/${encodeURIComponent(userId)}`;
};

export const listImagesEndpoint: RobotoEndpoint = () => {
  return `/images/image/record/list`;
};

export const listRepositoriesEndpoint: RobotoEndpoint = () => {
  return `/images/repository/record/list`;
};

export const imageEndpoint: RobotoEndpoint = () => `/images/image`;

export const repositoryEndpoint: RobotoEndpoint = () => `/images/repository`;

export const commentsEndpoint: RobotoEndpoint = () => `/comments`;

export const deleteCommentEndpoint: RobotoEndpoint = (pathParams) => {
  const commentId = pathParams?.commentId;

  if (!commentId) {
    throw Error("Delete comment endpoint requires a commentId");
  }

  return `/comments/${commentId}`;
};

export const updateCommentEndpoint: RobotoEndpoint = (pathParams) => {
  const commentId = pathParams?.commentId;

  if (!commentId) {
    throw Error("Update comment endpoint requires a commentId");
  }

  return `/comments/${commentId}`;
};

export const listCommentsEndpoint: RobotoEndpoint = (pathParams) => {
  const entityType = pathParams?.entityType;
  const entityId = pathParams?.entityId;
  const userId = pathParams?.userId;

  if (!userId && !entityType) {
    throw Error(
      "List comments endpoint requires an entityType (and optional entityId) or a userId",
    );
  }

  if (userId) {
    return `/comments/user/${userId}`;
  } else if (!entityId) {
    return `/comments/type/${entityType}`;
  }

  return `/comments/${entityType}/${entityId}`;
};

export interface CallState<ResponseData> {
  loading: boolean;
  error: APIServiceError | null;
  data: ResponseData | null;
}

export class APIServiceError extends Error {
  public errorCode?: string;
  public stackTrace?: string[];

  constructor(message: string, errorCode: string, stackTrace?: string[]) {
    super(message);
    this.errorCode = errorCode;
    this.stackTrace = stackTrace;
  }
}

export class LimitError extends APIServiceError {
  public resourceName: Resource;
  public limitQuantity: number;
  public currentQuantity: number;

  constructor(
    message: string,
    errorCode: string,
    resourceName: Resource,
    limitQuantity: number,
    currentQuantity: number,
    stackTrace?: string[],
  ) {
    super(message, errorCode, stackTrace);
    this.resourceName = resourceName;
    this.limitQuantity = limitQuantity;
    this.currentQuantity = currentQuantity;
  }
}

export interface APIErrorResponse {
  error: {
    error_code: string;
    message: string;
    stack_trace?: string[];
    resource_name?: Resource; // Only present for RobotoLimitExceededException
    limit_quantity?: number; // Only present for RobotoLimitExceededException
    current_quantity?: number; // Only present for RobotoLimitExceededException
  };
}

export interface APIResponse<T> {
  data: T;
}

export interface PaginatedAPIResponse<ItemType> {
  data?: {
    items?: ItemType[];
    next_token?: string;
  };
}

export interface RobotoAPICall {
  endpoint: RobotoEndpoint;
  apiVersion?: string; // Defaults to "v1"
  method: HTTPMethod;
  requestBody?: BodyInit;
  queryParams?: URLSearchParams;
  pathParams?: Record<string, string | number>;
  orgId?: string;
  headers?: Record<string, string>;
  isNotAuthenticated?: boolean;
  signal?: AbortSignal;
}

// Note: These two interfaces are the same, but I think we'll see it's probably a bad idea
// to use the same interface for both the filter type and the object type
export interface DatasetsFilter {
  created?: string; //'2023-06-30T20:10:55.529607+00:00',
  created_by?: string; //'example@roboto.ai',
  dataset_id?: string; //'49de1d91ec7d4bafa73042c3014e2614',
  metadata?: { [key: string]: unknown }; // {'key1': 'val1', 'key2': 2, 'key3': False, 'key4': ['item2', 'item3']},
  modified?: string; //'2023-06-30T20:10:55.529607+00:00',
  modified_by?: string; //'example@roboto.ai',
  org_id?: string; //'ce69b3709cad4e15b28ca8fd8a248ddf',
  tags?: string[]; //['tag2'],
  description?: string; //'my favorite dataset',
  device_id?: string; // 'benji-bot-123'
}

export interface Dataset {
  created: string; //'2023-06-30T20:10:55.529607+00:00',
  created_by: string; //'example@roboto.ai',
  dataset_id: string; //'49de1d91ec7d4bafa73042c3014e2614',
  metadata: Record<string, string>; // {'key1': 'val1', 'key2': 2, 'key3': False, 'key4': ['item2', 'item3']},
  modified: string; //'2023-06-30T20:10:55.529607+00:00',
  modified_by: string; //'example@roboto.ai',
  org_id: string; //'ce69b3709cad4e15b28ca8fd8a248ddf',
  tags: string[]; //['tag2'],
  description?: string; //'my favorite dataset',
  device_id: string; // 'benji-bot-123'
}

export interface DatasetsQueryResponse {
  data: {
    items: DatasetsFilter[];
    next_token?: string;
  };
}

export interface FileRecordResponse {
  data: FileRecord;
}

export interface FileSignedUrlResponse {
  data: {
    url: string;
  };
}

export interface DatasetFilesQueryResponse {
  data: {
    items: FileRecord[];
    next_token?: string;
  };
}

export interface GetDatasetResponse {
  data: Dataset;
}

export interface AuthZTuple {
  user: string;
  relation: string;
  obj: string;
}

export interface GetAccessResponse {
  data: {
    relations: AuthZTuple[];
  };
}

export interface DatasetsCredentialsResponse {
  data: {
    access_key_id: string;
    bucket: string;
    expiration: string;
    region: string;
    required_prefix: string;
    secret_access_key: string;
    session_token: string;
  };
}

export interface FeatureHealth {
  is_healthy: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail?: string | Record<string, any> | null;
}

export interface DeepHealthCheckResponse {
  data: Record<string, FeatureHealth>;
}

export interface MeteredResourceValue {
  resource_name: Resource;
  quantity: number;
  dimension: string;
  resource_type: "delta" | "running_total";
}

export interface MeteringReportResponse {
  data: MeteringReport | MeteringReport[];
}

export interface MeteringReport {
  org_id: string;
  start_time: string;
  end_time: string;
  values: MeteredResourceValue[];
}

export interface Image {
  org_id: string;
  repository_name: string;
  image_tag: string;
  image_uri: string;
}

export interface Repository {
  org_id: string;
  repository_name: string;
  repository_uri: string;
  arn: string;
}
