import { Box, CircularProgress, useTheme } from "@mui/material";
import React, { useState, FC } from "react";

interface IframeContentProps {
  signedUrl: URL | undefined;
}

export const IframeContent: FC<IframeContentProps> = ({ signedUrl }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);

  React.useEffect(() => {
    // Data changed, prepare for a reload
    setLoading(true);
  }, [signedUrl]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {loading && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
            }}
          >
            <CircularProgress size="1.75rem" />
          </Box>
        )}

        {signedUrl && (
          <iframe
            src={signedUrl.toString()}
            width="100%"
            style={{
              border: 0,
              opacity: loading ? 0 : 1,
              height: loading ? 0 : "80vh",
              transition: "opacity 0.5s",
            }}
            loading="lazy"
            onLoad={() => setLoading(false)}
          />
        )}
      </Box>
    </Box>
  );
};
