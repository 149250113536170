import { useParams } from "react-router-dom";

import { NavigationPageWithRedirectProtection } from "@/components";
import { InvocationPage } from "@/features/actions/pages";

const InvocationDetailPage: React.FC = () => {
  const { invocationId } = useParams();
  return (
    <NavigationPageWithRedirectProtection
      title={`Roboto - Invocation - ${invocationId}`}
    >
      <InvocationPage invocationId={invocationId} />
    </NavigationPageWithRedirectProtection>
  );
};

export default InvocationDetailPage;
