import { Monaco } from "@monaco-editor/react";
import { languages } from "monaco-editor";

import { RoboqlCompletionProvider, type RoboqlExternalCtx } from "./completion";
import { languageDef, configuration } from "./language";

const SINGLETON_COMPLETION_PROVIDER = new RoboqlCompletionProvider();

export { RoboqlCompletionProvider, type RoboqlExternalCtx };

export function singletonCompletionProvider(): RoboqlCompletionProvider {
  return SINGLETON_COMPLETION_PROVIDER;
}

export const roboqlEditorBeforeMount = (monaco: Monaco) => {
  if (
    !monaco.languages
      .getLanguages()
      .some(
        (language: languages.ILanguageExtensionPoint) =>
          language.id === "roboql",
      )
  ) {
    monaco.languages.register({ id: "roboql" });
    monaco.languages.setMonarchTokensProvider("roboql", languageDef);
    monaco.languages.setLanguageConfiguration("roboql", configuration);

    monaco.languages.registerCompletionItemProvider(
      "roboql",
      SINGLETON_COMPLETION_PROVIDER,
    );
  }
};
