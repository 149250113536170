import * as React from "react";

import { useMeasure } from "@/hooks";
import type { PlotPanelState } from "@/state/visualization";

import type { PlotManager } from "../PlotManager";
import type { Extents } from "../PlotRenderer";

import { CursorIndicator } from "./CursorIndicator";
import { TooltipBody, type HoverData } from "./TooltipBody";

interface TooltipProps {
  canvas: HTMLCanvasElement | null;
  chartExtents: Extents | null;
  plotManager: PlotManager | null;
  state: PlotPanelState;
}

export function Tooltip({
  canvas,
  chartExtents,
  plotManager,
  state,
}: TooltipProps) {
  const [hoverData, setHoverData] = React.useState<HoverData | null>(null);
  const tooltipAbortController = React.useRef<AbortController>(
    new AbortController(),
  );
  const [measured, containerDims] = useMeasure();
  React.useEffect(() => {
    if (canvas === null) {
      return;
    }

    measured(canvas);
  }, [canvas, measured]);

  /**
   * Get data under the cursor when hovering over the plot
   */
  const getDataUnderCursor = React.useCallback(
    function getDataUnderCursor(event: MouseEvent) {
      if (plotManager === null) {
        return;
      }

      if (!tooltipAbortController.current.signal.aborted) {
        tooltipAbortController.current.abort();
      }
      tooltipAbortController.current = new AbortController();

      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const x = mouseX - containerDims.left;
      const y = mouseY - containerDims.top;
      const abortController = tooltipAbortController.current;
      plotManager
        .getDataUnderCursor({
          x,
          y,
          signal: tooltipAbortController.current.signal,
        })
        .then((data) => {
          if (abortController.signal.aborted) {
            return;
          }

          setHoverData({ data, x: mouseX, y: mouseY });
        })
        .catch(() => {
          if (abortController.signal.aborted) {
            return;
          }

          setHoverData(null);
        });
    },
    [containerDims, plotManager],
  );

  const clearTooltip = React.useCallback(function clearTooltip() {
    setHoverData(null);
  }, []);

  React.useEffect(() => {
    if (canvas === null) {
      return;
    }

    // AbortController is used to remove the event listener
    const abortController = new AbortController();
    canvas.addEventListener("pointermove", getDataUnderCursor, {
      signal: abortController.signal,
    });

    canvas.addEventListener("pointerout", clearTooltip, {
      signal: abortController.signal,
    });

    return () => {
      abortController.abort();
    };
  }, [canvas, getDataUnderCursor, clearTooltip]);

  return (
    <>
      <CursorIndicator
        chartExtents={chartExtents}
        containerDims={containerDims}
        hidden={hoverData === null || hoverData.data.length === 0}
        mouseX={hoverData?.x ?? null}
      />
      <TooltipBody hoverData={hoverData} state={state} />
    </>
  );
}
