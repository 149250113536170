export enum EventDataEntityType {
  AllDatasets = "All Datasets",
  AllFiles = "All Files",
  AllMessagePaths = "All Message Paths",
  AllTopics = "All Topics",
}

export interface EventDataEntity {
  type: EventDataEntityType;
}

export enum EventSourceType {
  AllPanels = "All Panels",
  ThisPanel = "This Panel",
}

interface BaseSource {
  type: EventSourceType;
}

interface ThisPanelSource extends BaseSource {
  type: EventSourceType.ThisPanel;
  panelId: string;
}

export type EventSource = BaseSource | ThisPanelSource;

export function isThisPanelSource(
  source: EventSource,
): source is ThisPanelSource {
  return source.type === EventSourceType.ThisPanel;
}

export interface EventScope {
  dataEntity: EventDataEntity;
  source: EventSource;
}
