import { RobotoEndpoint } from "@/types";

export const collectionEndpoint: RobotoEndpoint = (params) => {
  const collectionId = params?.collectionId;
  const changeset = params?.changeset;

  if (!collectionId) {
    throw Error("Collection endpoints require a collectionId");
  }

  if (!changeset) {
    return `/collections/id/${collectionId}`;
  } else {
    return `/collections/id/${collectionId}/changes`;
  }
};

export const collectionCreationEndpoint: RobotoEndpoint = () => {
  return `/collections/create`;
};
