import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import classNames from "classnames";

import type { PlotPanelState } from "@/state/visualization";

import { colorForSeries } from "../colorScale";
import type { HoverDatum } from "../PlotRenderer";

import styles from "./Tooltip.module.css";

export interface HoverData {
  data: HoverDatum[];
  x: number;
  y: number;
}

interface TooltipBodyProps {
  hoverData: HoverData | null;
  state: PlotPanelState;
}

export function TooltipBody({ hoverData, state }: TooltipBodyProps) {
  return (
    <Box
      bgcolor={(theme) => alpha(theme.palette.paper.main, 0.8)}
      className={classNames(styles.container, {
        [styles.hidden]: hoverData === null || hoverData.data.length === 0,
      })}
      color={(theme) => theme.palette.text.primary}
      component="ul"
      fontFamily={(theme) => theme.typography.caption.fontFamily}
      fontSize={(theme) => theme.typography.caption.fontSize}
      left={hoverData?.x}
      top={hoverData?.y}
    >
      {hoverData?.data.map((datum) => {
        const series = state.data.find((series) =>
          datum.datasetId.startsWith(series.id),
        );
        if (series === undefined) {
          return null;
        }
        return (
          <li key={datum.datasetId}>
            <span
              className={styles.colorBox}
              style={{ backgroundColor: colorForSeries(series) }}
            />
            {datum.x.toFixed(1)}, {String(datum.y)}
          </li>
        );
      })}
    </Box>
  );
}
