import * as React from "react";

import styles from "./Label.module.css";

interface LabelProps {
  title?: string;
}

/**
 * Styled container used within Sidebar to render labels (files and topics).
 */
export function Label({
  title,
  children,
}: React.PropsWithChildren<LabelProps>) {
  return (
    <span className={styles.container} title={title}>
      {children}
    </span>
  );
}
