import {
  Alert,
  AlertTitle,
  Box,
  Paper,
  Popover,
  Skeleton,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { CommentsBox } from "@/components";
import {
  AnnotationViewFilters,
  AnnotationsMenu,
} from "@/components/Annotations";
import { Workspace } from "@/components/visualization";
import { IngestionStatus } from "@/domain/files/FileRecord";
import { useAllDirectoryFiles } from "@/domain/files/hooks/useAllDirectoryFiles";
import { useAnnotationData } from "@/domain/files/hooks/useAnnotationData";
import { useAnnotationFiles } from "@/domain/files/hooks/useAnnotationFiles";
import { useFile } from "@/domain/files/hooks/useFile";
import { actions, useVizDispatch } from "@/state/visualization";
import { FileNode } from "@/types";
import { isImage } from "@/utils";

import { FileFocalContent } from "./FileFocalContent";
import { FileFocalHeader } from "./FileFocalHeader";

interface FileFocalProps {
  fileId: string;
}

interface PopoverController {
  element: HTMLButtonElement | null;
  component: "annotations" | "details" | null;
}

export const FileFocal: React.FC<FileFocalProps> = ({ fileId }) => {
  const theme = useTheme();
  const vizDispatch = useVizDispatch();

  const fileRecordQuery = useFile(fileId);

  React.useEffect(() => {
    if (fileRecordQuery.isSuccess && fileRecordQuery.data) {
      const fileRecord = fileRecordQuery.data;

      vizDispatch(
        actions.putFiles([
          {
            fileId: fileRecord.file_id,
            relativePath: fileRecord.relative_path,
          },
        ]),
      );
    }
  }, [fileRecordQuery.isSuccess, fileRecordQuery.data, vizDispatch]);

  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<PopoverController>();

  const [annotationFile, setAnnotationFile] = React.useState<
    FileNode | undefined
  >();

  const [annotationViewFilters, setAnnotationViewFilters] =
    React.useState<AnnotationViewFilters>({
      confidenceThreshold: { percent: 0, show: false },
      labels: { show: true },
      boundingBox: { show: true },
      segmentation: { show: false },
    });

  const [datasetId, datasetOrgId] = React.useMemo(() => {
    if (!fileRecordQuery.data) {
      return [];
    }
    return [fileRecordQuery.data.association_id, fileRecordQuery.data.org_id];
  }, [fileRecordQuery.data]);

  const directoryPath = fileRecordQuery?.data?.relative_path
    .split("/")
    .slice(0, -1)
    .join("/");

  const allFilesInDirQuery = useAllDirectoryFiles(
    datasetId,
    directoryPath,
    datasetOrgId,
  );

  const annotationsDataQuery = useAnnotationData(annotationFile?.file);

  const annotationFilesQuery = useAnnotationFiles(datasetId, directoryPath);

  React.useEffect(() => {
    if (annotationFilesQuery.isSuccess) {
      setAnnotationFile(annotationFilesQuery.data[0]);
    }
  }, [annotationFilesQuery.data, annotationFilesQuery.isSuccess]);

  let fileFocalContent;

  if (fileRecordQuery.isSuccess) {
    if (fileRecordQuery.data.ingestion_status === IngestionStatus.Ingested) {
      // Show topic visualization UI
      fileFocalContent = <Workspace showHeader={false} />;
    } else {
      // Show file visualization UI
      fileFocalContent = (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            mt: theme.spacing(2),
            minHeight: "300px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxHeight: "75vh",
              overflow: "scroll",
            }}
          >
            <FileFocalContent
              fileRecord={fileRecordQuery.data}
              annotationData={annotationsDataQuery.data}
              annotationViewFilters={annotationViewFilters}
            />
          </Box>
        </Box>
      );
    }
  } else {
    fileFocalContent = (
      <Skeleton animation="wave" variant="rounded" height={"400px"} />
    );
  }

  return (
    <>
      {fileRecordQuery.isError ? (
        <Alert severity="error">
          <AlertTitle>Could not load file</AlertTitle>
          {fileRecordQuery.error.message}
        </Alert>
      ) : (
        <>
          <Box>
            <FileFocalHeader
              datasetId={datasetId}
              fileRecord={fileRecordQuery.data}
              siblingFiles={allFilesInDirQuery.data}
              isContentImage={
                fileRecordQuery.isSuccess
                  ? isImage(fileRecordQuery.data.relative_path) !== null
                  : false
              }
              handlePopover={(
                component: "annotations" | "details" | null,
                element: HTMLButtonElement | null,
              ) => {
                setPopoverAnchorEl({
                  component: component,
                  element: element,
                });
              }}
            />

            {fileFocalContent}
          </Box>
          <Box
            sx={{
              marginTop: theme.spacing(2),
              pl: theme.spacing(1),
              pr: theme.spacing(1),
            }}
          >
            <CommentsBox entityType={"file"} entityId={fileId || undefined} />
          </Box>
        </>
      )}
      <Popover
        id={"popover-file-annotations"}
        open={Boolean(popoverAnchorEl?.element)}
        anchorEl={popoverAnchorEl?.element}
        onClose={() =>
          setPopoverAnchorEl({
            component: popoverAnchorEl?.component || null,
            element: null,
          })
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: "center",
        }}
        marginThreshold={5}
        slotProps={{
          paper: {
            sx: {
              overflow: "unset",
            },
          },
        }}
      >
        <Box
          component={Paper}
          elevation={24}
          sx={{
            maxWidth: "400px",
            minWidth: "150px",
            padding: theme.spacing(1.5),
          }}
        >
          {popoverAnchorEl?.component === "annotations" && (
            <AnnotationsMenu
              annotationFiles={annotationFilesQuery.data}
              annotationFile={annotationFile}
              annotationDataLoading={annotationsDataQuery.isLoading}
              annotationDataError={annotationsDataQuery.error ?? undefined}
              annotationViewFilters={annotationViewFilters}
              onViewFiltersChanged={(filters) =>
                setAnnotationViewFilters(filters)
              }
              onAnnotationFileSelected={(file) => {
                if (!file) {
                  setAnnotationFile(undefined);
                } else {
                  setAnnotationFile(file);
                }
              }}
              inline={false}
            />
          )}
        </Box>
      </Popover>
    </>
  );
};
