import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useTheme } from "@mui/material";
import * as React from "react";

import { DataGridSortingOrder } from "@/types";

import { RobotoIconButton } from "../RobotoIconButton";

interface SorterIconProps {
  onSortStateChange: (sortingState: DataGridSortingOrder) => void;
  sortingState: DataGridSortingOrder;
}

export const SorterIcon: React.FC<SorterIconProps> = ({
  onSortStateChange,
  sortingState,
}) => {
  const theme = useTheme();

  let transform = "rotate(0deg)";

  if (sortingState === "descending") {
    transform = "rotate(180deg)";
  }

  return (
    <RobotoIconButton
      eventName={"DatagridColumnSorted"}
      onClick={() => {
        let newSortingState = sortingState;

        if (sortingState === "none") {
          newSortingState = "ascending";
        } else if (sortingState === "ascending") {
          newSortingState = "descending";
        } else {
          newSortingState = "none";
        }

        onSortStateChange(newSortingState);
      }}
      sx={{
        marginRight: theme.spacing(1),
        transition: "all 0.3s ease-in-out",
        transform: transform,
      }}
    >
      <ArrowUpwardIcon
        fontSize={"small"}
        color={sortingState === "none" ? "disabled" : "primary"}
        sx={{
          transition: "all 0.3s ease-in-out",
        }}
      />
    </RobotoIconButton>
  );
};
