/**
 * Shadow of types and enumerations from `roboto.domain.topics`
 */

import { type AssociationRecord } from "../association";

export enum RepresentationStorageFormat {
  MCAP = "mcap",
}

export interface AllTopicKeysRecord {
  topic_names: string[];
  message_paths: string[];
  message_path_metadata_keys: string[];
}

export interface RepresentationRecord {
  association: AssociationRecord;
  context: Record<string, string>;
  representation_id: string;
  storage_format: RepresentationStorageFormat;
}

export enum CanonicalDataType {
  Array = "array",
  Boolean = "boolean",
  Byte = "byte",
  Image = "image",
  Number = "number",
  Object = "object",
  String = "string",
  Unknown = "unknown",

  // Special purpose types for data that represents geographic points
  LatDegFloat = "latdegfloat",
  LonDegFloat = "londegfloat",
  LatDegInt = "latdegint",
  LonDegInt = "londegint",
}

export interface MessagePathRecord {
  canonical_data_type: CanonicalDataType;
  created: string; // timestamp
  created_by: string;
  data_type: string;
  message_path: string;
  message_path_id: string;
  metadata: Record<string, unknown>;
  modified: string; // timestamp
  modified_by: string;
  representations: RepresentationRecord[];
  topic_id: string;
}

export interface TopicRecord {
  [key: string]: unknown; // Enable TopicService::convertTypes
  association: AssociationRecord;
  created: string; // timestamp
  created_by: string;
  default_representation: RepresentationRecord | null;
  end_time: bigint | null;
  message_count: number | null;
  message_paths: MessagePathRecord[];
  metadata: Record<string, unknown>;
  modified: string; // timestamp
  modified_by: string;
  org_id: string;
  schema_checksum: string | null;
  schema_name: string | null;
  schema_version: number;
  start_time: bigint | null;
  topic_id: string;
  topic_name: string;
}
