import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAuth, useNavigation } from "@/providers";

import { LoadingScreen } from "./LoadingScreen";

interface WithRedirectProtectionProps {
  isNotOrgProtected?: boolean;
}

export function withRedirectProtection<P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P & WithRedirectProtectionProps> {
  return function ProtectedRoute(props: P & WithRedirectProtectionProps) {
    const { isAuthenticated, isLoading, getCurrentOrganization } = useAuth();

    const { goto } = useNavigation();

    const currentOrg = getCurrentOrganization();

    const { inviteId } = useParams();

    useEffect(() => {
      if (!isAuthenticated && !isLoading) {
        // redirect to Sign In with inviteId in the URL
        goto.signIn({ inviteId });
        return;
      }

      if (isAuthenticated && !isLoading) {
        if (currentOrg === null && !props.isNotOrgProtected) {
          // No org, redirect to sign in page. User is forced to select an org
          goto.signIn({ chooseOrg: true });
          return;
        }
      }
    }, [
      isAuthenticated,
      isLoading,
      inviteId,
      getCurrentOrganization,
      goto,
      props.isNotOrgProtected,
      currentOrg,
    ]);

    const orgValid = currentOrg !== null || props.isNotOrgProtected;

    if (isAuthenticated && orgValid) {
      return <Component {...(props as P)} />;
    } else {
      return <LoadingScreen />;
    }
  };
}
