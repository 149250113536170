import { PointerSensor as DefaultPointerSensor } from "@dnd-kit/core";

function shouldHandleEvent(element: HTMLElement | null) {
  let el = element;

  while (el) {
    if (el.dataset && el.dataset.notDraggable) {
      return false;
    }
    el = el.parentElement;
  }

  return true;
}

/**
 * This custom PointerSensor disables drag interaction events for all child nodes
 * when adding the custom html attribute data-not-draggable="true"
 *
 * This is useful when you wrap a draggable over a component that
 * has other primary (left) click interactions within it (i.e. a delete button inside a draggable element)
 */
export class PointerSensor extends DefaultPointerSensor {
  static activators = [
    {
      eventName: "onPointerDown" as const,
      handler: ({ nativeEvent: event }: React.PointerEvent) => {
        const leftClick = 0;
        if (event.button === leftClick) {
          return shouldHandleEvent(event.target as HTMLElement);
        }
        return false;
      },
    },
  ];
}
