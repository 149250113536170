import { selectParentLayoutByChildId } from "../hooks";
import { State } from "../schema";
import { Layout } from "../schema/v1";

import { scaleChildrenProportionally } from "./scaleChildrenProportionally";
import { updateLayout } from "./updateLayout";

/**
 * Helper function to replace a layout from its parent with that layout's children.
 */
export function mergeChildrenWithParent(state: State, layout: Layout): State {
  const parent = selectParentLayoutByChildId(state, layout.id);

  if (parent === undefined) {
    throw new Error(`Could not find parent of layout: ${layout.id}`);
  }

  // Scale old layout's children to fit the old layout's size
  const oldLayoutChildren = scaleChildrenProportionally(
    layout.children,
    layout.relativeSize,
  );

  // Find index at where to insert children in parent
  const index = parent.children.findIndex((child) => child.id === layout.id);

  // Remove old layout and insert new children
  const childrenCopy = [...parent.children];
  childrenCopy.splice(index, 1, ...oldLayoutChildren);

  return {
    ...state,
    layout: updateLayout(state.layout, parent.id, {
      children: childrenCopy,
    }),
  };
}
