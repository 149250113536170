import { CircularProgress } from "@mui/material";
import classNames from "classnames";

interface LoadingIndicatorProps {
  className?: classNames.Argument;
  visible: boolean;
}

export function LoadingIndicator({
  className,
  visible,
}: LoadingIndicatorProps) {
  if (!visible) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      <CircularProgress />
    </div>
  );
}
