import { Box, Divider, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  PageHeader,
  RobotoStyledTab,
  RobotoStyledTabs,
  RobotoTabPanel,
} from "@/components";
import { useAuth } from "@/providers";

import { Invocations, OrgActions, Triggers } from "../components";
import { Images } from "../components/Images";

export const ActionsPage: React.FC = () => {
  const { currentOrganization } = useAuth();

  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const tabSearchParam = searchParams.get("tab");
  const tabValue = tabSearchParam !== null ? parseInt(tabSearchParam) : 0;

  return (
    <>
      <PageHeader>Actions</PageHeader>

      <Box
        sx={{
          paddingTop: theme.spacing(3),
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <RobotoStyledTabs
            value={tabValue}
            onChange={(_event, newValue) => {
              setSearchParams({ tab: String(newValue) });
            }}
            aria-label="actions tabs"
            variant="scrollable"
          >
            <RobotoStyledTab label="Actions" />
            <RobotoStyledTab label="Triggers" />
            <RobotoStyledTab label="Invocations" />
            <RobotoStyledTab label="Images" />
          </RobotoStyledTabs>
        </Box>
        <Divider
          sx={{
            borderColor: theme.border.color,
          }}
        />
      </Box>

      <Box
        sx={{
          padding: theme.spacing(2, 0),
          paddingBottom: theme.spacing(1),
        }}
      >
        <RobotoTabPanel
          index={0}
          value={tabValue}
          sx={{
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <OrgActions currentOrg={currentOrganization} />
        </RobotoTabPanel>

        <RobotoTabPanel
          index={1}
          value={tabValue}
          sx={{
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Triggers currentOrg={currentOrganization} />
        </RobotoTabPanel>

        <RobotoTabPanel
          index={2}
          value={tabValue}
          sx={{
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Invocations currentOrg={currentOrganization} />
        </RobotoTabPanel>

        <RobotoTabPanel
          index={3}
          value={tabValue}
          sx={{
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Images currentOrg={currentOrganization} />
        </RobotoTabPanel>
      </Box>
    </>
  );
};
