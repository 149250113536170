import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";

interface VisibilityToggleProps {
  on: boolean;
  onToggle: (on: boolean) => void;
}

export function VisibilityToggle({ on, onToggle }: VisibilityToggleProps) {
  return (
    <IconButton
      onClick={() => onToggle(!on)}
      title="Toggle visibility"
      aria-label="Toggle visibility"
      aria-checked={on}
    >
      {on ? (
        <VisibilityIcon fontSize="small" />
      ) : (
        <VisibilityOffIcon
          fontSize="small"
          style={{
            opacity: 0.5,
          }}
        />
      )}
    </IconButton>
  );
}
