import { Stack, TextField } from "@mui/material";
import * as React from "react";

import { MetadataForm, MetadataView } from "@/components";
import { AutocompleteType } from "@/components/TagAndMetadataAutocomplete";

import { transformCommand } from "./actionUtils";

interface ActionContainerParamsProps {
  workdir: string | undefined;
  setWorkdir: (arg: string | undefined) => void;
  command: string | undefined;
  setCommand: (arg: string | undefined) => void;
  entrypoint: string | undefined;
  setEntrypoint: (arg: string | undefined) => void;
  envVars: Record<string, string>;
  setEnvVars: (arg: Record<string, string>) => void;
}

export const ActionContainerParams: React.FC<ActionContainerParamsProps> = ({
  workdir,
  setWorkdir,
  command,
  setCommand,
  entrypoint,
  setEntrypoint,
  envVars,
  setEnvVars,
}) => {
  return (
    <>
      <Stack spacing={2}>
        <TextField
          id="outlined-action-entrypoint"
          placeholder="Entrypoint (optional)"
          size="small"
          fullWidth
          value={entrypoint || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEntrypoint(event.target.value);
          }}
          InputLabelProps={{ shrink: Boolean(entrypoint) }}
          label="Entrypoint"
          helperText={
            entrypoint &&
            entrypoint !== "null" &&
            `ENTRYPOINT ["${entrypoint}"]`
          }
          inputProps={{
            autoComplete: "off",
          }}
        />

        <TextField
          id="outlined-action-workdir"
          placeholder="Workdir (optional)"
          size="small"
          label="Workdir"
          fullWidth
          value={workdir || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWorkdir(event.target.value);
          }}
          InputLabelProps={{ shrink: Boolean(workdir) }}
          inputProps={{
            autoComplete: "off",
          }}
        />

        <TextField
          id="outlined-action-command"
          placeholder="Command (optional)"
          size="small"
          label="Command"
          fullWidth
          value={command || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCommand(event.target.value);
          }}
          InputLabelProps={{ shrink: Boolean(command) }}
          helperText={
            command &&
            command !== "null" &&
            `CMD [${transformCommand(command, true)?.join(", ")}]`
          }
          inputProps={{
            autoComplete: "off",
          }}
        />

        <MetadataView
          metadata={envVars}
          onChange={(envVars) => {
            // Forcing type here because env values are always strings.
            // In the future we should use a different component
            setEnvVars(envVars as Record<string, string>);
          }}
        />
        <MetadataForm
          metadata={envVars}
          placeholderKeyName="Env Var"
          autocompleteType={AutocompleteType.ActionEnvVarKeys}
          onAddMetadata={(key: string, value: string) => {
            setEnvVars({
              ...envVars,
              [key]: value,
            });
          }}
        />
      </Stack>
    </>
  );
};
