import * as React from "react";

import { RobotoSwitch, RobotoTooltip } from "@/components";
import { TriggerRecord } from "@/domain/actions/TriggerRecord";
import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import { APIResponse, RobotoAPICall, triggerEndpoint } from "@/types";

interface TriggerSwitchProps {
  active: boolean;
  triggerName: string;
  setErrorText: (arg: string) => void;
  onUpdate?: (arg: APIResponse<TriggerRecord>) => void;
}

export const TriggerSwitch: React.FC<TriggerSwitchProps> = ({
  active,
  triggerName,
  setErrorText,
  onUpdate,
}) => {
  const { currentOrganization } = useAuth();

  const { initiateRequest } = useLazyAPICall<APIResponse<TriggerRecord>>();

  const [switchState, setSwitchState] = React.useState<boolean>(active);

  const updateTrigger = async () => {
    const updateCall: RobotoAPICall = {
      endpoint: triggerEndpoint,
      orgId: currentOrganization?.org_id,
      pathParams: { name: triggerName },
      method: "PUT",
      requestBody: JSON.stringify({
        enabled: !switchState,
      }),
    };

    const { error, data } = await initiateRequest(updateCall);

    if (error) {
      if (error.errorCode === "RobotoLimitExceededException") {
        setErrorText(
          "Your organization has reached the limit for active triggers. Please disable one of your existing triggers first, or upgrade your organization's account.",
        );
      } else {
        setErrorText(error.message);
      }
      return;
    }

    if (!error && data?.data) {
      setSwitchState(data?.data.enabled);
      if (onUpdate) {
        onUpdate(data);
      }
    }

    setErrorText("");
  };

  const handleToggle = () => {
    void updateTrigger();
  };

  return (
    <RobotoTooltip title={switchState ? "Disable Trigger" : "Enable Trigger"}>
      <RobotoSwitch
        checked={switchState}
        onChange={handleToggle}
        inputProps={{ "aria-label": "trigger toggle" }}
        color="success"
      />
    </RobotoTooltip>
  );
};
