import { CircularProgress } from "@mui/material";
import React from "react";

import { RobotoButton } from "@/components";
import { EphemeralWorkspaceStateContext } from "@/components/visualization/WorkspaceCtx";
import { EventRecord } from "@/domain/events";
import { useDeleteEvent } from "@/domain/events/hooks";

interface DeleteEventProps {
  eventId: EventRecord["event_id"];
  onError: (error: Error) => void;
  onSuccess: () => void;
}

export function DeleteEvent({ eventId, onError, onSuccess }: DeleteEventProps) {
  const deleteEventMutation = useDeleteEvent();
  const { removeEventFromWorkspace } = React.useContext(
    EphemeralWorkspaceStateContext,
  );

  const onDelete = () => {
    deleteEventMutation
      .mutateAsync(eventId)
      .then(() => {
        removeEventFromWorkspace(eventId);
        onSuccess();
      })
      .catch(onError);
  };

  return (
    <RobotoButton
      eventName={"WorkspaceEventDeleted"}
      eventProperties={{ eventId }}
      variant="contained"
      color="error"
      onClick={onDelete}
      disabled={deleteEventMutation.isPending}
      endIcon={
        deleteEventMutation.isPending ? (
          <CircularProgress size={"1rem"} />
        ) : null
      }
    >
      Delete Event
    </RobotoButton>
  );
}
