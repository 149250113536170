import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";
import { FC } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export const RobotoLink: FC<MUILinkProps & RouterLinkProps> = ({
  children,
  ...props
}) => {
  return (
    <MUILink component={RouterLink} {...props}>
      {children}
    </MUILink>
  );
};
