import { ComputeRequirements, ContainerParameters } from "@/domain/actions";

export const actionStorageMin = 21;
export const actionStorageMax = 200;
export const vCpuOptions = [256, 512, 1024, 2048, 4096, 8192, 16384];

export const isActionNameValid = (name: string) => {
  const regex = /^[a-zA-Z0-9_-]+$/;
  if (name && name.length > 0) {
    return regex.test(name);
  }
  return true;
};

export const isActionImageValid = (image: string) => {
  const regex = /.+:.+/;
  if (image && image.length > 0) {
    return regex.test(image);
  }
  return true;
};

export const actionNameError = (name: string | undefined) => {
  return name !== undefined && !isActionNameValid(name);
};

export const actionImageError = (image: string | undefined) => {
  return image !== undefined && !isActionImageValid(image);
};

export const actionStorageError = (
  storage: number | undefined,
  storageLimit = actionStorageMax,
) => {
  return storage !== undefined && !isActionStorageValid(storage, storageLimit);
};

export const isActionStorageValid = (
  storage: number,
  storageLimit?: number,
) => {
  if (
    storage < actionStorageMin ||
    storage > (storageLimit || actionStorageMax)
  ) {
    return false;
  }
  return true;
};

export const parseTimeout = (timeout: number | string) => {
  return parseInt(timeout.toString());
};

export const isActionTimeoutValid = (
  timeout: number | string | undefined | null,
) => {
  if (timeout === undefined || timeout === null) {
    // Timeout is optional, it is defaulted on the backend at invocation.
    return true;
  }
  const parsed = parseTimeout(timeout);
  if (Number.isNaN(parsed) || parsed < 1 || parsed > 720) {
    return false;
  }
  return true;
};

export const handleTimeoutOnChangeState = (
  event: React.ChangeEvent<HTMLInputElement>,
  setCurrentValue: (arg: number | string | undefined) => void,
) => {
  const val = event.target.value;
  if (val === undefined || val === "") {
    setCurrentValue(undefined);
    return;
  }
  const parsed = parseTimeout(val);
  if (Number.isNaN(parsed)) {
    // The customer-supplied value is not a number; set whatever was given to trigger the error state
    setCurrentValue(event.target.value);
    return;
  }

  setCurrentValue(parsed);
};

export const createInitialTimeoutState = (isGated: boolean) => {
  if (isGated) {
    return 30;
  } else {
    return 720;
  }
};

export const determineActionMemoryOptions = (cpu: string) => {
  function range(start: number, stop: number, step: number) {
    const a = [start];
    let b = start;
    while (b < stop) {
      a.push((b += step || 1));
    }
    return a;
  }

  const vCPU = parseInt(cpu);

  switch (vCPU) {
    case 256:
      return [512, 1024, 2048];
    case 512:
      return range(1024, 5 * 1024, 1024);
    case 1024:
      return range(2 * 1024, 9 * 1024, 1024);
    case 2048:
      return range(4 * 1024, 17 * 1024, 1024);
    case 4096:
      return range(8 * 1024, 31 * 1024, 1024);
    case 8192:
      return range(16 * 1024, 61 * 1024, 4 * 1024);
    case 16384:
      return range(32 * 1024, 121 * 1024, 8 * 1024);
  }
  return [];
};

export const parseComputeRequirements = (
  cpu: string | undefined,
  memory: string | undefined,
  gpu: string | undefined,
  storage: number | undefined,
) => {
  const computeRequirements = {} as ComputeRequirements;

  if (!cpu && !memory && !gpu && !storage) {
    return null;
  }

  if (cpu !== undefined) {
    computeRequirements.vCPU = parseInt(cpu);
  }
  if (memory !== undefined) {
    computeRequirements.memory = parseInt(memory);
  }
  if (gpu !== undefined) {
    computeRequirements.gpu = gpu === "true";
  }
  if (storage !== undefined) {
    computeRequirements.storage = storage;
  }

  return computeRequirements;
};

export const parseContainerParameters = (
  command: string | undefined,
  entrypoint: string | undefined,
  workdir: string | undefined,
  envVars: Record<string, string> | undefined,
) => {
  const containerParameters = {} as ContainerParameters;

  if (!command && !entrypoint && !workdir && !envVars) {
    return null;
  }

  if (command !== undefined) {
    containerParameters.command = transformCommand(command, false);
  } else {
    containerParameters.command = null;
  }

  if (entrypoint !== undefined) {
    if (entrypoint === "null" || entrypoint === "") {
      containerParameters.entry_point = null;
    } else {
      containerParameters.entry_point = [entrypoint];
    }
  } else {
    containerParameters.entry_point = null;
  }

  if (workdir !== undefined) {
    if (workdir === "null") {
      containerParameters.workdir = null;
    } else {
      containerParameters.workdir = workdir;
    }
  }

  if (envVars !== undefined) {
    containerParameters.env_vars = envVars;
  }

  return containerParameters;
};

export const stringifyCommand = (commandArr: string[] | null) => {
  if (commandArr === null) {
    return "null";
  }
  if (commandArr) {
    return commandArr.join(",");
  } else {
    return undefined;
  }
};

export const transformCommand = (
  commandStr: string,
  includeQuotes: boolean,
): string[] | null => {
  if (commandStr === "null" || commandStr === "") {
    return null;
  }
  if (includeQuotes) {
    return commandStr.split(",").map((chunk) => `"${chunk.trim()}"`);
  }

  return commandStr.split(",").map((chunk) => `${chunk.trim()}`);
};
