import { NavigationPageWithRedirectProtection } from "@/components";
import { TriggerPage } from "@/features/actions/pages";

const TriggerDetailPage: React.FC = () => {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Trigger"}>
      <TriggerPage />
    </NavigationPageWithRedirectProtection>
  );
};

export default TriggerDetailPage;
