import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "./RobotoTooltip";

interface ExpandableCopyTextProps {
  title?: string;
  text: string;
  trimLength: number;
}

export const ExpandableCopyText: React.FC<ExpandableCopyTextProps> = ({
  title,
  text,
  trimLength,
}) => {
  const theme = useTheme();
  const [expandedId, setExpandedId] = React.useState<boolean>(false);
  const [labelText, setLabelText] = React.useState<string>("Copy");
  const [hovering, setHovering] = React.useState<boolean>(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(0.5),
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {title} {expandedId || !trimLength ? text : text.slice(0, trimLength)}
      {text.length > trimLength && !expandedId ? (
        <IconButton
          size="small"
          sx={{
            p: 0,
            borderRadius: "2px",
            backgroundColor: theme.palette.hoverBackground.main,
          }}
          onClick={() => setExpandedId(true)}
        >
          <MoreHorizIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      ) : (
        <Box
          sx={{
            opacity: hovering ? 1 : 0,
            transition: "opacity 0.2s",
          }}
        >
          <RobotoTooltip title={labelText}>
            <IconButton
              size="small"
              onClick={() => {
                void navigator.clipboard.writeText(text);
                setLabelText("Copied!");
              }}
              onBlur={() => {
                setLabelText("Copy");
              }}
            >
              <ContentCopyIcon sx={{ fontSize: "0.85rem" }} />
            </IconButton>
          </RobotoTooltip>
        </Box>
      )}
    </Box>
  );
};
