import { Typography, TypographyProps } from "@mui/material";
import * as React from "react";

export const PageHeader: React.FC<TypographyProps> = (props) => {
  return (
    <Typography
      sx={{
        fontSize: "1.125rem",
        fontWeight: "500",
      }}
      component="span"
      {...props}
    />
  );
};
