export enum AutocompleteType {
  ActionEnvVarKeys = "actionEnvVarKeys",
  ActionMetadataKeys = "actionMetadataKeys",
  ActionTags = "actionTags",
  CollectionTags = "collectionTags",
  DatasetTags = "datasetTags",
  DatasetMetadataKeys = "datasetMetadataKeys",
  EventMetadataKeys = "eventMetadataKeys",
  EventNames = "eventNames",
  EventTags = "eventTags",
  FileTags = "fileTags",
  FileMetadataKeys = "fileMetadataKeys",
  TopicMetadataKeys = "topicMetadataKeys",
}
