import BoltIcon from "@mui/icons-material/Bolt";
import { SxProps, Theme } from "@mui/material";
import * as React from "react";

import { RobotoIconButton, RobotoTooltip } from "@/components";
import { ActionRecord, ActionReference } from "@/domain/actions";
import { useAuth, useNavigation } from "@/providers";

export interface InvokeActionIconButtonProps {
  action: ActionRecord;
  sx?: SxProps<Theme>;
}

/**
 * A lightning bolt icon button that navigates to the invoke action page when clicked.
 */
export default function InvokeActionIconButton({
  action,
  sx,
}: InvokeActionIconButtonProps) {
  const { currentOrganization } = useAuth();
  const { goto } = useNavigation();

  const disabled = !action.uri;

  const onClick = React.useCallback(() => {
    let actionReference: ActionReference | undefined = undefined;
    if (action) {
      actionReference = {
        name: action.name,
      };
      if (currentOrganization?.org_id !== action.org_id) {
        actionReference.owner = action.org_id;
      }
    }
    goto.invokeAction({
      action: actionReference,
    });
  }, [currentOrganization?.org_id, action, goto]);

  return (
    <RobotoTooltip title={disabled ? "Missing Image" : "Invoke Action"}>
      <span>
        <RobotoIconButton
          onClick={onClick}
          eventName={"InvokeActionClicked"}
          eventProperties={{
            name: action.name,
          }}
          sx={sx}
          disabled={disabled}
        >
          <BoltIcon color={disabled ? "disabled" : "secondary"} />
        </RobotoIconButton>
      </span>
    </RobotoTooltip>
  );
}
