import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Autocomplete,
  TextField,
  IconButton,
  Box,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { BoxOwnProps } from "@mui/system";
import * as React from "react";

import { LoggerService } from "@/service";

import { useElementsForAutocompleteType } from "./hook";
import { AutocompleteType } from "./types";

const INPUT_TEXT_FONT_SIZE = "0.9rem";
const REGULAR_INPUT_WIDTH = "125px";
const SMALLER_INPUT_WIDTH = "100px";
const MAX_AUTOCOMPLETE_OPTIONS_WIDTH = "200px";

interface TagAndMetadataAutocompleteProps {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => void;
  onTagCreated?: (tag: string) => void;
  onMetadataCreated?: (key: string, value: string) => void;
  // If existingTags is provided, the autocomplete will filter out these tags.
  existingTags?: string[];
  existingKeys?: string[];
  autocompleteType: AutocompleteType;
  shrink?: boolean;
  sx?: BoxOwnProps<Theme>;
  hideAddButton?: boolean;
  clearInputOnSelect?: boolean;
  keyPlaceholderText?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

enum Mode {
  Tags = "tags",
  Metadata = "metadata",
}

export const TagAndMetadataAutocomplete: React.FC<
  TagAndMetadataAutocompleteProps
> = ({
  onChange,
  onTagCreated,
  onMetadataCreated,
  autocompleteType,
  shrink,
  existingTags,
  existingKeys,
  sx,
  hideAddButton,
  clearInputOnSelect = true,
  keyPlaceholderText,
  disabled = false,
  isLoading = false,
}) => {
  const theme = useTheme();

  if (!existingTags && !existingKeys) {
    LoggerService.error(
      "existingTags or existingKeys must be provided to TagAndMetadataAutocomplete",
    );
  }

  if (existingTags && existingKeys) {
    LoggerService.error(
      "existingTags and existingKeys cannot both be provided to TagAndMetadataAutocomplete",
    );
  }

  // Fire every time existing tags change. Make sure the autocomplete is always up to date.
  const { elements: retrievedElements } = useElementsForAutocompleteType(
    autocompleteType,
    existingTags,
  );

  let elements: string[] = retrievedElements;

  // Add existing keys to the autocomplete options
  if (existingKeys) {
    const elementSet = new Set(elements);
    existingKeys.forEach((key) => {
      elementSet.add(key);
    });

    elements = Array.from(elementSet);
    elements = elements.sort();
  }

  let placeholder: string;
  let mode: Mode;

  switch (autocompleteType) {
    case AutocompleteType.ActionEnvVarKeys:
      placeholder = "Key";
      mode = Mode.Metadata;
      break;
    case AutocompleteType.ActionMetadataKeys:
      placeholder = "Key";
      mode = Mode.Metadata;
      break;
    case AutocompleteType.ActionTags:
      placeholder = "Tag";
      mode = Mode.Tags;
      break;
    case AutocompleteType.CollectionTags:
      placeholder = "Tag";
      mode = Mode.Tags;
      break;
    case AutocompleteType.DatasetMetadataKeys:
      placeholder = "Key";
      mode = Mode.Metadata;
      break;
    case AutocompleteType.DatasetTags:
      placeholder = "Tag";
      mode = Mode.Tags;
      break;
    case AutocompleteType.EventMetadataKeys:
      placeholder = "Key";
      mode = Mode.Metadata;
      break;
    case AutocompleteType.EventTags:
      placeholder = "Tag";
      mode = Mode.Tags;
      break;
    case AutocompleteType.FileMetadataKeys:
      placeholder = "Key";
      mode = Mode.Metadata;
      break;
    case AutocompleteType.FileTags:
      placeholder = "Tag";
      mode = Mode.Tags;
      break;
    case AutocompleteType.TopicMetadataKeys:
      placeholder = "Key";
      mode = Mode.Metadata;
      break;
    default:
      throw new Error("Unsupported autocomplete type");
  }

  if (keyPlaceholderText) {
    placeholder = keyPlaceholderText;
  }

  const [inputValue, setInputValue] = React.useState<string>("");
  const [metadataValue, setMetadataValue] = React.useState<string>("");
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null);

  if (mode === Mode.Tags && existingTags) {
    elements = elements.filter((tag) => !existingTags.includes(tag));
  }

  const handleElementChosen = (element: string) => {
    const cleanElement = element.trim();

    if (cleanElement) {
      if (mode === Mode.Tags) {
        if (clearInputOnSelect) {
          setInputValue("");
          setSelectedValue(null);
        } else {
          setInputValue(cleanElement);
          setSelectedValue(cleanElement);
        }

        if (onTagCreated && !existingTags?.includes(cleanElement)) {
          onTagCreated(cleanElement);
        }
      } else if (mode === Mode.Metadata) {
        setInputValue(cleanElement);
      }
    }
  };

  const handleMetadataCreated = (key: string, value: string) => {
    if (key && value) {
      setInputValue("");
      setMetadataValue("");
      setSelectedValue(null);
      if (onMetadataCreated) {
        onMetadataCreated(key, value);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing(1),
        ...sx,
      }}
    >
      <Autocomplete
        data-cy="tagAndMetadataAutocomplete"
        onChange={(_, value) => {
          if (value) {
            handleElementChosen(value);
          }
        }}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            // Prevent MUI default 'Enter' behavior.
            ev.defaultMuiPrevented = true;
            ev.preventDefault();
            // Now our handler code
            const evTarget = ev.target as HTMLInputElement;

            if (evTarget?.value) {
              const tagValue = evTarget.value;
              if (tagValue && typeof tagValue === "string") {
                handleElementChosen(tagValue);
              }
            }
          }
        }}
        inputValue={inputValue}
        value={selectedValue}
        onInputChange={(event, newInputValue, reason) => {
          onChange &&
            onChange(
              event as React.ChangeEvent<HTMLInputElement>,
              newInputValue,
            );

          // onChange also fires above in reset case. We don't want to handle it twice.
          if (reason !== "reset") {
            setInputValue(newInputValue);
          }
        }}
        freeSolo={true}
        size="small"
        sx={{
          width: shrink ? SMALLER_INPUT_WIDTH : REGULAR_INPUT_WIDTH,
          ...sx,
        }}
        options={elements}
        disabled={disabled}
        renderInput={(params) => {
          return (
            <TextField
              data-cy="tagAndMetadataAutocompleteInput"
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: INPUT_TEXT_FONT_SIZE,
                },
              }}
              inputProps={{
                ...params.inputProps,
                style: {
                  height: shrink ? "1.1em" : "auto",
                },
              }}
              placeholder={placeholder}
              onBlur={(ev) => {
                if (ev.target.value) {
                  handleElementChosen(ev.target.value);
                }
              }}
            />
          );
        }}
        componentsProps={{
          paper: {
            sx: {
              fontSize: "0.875rem",
            },
          },
          popper: {
            placement: "bottom-start",
            style: {
              minWidth: REGULAR_INPUT_WIDTH,
              maxWidth: MAX_AUTOCOMPLETE_OPTIONS_WIDTH,
            },
          },
        }}
      />
      {mode === Mode.Metadata && (
        <TextField
          data-cy="metadataValue"
          size="small"
          variant="outlined"
          placeholder="Value"
          disabled={disabled}
          InputProps={{
            style: {
              fontSize: INPUT_TEXT_FONT_SIZE,
              width: REGULAR_INPUT_WIDTH,
            },
            autoComplete: "off",
          }}
          value={metadataValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMetadataValue(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              // Prevent default 'Enter' behavior.
              event.preventDefault();
              const ev = event.target as HTMLInputElement;
              if (inputValue && ev.value) {
                handleMetadataCreated(inputValue, ev.value);
              }
            }
          }}
          onBlur={(ev) => {
            if (inputValue && ev.target.value) {
              handleMetadataCreated(inputValue, ev.target.value);
            }
          }}
        />
      )}
      {!hideAddButton && (
        <IconButton
          data-cy="createAddButton"
          aria-label="add-tag"
          size="small"
          onClick={() => {
            if (mode === Mode.Tags) {
              handleElementChosen(inputValue);
            } else {
              handleMetadataCreated(inputValue, metadataValue);
            }
          }}
        >
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <AddCircleOutlineIcon color="primary" fontSize="small" />
          )}
        </IconButton>
      )}
    </Box>
  );
};
