export function EmptyPanelSettings() {
  return (
    <div>
      <h4>No settings available.</h4>
      <h5>
        We&apos;re working to add configuration options to all of our supported
        visualization panels.
      </h5>
    </div>
  );
}
