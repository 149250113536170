import { Outlet } from "react-router-dom";

import { NavigationPageWithRedirectProtection } from "@/components";

interface PageLayoutProps {
  title?: string;
}

export function PageLayout({ title }: PageLayoutProps) {
  return (
    <NavigationPageWithRedirectProtection title={title}>
      <Outlet />
    </NavigationPageWithRedirectProtection>
  );
}
