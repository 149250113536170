import { styled } from "@mui/material/styles";
import * as React from "react";

export const MainContent = styled("main")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  minHeight: "100vh",
  backgroundColor: theme.palette.background.default,
  padding: 0,
  margin: 0,
}));

interface MainNavigationContentProps {
  sideBarWidth: string;
  topBarHeight: string;
}

const MainNavigationContent = styled("main")<MainNavigationContentProps>(
  ({ theme, sideBarWidth, topBarHeight }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    maxWidth: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    padding: 0,
    paddingTop: topBarHeight,
    paddingLeft: sideBarWidth,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    overflowX: "hidden",
  }),
);

interface MainContentWithNavigationProps extends MainNavigationContentProps {
  children: React.ReactNode;
}

export const MainContentWithNavigation: React.FC<
  MainContentWithNavigationProps
> = ({ sideBarWidth, topBarHeight, children }) => {
  return (
    <MainNavigationContent
      sideBarWidth={sideBarWidth}
      topBarHeight={topBarHeight}
    >
      {children}
    </MainNavigationContent>
  );
};
