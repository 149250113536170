import { AssociationType, AssociationRecord } from "../association";

import { EventRecord } from "./EventRecord";

export function eventByAssociationType(
  event: EventRecord,
): Record<AssociationType, AssociationRecord[]> {
  const associationsByType: Record<AssociationType, AssociationRecord[]> = {
    [AssociationType.Dataset]: [],
    [AssociationType.File]: [],
    [AssociationType.MessagePath]: [],
    [AssociationType.Topic]: [],
  };

  event.associations.forEach((association) => {
    associationsByType[association.association_type].push(association);
  });

  return associationsByType;
}
