import { Divider, useTheme } from "@mui/material";
import * as React from "react";

export const RobotoDivider: React.FC<
  React.ComponentProps<typeof Divider>
> = () => {
  const theme = useTheme();
  return (
    <Divider
      sx={{
        borderColor: theme.border.color,
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
      }}
    />
  );
};
