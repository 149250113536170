import classNames from "classnames";

import { type PanelState } from "@/state/visualization";

import { OpenPanelSettings, RemovePanel } from "../controls";

import styles from "./PanelHeader.module.css";
import { PanelIcon } from "./PanelIcon";
import { PanelTitle } from "./PanelTitle";

interface PanelHeaderProps {
  additionalTools?: React.ReactNode;
  className?: classNames.Argument;
  state: PanelState;
}

/**
 * A header for a panel that displays the panel title (if any) and controls for managing
 * the panel's layers.
 */
export function PanelHeader(props: PanelHeaderProps) {
  const { additionalTools, className, state } = props;

  return (
    <div className={classNames(styles.panelHeader, className)}>
      <div className={styles.panelRow}>
        <div className={styles.titleGroup}>
          <PanelIcon className={styles.panelIcon} panelType={state.type} />
          <PanelTitle className={styles.title} title={state.title} />
        </div>
        <div className={styles.panelControls} data-not-draggable="true">
          {additionalTools}
          <OpenPanelSettings state={state} />
          <RemovePanel panelId={state.id} />
        </div>
      </div>
    </div>
  );
}
