import * as React from "react";

import { Goto } from "./Goto";

interface NavigationContext {
  goto: Goto;
}

const context = {
  get goto(): Goto {
    throw new Error("Goto missing in NavigationContext");
  },
};

export const NavigationContext =
  React.createContext<NavigationContext>(context);
