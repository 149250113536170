import { Box, Divider, useTheme } from "@mui/material";
import * as React from "react";

import { PageHeader } from "@/components";
import { useAuth } from "@/providers";

import Collections from "../components/Collections";

const dividerStyles = {
  margin: "1em 0",
};

export const CollectionsPage: React.FC = () => {
  const { currentOrganization } = useAuth();

  const theme = useTheme();

  return (
    <>
      <PageHeader>Collections</PageHeader>

      <Divider sx={dividerStyles} />

      <Box marginTop={theme.spacing(3)} marginBottom={theme.spacing(3)}>
        <Collections currentOrg={currentOrganization} />
      </Box>
    </>
  );
};
