import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Avatar, Box, Link, Paper, Popover, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import * as React from "react";

import { useAuth } from "@/providers";
import { PageRoute } from "@/types";

import { RobotoMenuItem } from "../RobotoMenuItem";
import { RobotoTypography } from "../RobotoTypography";

interface ProfileMenuProps {
  anchorEl: HTMLDivElement | null;
  handleClose: () => void;
  userEmail: string | null;
  userPicture?: string;
  userName?: string;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  const theme = useTheme();
  const { signOut } = useAuth();

  const onClick = async () => {
    await signOut();
  };

  return (
    <Popover
      id={"popover-profile-menu"}
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: -20,
        horizontal: "center",
      }}
      marginThreshold={5}
      elevation={1}
    >
      <Box
        sx={{
          width: "15rem",
        }}
        component={Paper}
        elevation={24}
      >
        <Box
          sx={{
            padding: "1.25rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Avatar
              sx={{ width: 40, height: 40 }}
              src={props.userPicture}
              imgProps={{ referrerPolicy: "no-referrer" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.75rem",
            }}
          >
            {props.userName && (
              <RobotoTypography
                variant="body1"
                sx={{
                  textAlign: "center",
                  wordBreak: "break-word",
                }}
              >
                {props.userName}
              </RobotoTypography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.5rem",
            }}
          >
            <RobotoTypography
              variant="body2"
              sx={{
                color: "#909399",
                textAlign: "center",
                wordBreak: "break-word",
              }}
            >
              {props.userEmail}
            </RobotoTypography>
          </Box>
        </Box>
        <MenuList sx={{ paddingTop: 0 }}>
          <Divider sx={{ marginBottom: theme.spacing(1) }} />
          <RobotoMenuItem
            eventName={"AccountMenuOptionClicked"}
            sx={{
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            }}
            href={PageRoute.Account}
            component={Link}
          >
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
              }}
            >
              Account
            </ListItemText>
          </RobotoMenuItem>

          <Divider />
          <RobotoMenuItem
            eventName={"LogOutClicked"}
            sx={{
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            }}
            onClick={() => {
              void onClick();
            }}
          >
            <ListItemIcon>
              <LogoutOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              data-cy={"logout"}
              primaryTypographyProps={{
                variant: "body2",
              }}
            >
              Log out
            </ListItemText>
          </RobotoMenuItem>
        </MenuList>
      </Box>
    </Popover>
  );
};
