import { PageRoute } from "@/types";

export const useURLService = () => {
  // these functions can be used to generate urls for <Link> components
  function generateSignUpURL(inviteId?: string) {
    if (inviteId) {
      return `${PageRoute.SignUp}?inviteId=${inviteId}`;
    }

    return PageRoute.SignUp;
  }

  function generateSignInURL(inviteId?: string) {
    if (inviteId) {
      return `${PageRoute.SignIn}?inviteId=${inviteId}`;
    }

    return PageRoute.SignIn;
  }

  return {
    generateSignUpURL,
    generateSignInURL,
  };
};
