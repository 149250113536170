import { Box, useTheme } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import * as React from "react";

import { OrgRecord } from "@/domain/orgs";
import { usePaginatedAPICall } from "@/service/apiHooks";
import { Condition, RobotoAPICall, SearchQueryBody } from "@/types";

import { Collection } from "../model";

import CollectionsGrid from "./CollectionsGrid";
import { CreateCollectionPopover } from "./CreateCollectionPopover";
import { ColView } from "./types";

interface CollectionsViewProps {
  defaultView?: ColView;
  org: OrgRecord | null;
}

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const INITIAL_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[0];
/**
 * A paginated list of collections, fetched from a parameterized endpoint.
 * The list can be displayed as a compact table (soon) or a card grid.
 */
export function CollectionsView({
  //defaultView = ColView.Grid,
  org,
}: CollectionsViewProps) {
  const theme = useTheme();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    INITIAL_ROWS_PER_PAGE,
  );

  const {
    cacheLength,
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    isNextPageAvailable,
    loading,
    onRowsPerPageChange,
    pageData,
    //removeItemFromCache,
  } = usePaginatedAPICall<Collection>();

  const fetchCollections = React.useCallback(
    (tagFilters?: string[]) => {
      const baseEndpoint = "/collections/search";
      const requestBody: SearchQueryBody = {
        sort_direction: "DESC",
        sort_by: "created",
      };
      if (tagFilters?.length) {
        const conditions: Condition[] = tagFilters.map((tag) => {
          return {
            field: "tags",
            comparator: "CONTAINS",
            value: tag,
          };
        });
        requestBody.condition = {
          operator: "AND",
          conditions,
        };
      }

      const queryParams = new URLSearchParams();
      queryParams.set("content_mode", "references");

      const request: RobotoAPICall = {
        endpoint: () => {
          return baseEndpoint;
        },
        method: "POST",
        requestBody: JSON.stringify(requestBody),
        queryParams: queryParams,
        orgId: org?.org_id,
      };

      void getFirstPage(request, INITIAL_ROWS_PER_PAGE);
    },
    [getFirstPage, org?.org_id],
  );

  // On mount, fetch the first page of collections
  React.useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  const collectionsView = (
    <CollectionsGrid collections={pageData} isLoading={loading} />
  );

  const onPageChange = async (newPage: number) => {
    if (newPage > page) {
      setPage(page + 1);
      await fetchNextPage(newPage, rowsPerPage);
    } else {
      setPage(page - 1);
      fetchPreviousPage(newPage, rowsPerPage);
    }
  };

  const onRowsPerPageChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let val = parseInt(e?.target?.value);
    if (!Number.isFinite(val)) {
      val = ROWS_PER_PAGE_OPTIONS[0];
    }
    setPage(0);
    setRowsPerPage(val);
    await onRowsPerPageChange(val);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom={theme.spacing(3)}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            overflow: "hidden",
          }}
        >
          <CreateCollectionPopover />
        </Box>

        {/*
        <ActionsViewToggle
          setView={setView}
          sx={viewToggleStyles}
          view={view}
        />
        */}
      </Box>

      <Box width="100%">
        {collectionsView}

        <TablePagination
          component="div"
          count={isNextPageAvailable ? -1 : cacheLength}
          nextIconButtonProps={{
            disabled: !isNextPageAvailable,
          }}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={page}
          onPageChange={(_, newPage) => {
            void onPageChange(newPage);
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            void onRowsPerPageChangeHandler(e);
          }}
          labelRowsPerPage="Collections per page:"
        />
      </Box>
    </>
  );
}
