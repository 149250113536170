import { useTheme, Box, Paper } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";

import { HealthCheckText } from "./HealthCheckText";
export const Footer: React.FC = () => {
  const theme = useTheme();

  const { currentUser } = useAuth();

  return (
    <>
      <Box
        sx={{
          justifyContent: "center",
          justifyItems: "center",
          marginTop: theme.spacing(1.75),
          marginBottom: theme.spacing(1.75),
          display: "flex",
          gap: theme.spacing(2),
        }}
      >
        <Box
          component={Paper}
          variant="outlined"
          sx={{
            padding: theme.spacing(0.5),
            paddingLeft: theme.spacing(1.25),
            paddingRight: theme.spacing(1.25),
            backgroundColor: theme.palette.code.main,
            borderRadius: 2,
          }}
        >
          <HealthCheckText detailPopover={currentUser?.is_system_user} />
        </Box>
      </Box>
    </>
  );
};
