import ErrorIcon from "@mui/icons-material/Error";
import { CircularProgress, Box, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { FileNode } from "@/types";

import { RobotoTooltip } from "../RobotoTooltip";

import { AnnotationOperations } from "./AnnotationOperations";
import { AnnotationSelector } from "./AnnotationSelector";
import { InvalidAnnotationsError } from "./schema";
import { AnnotationViewFilters } from "./types";

interface AnnotationsMenuProps {
  annotationFiles?: FileNode[];
  annotationFile?: FileNode;
  annotationDataLoading?: boolean;
  annotationDataError?: Error;
  annotationViewFilters?: AnnotationViewFilters;
  onViewFiltersChanged(filters: AnnotationViewFilters): void;
  onAnnotationFileSelected(file: FileNode | undefined): void;
  inline: boolean;
}

export const AnnotationsMenu: React.FC<AnnotationsMenuProps> = ({
  annotationFiles,
  annotationFile,
  annotationDataLoading,
  annotationDataError,
  annotationViewFilters,
  onViewFiltersChanged,
  onAnnotationFileSelected,
  inline,
}) => {
  const theme = useTheme();

  const status = (
    <>
      {annotationDataLoading && <CircularProgress size="1rem" />}
      {!annotationDataLoading && annotationDataError && (
        <RobotoTooltip
          title={
            annotationDataError instanceof InvalidAnnotationsError
              ? "Invalid annotation file"
              : "Failed to load annotations"
          }
        >
          <ErrorIcon fontSize="small" color="error" />
        </RobotoTooltip>
      )}
    </>
  );

  return (
    <Box
      sx={{
        mb: inline ? 0 : theme.spacing(1),
        display: inline ? "flex" : "block",
        gap: theme.spacing(1),
        alignItems: "center",
      }}
    >
      <Typography variant="body2" component="span">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1.5),
          }}
        >
          {!inline && <Typography variant="subtitle2">Annotations</Typography>}
          {!inline && status}
        </Box>
        <Box
          sx={{
            mt: inline ? 0 : theme.spacing(1),
            mb: inline ? 0 : theme.spacing(1.5),
          }}
        >
          <AnnotationSelector
            annotationFiles={annotationFiles}
            activeAnnotationFile={annotationFile}
            onAnnotationFileSelected={onAnnotationFileSelected}
          />
        </Box>
      </Typography>

      {annotationFile && annotationViewFilters && (
        <AnnotationOperations
          annotationViewFilters={annotationViewFilters}
          onViewFiltersChanged={onViewFiltersChanged}
        />
      )}
      {inline && status}
    </Box>
  );
};
