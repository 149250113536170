import * as React from "react";

export function usePopupState() {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openPopup = React.useCallback(function open(anchorEl?: HTMLElement) {
    setAnchorEl(anchorEl ?? null);
    setOpen(true);
  }, []);

  const closePopup = React.useCallback(function close() {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  return {
    isOpen: open,
    anchor: anchorEl,
    open: openPopup,
    close: closePopup,
  };
}
