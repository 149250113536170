import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { Token } from "../Token";

export function useListTokens(onSuccess: (tokens: Token[]) => void = () => {}) {
  const { tokens } = React.useContext(DomainServicesContext);

  return useQuery<Token[], Error>({
    queryKey: ["listTokens"],
    queryFn: ({ signal }) => {
      return tokens
        .listTokens({
          abortSignal: signal,
        })
        .then((record) => {
          onSuccess(record);
          return record;
        });
    },

    // 1 minute until considered stale.
    staleTime: 60000,
  });
}
