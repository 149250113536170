import { useTheme } from "@mui/material";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { RobotoButton } from "@/components";
import { Env } from "@/config";
import { useAuth } from "@/providers";

import buttonStyles from "./AuthFlowButton.module.css";

interface CustomCognitoProviderAuthButtonProps {
  signUp: boolean;
  onClick: () => void;
}

export const CustomCognitoProviderAuthButton: React.FC<
  CustomCognitoProviderAuthButtonProps
> = ({ signUp, onClick }) => {
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get("inviteId");
  const theme = useTheme();
  const { ssoSignIn } = useAuth();

  return (
    <RobotoButton
      data-cy={"sign-in-with-custom-provider"}
      size="large"
      sx={{
        className: buttonStyles.authFlowButton,
        marginBottom: theme.spacing(2),
      }}
      eventName={signUp ? "SignUpClicked" : "SignInClicked"}
      eventProperties={{ method: "sso" }}
      variant="contained"
      color="primary"
      onClick={() => {
        onClick();
        void ssoSignIn(inviteId);
      }}
    >
      {signUp ? "Sign up" : "Sign in"} with{" "}
      {Env.ssoSignInConfig?.displayName || "SSO"}
    </RobotoButton>
  );
};
