import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SxProps } from "@mui/material";

import { RobotoIconButton, RobotoTooltip } from "@/components";
import { ActionReference, InvocationRecord } from "@/domain/actions";
import { OrgRecord } from "@/domain/orgs";
import { useNavigation } from "@/providers";

interface CloneInvocationIconButtonProps {
  invocation?: InvocationRecord;
  org: OrgRecord | null;
  sx?: SxProps;
}

export default function CloneInvocationIconButton({
  invocation,
  org,
  sx,
}: CloneInvocationIconButtonProps) {
  const { goto } = useNavigation();
  return (
    <>
      <RobotoTooltip title="Clone">
        <span>
          <RobotoIconButton
            eventName={"InvocationCloned"}
            eventProperties={{
              id: invocation?.invocation_id || "",
            }}
            onClick={() => {
              const actionRef = {
                name: invocation?.provenance.action.name,
                digest: invocation?.provenance.action.digest,
                owner:
                  org?.org_id !== invocation?.provenance.action.org_id
                    ? invocation?.provenance.action.org_id
                    : undefined,
              } as ActionReference;
              goto.invokeAction({
                action: actionRef,
                datasetId: invocation?.data_source.data_source_id,
                baseInvocation: invocation?.invocation_id,
              });
            }}
            sx={sx}
          >
            <ContentCopyIcon sx={{ fontSize: "0.9rem" }} />
          </RobotoIconButton>
        </span>
      </RobotoTooltip>
    </>
  );
}
