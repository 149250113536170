import { InputAdornment, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { nanoSecToSec } from "@/utils/time";

import { RobotoTypography } from "../RobotoTypography";

import { EventDuration } from "./EventDuration";

interface EventDurationFormProps {
  showDuration?: boolean;
}

function calculateDuration(startTime: string, endTime: string): number {
  try {
    const startTimeNS = BigInt(startTime);
    const endTimeNS = BigInt(endTime);

    return nanoSecToSec(endTimeNS - startTimeNS);
  } catch {
    return -1;
  }
}

export function EventDurationForm({
  showDuration = true,
}: EventDurationFormProps) {
  const form = useFormContext<{
    start_time: string;
    end_time: string;
  }>();

  const startTime = form.watch("start_time");
  const endTime = form.watch("end_time");

  return (
    <section>
      <RobotoTypography
        component={"h3"}
        sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
      >
        Duration
      </RobotoTypography>
      <EventDuration
        durationInSeconds={calculateDuration(startTime, endTime)}
        show={showDuration}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "1rem",
        }}
      >
        <TextField
          id="startTime"
          label="Start Time"
          size="small"
          {...form.register("start_time")}
          InputProps={{
            endAdornment: <InputAdornment position="end">ns</InputAdornment>,
          }}
          error={form.formState.errors.start_time !== undefined}
        />
        <TextField
          id="endTime"
          label="End Time"
          size="small"
          {...form.register("end_time")}
          InputProps={{
            endAdornment: <InputAdornment position="end">ns</InputAdornment>,
          }}
          error={form.formState.errors.end_time !== undefined}
        />
      </div>
    </section>
  );
}
