import { type KeyValueStorageInterface } from "aws-amplify/utils";

// Best-effort cleanup when the object is garbage collected.
const registry = new FinalizationRegistry<() => void>((dispose) => {
  dispose();
});

/**
 * An indexedDB storage implementation for Amplify's custom auth storage.
 * Amplify uses a localStorage implementation by default,
 * but localStorage is not available in web workers.
 * By using indexedDB, we can use Amplify to retrieve bearer tokens in web workers.
 *
 * Ref: https://docs.amplify.aws/react/build-a-backend/auth/manage-user-session/#custom-storage
 */
export class IndexDbStorage implements KeyValueStorageInterface {
  public static DB_NAME = "auth";
  public static VERSION = 1;
  public static STORE_NAME = "cognito-user-pools-token-storage";

  #db: IDBDatabase;

  static build(): Promise<IndexDbStorage> {
    return new Promise((resolve, reject) => {
      const request = globalThis.indexedDB.open(
        IndexDbStorage.DB_NAME,
        IndexDbStorage.VERSION,
      );
      request.onerror = () => {
        const msg = `Error opening browser storage to store authentication. 
        If you were prompted to allow this site access to 'IndexedDB', please allow it. 
        Otherwise, please refresh the page and try again.`;
        globalThis.indexedDB.deleteDatabase(IndexDbStorage.DB_NAME);
        reject(new Error(msg, { cause: request.error }));
      };

      // Called when the database is created or the version is updated, before the onsuccess event is triggered.
      request.onupgradeneeded = () => {
        const db = request.result;
        if (db.objectStoreNames.contains(IndexDbStorage.STORE_NAME)) {
          db.deleteObjectStore(IndexDbStorage.STORE_NAME);
        }
        db.createObjectStore(IndexDbStorage.STORE_NAME);
      };

      request.onsuccess = () => {
        const db = request.result;
        db.onerror = (event) => {
          const msg = `Error accessing cached authentication. Please try refreshing the page and logging in again.`;
          reject(new Error(msg, { cause: event.target }));
        };
        resolve(new IndexDbStorage(db));
      };
    });
  }

  constructor(db: IDBDatabase) {
    this.#db = db;
    registry.register(this, () => this.close());
  }

  close(): void {
    this.#db.close();
  }

  setItem(key: string, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.startTransaction("readwrite", {
        onComplete: resolve,
        onError: reject,
      });

      const store = transaction.objectStore(IndexDbStorage.STORE_NAME);
      store.put(value, key);
    });
  }

  getItem(key: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const transaction = this.startTransaction("readonly", {
        onError: reject,
      });

      const store = transaction.objectStore(IndexDbStorage.STORE_NAME);
      const request = store.get(key) as IDBRequest<string>;
      request.onsuccess = () => {
        resolve(request.result);
      };
    });
  }

  removeItem(key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.startTransaction("readwrite", {
        onComplete: resolve,
        onError: reject,
      });

      const store = transaction.objectStore(IndexDbStorage.STORE_NAME);
      store.delete(key);
    });
  }

  clear(): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.startTransaction("readwrite", {
        onComplete: resolve,
        onError: reject,
      });

      const store = transaction.objectStore(IndexDbStorage.STORE_NAME);
      store.clear();
    });
  }

  private startTransaction(
    mode: IDBTransactionMode,
    handlers: {
      onComplete?: () => void;
      onError?: (event: IDBTransactionEventMap["error"]) => void;
    },
  ): IDBTransaction {
    const transaction = this.#db.transaction(IndexDbStorage.STORE_NAME, mode);
    if (handlers.onError) {
      transaction.onerror = handlers.onError;
    }
    if (handlers.onComplete) {
      transaction.oncomplete = handlers.onComplete;
    }
    return transaction;
  }
}
