import { RobotoAPICall, APIServiceError, CallState } from "@/types";

import { APIService } from "../ApiService";

export const initiateRequestFunc = async <ResponseData>(
  apiCall: RobotoAPICall,
  setCallState: React.Dispatch<React.SetStateAction<CallState<ResponseData>>>,
  isMountedRef: React.MutableRefObject<boolean>,
): Promise<{ error: APIServiceError | null; data: ResponseData | null }> => {
  //
  if (isMountedRef.current) {
    setCallState((prevState) => ({
      loading: true,
      error: null,
      data: prevState.data,
    }));
  }

  const { response, error } =
    await APIService.authorizedRequest<ResponseData>(apiCall);

  if (error) {
    if (isMountedRef.current) {
      setCallState({ loading: false, error: error, data: null });
    }

    return {
      error: error,
      data: null,
    };
  }

  if (isMountedRef.current) {
    setCallState({
      loading: false,
      error: null,
      data: response as ResponseData,
    });
  }

  return { error: null, data: response as ResponseData };
};
