import * as React from "react";
import { createSelector } from "reselect";

import { VizStateContext, VizStateDispatchContext } from "./context";
import {
  type File,
  type PanelState,
  type PlotSeries,
  type State,
  isPlotPanelState,
} from "./schema";
import { EventConfig, Layout, LayoutItem, isLayoutItem } from "./schema/v1";

const createTypedSelector = createSelector.withTypes<State>();

/**
 * Return function to dispatch actions to the viz state reducer.
 */
export function useVizDispatch() {
  return React.useContext(VizStateDispatchContext);
}

/**
 * Return top level state for visualization.
 */
export function useVizState() {
  return React.useContext(VizStateContext);
}

/**
 * Return list of files available for visualization.
 */
export function useFiles(): File[] {
  const state = React.useContext(VizStateContext);
  return state.files;
}

export function usePlotSeries(
  panelId: string,
  seriesId?: string,
): PlotSeries | undefined {
  const state = React.useContext(VizStateContext);
  const panel = selectPanelById(state, panelId);
  if (
    panel === undefined ||
    !isPlotPanelState(panel) ||
    seriesId === undefined
  ) {
    return undefined;
  }
  return panel.data.find((series) => series.id === seriesId);
}

/**
 * Return list of all panels to render in the workspace.
 */
const selectPanels = createTypedSelector(
  [(state) => state.panels ?? {}],
  (panels) => Object.values(panels),
);
export function usePanels(): PanelState[] {
  const state = React.useContext(VizStateContext);
  return selectPanels(state);
}

/**
 * Return current state for a specific panel.
 */
export const selectPanelById = (
  state: State,
  panelId: string,
): PanelState | undefined => {
  const panels = state.panels ?? {};
  return panels[panelId];
};
export function usePanelState(panelId: string): PanelState | undefined {
  const state = React.useContext(VizStateContext);
  return selectPanelById(state, panelId);
}

export function selectLayoutById(
  state: State,
  id: string,
): LayoutItem | Layout | undefined {
  if (state.layout.id === id) {
    return state.layout;
  }

  let queue = [...state.layout.children];

  while (queue.length > 0) {
    const layout = queue.shift();

    if (layout === undefined) {
      continue;
    }

    if (layout.id === id) {
      return layout;
    }

    if (isLayoutItem(layout) === false) {
      queue = queue.concat(layout.children);
    }
  }

  return undefined;
}

export function selectParentLayoutByChildId(
  state: State,
  id: string,
): Layout | undefined {
  // Walk the tree until we find the parent whose children includes layoutId
  const initialChildren = state.layout.children;

  let parent = state.layout;
  const stack: Layout[] = [];

  for (const child of initialChildren) {
    if (child.id === id) {
      return parent; // Parent = root
    }
    if (isLayoutItem(child) === false) {
      stack.push(child);
    }
  }

  while (stack.length > 0) {
    const layout = stack.pop();

    if (layout === undefined) {
      continue;
    }

    parent = layout;

    for (const child of layout.children) {
      if (child.id === id) {
        return parent;
      }

      if (isLayoutItem(child) === false) {
        stack.push(child);
      }
    }
  }

  return undefined;
}

export function useEventConfigs(): Record<string, EventConfig> | undefined {
  const state = React.useContext(VizStateContext);
  return state.events;
}

export function useEventConfig(eventId: string): EventConfig | undefined {
  const state = React.useContext(VizStateContext);
  return state.events?.[eventId];
}
