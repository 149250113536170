import * as React from "react";

import { Timer } from "./Timer";

interface TimerState {
  duration: number;
  elapsedMs: number;
  isPlaying: boolean;
  isHeld: boolean;
  timeNs: bigint;
}

/**
 * Custom React hook for subscribing to `Timer` state.
 * Best to use sparingly/with precision, as `Timer` tick events are high-frequency state updates.
 */
export function useTimerState(timer: Timer): TimerState {
  const [timerState, setTimerState] = React.useState(() => {
    return {
      duration: timer.duration,
      elapsedMs: timer.elapsed,
      isPlaying: timer.active,
      isHeld: timer.isHeld,
      timeNs: timer.currentTime,
    };
  });

  React.useEffect(() => {
    const abortController = new AbortController();

    function onUpdate(updated: Timer) {
      if (abortController.signal.aborted) {
        return;
      }

      setTimerState({
        duration: updated.duration,
        elapsedMs: updated.elapsed,
        isPlaying: updated.active,
        isHeld: updated.isHeld,
        timeNs: updated.currentTime,
      });
    }

    timer.addListener("start", onUpdate, { signal: abortController.signal });
    timer.addListener("stop", onUpdate, { signal: abortController.signal });
    timer.addListener("tick", onUpdate, { signal: abortController.signal });
    timer.addListener("seek", onUpdate, { signal: abortController.signal });
    timer.addListener("hold", onUpdate, {
      signal: abortController.signal,
    });
    timer.addListener("holdRelease", onUpdate, {
      signal: abortController.signal,
    });
    timer.addListener("timeSpanUpdate", onUpdate, {
      signal: abortController.signal,
    });

    return () => {
      abortController.abort();
    };
  }, [timer]);

  return timerState;
}
