import { ResizeCallbackData } from "react-resizable";

import { TableState } from "@/types";

export const tableHeaderCheckboxChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  table: TableState,
  setTable: React.Dispatch<React.SetStateAction<TableState>>,
  onSelectedRowsChange: (selectedRows: Set<string>) => void,
) => {
  if (e.target.checked) {
    const newSet = new Set<string>();

    table.rowState.forEach((row) => {
      newSet.add(row.id);
    });

    setTable((prevTable) => ({
      ...prevTable,
      selectedRows: newSet,
    }));
    onSelectedRowsChange(newSet);
  } else {
    setTable((prevTable) => ({
      ...prevTable,
      selectedRows: new Set<string>(),
    }));
    onSelectedRowsChange(new Set<string>());
  }
};

export const onTableHeaderResize = (
  columnIndex: number,
  data: ResizeCallbackData,
  table: TableState,
  setTable: React.Dispatch<React.SetStateAction<TableState>>,
) => {
  const newState = [...table.columnState];

  newState[columnIndex].width = data.size.width;

  setTable((prevTable) => ({
    ...prevTable,
    columnState: newState,
  }));
};
