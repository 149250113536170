import { Box, Popover, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTypography } from "@/components/RobotoTypography";
import { OrgRecord } from "@/domain/orgs";

interface OrgListMenuProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  availableOrganizations: OrgRecord[] | null;
  currentOrganization: OrgRecord | null;
  orgClicked: (org: OrgRecord) => void;
}

export const OrgListMenu: React.FC<OrgListMenuProps> = ({
  anchorEl,
  handleClose,
  availableOrganizations: availableOrgs,
  currentOrganization,
  orgClicked,
}) => {
  const theme = useTheme();

  const availableOrganizations = availableOrgs?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: -10,
        horizontal: "left",
      }}
      marginThreshold={5}
      elevation={1}
      sx={{
        zIndex: theme.zLayer(4),
      }}
    >
      <Box sx={{ padding: theme.spacing(2) }}>
        <RobotoTypography
          sx={{ marginBottom: theme.spacing(0.5) }}
          variant={"subtitle2"}
        >
          Organizations
        </RobotoTypography>

        {availableOrganizations?.map((org) => {
          return (
            <RobotoTypography
              key={org.org_id}
              variant={"body2"}
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.primary.light,
                },
                cursor: "pointer",
                borderRadius: theme.border.radius,
                padding: theme.spacing(1),
                marginBottom: theme.spacing(0.45),
                backgroundColor:
                  org.org_id === currentOrganization?.org_id
                    ? theme.palette.primary.light
                    : "transparent",
              }}
              onClick={() => {
                orgClicked(org);
              }}
            >
              {org.name}
            </RobotoTypography>
          );
        })}
      </Box>
    </Popover>
  );
};
