import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { RobotoTableCell } from "@/components";

import { EventTableBody } from "./EventTableBody";

interface DatasetEventsTableProps {
  datasetId: string;
}

export const DatasetEventsTable = ({ datasetId }: DatasetEventsTableProps) => {
  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" sx={{ minWidth: 650 }} aria-label="events-table">
          <TableHead>
            <TableRow>
              <RobotoTableCell>Name</RobotoTableCell>
              <RobotoTableCell>Event ID</RobotoTableCell>
              <RobotoTableCell>Start Time</RobotoTableCell>
              <RobotoTableCell>Created By</RobotoTableCell>
              <RobotoTableCell>Created</RobotoTableCell>
              <RobotoTableCell>Scope</RobotoTableCell>

              {/* Cell for click to expand column button */}
              <RobotoTableCell></RobotoTableCell>
            </TableRow>
          </TableHead>
          <EventTableBody datasetId={datasetId} numColumns={7} />
        </Table>
      </TableContainer>
    </Box>
  );
};
