import { styled } from "@mui/material/styles";

interface StyledSidebarProps {
  topBarHeight: string;
  sideBarWidth: string;
}

export const StyledSidebar = styled("aside")<StyledSidebarProps>(
  ({ theme, topBarHeight, sideBarWidth }) => ({
    zIndex: theme.zLayer(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    paddingTop: "30px",
    background: theme.palette.navMenu.main,
    position: "fixed",
    top: topBarHeight,
    left: 0,
    transform: "translateX(-100%)",
    height: `calc(100vh - ${topBarHeight})`,
    borderRight: theme.border.thin,
    width: sideBarWidth,
    whiteSpace: "nowrap",
    overflowX: "hidden",
    "&:hover": {
      width: "168px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    [theme.breakpoints.up("sm")]: {
      transform: "translateX(0%)",
      alignItems: "flex-start",
    },
  }),
);
