import {
  Checkbox,
  FormControl,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import * as React from "react";

interface DatasetFileTypeSelectorProps {
  extensions: string[];
  types: string[];
  handleFileTypeSelection: (arg: string[]) => void;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 350,
      width: 150,
    },
  },
};

export const DatasetFileTypeSelector: React.FC<
  DatasetFileTypeSelectorProps
> = ({ extensions, types, handleFileTypeSelection }) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof types>) => {
    const {
      target: { value },
    } = event;

    handleFileTypeSelection(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ width: 120 }}>
        <Select
          labelId="multiple-checkbox-types"
          id="multiple-checkbox-types"
          multiple
          value={types}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return "File Types";
            } else if (selected.length > 2) {
              return `${selected.length} file types`;
            } else {
              return selected.join(", ");
            }
          }}
          MenuProps={MenuProps}
          size="small"
          variant="outlined"
          color="primary"
          displayEmpty
          sx={{
            height: "32px",
            lineHeight: "1.75",
            fontSize: "0.8125rem",
            color: "#999",
          }}
        >
          <ListSubheader>Extensions</ListSubheader>
          {extensions.map((extension) => (
            <MenuItem key={extension} value={extension} dense>
              <Checkbox
                sx={{
                  padding: theme.spacing(0.5),
                  paddingRight: theme.spacing(1),
                }}
                size="small"
                checked={types.indexOf(extension) > -1}
              />
              <ListItemText
                primary={extension}
                primaryTypographyProps={{
                  variant: "body2",
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
