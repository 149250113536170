import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";

import {
  VisualizationPageLayout,
  Workspace,
  WorkspaceErrorBoundary,
} from "@/components/visualization";
import { PageLayout } from "@/layouts/PageLayout";
import Account from "@/pages/Account";
import Actions from "@/pages/Actions";
import ActionRef from "@/pages/actions/ActionRef";
import ActionsCreate from "@/pages/actions/Create";
import ActionInvocationPage from "@/pages/actions/Invoke";
import Admin from "@/pages/Admin";
import AppProviders from "@/pages/AppProviders";
import Collections from "@/pages/Collections";
import CollectionId from "@/pages/collections/CollectionId";
import DatasetCreationPage from "@/pages/datasets/Create";
import { CreateDatasetEvent } from "@/pages/datasets/CreateDatasetEvent";
import DatasetExplorer from "@/pages/datasets/DatasetExplorer";
import DeviceId from "@/pages/devices/DeviceId";
import { ErrorPage } from "@/pages/ErrorPage";
import FileId from "@/pages/files/FileId";
import Hub from "@/pages/Hub";
import IndexRoute from "@/pages/index";
import InviteId from "@/pages/invites/InviteId";
import InvocationId from "@/pages/invocations/InvocationId";
import Orgs from "@/pages/orgs/Orgs";
import SearchWithResultsPage from "@/pages/search/SearchWithResults";
import Settings from "@/pages/Settings";
import SignIn from "@/pages/SignIn";
import SignUp from "@/pages/SignUp";
import Create from "@/pages/triggers/Create";
import TriggerName from "@/pages/triggers/TriggerName";
import UserId from "@/pages/users/UserId";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppProviders />} errorElement={<ErrorPage />}>
      <Route index element={<IndexRoute />} />
      <Route path="/account" element={<Account />} />
      <Route path="/actions" element={<Actions />} />
      <Route path="/actions/:actionRef/*" element={<ActionRef />} />
      <Route path="/actions/create" element={<ActionsCreate />} />
      <Route path="/actions/invoke" element={<ActionInvocationPage />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/collections/:collectionId/*" element={<CollectionId />} />
      <Route path="/datasets" element={<Navigate to="/search" replace />} />
      <Route path="/datasets/create" element={<DatasetCreationPage />} />
      <Route
        path="/datasets/:datasetPath/events/create"
        element={<CreateDatasetEvent />}
      />
      <Route path="/datasets/:datasetPath/*" element={<DatasetExplorer />} />
      <Route path="/devices/:deviceId/*" element={<DeviceId />} />
      <Route path="/hub" element={<Hub />} />
      <Route path="/invites/:inviteId" element={<InviteId />} />
      <Route path="/invocations/:invocationId/*" element={<InvocationId />} />
      <Route
        path="/files"
        element={<Navigate to="/search?target=files" replace />}
      />
      <Route path="/files/:fileId/:workspaceId?" element={<FileId />} />

      <Route path="/orgs/:orgId/*" element={<Orgs />} />
      <Route path="/search" element={<SearchWithResultsPage />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route
        path="/topics"
        element={<Navigate to="/search?target=topics" replace />}
      />
      <Route path="/triggers/:triggerName/*" element={<TriggerName />} />
      <Route path="/triggers/create" element={<Create />} />
      <Route path="/users/:userId/*" element={<UserId />} />
      <Route element={<PageLayout title="Roboto - Visualization" />}>
        <Route path="/visualize" element={<VisualizationPageLayout />}>
          <Route
            path=":workspaceId?"
            element={<Workspace />}
            errorElement={<WorkspaceErrorBoundary />}
          />
        </Route>
      </Route>
    </Route>,
  ),
);

export default function App() {
  return <RouterProvider router={router} />;
}
