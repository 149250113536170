import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  AccordionProps,
  AccordionSummaryProps,
  Alert,
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  Theme,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { SystemUserOnly } from "@/components/SystemUserOnly.tsx";
import {
  InvocationProcess,
  InvocationRecord,
  InvocationStatus,
  InvocationStatusRecord,
} from "@/domain/actions";
import { OrgRecord } from "@/domain/orgs";
import InvocationDebugLinks from "@/features/actions/components/invocation/InvocationLogs/InvocationDebugLinks.tsx";
import { APIServiceError } from "@/types";

import { LogStreamer } from "../service";

import InvocationLogRecords from "./InvocationLogRecords";
import InvocationLogsHeader from "./InvocationLogsHeader";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.7rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  maxHeight: "500px",
  transition: "height 0.5s",
  overflow: "auto",
}));

const HeaderTypographyStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const DurationIndicatorStyles = (theme: Theme) => {
  return {
    fontWeight: 400,
    fontFamily: "monospace",
    fontSize: "0.7rem",
    color: theme.palette.text.secondary,
  };
};

const ACCORDION_TRANSITION_PROPS = { mountOnEnter: true, unmountOnExit: true };

interface InvocationLogsProps {
  currentOrg: OrgRecord | null;
  invocation: InvocationRecord;
  showStatus?: boolean;
}

enum Panel {
  Action = "Action",
  OutputHandler = "OutputHandler",
  Setup = "Setup",
}

const ALL_PANELS = [Panel.Action, Panel.OutputHandler, Panel.Setup];

function calculateStatusDurations(
  statuses: InvocationStatusRecord[],
): [string, string, string] {
  let downloadTime = 0;
  let processingTime = 0;
  let uploadingTime = 0;
  statuses.forEach((status, index) => {
    const statusTimestamp = Date.parse(status.timestamp);
    if (index > 0 && statuses.length > 1) {
      const previousStatusTimestamp = Date.parse(statuses[index - 1].timestamp);
      if (status.status === InvocationStatus.Processing) {
        downloadTime = statusTimestamp - previousStatusTimestamp;
      } else if (status.status === InvocationStatus.Uploading) {
        processingTime = statusTimestamp - previousStatusTimestamp;
      } else if (status.status === InvocationStatus.Completed) {
        uploadingTime = statusTimestamp - previousStatusTimestamp;
      }
    }
  });
  return [
    msToTime(downloadTime),
    msToTime(uploadingTime),
    msToTime(processingTime),
  ];
}

function msToTime(duration: number) {
  const milliseconds = Math.floor(duration % 1000);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));

  let readableTimeString = "";

  if (days !== 0) {
    readableTimeString += days.toString() + "d ";
  }

  if (hours !== 0) {
    readableTimeString += hours.toString() + "h ";
  }

  if (minutes !== 0) {
    readableTimeString += minutes.toString() + "m ";
  }

  if (seconds !== 0) {
    readableTimeString += seconds.toString() + "s";
  }

  // Only show ms if there are no d / h / m / s values
  // i.e. duration < 1000 and non-zero
  if (duration < 1000 && duration !== 0) {
    readableTimeString += milliseconds.toString() + "ms";
  }

  return readableTimeString;
}

export default function InvocationLogs({
  invocation,
  currentOrg,
  showStatus = false,
}: InvocationLogsProps) {
  const [expanded, setExpanded] = React.useState<Panel[]>(() => {
    return invocation.last_status < InvocationStatus.Completed
      ? ALL_PANELS
      : [Panel.Action];
  });
  const [error, setError] = React.useState<Error | null>(null);

  const theme = useTheme();

  const logStream = React.useMemo(() => {
    return new LogStreamer(invocation.invocation_id, currentOrg?.org_id);
  }, [invocation.invocation_id, currentOrg?.org_id]);

  React.useEffect(() => {
    let isMounted = true;
    logStream
      .stream(crypto.randomUUID())
      .catch((err: APIServiceError | null) => {
        if (isMounted && err?.name !== "AbortError") {
          setError(err);
        }
      });

    return () => {
      isMounted = false;
      logStream.close();
    };
  }, [logStream]);

  const state = React.useSyncExternalStore(
    logStream.subscribe,
    logStream.getSnapshot,
  );

  const handleAccordionChange =
    (panel: Panel) =>
    (_event: React.SyntheticEvent, panelIsExpanded: boolean) => {
      setExpanded((prev) =>
        panelIsExpanded ? [...prev, panel] : prev.filter((p) => p !== panel),
      );
    };

  const [downloadTime, uploadTime, processingTime] = calculateStatusDurations(
    state.invocation?.status ?? invocation.status,
  );

  return (
    <Box>
      {error ? (
        <Box
          sx={{
            m: theme.spacing(2),
          }}
        >
          <Alert severity="error">{error.message}</Alert>
        </Box>
      ) : (
        <>
          <InvocationLogsHeader
            loading={state.loading}
            status={
              showStatus
                ? state.invocation?.last_status ?? invocation.last_status
                : undefined
            }
          />
          <SystemUserOnly>
            <InvocationDebugLinks invocation={state.invocation} />
          </SystemUserOnly>
          <Box marginTop={1}>
            <Accordion
              expanded={expanded.includes(Panel.Setup)}
              onChange={handleAccordionChange(Panel.Setup)}
              TransitionProps={ACCORDION_TRANSITION_PROPS}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography variant="subtitle2" sx={HeaderTypographyStyles}>
                  Downloading&nbsp;
                  <span style={DurationIndicatorStyles(theme)}>
                    {downloadTime}
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <InvocationLogRecords
                  isLoading={state.loading}
                  logRecords={state[InvocationProcess.Setup]}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded.includes(Panel.Action)}
              onChange={handleAccordionChange(Panel.Action)}
              TransitionProps={ACCORDION_TRANSITION_PROPS}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography variant="subtitle2" sx={HeaderTypographyStyles}>
                  Processing&nbsp;
                  <span style={DurationIndicatorStyles(theme)}>
                    {processingTime}
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  p: theme.spacing(2, 7, 2, 2),
                }}
              >
                <InvocationLogRecords
                  isLoading={state.loading}
                  logRecords={state[InvocationProcess.Action]}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded.includes(Panel.OutputHandler)}
              onChange={handleAccordionChange(Panel.OutputHandler)}
              TransitionProps={ACCORDION_TRANSITION_PROPS}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography variant="subtitle2" sx={HeaderTypographyStyles}>
                  Uploading&nbsp;
                  <span style={DurationIndicatorStyles(theme)}>
                    {uploadTime}
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <InvocationLogRecords
                  isLoading={state.loading}
                  logRecords={state[InvocationProcess.OutputHandler]}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      )}
    </Box>
  );
}
