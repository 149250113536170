import Chip, { ChipProps } from "@mui/material/Chip";
import * as React from "react";

interface TagProps extends ChipProps {
  onTagClick?: (tag: string) => void;
  tooltip?: string;
  value: string;
  shrink?: boolean;
}

const TEXT_ABBREV_STYLE: { [key: string]: string } = {
  textDecoration: "none",
  userSelect: "none",
};

export default function Tag({
  onTagClick,
  tooltip,
  value,
  shrink,
  ...props
}: TagProps) {
  const label = (
    <abbr style={TEXT_ABBREV_STYLE} title={tooltip ?? value}>
      {value}
    </abbr>
  );

  const handleClick = React.useCallback(() => {
    if (onTagClick !== undefined) {
      onTagClick(value);
    }
  }, [onTagClick, value]);

  return (
    <Chip
      clickable={onTagClick !== undefined}
      label={label}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      {...props}
      variant={props.variant || "filled"}
      sx={{
        fontSize: "0.8rem",
        borderRadius: "8px",
        minWidth: "50px",
        maxWidth: shrink ? "125px" : "auto",
        width: "fit-content",
        ...props.sx,
      }}
    />
  );
}
