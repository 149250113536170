import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { AllTopicKeysRecord } from "@/domain/topics/TopicRecord.ts";
import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";

export default function useAllTopicKeys(orgId?: string) {
  const { currentOrganization } = useAuth();
  const canonicalOrgId = orgId || currentOrganization?.org_id;
  const { topics } = useContext(DomainServicesContext);

  return useQuery<AllTopicKeysRecord, Error>({
    queryKey: ["orgTopicKeys", canonicalOrgId],
    queryFn: ({ signal }) => {
      if (canonicalOrgId === undefined) {
        return Promise.reject(new Error("Invalid orgId"));
      }

      return topics.getAllTopicKeysForOrg({
        abortSignal: signal,
        resourceOwnerId: canonicalOrgId,
      });
    },
    enabled: canonicalOrgId !== undefined,
    staleTime: 30000,
  });
}
