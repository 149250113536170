import CancelIcon from "@mui/icons-material/Cancel";
import { Box, IconButton, useTheme } from "@mui/material";
import React from "react";

interface DeleteProps extends React.PropsWithChildren {
  onDelete: () => void;
  disabled?: boolean;
}

export function DeletableBox({
  onDelete,
  children,
  disabled = false,
}: DeleteProps) {
  const [hovered, setHovered] = React.useState(false);
  const theme = useTheme();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        color: theme.palette.text.secondary,
        border:
          theme.palette.mode === "light" ? `1px solid #ddd` : `1px solid #555`,
        borderRadius: 1.5,
        ":hover": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#212831" : "#fafafa",
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!disabled && (
        <IconButton
          sx={{
            position: "absolute",
            top: -8,
            right: -8,
            zIndex: 1,
            backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            width: 20,
            height: 20,
            opacity: hovered ? 1 : 0,
            transition: "opacity 0.3s ease",
            ":hover": {
              backgroundColor:
                theme.palette.mode === "dark" ? "#1A2027" : "#eee",
            },
          }}
          aria-label="delete"
          onClick={handleDelete}
        >
          <CancelIcon />
        </IconButton>
      )}
      {children}
    </Box>
  );
}
