import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "./RobotoTooltip";

interface EditFieldButtonProps {
  editable: boolean;
  onClick: () => void;
  showEditableIcon: boolean;
  disabled?: boolean;
}

export const EditFieldButton: React.FC<EditFieldButtonProps> = ({
  editable,
  onClick,
  showEditableIcon,
  disabled,
}) => {
  const theme = useTheme();
  return (
    <RobotoTooltip title={editable ? "Save" : "Edit"}>
      <span>
        <IconButton
          onClick={() => onClick()}
          disabled={disabled}
          sx={{
            p: "5px",
            verticalAlign: "middle",
            ml: theme.spacing(0.25),
            opacity: showEditableIcon ? 1 : 0,
            position: "relative",
            top: "auto",
            right: "auto",
          }}
          aria-label="edit-field"
        >
          {editable ? (
            <SaveIcon
              color={disabled ? "disabled" : "primary"}
              sx={{
                fontSize: "1.1rem",
                cursor: "pointer",
              }}
            />
          ) : (
            <EditIcon
              color={disabled ? "disabled" : "primary"}
              sx={{
                fontSize: "1.1rem",
                cursor: "pointer",
              }}
            />
          )}
        </IconButton>
      </span>
    </RobotoTooltip>
  );
};
