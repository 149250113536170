import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import * as React from "react";

import { MetadataView, TagList } from "@/components";
import {
  FileVizLink,
  TopicVizLink,
  DatasetLink,
  MessagePathLinkFromId,
} from "@/components/links";
import { AssociationType } from "@/domain/association.ts";
import { EventRecord, eventByAssociationType } from "@/domain/events";
import { timeToSeconds } from "@/mcap";
import { nanoSecToDate } from "@/utils/time.ts";

const listIconStyles = {
  minWidth: "35px",
};

interface EventExpanderDetailsProps {
  event: EventRecord;
}

export const EventDetails: React.FC<EventExpanderDetailsProps> = ({
  event,
}) => {
  const associationByType = eventByAssociationType(event);
  const associatedDatasets = associationByType[AssociationType.Dataset];
  const associatedFiles = associationByType[AssociationType.File];
  const associatedTopics = associationByType[AssociationType.Topic];
  const associatedMessagePaths = associationByType[AssociationType.MessagePath];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemText
                primary={"Start Time"}
                secondary={
                  <>
                    {nanoSecToDate(event.start_time).toLocaleString(undefined, {
                      timeZoneName: "short",
                    })}
                    <br />
                    &rArr; {timeToSeconds(event.start_time)} seconds since epoch
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"End Time"}
                secondary={
                  <>
                    {nanoSecToDate(event.end_time).toLocaleString(undefined, {
                      timeZoneName: "short",
                    })}
                    <br />
                    &rArr; {timeToSeconds(event.end_time)} seconds since epoch
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Description"}
                secondary={event.description || "No description provided"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Tags"}
                secondary={
                  event.tags.length > 0 ? <TagList tags={event.tags} /> : "None"
                }
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Metadata"}
                secondary={
                  Object.keys(event.metadata).length > 0 ? (
                    <MetadataView metadata={event.metadata} disabled={true} />
                  ) : (
                    "None"
                  )
                }
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemText
                primary="Scope"
                secondary={
                  <List dense>
                    {associatedDatasets.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <TopicOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <DatasetLink
                              datasetId={association.association_id}
                              key={association.association_id}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                    {associatedFiles.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FileVizLink
                              fileId={association.association_id}
                              key={association.association_id}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                    {associatedTopics.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <AccountTreeOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <TopicVizLink
                              topicId={association.association_id}
                              key={association.association_id}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                    {associatedMessagePaths.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <TimelineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <MessagePathLinkFromId
                              messagePathId={association.association_id}
                              key={association.association_id}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                }
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};
