import { LayoutOrientation, Placement } from "../actions";
import { selectParentLayoutByChildId } from "../hooks";
import { State } from "../schema";
import { Layout, LayoutItem, isLayoutItem } from "../schema/v1";

import { addLayout, insertLayoutAdjacentToSibling } from "./addLayout";
import { isAxisAlignedWithOrientation } from "./isAxisAlignedWithOrientation";
import { scaleChildrenProportionally } from "./scaleChildrenProportionally";
import { setChildrenAxisSize } from "./setChildrenAxisSize";
import { updateLayout } from "./updateLayout";

/**
 * Helper function to add a Layout item to the layout tree
 */
export function addItemToLayout(
  state: State,
  item: LayoutItem,
  placement: Placement,
): State {
  const { siblingLayout, orientation } = placement;

  if (siblingLayout.id === state.layout.id) {
    // Adding layout to the root directly

    if (isAxisAlignedWithOrientation(state.layout.axis, orientation)) {
      // Add the new layout item to the root in the correct orientation
      let nextLayout = {
        ...state.layout,
        children: addLayout(item, state.layout.children, orientation),
      };

      if (nextLayout.children.length > 1) {
        // Halve all of the rootLayout children including the new layout's size
        const nextChildren = scaleChildrenProportionally(
          nextLayout.children,
          0.5,
        );

        nextLayout = {
          ...nextLayout,
          children: nextChildren,
        };
      }

      return {
        ...state,
        layout: nextLayout,
      };
    } else if (state.layout.children.length <= 1) {
      // Special rootLayout case where it has zero or one child and
      // we're trying to place a new layout item in its opposite axis

      const nextState: State = {
        ...state,
        layout: {
          ...state.layout,
          // Swap the axis of the rootLayout since we've now created a layout item in the opposite direction
          axis: state.layout.axis === "x" ? "y" : "x",

          // Add the new layout item to the rootLayout in the correct orientation
          children: addLayout(item, state.layout.children, orientation),
        },
      };

      // Set the size of the children to be either 1 if there's only child or 0.5 if there are two children
      return {
        ...nextState,
        layout: {
          ...nextState.layout,
          children: setChildrenAxisSize(
            nextState.layout.children,
            1 / nextState.layout.children.length,
          ),
        },
      };
    } else {
      // Create nested layout with rootLayout's current axis and its children

      const nestedLayout: Layout = {
        id: crypto.randomUUID(),

        // New layout will arrange elements in same direction as its parent
        axis: state.layout.axis,

        // New layout will take the root's original children
        children: state.layout.children,

        // New layout will initially take the entire space
        relativeSize: 1,
      };

      const nextState: State = {
        ...state,
        layout: {
          ...state.layout,
          // Swap the axis of the root since we've now created a nested layout in the original direction
          axis: state.layout.axis === "x" ? "y" : "x",

          // Add the new layout item and nested layout to the root in the correct orientation
          children: addLayout(item, [nestedLayout], orientation),
        },
      };

      // Set the size of the nested layout and new layout item to be 0.5
      return {
        ...nextState,
        layout: {
          ...nextState.layout,
          children: setChildrenAxisSize(nextState.layout.children, 0.5),
        },
      };
    }
  } else {
    const parentLayout = selectParentLayoutByChildId(state, siblingLayout.id);

    if (parentLayout === undefined) {
      // Couldn't find the parent. Shouldn't reach here
      return state;
    }

    if (isAxisAlignedWithOrientation(parentLayout.axis, orientation)) {
      // Add the layout item to the parent's children using the orientation and siblingLayout
      const children = insertLayoutAdjacentToSibling(
        parentLayout.children,
        item,
        siblingLayout,
        orientation,
      );

      return {
        ...state,
        layout: updateLayout(state.layout, parentLayout.id, {
          children: children.map(function setSizeToHalfOfSiblingLayout(layout) {
            // Set the size of the new layout item and sibling layout to be half of sibling layout's original size
            if (layout.id === item.id || layout.id === siblingLayout.id) {
              return {
                ...layout,
                relativeSize: siblingLayout.relativeSize / 2,
              };
            }
            return layout;
          }),
        }),
      };
    } else if (
      parentLayout.id === state.layout.id &&
      parentLayout.children.length === 1
    ) {
      // Special rootLayout case where it only has one child (siblingLayout)
      // and we're trying to place a new layout item in its opposite axis

      const nextState: State = {
        ...state,
        layout: {
          ...state.layout,
          // Swap the axis of the rootLayout since we've now created a layout item in the opposite direction
          axis: state.layout.axis === "x" ? "y" : "x",

          // Add the new layout item to the rootLayout in the correct orientation
          children: addLayout(item, state.layout.children, orientation),
        },
      };

      // Set the size of the nested layout and new layout item to be 0.5
      return {
        ...nextState,
        layout: {
          ...nextState.layout,
          children: setChildrenAxisSize(nextState.layout.children, 0.5),
        },
      };
    } else if (isLayoutItem(siblingLayout)) {
      // Create a nestedLayout with siblingLayout and new layout in the correct orientation
      const nestedLayout: Layout = {
        id: crypto.randomUUID(),
        children: [LayoutOrientation.TOP, LayoutOrientation.LEFT].includes(
          orientation,
        )
          ? [item, siblingLayout]
          : [siblingLayout, item],

        // New layout will arrange elements in the opposite axis as its parent
        axis: parentLayout.axis === "x" ? "y" : "x",

        // New layout will take up the siblingLayout's size
        relativeSize: siblingLayout.relativeSize,
      };

      // Replaces siblingLayout with the nested layout from its parent
      const index = parentLayout.children.findIndex(
        (child) => child.id === siblingLayout.id,
      );

      if (index < 0) {
        // Shouldn't reach here since we used siblingLayout to find the parent
        throw new Error(
          `Couldn't find siblingLayout: ${siblingLayout.id} in parent: ${parentLayout.id}`,
        );
      }

      // Make the two children of the nested layout take half of the space
      nestedLayout.children = setChildrenAxisSize(nestedLayout.children, 0.5);

      const childrenCopy = [...parentLayout.children];
      childrenCopy.splice(index, 1, nestedLayout);

      return {
        ...state,
        layout: updateLayout(state.layout, parentLayout.id, {
          children: childrenCopy,
        }),
      };
    }

    // Case 3: Orientation doesn't match the axis direction and the siblingLayout is a nestedLayout
    // This case shouldn't exist because we should not show drop targets
    // in the same orientation as the siblingLayout's axis.
    return state;
  }
}
