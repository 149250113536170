import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

import { IndexDbStorage } from "./IndexDbStorage";

export async function configureAmplify() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: `${import.meta.env.VITE_AWS_USER_POOLS_ID}`,
        userPoolClientId: `${import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID}`,
        loginWith: {
          username: true,
          email: true,
          oauth: {
            domain: `${import.meta.env.VITE_OAUTH_COGNITO_DOMAIN}`,
            scopes: ["email", "profile", "openid"],
            redirectSignIn: [`${import.meta.env.VITE_OAUTH_REDIRECT_SIGN_IN}`],
            redirectSignOut: [
              `${import.meta.env.VITE_OAUTH_REDIRECT_SIGN_OUT}`,
            ],
            responseType: "code",
          },
        },
      },
    },
  });

  const indexDbStorage = await IndexDbStorage.build();
  cognitoUserPoolsTokenProvider.setKeyValueStorage(indexDbStorage);
}
