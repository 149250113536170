import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoTooltip, TagList } from "@/components";
import { Accessibility } from "@/domain/actions";
import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import { APIResponse, RobotoAPICall } from "@/types";

const BASE_ENDPOINT = "/actions/collection-info/tags";

interface ActionTagFilterProps {
  accessibility: Accessibility;
  onFilterChange?: (filters: string[]) => void;
  sx?: SxProps;
}

const EMPTY_LIST: string[] = [];

const tagListStyles = {
  flex: "1 1 auto",
  minWidth: 0,
  overflowX: "auto",
  whiteSpace: "nowrap",
  alignItems: "center",
  ml: 2,
};

export default function ActionTagFilter({
  accessibility,
  onFilterChange,
  sx,
}: ActionTagFilterProps) {
  const theme = useTheme();
  const { currentOrganization } = useAuth();
  const [tags, setTags] = React.useState<string[]>([]);
  const [filters, setFilters] = React.useState<string[]>([]);
  const [searchBoxInputValue, setSearchBoxInputValue] =
    React.useState<string>("");

  const { data, error, initiateRequest } =
    useLazyAPICall<APIResponse<string[]>>();
  React.useEffect(() => {
    if (
      currentOrganization?.org_id === undefined &&
      accessibility === Accessibility.Organization
    ) {
      return;
    }
    const endpoint =
      accessibility === Accessibility.Organization
        ? BASE_ENDPOINT
        : `${BASE_ENDPOINT}/actionhub`;
    const request: RobotoAPICall = {
      endpoint: () => endpoint,
      method: "GET",
      orgId: currentOrganization?.org_id,
    };
    void initiateRequest(request).then(({ data }) => {
      if (data?.data !== undefined) {
        setTags(data.data);
      }
    });
  }, [initiateRequest, currentOrganization?.org_id, accessibility]);

  React.useEffect(() => {
    if (onFilterChange) {
      onFilterChange(filters);
    }
  }, [filters, onFilterChange]);

  const onTagClick = React.useCallback((tag: string) => {
    setFilters((prevFilters) => {
      if (prevFilters.includes(tag)) {
        return prevFilters.filter((filter) => filter !== tag);
      }
      return [...prevFilters, tag];
    });
  }, []);

  const onChange = React.useCallback(
    (_event: React.SyntheticEvent, value: string | null) => {
      if (value === null) {
        setFilters(EMPTY_LIST);
        return;
      }

      const tag = data?.data?.find((tag) => tag === value);
      if (tag === undefined) {
        return;
      }

      setFilters(() => [tag]);
    },
    [data?.data],
  );

  const onInputChange = React.useCallback(
    (_event: React.SyntheticEvent, value: string | null) => {
      setSearchBoxInputValue(value ?? "");
      if (data?.data === undefined) {
        return;
      }

      if (!value) {
        setTags(data.data);
        return;
      }

      const tags = data?.data.filter((tag) =>
        tag.toLowerCase().includes(value.toLowerCase()),
      );

      setTags(tags);
    },
    [data?.data],
  );

  const reset = React.useCallback(() => {
    setTags((prevTags) => {
      if (prevTags === data?.data) {
        return prevTags;
      }
      return data?.data ?? EMPTY_LIST;
    });
    setFilters((prevFilters) => {
      if (!prevFilters.length) {
        return prevFilters;
      }
      return EMPTY_LIST;
    });
    setSearchBoxInputValue((prevInputValue) => {
      if (!prevInputValue) {
        return prevInputValue;
      }
      return "";
    });
  }, [data?.data]);

  let errorMessage = null;
  if (error) {
    errorMessage = <Alert severity="error">{error.message}</Alert>;
  }

  const searchFieldInputProps = {
    startAdornment: (
      <InputAdornment
        position="start"
        sx={{
          ml: "3px",
          mr: "5px",
        }}
      >
        <SearchIcon color="disabled" sx={{ fontSize: "1.2rem" }} />
      </InputAdornment>
    ),
    placeholder: "Filter by tags",
    sx: {
      fontSize: "0.85rem",
      height: theme.actionButtonHeight,
    },
  };

  return (
    <Box
      display="flex"
      marginRight="2em"
      minWidth={0}
      overflow={"auto"}
      sx={sx}
    >
      <Autocomplete
        freeSolo
        inputValue={searchBoxInputValue}
        size="small"
        onChange={onChange}
        onInputChange={onInputChange}
        options={data?.data ?? EMPTY_LIST}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, ...searchFieldInputProps }}
          />
        )}
        componentsProps={{
          paper: {
            sx: {
              fontSize: "0.9rem",
            },
          },
        }}
        sx={{
          minWidth: "175px",
          p: 0,
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
            {
              p: 0,
            },
        }}
      />
      {errorMessage && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pl: theme.spacing(1),
            pr: theme.spacing(2),
          }}
        >
          {errorMessage}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(1),
          overflow: "hidden",
        }}
      >
        <TagList
          onClick={onTagClick}
          maxDisplayCount={8}
          selections={filters}
          sx={tagListStyles}
          tags={tags}
          formatHover={true}
        />
        {filters.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RobotoTooltip title="Reset filters">
              <span>
                <IconButton onClick={reset} color="primary" sx={{ p: 0.1 }}>
                  <HighlightOffIcon />
                </IconButton>
              </span>
            </RobotoTooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
}
