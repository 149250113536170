import { NavigationPageWithRedirectProtection } from "@/components";
import { SettingsPage } from "@/features/settings/pages/SettingsPage";

export default function Settings() {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Settings"}>
      <SettingsPage />
    </NavigationPageWithRedirectProtection>
  );
}
