import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { EventRecord } from "@/domain/events";
import { useUpdateEvent } from "@/domain/events/hooks";

import { MetadataForm, MetadataView } from "../../Metadata";
import { RobotoTypography } from "../../RobotoTypography";
import { AutocompleteType } from "../../TagAndMetadataAutocomplete";
import { useEphemeralWorkspaceState, useWorkspaceEvent } from "../WorkspaceCtx";

import styles from "./EventMetadata.module.css";

interface EventMetadataProps {
  eventId: EventRecord["event_id"];
  onError: (error: Error) => void;
}

export function EventMetadata({ eventId, onError }: EventMetadataProps) {
  const eventQuery = useWorkspaceEvent(eventId);
  const updateMetadataMutation = useUpdateEvent();
  const { updateEventInWorkspace } = useEphemeralWorkspaceState();

  const addMetadataToEvent = (key: string, value: string) => {
    updateMetadataMutation
      .mutateAsync({
        id: eventId,
        requestBody: {
          metadata_changeset: {
            put_fields: { [key]: value },
          },
        },
      })
      .then((event: EventRecord) => {
        updateEventInWorkspace(event);
      })
      .catch(onError);
  };

  const removeMetadataFromEvent = (key: string) => {
    updateMetadataMutation
      .mutateAsync({
        id: eventId,
        requestBody: {
          metadata_changeset: {
            remove_fields: [key],
          },
        },
      })
      .then((event: EventRecord) => {
        updateEventInWorkspace(event);
      })
      .catch(onError);
  };

  if (eventQuery.isLoading) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Metadata
        </RobotoTypography>
        <MetadataForm
          metadata={{}}
          onAddMetadata={addMetadataToEvent}
          autocompleteType={AutocompleteType.EventMetadataKeys}
        />
      </section>
    );
  }

  if (eventQuery.isError) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Metadata
        </RobotoTypography>

        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <ErrorOutlineIcon color="error" />
          <RobotoTypography>Failed to load event tags</RobotoTypography>
        </div>
      </section>
    );
  }

  const { metadata } = eventQuery.data;

  return (
    <section>
      <RobotoTypography component={"h3"} className={styles.title}>
        Metadata
      </RobotoTypography>

      <MetadataView
        metadata={metadata}
        onDeleteMetadata={removeMetadataFromEvent}
      />
      <MetadataForm
        metadata={metadata}
        onAddMetadata={addMetadataToEvent}
        autocompleteType={AutocompleteType.EventMetadataKeys}
      />
    </section>
  );
}
