import { NavigationPageWithRedirectProtection } from "@/components";
import { AdminPage } from "@/features/admin";

export default function Settings() {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Admin Console"}>
      <AdminPage />
    </NavigationPageWithRedirectProtection>
  );
}
