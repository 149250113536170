import { UploadableFile } from "./UploadableFile";

export const MAX_FILES_PER_MANIFEST = 500;

export type OnUploadItemBegin = (itemId: string) => void;
export type OnUploadItemBeginCancellation = (itemId: string) => void;
export type OnUploadItemCancelled = (itemId: string) => void;
export type OnUploadItemComplete = (itemId: string) => void;
export type OnUploadItemFirstBytes = (itemId: string) => void;
export type OnUploadItemProgress = (
  itemId: string,
  bytesUploaded: number,
) => void;
export type OnUploadItemError = (itemId: string, error: Error) => void;

export interface UIUploadItem {
  id: string;
  name: string;
  bytesUploaded: number;
  totalBytes: number;
  created: number;
  isCancelling: boolean;
  isCancelled: boolean;
  isUploadCompleted: boolean;
  isUploadStarted: boolean;
  prefix?: string;
  relative_path: string;
  dataset_id: string;
  type: UploadType;
  uploadError?: Error;
}

export class UploadItem {
  id: string;
  name: string;
  totalBytes: number;
  path: string;
  type: UploadType;
  filesToUpload: UploadableFile[];
  orgId: string;
  datasetId: string;
  prefix?: string;

  constructor(
    name: string,
    totalBytes: number,
    path: string,
    type: UploadType,
    filesToUpload: UploadableFile[],
    orgId: string,
    datasetId: string,
    prefix?: string,
  ) {
    this.id = `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    this.name = name;
    this.totalBytes = totalBytes;
    this.path = path;
    this.type = type;
    this.filesToUpload = filesToUpload;
    this.orgId = orgId;
    this.datasetId = datasetId;
    this.prefix = prefix;
  }
}

// The cache key here is the id to the upload item
type UploadItemsCache = Record<string, UIUploadItem>;

export interface UploadProviderState {
  isCancelDialogOpen: boolean;
  isCancelUploadInProgress: boolean;
  isProcessingFiles: boolean;
  isToastVisible: boolean;
  processingError: Error | null;
  uploadItems: UploadItemsCache;
}

export enum UploadType {
  Folder = "folder",
  File = "file",
}

export enum UploadFileActionTypes {
  BeginCancelUpload = "BeginCancelUpload",
  BeginProcessing = "BeginProcessing",
  CloseToastDisplay = "CloseToastDisplay",
  EndProcessingBeginUpload = "EndProcessingBeginUpload",
  ProcessingError = "ProcessingError",
  ReportBytesUploaded = "ReportBytesUploaded",
  ToggleCancelDialog = "ToggleCancelDialog",
  UploadItemBegin = "UploadItemBegin",
  UploadItemError = "UploadItemError",
  UploadItemBeginCancellation = "UploadItemBeginCancellation",
  UploadItemCancelled = "UploadItemCancelled",
  UploadItemComplete = "UploadItemComplete",
  UploadItemFirstBytes = "UploadItemFirstBytes",
  UploadItemProgress = "UploadItemProgress",
}

interface BeginCancelUploadAction {
  type: UploadFileActionTypes.BeginCancelUpload;
}

interface BeginProcessingAction {
  type: UploadFileActionTypes.BeginProcessing;
}

interface CloseToastDisplayAction {
  type: UploadFileActionTypes.CloseToastDisplay;
}

interface EndProcessingBeginUploadAction {
  type: UploadFileActionTypes.EndProcessingBeginUpload;
  payload: {
    uploadItems: UIUploadItem[];
  };
}

interface ProcessingErrorAction {
  type: UploadFileActionTypes.ProcessingError;
  payload: {
    error: Error;
  };
}

interface ReportBytesUploadedAction {
  type: UploadFileActionTypes.ReportBytesUploaded;
  payload: {
    uploadItemKey: string;
    bytesUploaded: number;
  };
}

interface ToggleCancelDialogAction {
  type: UploadFileActionTypes.ToggleCancelDialog;
}

interface UploadItemBeginAction {
  type: UploadFileActionTypes.UploadItemBegin;
  payload: {
    itemId: string;
  };
}

interface UploadItemBeginCancellationAction {
  type: UploadFileActionTypes.UploadItemBeginCancellation;
  payload: {
    itemId: string;
  };
}

interface UploadItemCancelledAction {
  type: UploadFileActionTypes.UploadItemCancelled;
  payload: {
    itemId: string;
  };
}

interface UploadItemCompleteAction {
  type: UploadFileActionTypes.UploadItemComplete;
  payload: {
    itemId: string;
  };
}

interface UploadItemErrorAction {
  type: UploadFileActionTypes.UploadItemError;
  payload: {
    itemId: string;
    error: Error;
  };
}

interface UploadItemFirstBytesAction {
  type: UploadFileActionTypes.UploadItemFirstBytes;
  payload: {
    itemId: string;
  };
}

interface UploadItemProgressAction {
  type: UploadFileActionTypes.UploadItemProgress;
  payload: {
    itemId: string;
    bytesUploaded: number;
  };
}

export type FileUploadAction =
  | BeginCancelUploadAction
  | BeginProcessingAction
  | CloseToastDisplayAction
  | EndProcessingBeginUploadAction
  | ProcessingErrorAction
  | ReportBytesUploadedAction
  | ToggleCancelDialogAction
  | UploadItemBeginAction
  | UploadItemBeginCancellationAction
  | UploadItemCancelledAction
  | UploadItemCompleteAction
  | UploadItemErrorAction
  | UploadItemFirstBytesAction
  | UploadItemProgressAction;
