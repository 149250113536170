import { Alert, Box, Skeleton, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { FileSource } from "@/components";
import { UpdateFileTags } from "@/components/FileMetadataAndTopics/UpdateFileTags";
import { FileRecord } from "@/domain/files";
import { humanFileSize } from "@/utils";

interface FileDetailsProps {
  fileRecord?: FileRecord;
  fileRecordLoading: boolean;
}

export const FileDetails: React.FC<FileDetailsProps> = ({
  fileRecord,
  fileRecordLoading,
}) => {
  const theme = useTheme();

  const SIDEBAR_HEADER_STYLE = {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  };

  let detailContent;

  if (fileRecord) {
    detailContent = (
      <Box>
        <Box mb={theme.spacing(3)}>
          <Typography variant="body2">
            <div style={SIDEBAR_HEADER_STYLE}>Size</div>
            {fileRecord?.size !== undefined && humanFileSize(fileRecord.size)}
          </Typography>
        </Box>

        <Box mb={theme.spacing(3)}>
          <Typography variant="body2">
            <div style={SIDEBAR_HEADER_STYLE}>Last Modified</div>
            {fileRecord?.modified !== undefined && (
              <>{new Date(fileRecord?.modified).toLocaleString()}</>
            )}
          </Typography>
        </Box>

        <Box mb={theme.spacing(3)}>
          <Typography variant="body2">
            <div style={SIDEBAR_HEADER_STYLE}>Source</div>
            {<FileSource fileRecord={fileRecord} />}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            <div style={SIDEBAR_HEADER_STYLE}>Tags</div>
            <Box sx={{ mt: 1 }}>
              <UpdateFileTags fileId={fileRecord.file_id} />
            </Box>
          </Typography>
        </Box>
      </Box>
    );
  } else if (fileRecordLoading) {
    detailContent = (
      <Box>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={"300px"}
          width={"200px"}
        />
      </Box>
    );
  } else {
    detailContent = <Alert severity="error">Failed to load file details</Alert>;
  }

  return detailContent;
};
