import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Box, Collapse, TableCell, TableRow, useTheme } from "@mui/material";
import * as React from "react";

import { Row, TableState } from "@/types";

import { TooltipIconButton } from "../TooltipIconButton";

import { DataGridCell } from "./DataGridCell";
import { RobotoCheckbox } from "./RobotoCheckbox";

interface RobotoTableRowProps {
  table: TableState;
  row: Row;
  rowIdx: number;
  constantHeight: number;
  checkboxWidth: number;
  onRowCheckboxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
  ) => void;
  onRowSingleClick: (rowId: string) => void;
  onRowRightClick?: (
    rowId: string,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  isRowSelectable: boolean;
  isRowExpandable: boolean;
  selectedRows: Set<string>;
  activeRowId?: string;
  secondaryRowId?: string;
  expandableContent?: (rowId: string) => React.ReactNode;
}

export const RobotoTableRow: React.FC<RobotoTableRowProps> = ({
  table,
  row,
  rowIdx,
  constantHeight,
  checkboxWidth,
  onRowCheckboxChange,
  onRowSingleClick,
  onRowRightClick,
  isRowSelectable,
  isRowExpandable,
  selectedRows,
  activeRowId,
  secondaryRowId,
  expandableContent,
}) => {
  const theme = useTheme();
  const [openExpandableRow, setOpenExpandableRow] =
    React.useState<boolean>(false);

  const bgOdd =
    selectedRows.has(row.id) ||
    activeRowId === row.id ||
    secondaryRowId === row.id
      ? "primary.light"
      : theme.palette.background.default;

  const bgEven =
    selectedRows.has(row.id) ||
    activeRowId === row.id ||
    secondaryRowId === row.id
      ? "primary.light"
      : undefined;

  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          borderBottom: "1px solid " + theme.palette.background.default,
          "&:last-child td, &:last-child th": { border: 0 },
          "&:nth-of-type(odd)": {
            backgroundColor: bgOdd,
          },
          backgroundColor: bgEven,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        }}
        onClick={() => {
          onRowSingleClick(row.id);
        }}
        onContextMenu={(e) => {
          if (onRowRightClick) {
            e.preventDefault();
            onRowRightClick(row.id, e);
          }
        }}
      >
        {isRowSelectable && (
          <TableCell
            sx={{
              border: 0,
              height: constantHeight + "px",
              padding: "0px",
              width: checkboxWidth + "px",
              maxWidth: checkboxWidth + "px",
            }}
          >
            <RobotoCheckbox
              sx={{
                ml: theme.spacing(1),
                display: {
                  xs: "none",
                  sm: "inline-flex",
                },
              }}
              checked={selectedRows.has(row.id)}
              onClick={(e) => {
                // Stop the row from being clicked
                e.stopPropagation();
              }}
              onChange={(e) => {
                onRowCheckboxChange(e, rowIdx);
              }}
            />
          </TableCell>
        )}

        {isRowExpandable && expandableContent && (
          <TableCell
            sx={{
              border: 0,
              height: constantHeight + "px",
              padding: "0px",
              width: checkboxWidth + "px",
              maxWidth: checkboxWidth + "px",
            }}
          >
            <Box
              sx={{
                ml: theme.spacing(1),
              }}
            >
              <TooltipIconButton
                title={"Expand"}
                onClick={() => setOpenExpandableRow((prevState) => !prevState)}
                sx={{
                  display: {
                    xs: "none",
                    sm: "inline-flex",
                  },
                  transform: openExpandableRow ? "none" : "rotate(270deg)",
                }}
                color="primary"
              >
                <ExpandCircleDownOutlinedIcon fontSize="small" />
              </TooltipIconButton>
            </Box>
          </TableCell>
        )}

        {row.cells.map((cell, index) => {
          if (table.columnState.length - 1 < index) {
            return null;
          }

          return (
            <DataGridCell
              key={cell.id}
              column={table.columnState[index]}
              constantHeight={constantHeight}
              cell={cell}
            />
          );
        })}
      </TableRow>

      {openExpandableRow && expandableContent && (
        <TableRow>
          <TableCell
            sx={{
              border: 0,
              borderColor: theme.palette.background.default,
              borderTopWidth: "1px",
              borderBottomWidth: "1px",
              padding: theme.spacing(0, 2),
            }}
            // Add 1 to include the chevron column
            colSpan={table.columnState.length + 2}
          >
            <Collapse in={openExpandableRow} timeout="auto" unmountOnExit>
              {expandableContent(row.id)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
