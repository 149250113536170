import { EventRecord } from "@/domain/events";
import { HttpClient, type PaginatedResponse, robotoHeaders } from "@/http";
import { parseTimestampsAsBigInt } from "@/utils/jsonUtils";

import { DatasetRecord, UpdateDatasetRequest } from "./DatasetRecord";

interface Options {
  abortSignal: AbortSignal;
  pageToken: string;
  limit: number;
  resourceOwnerId: string;
  searchParams: URLSearchParams;
}

export class DatasetService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getEventsForDataset(
    datasetId: string,
    options?: Partial<Options>,
  ): Promise<PaginatedResponse<EventRecord>> {
    const searchParams = new URLSearchParams();

    if (options?.pageToken !== undefined) {
      searchParams.append("page_token", options.pageToken);
    }

    if (options?.limit !== undefined) {
      searchParams.append("limit", options.limit.toString());
    }

    const url = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/events`,
      searchParams,
    );

    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
    });

    return await response.json<PaginatedResponse<EventRecord>>(
      parseTimestampsAsBigInt,
    );
  }

  public async getTagsForOrg(options?: Partial<Options>): Promise<string[]> {
    if (!options?.resourceOwnerId) {
      throw Error("getTagsForOrg requires an Org ID, none was provided");
    }

    const requestUrl = this.#httpClient.constructUrl("v1/datasets/tags");
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  public async getMetadataKeysForOrg(
    options?: Partial<Options>,
  ): Promise<string[]> {
    if (!options?.resourceOwnerId) {
      throw Error(
        "getMetadataKeysForOrg requires an Org ID, none was provided",
      );
    }

    const requestUrl = this.#httpClient.constructUrl(
      "v1/datasets/metadata/keys",
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  public async deleteDataset(
    datasetId: string,
    options?: Partial<Options>,
  ): Promise<void> {
    if (!options?.resourceOwnerId) {
      throw Error("deleteDataset requires an Org ID, none was provided");
    }

    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}`,
    );
    const response = await this.#httpClient.delete(requestUrl, {
      idempotent: false,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    await response.throwIfError();
  }

  public async getDataset(
    datasetId: string,
    options?: Partial<Options>,
  ): Promise<DatasetRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}`,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
    });

    return await response.json<DatasetRecord>();
  }

  public async updateDataset(
    datasetId: string,
    updates: UpdateDatasetRequest,
    options?: Partial<Options>,
  ): Promise<DatasetRecord> {
    if (!options?.resourceOwnerId) {
      throw Error("updateDataset requires an Org ID, none was provided");
    }

    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}`,
    );

    const response = await this.#httpClient.put(requestUrl, {
      idempotent: true,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
      body: JSON.stringify(updates),
    });

    return response.json<DatasetRecord>();
  }
}
