import * as React from "react";

import { PlotTool } from "../plotTools/PlotTool";

import { PlotPanelContext } from "./PlotPanelContext";

export function PlotPanelProvider({ children }: React.PropsWithChildren) {
  const [activeTool, setActiveTool] = React.useState<PlotTool>(PlotTool.Move);

  return (
    <PlotPanelContext.Provider
      value={{
        activeTool,
        setActiveTool,
      }}
    >
      {children}
    </PlotPanelContext.Provider>
  );
}
