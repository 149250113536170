/**
 * A file that can be uploaded.
 * Necessary because of browser differences in the File API (whether File::relativePath is set)
 * compounded by different entrypoints to file upload in our application (drag and drop, file input).
 */
export class UploadableFile {
  public readonly file: File;
  public readonly relativePath: string;
  public readonly uploadItemKey: string;
  constructor(file: File, relativePath: string, uploadItemKey: string) {
    this.file = file;
    this.relativePath = relativePath;
    this.uploadItemKey = uploadItemKey;
  }

  public get name(): string {
    return this.file.name;
  }

  public get size(): number {
    return this.file.size;
  }
}
