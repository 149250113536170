import { useQuery, UseQueryResult } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { QueryRecord } from "../QueryRecord";

export function useQueryRecord(
  queryId: string | undefined,
  ttlMillis = 1000,
): UseQueryResult<QueryRecord, Error> {
  const { queryService } = React.useContext(DomainServicesContext);

  return useQuery<QueryRecord, Error>({
    queryKey: ["queryRecord", queryId],
    queryFn: ({ signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryRecord(queryId, {
        abortSignal: signal,
      });
    },
    enabled: queryId !== undefined,
    staleTime: ttlMillis,
  });
}
