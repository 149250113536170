import { Box, Checkbox, Paper, useTheme } from "@mui/material";

import { RobotoTypography } from "@/components";

interface DataRegionCardProps {
  emoji: string;
  selected: boolean;
  title: string;
  regionSelected: () => void;
}

export const DataRegionCard: React.FC<DataRegionCardProps> = ({
  emoji,
  selected,
  title,
  regionSelected,
}) => {
  const theme = useTheme();

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <RobotoTypography sx={{ marginRight: theme.spacing(2) }}>
          {emoji}
        </RobotoTypography>
        <RobotoTypography variant="h6" textAlign={"center"}>
          {title}
        </RobotoTypography>
      </Box>

      <Checkbox checked={selected} onChange={regionSelected} />
    </Paper>
  );
};
