import { useEffect } from "react";
import * as React from "react";

import { MainContent } from "./MainContent";

export interface PageProps {
  title: string;
  children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({ title, children }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <MainContent>{children}</MainContent>
    </>
  );
};
