import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { STALE_QUERY_TIMEOUT_MSEC } from "./constants";

export function useEventNames(orgId: string): UseQueryResult<string[], Error> {
  const { events } = useContext(DomainServicesContext);

  return useQuery({
    queryKey: ["orgEventNames", orgId],
    queryFn: ({ signal }) => {
      return events.getNamesForOrg({
        abortSignal: signal,
        resourceOwnerId: orgId,
      });
    },
    staleTime: STALE_QUERY_TIMEOUT_MSEC,
  });
}
