import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

import { FileRecord } from "@/domain/files";

interface TextContentProps {
  fileRecord: FileRecord;
  signedUrl: URL | undefined;
}

export const TextContent: FC<TextContentProps> = ({
  fileRecord,
  signedUrl,
}) => {
  const theme = useTheme();

  const contentsQuery = useQuery({
    queryKey: ["contents", fileRecord.file_id],
    queryFn: ({ signal }) => {
      if (!signedUrl) {
        return;
      }
      return fetch(signedUrl, { signal })
        .then((response) => response.text())
        .catch((reason: Error) => {
          throw new Error(reason.message);
        });
    },
    enabled: signedUrl !== undefined,
  });

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      {contentsQuery.isLoading ? (
        <Box
          sx={{
            padding: theme.spacing(3),
            backgroundColor: theme.palette.code.main,
            minHeight: "100%",
            height: "100%",
          }}
        >
          <CircularProgress size="1.75rem" />
        </Box>
      ) : (
        <Typography
          variant="body2"
          component={"span"}
          sx={{
            fontSize: "0.75rem",
            wordBreak: "break-all",
            height: "100%",
          }}
        >
          <pre
            style={{
              padding: theme.spacing(3),
              whiteSpace: "pre-wrap",
              backgroundColor: theme.palette.code.main,
              minHeight: "100%",
            }}
          >
            {contentsQuery.data}
          </pre>
        </Typography>
      )}
    </Box>
  );
};
