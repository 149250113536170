import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/components";
import { useTopicById } from "@/domain/topics/hooks/useTopic";

import styles from "./Scopes.module.css";

interface AssociatedTopicProps {
  topicId: string;
}

export function AssociatedTopic({ topicId }: AssociatedTopicProps) {
  const topicQuery = useTopicById(topicId);

  if (topicQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }

  if (topicQuery.isError) {
    return (
      <span className={styles.container}>
        <ErrorOutlineIcon color="error" className={styles.icon} />
        <RobotoTypography component={"span"} className={styles.text}>
          Failed to load topic
        </RobotoTypography>
      </span>
    );
  }

  return (
    <span className={styles.container}>
      <AccountTreeOutlinedIcon className={styles.icon} />
      <RobotoTypography
        component={"span"}
        className={styles.text}
        title={topicQuery.data.topic_name}
      >
        {topicQuery.data.topic_name}
      </RobotoTypography>
    </span>
  );
}
