import { HttpClient, robotoHeaders, type PaginatedResponse } from "@/http";
import { parseTimestampsAsBigInt } from "@/utils/jsonUtils";

import { Association, type AssociationRecord } from "../association";

import {
  MessagePathRecord,
  type AllTopicKeysRecord,
  type TopicRecord,
} from "./TopicRecord";

export class TopicService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getTopicsByAssociation(
    association: AssociationRecord,
    options?: Partial<{
      abortSignal: AbortSignal;
      pageToken: string;
      resourceOwnerId: string;
    }>,
  ): Promise<TopicRecord[]> {
    const assoc = new Association(association);
    const paginatedResponse = await this.getPagedTopicsByAssociation(
      assoc,
      options,
    );
    const topicRecords = [...paginatedResponse.items];

    let nextPageToken = paginatedResponse.next_token;
    while (nextPageToken) {
      const nextPaginatedResponse = await this.getPagedTopicsByAssociation(
        assoc,
        { ...options, pageToken: nextPageToken },
      );
      topicRecords.push(...nextPaginatedResponse.items);
      nextPageToken = nextPaginatedResponse.next_token;
    }

    return topicRecords;
  }

  public async getTopicByNameAndAssociation(
    topicName: string,
    association: AssociationRecord,
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<TopicRecord> {
    const encodedAssociation = new Association(association).urlEncode();
    const encodedTopicName = encodeURIComponent(topicName);
    const url = this.#httpClient.constructUrl(
      `v1/topics/association/${encodedAssociation}/name/${encodedTopicName}`,
    );
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return response.json<TopicRecord>(parseTimestampsAsBigInt);
  }

  public async getTopicById(
    topicId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<TopicRecord> {
    const url = this.#httpClient.constructUrl(`v1/topics/id/${topicId}`);
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
    });
    return response.json<TopicRecord>(parseTimestampsAsBigInt);
  }

  public async getTopicNamesForOrg(
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<string[]> {
    if (!options?.resourceOwnerId) {
      throw Error("getTagsForOrg requires an org ID, none was provided");
    }

    const requestUrl = this.#httpClient.constructUrl(
      "v1/topics/unique/topic_names",
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    return response.json<string[]>();
  }

  public async getAllTopicKeysForOrg(
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<AllTopicKeysRecord> {
    if (!options?.resourceOwnerId) {
      throw Error("getTagsForOrg requires an org ID, none was provided");
    }

    const requestUrl = this.#httpClient.constructUrl(
      "v1/topics/unique/topic_names",
    );
    const response = this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    const requestUrl2 = this.#httpClient.constructUrl(
      "v1/topics/unique/topic_message_paths",
    );
    const response2 = this.#httpClient.get(requestUrl2, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    const requestUrl3 = this.#httpClient.constructUrl(
      "v1/topics/unique/topic_message_path_metadata_keys",
    );
    const response3 = this.#httpClient.get(requestUrl3, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    const [
      topic_names_response,
      message_paths_response,
      metadata_keys_response,
    ] = await Promise.all([response, response2, response3]);

    const topic_names = await topic_names_response.json<string[]>();
    const message_paths = await message_paths_response.json<string[]>();
    const message_path_metadata_keys =
      await metadata_keys_response.json<string[]>();

    return {
      topic_names,
      message_paths,
      message_path_metadata_keys,
    };
  }

  private async getPagedTopicsByAssociation(
    association: Association,
    options?: Partial<{
      abortSignal: AbortSignal;
      pageToken: string;
      resourceOwnerId: string;
    }>,
  ): Promise<PaginatedResponse<TopicRecord>> {
    const encodedAssociation = association.urlEncode();
    const searchParams = new URLSearchParams();
    if (options?.pageToken !== undefined) {
      searchParams.append("page_token", options.pageToken);
    }
    const url = this.#httpClient.constructUrl(
      `v1/topics/association/${encodedAssociation}`,
      searchParams,
    );
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return response.json<PaginatedResponse<TopicRecord>>(
      parseTimestampsAsBigInt,
    );
  }

  public async getMessagePathById(
    messagePathId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<MessagePathRecord> {
    const url = this.#httpClient.constructUrl(
      `v1/topics/message-path/id/${messagePathId}`,
    );
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
    });
    return response.json<MessagePathRecord>();
  }
}
