import { Box, useTheme } from "@mui/material";
import * as React from "react";

import {
  Annotation,
  Annotations,
  ImageAnnotation,
  ImageSegmentations,
} from "@/components/Annotations";
import { AnnotationViewFilters } from "@/components/Annotations/types";
import { FileRecord } from "@/domain/files";
import { basename } from "@/utils";

interface ImageContentProps {
  fileRecord: FileRecord;
  signedUrl: URL | undefined;
  annotationData?: Annotations;
  annotationViewFilters?: AnnotationViewFilters;
}

export const ImageContent: React.FC<ImageContentProps> = ({
  fileRecord,
  signedUrl,
  annotationData,
  annotationViewFilters,
}) => {
  const theme = useTheme();
  const [showAnnotations, setShowAnnotations] = React.useState(false);
  const [showImage, setShowImage] = React.useState(false);

  /**
   * Note: (Pratik)
   * This is a heuristic to make subsequent image loads more seamless.
   * We assume that the next image loaded is the same aspect ratio as
   * the previous one so we optimistically display a loading skeleton with
   * this aspect ratio.
   *
   * A better approach is to obtain image width and height
   * information when fetching the image file from the server.
   */
  const [aspectRatio, setAspectRatio] = React.useState("3 / 2");

  const annotationsForImage = React.useMemo(() => {
    const annotations = annotationData;
    let annotationsForFile: Annotation[] = [];
    if (annotations && annotations.images) {
      // Extract detections for the current image frame
      annotationsForFile =
        annotations.images[basename(fileRecord.relative_path)] || [];
    }
    return annotationsForFile;
  }, [fileRecord, annotationData]);

  const confidenceThreshold =
    annotationViewFilters?.confidenceThreshold.percent;

  React.useEffect(() => {
    if (!signedUrl) {
      // Clear annotations while url is loading
      setShowAnnotations(false);
    }
  }, [signedUrl]);

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Box
        sx={{
          aspectRatio: aspectRatio,
          backgroundColor:
            theme.palette.mode === "dark" ? "#202020" : "#d6d6d6",
          position: "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: "100%",
          margin: "auto",
          transition: "aspect-ratio 0.25s ease",
        }}
        onTransitionEnd={() => {
          // Display annotations after the aspect-ratio animation
          setShowAnnotations(true);
        }}
      >
        {showAnnotations && signedUrl && (
          <>
            {annotationViewFilters &&
              confidenceThreshold !== undefined &&
              annotationsForImage.map((annotation, idx) => {
                if (
                  annotation.confidence !== undefined &&
                  annotation.confidence >= confidenceThreshold / 100
                ) {
                  return (
                    <ImageAnnotation
                      key={idx}
                      annotation={annotation}
                      annotationViewFilters={annotationViewFilters}
                    />
                  );
                }
              })}
            {confidenceThreshold !== undefined &&
              annotationViewFilters?.segmentation.show && (
                <ImageSegmentations
                  annotations={annotationsForImage}
                  confidenceThreshold={confidenceThreshold}
                />
              )}
          </>
        )}
      </Box>
      {signedUrl && (
        <img
          style={{
            display: "block",
            position: "absolute",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            opacity: showImage ? 1 : 0,
            transition: "opacity 0.5s ease-out",
          }}
          // loading = lazy is not strictly necessary because we only have one image to show but can help for future proofing
          loading="lazy"
          onLoad={(image) => {
            setShowImage(true);

            const newAspectRatio = `${image.currentTarget.naturalWidth} / ${image.currentTarget.naturalHeight}`;
            if (newAspectRatio !== aspectRatio) {
              setAspectRatio(newAspectRatio);
            } else {
              setShowAnnotations(true);
            }
          }}
          alt={fileRecord.relative_path}
          src={signedUrl?.toString()}
        />
      )}
    </Box>
  );
};
