import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";
import { FC } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export const RobotoLinkHoverUnderline: FC<MUILinkProps & RouterLinkProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <MUILink
      component={RouterLink}
      underline="hover"
      sx={{
        cursor: "pointer",
        wordBreak: "break-all",
        ...sx,
      }}
      {...props}
    >
      {children}
    </MUILink>
  );
};
