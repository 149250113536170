import { useMutation, UseMutationResult } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

type DeleteDirectoryMutationArgs = {
  directoryPaths: string[];
  datasetId: string;
};

export function useDeleteDirectoryMutation(): UseMutationResult<
  void,
  unknown,
  DeleteDirectoryMutationArgs
> {
  const { files } = React.useContext(DomainServicesContext);
  return useMutation<void, unknown, DeleteDirectoryMutationArgs>({
    mutationFn: async ({ directoryPaths, datasetId }) => {
      await files.deleteDirectories(datasetId, directoryPaths);
    },
  });
}
