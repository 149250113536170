import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, IconButton } from "@mui/material";
import { alpha } from "@mui/material/styles";
import classNames from "classnames";
import * as React from "react";

import { useDebouncedCallback } from "@/hooks";
import {
  type PlotPanelState,
  actions,
  VizStateDispatchContext,
} from "@/state/visualization";

import { formatMessagePath } from "../../message";

import { colorForSeries } from "./colorScale";
import styles from "./Legend.module.css";

interface LegendProps {
  hide?: boolean;
  onConfigureSeries?: (seriesId: string) => void;
  state: PlotPanelState;
}

export function Legend({ hide, onConfigureSeries, state }: LegendProps) {
  const vizStateDispatch = React.useContext(VizStateDispatchContext);

  const changeSeriesColor = useDebouncedCallback(function setSeriesColor(
    seriesId: string,
    color: string,
  ) {
    vizStateDispatch(
      actions.setSeriesStyle(state.id, seriesId, { lineColor: color }),
    );
  }, 15);

  const removeSeries = (seriesId: string) => {
    vizStateDispatch(actions.removeSeriesFromPlotPanel(state.id, seriesId));
  };

  const setSeriesVisibility = (seriesId: string, visible: boolean) => {
    vizStateDispatch(actions.setSeriesVisibility(state.id, seriesId, visible));
  };

  if (hide === true || state.data.length === 0) {
    return null;
  }

  const overflow =
    state.data.length > 5 ? (
      <li key="overflow" className={styles.legendItem}>
        ...and others
      </li>
    ) : null;
  const series = state.data.slice(0, 5);

  return (
    <Box
      className={styles.container}
      component="ul"
      bgcolor={(theme) => alpha(theme.palette.paper.main, 0.8)}
    >
      {series.map((seriesItem) => {
        const lineColor = colorForSeries(seriesItem);
        const formattedMessagePath = formatMessagePath(
          seriesItem.data.messagePath.parts,
        );
        return (
          <li className={styles.legendItem} key={seriesItem.id}>
            <div className={styles.legendItemLabel}>
              <input
                className={styles.legendItemColor}
                type="color"
                value={lineColor}
                name={seriesItem.id}
                onChange={(event) => {
                  changeSeriesColor(seriesItem.id, event.target.value);
                }}
              />
              <p
                role="button"
                tabIndex={0}
                aria-label="Toggle visibility of series"
                className={classNames({
                  [styles.hidden]: !seriesItem.visible,
                })}
                onClick={function showOrHideSeries(event) {
                  // Prevent triggering the other chart onclick handlers
                  event.stopPropagation();

                  setSeriesVisibility(seriesItem.id, !seriesItem.visible);
                }}
              >
                {`${seriesItem.data.topic.name}.${formattedMessagePath}`}
              </p>
            </div>
            <div>
              <IconButton
                className={styles.iconButton}
                onClick={function showSettings() {
                  onConfigureSeries?.(seriesItem.id);
                }}
              >
                <SettingsIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                className={styles.iconButton}
                onClick={function removeSeriesFromView() {
                  removeSeries(seriesItem.id);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          </li>
        );
      })}
      {overflow}
    </Box>
  );
}
