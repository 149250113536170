export enum View {
  CardGrid = "grid",
  Table = "table",
}

export enum Capability {
  // Actions are invocable from this view
  Invoke = "invoke",

  // Actions are deletable from this view
  Delete = "delete",
}
