import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

export const RobotoTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const RobotoTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.foreground.main,
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
