import { Alert, Box, useTheme } from "@mui/material";
import * as React from "react";

import { AlertDialog } from "@/components";
import { OrgRecord } from "@/domain/orgs";

import { RepoTable } from "./image/RepoTable";

interface ImagesProps {
  currentOrg: OrgRecord | null;
}

export const Images: React.FC<ImagesProps> = ({ currentOrg }) => {
  const theme = useTheme();

  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);
  const [alertDialogText, setAlertDialogText] = React.useState<string>("");
  const [alertDialogAction, setAlertDialogAction] =
    React.useState<() => Promise<void>>();

  return (
    <>
      <Box sx={{ mb: theme.spacing(3) }}>
        <Alert severity="info">
          Images are Docker containers from public or private registries that
          contain all of the code for an action. The table below shows images
          uploaded to Roboto&apos;s private registry.
        </Alert>
      </Box>
      <RepoTable
        currentOrg={currentOrg}
        setAlertDialogText={setAlertDialogText}
        setAlertDialogAction={setAlertDialogAction}
        setAlertDialogOpen={setAlertDialogOpen}
      />
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle={"Delete Image"}
        dialogText={alertDialogText}
        dialogAction={alertDialogAction}
      />
    </>
  );
};
