import * as React from "react";

import { useAuth } from "@/providers";

type SystemUserOnlyProps = {
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

/**
 * Renders children only if the current user is a system user.
 * If the current user is not a system user, the fallback is rendered,
 * or `null` if no fallback is provided.
 */
export const SystemUserOnly: React.FC<SystemUserOnlyProps> = ({
  children,
  fallback = null,
}) => {
  const { currentUser } = useAuth();
  if (!currentUser?.is_system_user) {
    return fallback;
  }

  return children;
};
