import {
  Box,
  SxProps,
  Typography,
  TypographyVariant,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

interface UserLinkProps {
  userId: string;
  component?: React.ElementType;
  linkSx?: SxProps;
  variant?: TypographyVariant;
  wrapperSx?: SxProps;
}

const TEXT_BASE_SX = {
  cursor: "pointer",
  fontVariant: "none",
  textDecoration: "none",
};

export function UserLink({
  userId,
  component = "span",
  linkSx,
  variant = "body2",
  wrapperSx,
}: UserLinkProps) {
  const theme = useTheme();

  if (!userId) {
    return null;
  }

  const encodedId = encodeURIComponent(userId);

  const href = `/users/${encodedId}`;

  const wrapperStyle = {
    "& a": {
      textDecorationColor: theme.palette.primary.main,
    },
    "& a:not(:hover)": {
      textDecoration: "none",
    },
    ...wrapperSx,
  };

  const textStyle = linkSx ? { ...TEXT_BASE_SX, ...linkSx } : TEXT_BASE_SX;

  return (
    <Box component="span" color={theme.palette.primary.main} sx={wrapperStyle}>
      <Link to={href} onClick={(e) => e.stopPropagation()}>
        <Typography
          component={component}
          title={component === "abbr" ? userId : undefined}
          color={theme.palette.primary.main}
          fontSize={theme.typography.body2.fontSize}
          sx={textStyle}
          variant={variant}
        >
          {userId}
        </Typography>
      </Link>
    </Box>
  );
}
