import { Box, Paper, Typography, useTheme } from "@mui/material";
import * as React from "react";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

function round(number: number, places: number) {
  return +(
    Math.round((number + "e+" + places) as unknown as number) +
    "e-" +
    places
  );
}

export interface ChartTooltipFrameProps {
  children: React.ReactNode;
}

export const ChartTooltipFrame: React.FC<ChartTooltipFrameProps> = ({
  children,
}) => {
  const theme = useTheme();

  return (
    <Box
      component={Paper}
      variant="outlined"
      sx={{
        borderWidth: "1px",
        borderColor: theme.border.color,
        borderRadius: "0.5rem",
        backgroundColor: theme.palette.foreground.main,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        textAlign: "left",
        boxShadow:
          "0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1)",
      }}
    >
      {children}
    </Box>
  );
};

export interface ChartTooltipRowProps {
  value: string;
  name: string;
  fill: string;
}

export const ChartTooltipRow: React.FC<ChartTooltipRowProps> = ({
  value,
  name,
  fill,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            marginRight: theme.spacing(1),
            width: "0.75rem",
            height: "0.75rem",
            flexShrink: 0,
            borderRadius: "9999px",
            borderWidth: "2px",
            borderColor: "#FFFFFF",
            backgroundColor: fill,
            boxShadow:
              "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
          }}
        />
        <Typography
          variant="body2"
          sx={{
            textAlign: "right",
            whiteSpace: "nowrap",
            color: theme.palette.text.secondary,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          {name}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.text.primary,
          fontWeight: 500,
          textAlign: "right",
          whiteSpace: "nowrap",
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export interface ChartTooltipProps {
  active: boolean | undefined;
  payload: Payload<ValueType, NameType>[] | undefined;
  label: string;
  fill: string;
}

const ChartTooltip = ({ active, payload, label, fill }: ChartTooltipProps) => {
  const theme = useTheme();

  if (active && payload) {
    return (
      <ChartTooltipFrame>
        <Box
          sx={{
            pt: "0.5rem",
            pb: "0.5rem",
            pl: "1rem",
            pr: "1rem",
            border: "1px solid #e5e6eb",
            borderColor: theme.border.color,
            borderWidth: "0px",
            borderBottomWidth: "1px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: theme.palette.text.primary,
            }}
          >
            {label}
          </Typography>
        </Box>

        <Box
          sx={{
            pt: "0.5rem",
            pb: "0.5rem",
            pl: "1rem",
            pr: "1rem",
          }}
        >
          {payload.map(
            ({ value, name }: Payload<ValueType, NameType>, idx: number) => (
              <ChartTooltipRow
                key={`id-${idx}`}
                value={round(value as number, 3).toString()}
                name={name as string}
                fill={fill}
              />
            ),
          )}
        </Box>
      </ChartTooltipFrame>
    );
  }
  return null;
};

export default ChartTooltip;
