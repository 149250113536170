import ColorHash from "color-hash";
import * as React from "react";

import { Annotation } from "./schema";

const colorHash = new ColorHash();

interface ImageSegmentationsProps {
  annotations: Annotation[];
  confidenceThreshold: number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ImageSegmentations: React.FC<ImageSegmentationsProps> = ({
  annotations,
  confidenceThreshold,
  onClick,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx?.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < annotations.length; i++) {
        const { confidence } = annotations[i];
        if (
          confidence !== undefined &&
          confidence >= confidenceThreshold / 100
        ) {
          createSegmentationImage(annotations[i], canvas);
        }
      }
    }
  }, [annotations, canvasRef, confidenceThreshold]);

  function createSegmentationImage(
    segmentationData: Annotation,
    canvas: HTMLCanvasElement,
  ) {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    if (!segmentationData.segments) {
      return;
    }

    const label = segmentationData.name.replace(/\b\w/g, function (l) {
      return l.toUpperCase();
    });
    const labelColor = colorHash.rgb(label || "");
    const labelColorStr = "rgba(" + labelColor.toString() + ",0.5)";
    ctx.fillStyle = labelColorStr;

    // Draw the mask based on the segmentation data
    for (let i = 0; i < segmentationData.segments.x.length; i++) {
      const x = segmentationData.segments.x[i] * canvas.width;
      const y = segmentationData.segments.y[i] * canvas.height;

      if (i === 0) {
        ctx.beginPath();
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    }

    ctx.closePath();
    ctx.fill();
  }

  return (
    <canvas
      ref={canvasRef}
      width={2000}
      height={2000}
      style={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
      }}
      onClick={onClick}
    ></canvas>
  );
};
