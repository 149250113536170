import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";

export const useActiveInvocations = (datasetId: string) => {
  const { currentOrganization } = useAuth();
  const { actionService } = useContext(DomainServicesContext);

  return useQuery({
    queryKey: ["nonTerminalInvocations", datasetId],
    queryFn: async ({ signal }) => {
      if (currentOrganization === null) {
        return Promise.reject(new Error("CurrentOrganization is not set"));
      }

      return await actionService.queryActiveInvocationsForDataset(
        currentOrganization.org_id,
        datasetId,
        signal,
      );
    },
    refetchInterval: 5000,
  });
};
