import { SidebarViewType, useWorkspaceSidebar } from "../../sidebarContext";

import styles from "./Annotations.module.css";
import { RenderedAnnotation } from "./messaging";
import { Extents } from "./PlotRenderer";

interface AnnotationsProps {
  annotations: RenderedAnnotation[];
  chartExtents: Extents | null;
}

// Clips the annotation to be within the bounds of the chart when necessary
function clipAnnotation(
  annotation: RenderedAnnotation,
  chartExtents: Extents,
): RenderedAnnotation {
  const {
    pixel: { left: chartLeft, width: chartWidth },
  } = chartExtents;

  const annotationLeft = Math.max(annotation.pixel.x, chartLeft);
  const annotationRight = Math.min(
    chartLeft + chartWidth,
    annotation.pixel.x + annotation.pixel.width,
  );

  return {
    ...annotation,
    pixel: {
      ...annotation.pixel,
      x: annotationLeft,
      width: annotationRight - annotationLeft,
    },
  };
}

export function Annotations({ annotations, chartExtents }: AnnotationsProps) {
  const sidebar = useWorkspaceSidebar();

  if (chartExtents === null) {
    return null;
  }

  return annotations.map((annotation) => {
    const clippedAnnotation = clipAnnotation(annotation, chartExtents);
    return (
      <div
        key={annotation.id}
        className={styles.annotation}
        style={{
          left: `${clippedAnnotation.pixel.x}px`,
          width: `${clippedAnnotation.pixel.width}px`,
          bottom: `${chartExtents.pixel.bottom}px`,
          backgroundColor: annotation.color,
        }}
        onClick={function onClickAnnotation() {
          if (annotation.type === "event") {
            sidebar.openView({
              type: SidebarViewType.EventDetails,
              data: {
                eventId: annotation.id,
              },
            });
          }
        }}
      ></div>
    );
  });
}
