import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { FileNode, FileSystemNode, DirectoryNode } from "@/types";

import { DirectoryRecord } from "../FileRecord";

export function useDirectoryContents(
  directoryPath: string,
  datasetId: string,
  resourceOwnerId?: string,
  pageSize: number = 25,
  nextToken?: string,
  searchTerm?: string,
  showHiddenFiles: boolean = false,
  extensions?: string[],
) {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery<
    { items: FileSystemNode[]; nextToken: string | undefined },
    Error
  >({
    queryKey: [
      "directoryContents",
      directoryPath,
      datasetId,
      nextToken,
      pageSize,
      searchTerm,
      showHiddenFiles,
      extensions,
    ],
    queryFn: async ({ signal }) => {
      if (searchTerm) {
        const data = await files.getFilesForDirectory({
          path: directoryPath,
          fileNameSearchTerm: searchTerm,
          datasetId,
          pageSize,
          nextToken,
          showHiddenFiles,
          extensions,
          options: {
            abortSignal: signal,
            resourceOwnerId,
          },
        });

        const items: FileNode[] = data.items.map((item) => {
          return {
            type: "file",
            name: item.relative_path.split("/").slice(-1)[0],
            file: item,
          };
        });

        return {
          items,
          nextToken: data.next_token ?? undefined,
        };
      }

      const directoryContents = await files.getItemsForDirectory({
        directoryPath,
        datasetId,
        pageSize,
        nextToken,
        showHiddenFiles,
        extensions,
        options: {
          abortSignal: signal,
          resourceOwnerId,
        },
      });

      const directories: DirectoryNode[] = directoryContents.directories.map(
        (item) => {
          const strippedSlashPath = directoryPath.replace(/^\/+|\/+$/g, "");

          const dir: DirectoryRecord = {
            association_id: datasetId,
            name: item.name,
            relative_path: `${strippedSlashPath}/${item.name}`,
          };

          return {
            type: "directory",
            name: item.name,
            directory: dir,
          };
        },
      );

      const fileItems: FileNode[] = directoryContents.files.map((item) => {
        return {
          type: "file",
          name: item.relative_path.split("/").slice(-1)[0],
          file: item,
        };
      });

      const items = [...directories, ...fileItems];

      items.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
        });
      });

      return {
        items,
        nextToken: directoryContents.next_token ?? undefined,
      };
    },
    retry: false,
  });
}
