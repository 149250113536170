import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

export function useDirectoryExtensions(
  directoryPath: string,
  datasetId: string,
  resourceOwnerId?: string,
) {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery<string[], Error>({
    queryKey: ["directoryExtensions", directoryPath, datasetId],
    queryFn: async ({ signal }) => {
      const extensions = await files.getExtensionsForDirectory(
        datasetId,
        directoryPath,
        {
          abortSignal: signal,
          resourceOwnerId,
        },
      );

      return extensions;
    },
  });
}
