import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";

import { AlertDialog, RobotoButton } from "@/components";
import { TriggerRecord } from "@/domain/actions/TriggerRecord";
import { OrgRecord } from "@/domain/orgs";
import { useLazyAPICall } from "@/service/apiHooks";
import { triggerEndpoint } from "@/types";

interface DeleteTriggerButtonProps {
  trigger: TriggerRecord;
  onDelete: (action: TriggerRecord) => void;
  org: OrgRecord;
}

/**
 * Deletes a trigger after confirmation.
 */
export default function DeleteTriggerButton({
  trigger,
  onDelete,
  org,
}: DeleteTriggerButtonProps) {
  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);

  const { initiateRequest } = useLazyAPICall();

  const dialogAction = React.useCallback(
    async function deleteTrigger() {
      const { error } = await initiateRequest({
        endpoint: triggerEndpoint,
        method: "DELETE",
        orgId: org?.org_id,
        pathParams: { name: trigger.name },
      });

      if (!error) {
        onDelete(trigger);
      }
    },
    [trigger, initiateRequest, onDelete, org?.org_id],
  );

  return (
    <>
      <RobotoButton
        color="error"
        eventName={"DeleteTriggerClicked"}
        eventProperties={{
          triggerId: trigger.trigger_id,
        }}
        onClick={() => {
          setAlertDialogOpen(true);
        }}
        startIcon={<DeleteIcon />}
        variant="outlined"
        size="small"
      >
        Delete
      </RobotoButton>
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle="Delete Trigger"
        dialogText={`Are you sure you want to delete '${trigger.name}'?`}
        dialogAction={dialogAction}
      />
    </>
  );
}
