import BoltIcon from "@mui/icons-material/Bolt";
import { SxProps, Theme } from "@mui/material";
import * as React from "react";

import { RobotoButton } from "@/components";
import { ActionRecord, ActionReference } from "@/domain/actions";
import { useAuth, useNavigation } from "@/providers";

export interface InvokeActionButtonProps {
  action?: ActionRecord;
  datasetId?: string;
  sx?: SxProps<Theme>;
}

export default function InvokeActionButton({
  action,
  datasetId,
  sx,
}: InvokeActionButtonProps) {
  const { currentOrganization } = useAuth();
  const { goto } = useNavigation();
  const onClick = React.useCallback(() => {
    let actionReference: ActionReference | undefined = undefined;
    if (action) {
      actionReference = {
        name: action.name,
      };
      if (currentOrganization?.org_id !== action.org_id) {
        actionReference.owner = action.org_id;
      }
    }
    goto.invokeAction({
      action: actionReference,
      datasetId,
    });
  }, [currentOrganization?.org_id, action, datasetId, goto]);

  return (
    <RobotoButton
      eventName={"InvokeActionClicked"}
      eventProperties={{
        name: action?.name ?? "",
      }}
      variant={"outlined"}
      size="small"
      color="secondary"
      startIcon={<BoltIcon />}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      disabled={action && !action?.uri}
      sx={{
        minWidth: 80,
        ...sx,
      }}
    >
      Invoke
    </RobotoButton>
  );
}
