import { TopicRecord } from "@/domain/topics";

export interface TimeBounds {
  earliest?: bigint;
  latest?: bigint;
  difference?: bigint;
}

// We might want to write this as metadata to the FileRecord
// during ingestion instead of needing to calculate it here
export function determineTimeBounds(topics: TopicRecord[]): TimeBounds {
  if (topics.length === 0) {
    return { earliest: undefined, latest: undefined };
  }

  const { earliest, latest } = topics.reduce(
    (acc, topic) => {
      const cStartTime = topic.start_time;
      const cEndTime = topic.end_time;

      let { earliest, latest } = acc;

      // Check if the current start time is less than the current earliest
      if (
        cStartTime !== null &&
        (earliest === undefined || cStartTime < earliest)
      ) {
        earliest = cStartTime;
      }

      // Check if the current end time is greater than the current latest
      if (cEndTime !== null && (latest === undefined || cEndTime > latest)) {
        latest = cEndTime;
      }

      return { earliest, latest };
    },
    // Initialize earliest and latest as undefined
    {
      earliest: undefined as bigint | undefined,
      latest: undefined as bigint | undefined,
    },
  );

  // If either is still undefined, return them without difference
  if (earliest === undefined || latest === undefined) {
    return { earliest, latest };
  }

  const difference: bigint = latest - earliest;

  return { earliest, latest, difference };
}
