import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

interface MutateFileTagProps {
  fileId: string;
  putTags?: string[];
  removeTags?: string[];
}

interface TagsChangeSet {
  metadata_changeset: {
    put_tags?: string[];
    remove_tags?: string[];
  };
}

interface MutateTagsResponse {
  fileId: string;
  tags: string[];
}

export function useMutateFileTags() {
  const { files } = React.useContext(DomainServicesContext);
  const queryClient = useQueryClient();

  return useMutation<MutateTagsResponse, Error, MutateFileTagProps>({
    mutationFn: async ({ fileId, putTags, removeTags }) => {
      const body: TagsChangeSet = {
        metadata_changeset: {},
      };

      if (putTags !== undefined) {
        body.metadata_changeset.put_tags = putTags;
      }

      if (removeTags !== undefined) {
        body.metadata_changeset.remove_tags = removeTags;
      }

      const record = await files.putFileRecord(fileId, {
        body: JSON.stringify(body),
      });

      return {
        fileId: record.file_id,
        tags: record.tags,
      };
    },
    onSuccess: (response) => {
      // Update the cache with the updated file tags from putFileRecord
      queryClient.setQueryData(["fileTags", response.fileId], response.tags);
    },
  });
}
