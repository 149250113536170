import { Box, ListItem, ListItemText, useTheme } from "@mui/material";
import * as React from "react";

import { EditFieldButton } from "@/components";
import { TriggerRecord } from "@/domain/actions/TriggerRecord";
import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import { APIResponse, RobotoAPICall, triggerEndpoint } from "@/types";

import { ActionInputData } from "../action";

interface EditableInputDataProps {
  title: string;
  field: string;
  value: string[];
  triggerName: string;
  setErrorText: (arg: string) => void;
  minRequired: number;
  onUpdate: (arg: APIResponse<TriggerRecord>) => void;
}

export const EditableInputData: React.FC<EditableInputDataProps> = ({
  title,
  field,
  value,
  triggerName,
  setErrorText,
  minRequired,
  onUpdate,
}) => {
  const theme = useTheme();

  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [requiredInputs, setRequiredInputs] = React.useState<string[]>(value);
  const { currentOrganization } = useAuth();

  const { initiateRequest } = useLazyAPICall<APIResponse<TriggerRecord>>();

  const update = async () => {
    const updateCall: RobotoAPICall = {
      endpoint: triggerEndpoint,
      orgId: currentOrganization?.org_id,
      pathParams: { name: triggerName },
      method: "PUT",
      requestBody: JSON.stringify({
        [field]: requiredInputs.filter(
          (requiredInput) => requiredInput.trim() !== "",
        ),
      }),
    };

    const { data, error } = await initiateRequest(updateCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data) {
      onUpdate(data);
    }

    setErrorText("");

    return true;
  };

  const onClick = async () => {
    if (editable) {
      const res = await update();
      if (res) {
        setEditable(false);
      }
    }
    setEditable(!editable);
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>
            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                void onClick();
              }}
            />
          </>
        }
        secondaryTypographyProps={{
          component: "span",
        }}
        secondary={
          <>
            <Box>
              {editable === false ? (
                <>
                  {<pre>{JSON.stringify(requiredInputs, null, 2)}</pre> ||
                    "None"}
                </>
              ) : (
                <Box sx={{ mt: theme.spacing(1) }}>
                  <ActionInputData
                    inputData={
                      requiredInputs && requiredInputs.length > 0
                        ? requiredInputs
                        : [""]
                    }
                    setInputData={setRequiredInputs}
                    minRequired={minRequired}
                  />
                </Box>
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
};
