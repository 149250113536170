import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { NavigationPageWithRedirectProtection, PageHeader } from "@/components";
import { ProfilePage } from "@/features";

const UserProfilePage: React.FC = () => {
  const theme = useTheme();
  const { userId } = useParams<string>();
  return (
    <NavigationPageWithRedirectProtection
      title={`Roboto - Profile - ${userId}`}
    >
      <PageHeader>User Profile</PageHeader>

      <Box
        sx={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <ProfilePage userId={userId} />
      </Box>
    </NavigationPageWithRedirectProtection>
  );
};

export default UserProfilePage;
