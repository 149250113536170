import * as React from "react";

interface DatasetVideoItemProps {
  signedUrl: string;
  loading: boolean;
  setLoading: (arg: boolean) => void;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export const DatasetVideoItem: React.FC<DatasetVideoItemProps> = ({
  signedUrl,
  loading,
  setLoading,
  onClick,
}) => {
  return (
    <>
      <video
        style={{
          width: "100%",
          height: loading ? 0 : "auto",
          opacity: loading ? 0 : 1,
          transition: "opacity 0.5s",
          display: "block",
        }}
        src={signedUrl}
        onCanPlay={() => setLoading(false)}
        onClick={onClick}
      />
    </>
  );
};
