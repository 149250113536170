interface TriggerEvaluationStatusDetailProps {
  statusDetail?: string;
}

export function TriggerEvaluationStatusDetail({
  statusDetail,
}: TriggerEvaluationStatusDetailProps) {
  if (!statusDetail) {
    return "-";
  }

  return (
    <>
      {statusDetail.split("\n").map((line, idx) => (
        <p key={idx}>{line}</p>
      ))}
    </>
  );
}
