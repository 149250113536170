import { FileRecord } from "@/domain/files";
import { FileNode } from "@/types";

export function getIndexOfFile(
  file: FileRecord | undefined,
  files: FileNode[] | undefined,
) {
  if (!file || !files) {
    return 0;
  }

  for (let i = 0; i < files.length; i++) {
    if (file.file_id === files[i].file?.file_id) {
      return i;
    }
  }

  return -1;
}

/*
In JavaScript, '%' is a remainder operator, not modulo.
This makes no difference for positive values, but when the
dividend and divisor are of different signs, they give different
results.
*/
export function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}
