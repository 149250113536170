import { InvocationStatus } from "@/domain/actions";

export const invocationStatusToString = (status?: InvocationStatus) => {
  switch (status) {
    case InvocationStatus.Queued:
      return "Pending";
    case InvocationStatus.Scheduled:
      return "Provisioning";
    case InvocationStatus.Downloading:
    case InvocationStatus.Processing:
    case InvocationStatus.Uploading: // Fall through
      return "Running";
    case InvocationStatus.Completed:
      return "Completed";
    case InvocationStatus.Cancelled:
      return "Cancelled";
    case InvocationStatus.Failed:
    case InvocationStatus.Deadly: // Fall through
      return "Failed";
  }
  return "Unknown";
};

export const invocationStatusToChipColor = (status?: InvocationStatus) => {
  switch (status) {
    case InvocationStatus.Queued:
      return "default";
    case InvocationStatus.Scheduled:
    case InvocationStatus.Downloading:
    case InvocationStatus.Processing:
    case InvocationStatus.Uploading:
    case InvocationStatus.Cancelled: // Fall through
      return "info";
    case InvocationStatus.Completed:
      return "success";
    case InvocationStatus.Failed:
    case InvocationStatus.Deadly: // Fall through
      return "error";
  }

  return "default";
};

export const invocationStatusToAlertSeverity = (status?: InvocationStatus) => {
  switch (status) {
    case InvocationStatus.Queued:
    case InvocationStatus.Scheduled:
    case InvocationStatus.Downloading:
    case InvocationStatus.Processing:
    case InvocationStatus.Uploading:
    case InvocationStatus.Cancelled: // Fall through
      return "info";
    case InvocationStatus.Completed:
      return "success";
    case InvocationStatus.Failed:
    case InvocationStatus.Deadly: // Fall through
      return "error";
  }

  return "info";
};
