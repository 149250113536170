import { useTheme } from "@mui/material";
import React from "react";

interface TagsGroupProps extends React.PropsWithChildren {}

export function TagsGroup({ children }: TagsGroupProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing(1.25),
      }}
    >
      {children}
    </div>
  );
}
