import * as React from "react";

import CopyToClipboardButton from "@/components/CopyToClipboardButton";
import { RobotoTypography } from "@/components/RobotoTypography";

import styles from "./EventId.module.css";

export function EventId({ eventId }: { eventId: string }) {
  const [showCopyButton, setShowCopyButton] = React.useState(false);

  return (
    <section>
      <RobotoTypography component={"h3"} className={styles.sectionTitle}>
        Event ID
      </RobotoTypography>
      <span
        className={styles.copyHitBox}
        onMouseEnter={() => setShowCopyButton(true)}
        onMouseLeave={() => setShowCopyButton(false)}
      >
        <RobotoTypography className={styles.idText}>{eventId}</RobotoTypography>
        <CopyToClipboardButton
          text={eventId}
          sx={{ opacity: showCopyButton ? 1 : 0, transition: "opacity 0.2s" }}
        />
      </span>
    </section>
  );
}
