import * as React from "react";

import { type Action } from "./actions";
import { type ImagePanelConfig, type State } from "./schema";

export const INITIAL_STATE: State = {
  version: "v0",
  files: [],
  panels: {},
  layout: {
    id: "root",
    axis: "x",
    relativeSize: 1,
    children: [],
  },
};

export const DEFAULT_IMAGE_PANEL_CONFIG: ImagePanelConfig = {
  rotation: 0,
  stretchToFitCanvas: true,
};

export type Dispatch = React.Dispatch<Action<unknown>>;
const INITIAL_DISPATCH: Dispatch = () => {
  /** noop */
};

export const VizStateContext = React.createContext(INITIAL_STATE);
export const VizStateDispatchContext = React.createContext(INITIAL_DISPATCH);
