import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import * as React from "react";

import { RegexIcon } from "@/components/icons/RegexIcon";
import { useDebouncedCallback } from "@/hooks";

import { SearchMode, isQueryValid } from "./search";

interface LogSearchInputProps {
  disabled: boolean;
  onSearch: (query: string, mode: SearchMode) => void;
}

export function LogSearchInput(props: LogSearchInputProps) {
  const { disabled, onSearch } = props;

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [searchMode, setSearchMode] = React.useState<SearchMode>(
    SearchMode.String,
  );
  const [isValidationError, setIsValidationError] =
    React.useState<boolean>(false);

  // Debounce time chosen through trial and error
  const debouncedSearch = useDebouncedCallback(onSearch, 300);

  return (
    <TextField
      value={searchTerm}
      disabled={disabled}
      fullWidth
      error={isValidationError}
      size="small"
      placeholder={
        searchMode === SearchMode.Regex
          ? "Search by pattern"
          : "Search by keyword"
      }
      onChange={(event) => {
        if (isQueryValid(event.target.value, searchMode)) {
          debouncedSearch(event.target.value, searchMode);
          setIsValidationError(false);
        } else {
          setIsValidationError(true);
        }

        setSearchTerm(event.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              title="Search using regular expressions"
              color={searchMode === SearchMode.Regex ? "primary" : "default"}
              aria-pressed={searchMode === SearchMode.Regex}
              aria-label="Toggle search by regular expression"
              onClick={() => {
                const nextSearchMode =
                  searchMode === SearchMode.Regex
                    ? SearchMode.String
                    : SearchMode.Regex;

                if (isQueryValid(searchTerm, nextSearchMode)) {
                  debouncedSearch(searchTerm, nextSearchMode);
                  setIsValidationError(false);
                } else {
                  setIsValidationError(true);
                }

                setSearchMode(nextSearchMode);
              }}
              size="small"
            >
              <RegexIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
