import { NavigationPageWithRedirectProtection } from "@/components";
import { SearchWithResults } from "@/features/search/pages";

const SearchWithResultsPage: React.FC = () => {
  return (
    <NavigationPageWithRedirectProtection
      title={"Roboto - Search"}
      isNotOnPaper={true}
    >
      <SearchWithResults />
    </NavigationPageWithRedirectProtection>
  );
};

export default SearchWithResultsPage;
