import {
  TableBody,
  TableRow,
  Skeleton,
  Alert,
  AlertTitle,
} from "@mui/material";

import { RobotoTableCell } from "@/components";
import { useDatasetEvents } from "@/domain/datasets/hooks/useDatasetEvents";

import { EventRow } from "./EventRow";
import styles from "./EventTableBody.module.css";

interface EventTableBodyProps {
  datasetId: string;
  numColumns: number;
}

export function EventTableBody({ datasetId, numColumns }: EventTableBodyProps) {
  const events = useDatasetEvents(datasetId);

  if (events.isLoading) {
    return (
      <TableBody>
        <TableRow>
          {Array.from({ length: numColumns - 1 }, (_, i) => (
            <RobotoTableCell key={`cell-${i}`}>
              <Skeleton animation="pulse" variant="text" />
            </RobotoTableCell>
          ))}
        </TableRow>
      </TableBody>
    );
  }

  if (events.error) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>Failed to fetch events</AlertTitle>
              Please contact{" "}
              <a href="mailto:support@roboto.ai">support@roboto.ai</a> if this
              problem persists.
            </Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (events.data === undefined || events.data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="info" className={styles.alert}>
              No events have been created for this dataset, its files, or
              topics.
            </Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {events.data.map((event) => (
        <EventRow key={event.event_id} event={event} numColumns={numColumns} />
      ))}
    </TableBody>
  );
}
