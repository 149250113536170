import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Collapse, useTheme } from "@mui/material";
import * as React from "react";

import {
  RobotoTableCell,
  RobotoTableRow,
  TooltipIconButton,
  UserLink,
} from "@/components";
import { EventScope } from "@/components/EventScope";
import { EventRecord } from "@/domain/events";
import { nanoSecToDate } from "@/utils/time";

import { EventDetails } from "./EventDetails";

interface EventRowProps {
  event: EventRecord;
  numColumns: number;
}

export const EventRow: React.FC<EventRowProps> = ({ event, numColumns }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpandingContent = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <RobotoTableRow hover onClick={toggleExpandingContent}>
        <RobotoTableCell>{event.name}</RobotoTableCell>
        <RobotoTableCell>{event.event_id}</RobotoTableCell>
        <RobotoTableCell>
          {nanoSecToDate(event.start_time).toLocaleString()}
        </RobotoTableCell>
        <RobotoTableCell>
          <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
            <PersonIcon fontSize="small" color="primary" />
            <UserLink userId={event.created_by} />
          </Box>
        </RobotoTableCell>
        <RobotoTableCell>
          {new Date(event.created).toLocaleString()}
        </RobotoTableCell>
        <RobotoTableCell>
          <EventScope event={event} />
        </RobotoTableCell>
        <RobotoTableCell onClick={toggleExpandingContent}>
          <TooltipIconButton
            title={expanded ? "Collapse" : "Expand"}
            onClick={toggleExpandingContent}
          >
            <ExpandCircleDownOutlinedIcon
              fontSize="small"
              sx={{
                transform: expanded ? "rotate(-0.5turn)" : "none",
              }}
              color="primary"
            />
          </TooltipIconButton>
        </RobotoTableCell>
      </RobotoTableRow>

      <RobotoTableRow>
        <RobotoTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderWidth: expanded ? "1px" : 0,
          }}
          colSpan={numColumns}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <EventDetails event={event} />
          </Collapse>
        </RobotoTableCell>
      </RobotoTableRow>
    </>
  );
};
