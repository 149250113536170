export enum SearchMode {
  Regex,
  String,
}

export interface SearchParams {
  query: string;
  mode: SearchMode;
}

export function isQueryValid(query: string, mode: SearchMode): boolean {
  switch (mode) {
    case SearchMode.Regex:
      try {
        new RegExp(query);
        return true;
      } catch {
        return false;
      }
    case SearchMode.String:
      return true;
  }
}

export function sanitizeQuery(query: string, mode: SearchMode): string {
  switch (mode) {
    case SearchMode.Regex:
      return query;
    case SearchMode.String:
      return query.trim().toLowerCase();
  }
}
