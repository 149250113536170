import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";

import { Column, DataGridSortingOrder, Row, TableState } from "@/types";
import { getBrowser, getOS } from "@/utils";

import { LoadingView } from "./LoadingView";
import { RobotoTableBody } from "./RobotoTableBody";
import { RobotoTableHeader } from "./RobotoTableHeader";
import { onTableHeaderResize, tableHeaderCheckboxChange } from "./tableLogic";

/**
 * NOTE: Make sure the length of any row is equal to the length of the columnNames array.
 */

export type RowsPerPage = 10 | 25 | 50;

interface RobotoDataGridProps {
  page: number;
  rowsPerPage: RowsPerPage;
  nextPageButtonDisabled: boolean;
  columnNames: string[];
  sortableColumns?: Set<string>;
  rows: Row[];
  loading: boolean;
  onPageChange: (newPageNumber: number, rowsPerPage: RowsPerPage) => void;
  onRowsPerPageChange: (
    pageNumber: number,
    newRowsPerPage: RowsPerPage,
  ) => void;
  onColumnSortClicked: (
    columnIndex: number,
    sortingOrder: DataGridSortingOrder,
    columnName: string,
  ) => void;
  onAddColumnClick: () => void;
  onRowDoubleClick: (rowId: string) => void;
  onRowSingleClick: (rowId: string) => void;
  onRowRightClick?: (
    rowId: string,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  containerStyle?: React.CSSProperties;
  currentRowLength: number;
  sortingOrder?: DataGridSortingOrder;
  sortingColumnIndex?: number | null;
  isRowExpandable?: boolean;
  isRowSelectable?: boolean;
  onSelectedRowsChange: (selectedRows: Set<string>) => void;
  clearSelectedRowsToggle: boolean;
  activeRowId?: string;
  secondaryRowId?: string;
  expandableContent?: (rowId: string) => React.ReactNode;
}

const constantHeight = 55;
const maxCellWidth = 2000;
const minCellWidth = 50;

const browser = getBrowser();
const os = getOS();

let scrollbarWidth = 0;
if (
  browser === "Google Chrome or Chromium" &&
  (os === "Linux" || os === "UNIX")
) {
  scrollbarWidth = 25;
}

export const RobotoDataGrid: React.FC<RobotoDataGridProps> = ({
  page,
  rowsPerPage,
  nextPageButtonDisabled,
  currentRowLength,
  columnNames,
  sortableColumns,
  rows,
  loading,
  onPageChange,
  onRowsPerPageChange,
  onColumnSortClicked,
  onAddColumnClick,
  onRowDoubleClick,
  onRowSingleClick,
  onRowRightClick,
  containerStyle,
  sortingOrder: parentSortingOrder,
  sortingColumnIndex: parentSortingColumnIndex,
  isRowExpandable = false,
  isRowSelectable = true,
  onSelectedRowsChange,
  clearSelectedRowsToggle,
  activeRowId,
  secondaryRowId,
  expandableContent,
}) => {
  //
  const [table, setTable] = useState<TableState>({
    columnState: [],
    rowState: [],
    columnsInitialized: false,
    selectedRows: new Set<string>(),
    sortingOrder: "none",
    sortingColumnIndex: null,
  });

  const tableRef = useRef<HTMLDivElement>(null);

  const columnWidthsRef = useRef<{ [key: string]: number }>({});

  const theme = useTheme();

  const checkboxWidth = 42.5;

  const [clickTimeoutId, setClickTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const [headerChecked, setHeaderChecked] = React.useState<boolean>(false);

  const handleRowClick = useCallback(
    (rowId: string) => {
      if (clickTimeoutId !== null) {
        clearTimeout(clickTimeoutId);
        setClickTimeoutId(null);

        onRowDoubleClick(rowId);
      } else {
        const timeoutId = setTimeout(() => {
          onRowSingleClick(rowId);
          setClickTimeoutId(null);
        }, 250);

        setClickTimeoutId(timeoutId);
      }
    },
    [clickTimeoutId, onRowDoubleClick, onRowSingleClick],
  );

  const [widthState, setWidthState] = useState<{
    clientWidth: number;
    widthLoading: boolean;
  }>({ clientWidth: 500, widthLoading: true });

  // Ensure that table re-renders and below useEffect runs once tableRef has a value.
  // Necessary to ensure correct column widths.
  useEffect(() => {
    const setTableWidth = () => {
      if (tableRef.current) {
        const tableWidth = tableRef.current.clientWidth;
        if (tableWidth > 0) {
          setWidthState({ clientWidth: tableWidth, widthLoading: false });
        } else {
          // If tableWidth is still not available, try again after a delay
          setTimeout(setTableWidth, 50);
        }
      } else {
        // If tableRef.current is still not available, try again after a delay
        setTimeout(setTableWidth, 50);
      }
    };
    setTableWidth();
  }, []);

  useEffect(() => {
    setTable((prevTable) => {
      let columnsInitialized = prevTable.columnsInitialized;

      let tableWidth = tableRef.current?.clientWidth ?? 0;
      tableWidth -= checkboxWidth;
      tableWidth -= scrollbarWidth;

      let initialColumnWidth = tableWidth / columnNames.length;

      if (tableRef.current === null) {
        initialColumnWidth = 200;
      } else {
        columnsInitialized = true;
      }

      const columnState = columnNames.map((name) => {
        //
        let columnWidth: number;

        // only use the column width from the ref if the columns have been initialized
        if (prevTable.columnsInitialized) {
          columnWidth = columnWidthsRef.current[name] ?? initialColumnWidth;
        } else {
          columnWidth = initialColumnWidth;
        }

        columnWidthsRef.current[name] = columnWidth;

        return {
          title: name,
          width: columnWidth,
          height: constantHeight,
          sortingOrder: "none",
        } as Column;
      });

      return {
        ...prevTable,
        columnState,
        columnsInitialized,
      };
    });
  }, [columnNames, widthState.clientWidth]);

  useEffect(() => {
    const rowState = rows.map((row) => {
      return {
        id: row.id,
        cells: [...row.cells],
      };
    });

    setTable((prevTable) => ({
      ...prevTable,
      rowState,
    }));
  }, [rows]);

  useEffect(() => {
    setTable((prevTable) => ({
      ...prevTable,
      selectedRows: new Set<string>(),
    }));
    setHeaderChecked(false);
  }, [clearSelectedRowsToggle]);

  return (
    <Box
      sx={{
        ...containerStyle,
        position: "relative",
      }}
      ref={tableRef}
    >
      <LoadingView loading={loading || widthState.widthLoading} />
      <TableContainer
        component={Box}
        sx={{
          backgroundColor: theme.palette.foreground.main,
          borderTopLeftRadius: theme.border.radius,
          borderTopRightRadius: theme.border.radius,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          padding: 0,
          maxHeight: `calc(${containerStyle?.maxHeight})`,
        }}
      >
        <Table
          stickyHeader
          sx={{
            width: "auto",
          }}
        >
          <RobotoTableHeader
            sortableColumns={sortableColumns}
            constantHeight={constantHeight}
            checkboxWidth={checkboxWidth}
            maxCellWidth={maxCellWidth}
            minCellWidth={minCellWidth}
            columns={table.columnState}
            onCheckboxChange={(e) => {
              tableHeaderCheckboxChange(
                e,
                table,
                setTable,
                onSelectedRowsChange,
              );
            }}
            onResize={(columnIndex, data) => {
              const column = table.columnState[columnIndex];

              columnWidthsRef.current[column.title] = data.size.width;

              const newTable = { ...table };
              newTable.columnState = table.columnState.map((column) => {
                column.width = columnWidthsRef.current[column.title];
                return column;
              });

              onTableHeaderResize(columnIndex, data, newTable, setTable);
            }}
            onSortStateChange={(sortingOrder, columnIndex) => {
              //
              setTable((prevTable) => ({
                ...prevTable,
                sortingOrder: sortingOrder,
                sortingColumnIndex: columnIndex,
              }));

              onColumnSortClicked(
                columnIndex,
                sortingOrder,
                table.columnState[columnIndex].title,
              );
            }}
            onAddColumnClick={onAddColumnClick}
            sortingColumnIndex={
              parentSortingColumnIndex
                ? parentSortingColumnIndex
                : table.sortingColumnIndex
            }
            sortOrder={
              parentSortingOrder ? parentSortingOrder : table.sortingOrder
            }
            headerChecked={headerChecked}
            setHeaderChecked={setHeaderChecked}
            isRowSelectable={isRowSelectable}
            isRowExpandable={isRowExpandable}
          />

          <RobotoTableBody
            table={table}
            constantHeight={constantHeight}
            checkboxWidth={checkboxWidth}
            onRowCheckboxChange={(e, rowIndex) => {
              const selectedRows = new Set(table.selectedRows);

              if (e.target.checked) {
                selectedRows.add(table.rowState[rowIndex].id);
              } else {
                selectedRows.delete(table.rowState[rowIndex].id);
              }

              setTable((prevTable) => ({
                ...prevTable,
                selectedRows,
              }));
              onSelectedRowsChange(selectedRows);
            }}
            onRowSingleClick={handleRowClick}
            onRowRightClick={onRowRightClick}
            isRowSelectable={isRowSelectable}
            isRowExpandable={isRowExpandable}
            selectedRows={table.selectedRows}
            activeRowId={activeRowId}
            secondaryRowId={secondaryRowId}
            expandableContent={expandableContent}
          />
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={nextPageButtonDisabled ? currentRowLength : -1}
        rowsPerPageOptions={[10, 25, 50]}
        page={page}
        nextIconButtonProps={{
          disabled: nextPageButtonDisabled,
        }}
        onPageChange={(_e, newPage) => {
          onPageChange(newPage, rowsPerPage);
        }}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(
            page,
            (parseInt(e?.target?.value) ?? 10) as RowsPerPage,
          );
        }}
        labelRowsPerPage={"Items per page:"}
        sx={{
          backgroundColor: theme.palette.foreground.main,
          borderTop: `1px solid ${theme.border.color}`,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: theme.border.radius,
          borderBottomRightRadius: theme.border.radius,
        }}
      />
    </Box>
  );
};
