import { SearchQueryBody, ConditionGroup, SimpleCondition } from "@/types";

import { HttpClient, robotoHeaders } from "../../http";

import { InvocationRecord, InvocationStatus } from "./ActionRecord";

export class ActionService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async queryActiveInvocationsForDataset(
    orgId: string,
    datasetId: string,
    signal: AbortSignal,
  ): Promise<{ items: InvocationRecord[]; next_token: string | null } | null> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/actions/invocations/query`,
    );

    const datasetCondition: SimpleCondition = {
      field: "data_source_id",
      comparator: "EQUALS",
      value: datasetId,
    };

    const conditions = [
      InvocationStatus.Completed,
      InvocationStatus.Cancelled,
      InvocationStatus.Failed,
      InvocationStatus.Deadly,
    ].map((status) => {
      const condition: SimpleCondition = {
        field: "last_status",
        comparator: "NOT_EQUALS",
        value: status,
      };

      return condition;
    });

    const nonTerminalCondition: ConditionGroup = {
      conditions: conditions,
      operator: "AND",
    };

    const searchConditions: ConditionGroup = {
      conditions: [datasetCondition, nonTerminalCondition],
      operator: "AND",
    };

    const searchBody: SearchQueryBody = {
      condition: searchConditions,
    };

    const response = await this.#httpClient.post(requestUrl, {
      headers: robotoHeaders({ orgId }),
      signal,
      body: JSON.stringify(searchBody),
    });

    return await response.json<{
      items: InvocationRecord[];
      next_token: string | null;
    } | null>();
  }
}
