import { Box, TablePagination } from "@mui/material";
import * as React from "react";

import { FileRecord } from "@/domain/files";
import { DatasetFileGrid } from "@/features/dataset/components/DatasetFileGrid";
import { DatasetTableConfig } from "@/features/dataset/components/types";
import { FileNode, FileSystemNode, isFile } from "@/types";

const viewFilters = {
  confidenceThreshold: { percent: 0, show: false },
  labels: { show: false },
  boundingBox: { show: false },
  segmentation: { show: false },
};

export interface FileGridProps {
  files?: FileRecord[];
  selectedFiles?: FileNode[];
  setSelectedFiles?: (arg: FileNode[]) => void;
}

export const FileGrid: React.FC<FileGridProps> = ({
  files,
  selectedFiles,
  setSelectedFiles = () => {},
}) => {
  const [currentPage, setCurrentPage] = React.useState<FileNode[] | null>(null);

  const [tableConfig, setTableConfig] = React.useState<DatasetTableConfig>({
    page: 0,
    rowsPerPage: 25,
    pageTokens: {},
  });

  const fileNodes = React.useMemo(() => {
    if (!files) {
      return null;
    }
    return files.map((file) => {
      return {
        type: "file",
        name: file.relative_path,
        file,
      } as FileNode;
    });
  }, [files]);

  React.useEffect(() => {
    // Constrain rows to current page size
    if (fileNodes !== null) {
      setCurrentPage(
        fileNodes.slice(
          tableConfig.page * tableConfig.rowsPerPage,
          tableConfig.page * tableConfig.rowsPerPage + tableConfig.rowsPerPage,
        ),
      );
    }
  }, [fileNodes, tableConfig.page, tableConfig.rowsPerPage]);

  React.useEffect(() => {
    setTableConfig({
      rowsPerPage: tableConfig.rowsPerPage,
      page: 0,
      pageTokens: {},
    });
  }, [tableConfig.rowsPerPage]);

  return (
    <>
      <DatasetFileGrid
        currentPage={currentPage}
        gridItems={3}
        annotationViewFilters={viewFilters}
        selectedItems={selectedFiles}
        datasetId=""
        handleSelectRows={(rows: FileSystemNode[], isSelected: boolean) => {
          if (!rows.every((row) => isFile(row))) {
            return;
          }

          if (selectedFiles) {
            if (isSelected) {
              const itemsToAdd = [];
              for (const row of rows) {
                if (
                  !selectedFiles.some((item) => {
                    if (!isFile(item)) {
                      return false;
                    }

                    if (!isFile(row)) {
                      return false;
                    }

                    return item.file.file_id === row.file.file_id;
                  })
                ) {
                  itemsToAdd.push(row);
                }
              }
              const newState = selectedFiles.concat(itemsToAdd);
              setSelectedFiles(newState);
            } else {
              const newState = selectedFiles.filter(
                (item) =>
                  !rows.some((row) => {
                    if (!isFile(row)) {
                      return false;
                    }

                    row.file.file_id === item.file.file_id;
                  }),
              );
              setSelectedFiles(newState);
            }
          } else {
            if (isSelected) {
              setSelectedFiles(rows);
            }
          }
        }}
      />
      <TablePagination
        component={Box}
        count={files?.length || 0}
        page={tableConfig.page}
        rowsPerPage={tableConfig.rowsPerPage}
        labelRowsPerPage={"Items per page:"}
        rowsPerPageOptions={[10, 25, 50]}
        onPageChange={(_event, newPage) => {
          setTableConfig((prevConfig) => ({
            ...prevConfig,
            page: newPage,
          }));
        }}
        onRowsPerPageChange={(event) => {
          setTableConfig({
            rowsPerPage: parseInt(event.target.value, 10) as 10 | 25 | 50,
            page: 0,
            pageTokens: {},
          });
        }}
      />
    </>
  );
};
