import { ChipProps } from "@mui/material";

import {
  type TriggerEvaluationRecord,
  TriggerEvaluationCause,
  TriggerEvaluationStatus,
  TriggerEvaluationOutcome,
  TriggerEvaluationOutcomeReason,
} from "@/domain/actions";

export type EvaluationColumn = keyof TriggerEvaluationRecord | "dataset_id";

export const DEFAULT_EVALUATION_COLUMNS: EvaluationColumn[] = [
  "trigger_id",
  "cause",
  "data_source",
  "evaluation_start",
  "evaluation_end",
  "status",
  "status_detail",
  "outcome",
  "outcome_reason",
];

export function causeToFormattedString(cause?: TriggerEvaluationCause): string {
  switch (cause) {
    case TriggerEvaluationCause.DatasetMetadataUpdate:
      return "Dataset Metadata Update";
    case TriggerEvaluationCause.FileUpload:
      return "File Upload";
    default:
      return "-";
  }
}

export function outcomeToFormattedString(
  outcome?: TriggerEvaluationOutcome,
): string {
  switch (outcome) {
    case TriggerEvaluationOutcome.InvokedAction:
      return "Invoked Action";
    case TriggerEvaluationOutcome.Skipped:
      return "Skipped";
    default:
      return "-";
  }
}

export function outcomeReasonToFormattedString(
  outcomeReason?: TriggerEvaluationOutcomeReason,
): string {
  switch (outcomeReason) {
    case TriggerEvaluationOutcomeReason.AlreadyRun:
      return "Already Run";
    case TriggerEvaluationOutcomeReason.ConditionNotMet:
      return "Condition Not Met";
    case TriggerEvaluationOutcomeReason.NoMatchingFiles:
      return "No Matching Files";
    case TriggerEvaluationOutcomeReason.TriggerDisabled:
      return "Trigger Disabled";
    default:
      return "-";
  }
}

export function statusToColor(
  status: TriggerEvaluationStatus,
): ChipProps["color"] {
  switch (status) {
    case TriggerEvaluationStatus.Evaluated:
      return "success";
    case TriggerEvaluationStatus.Failed:
      return "error";
    default:
      return "info";
  }
}

export function statusToFormattedString(
  status: TriggerEvaluationStatus,
): string {
  switch (status) {
    case TriggerEvaluationStatus.Evaluated:
      return "Evaluated";
    case TriggerEvaluationStatus.Failed:
      return "Failed";
    default:
      return "Pending";
  }
}
