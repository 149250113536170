import { useMutation } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";

import { EventRecord } from "../EventRecord";

export function useDeleteEvent() {
  const { events } = useDomainServices();
  return useMutation<void, Error, EventRecord["event_id"]>({
    mutationFn: function deleteEvent(eventId) {
      return events.deleteEvent(eventId);
    },
  });
}
