import { Autocomplete, TextField } from "@mui/material";
import { SetStateAction } from "react";
import * as React from "react";

interface TriggerForEachSelectorProps {
  forEach: string | null;
  setForEach: React.Dispatch<SetStateAction<string | null>>;
}

export const TriggerForEachSelector: React.FC<TriggerForEachSelectorProps> = ({
  forEach,
  setForEach,
}) => {
  return (
    <Autocomplete
      disablePortal
      id="available-for-each"
      options={["dataset", "dataset_file"]}
      sx={{ width: 400 }}
      value={forEach}
      size={"small"}
      renderInput={(params) => <TextField {...params} label="For Each" />}
      onChange={(_event, newValue: string | null) => {
        setForEach(newValue);
      }}
      componentsProps={{
        paper: {
          sx: {
            fontSize: "0.875rem",
          },
        },
      }}
    />
  );
};
