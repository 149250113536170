import { NavigationPageWithRedirectProtection } from "@/components";
import ActionHubPage from "@/features/actions/pages/ActionHub";

export default function ActionHubLandingPage() {
  return (
    <NavigationPageWithRedirectProtection title="Roboto - Action Hub">
      <ActionHubPage />
    </NavigationPageWithRedirectProtection>
  );
}
