import {
  UploadProviderState,
  FileUploadAction,
  UploadFileActionTypes,
  UIUploadItem,
} from "./types";

export const uploadReducer = (
  state: UploadProviderState,
  action: FileUploadAction,
): UploadProviderState => {
  switch (action.type) {
    case UploadFileActionTypes.BeginCancelUpload: {
      return {
        ...state,
        isCancelUploadInProgress: true,
      };
    }

    case UploadFileActionTypes.BeginProcessing: {
      return {
        ...state,
        isToastVisible: true,
        isProcessingFiles: true,
        processingError: null,
      };
    }

    case UploadFileActionTypes.CloseToastDisplay: {
      return {
        ...state,
        isCancelDialogOpen: false,
        isCancelUploadInProgress: false,
        isProcessingFiles: false,
        isToastVisible: false,
        processingError: null,
        uploadItems: {},
      };
    }

    case UploadFileActionTypes.EndProcessingBeginUpload: {
      const uploadItems: Record<string, UIUploadItem> = {};

      action.payload.uploadItems.forEach((item) => {
        uploadItems[item.id] = item;
      });

      return {
        ...state,
        isProcessingFiles: false,
        uploadItems: {
          ...state.uploadItems,
          ...uploadItems,
        },
      };
    }

    case UploadFileActionTypes.ProcessingError: {
      return {
        ...state,
        processingError: action.payload.error,
      };
    }

    case UploadFileActionTypes.ReportBytesUploaded: {
      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.uploadItemKey]: {
            ...state.uploadItems[action.payload.uploadItemKey],
            bytesUploaded:
              state.uploadItems[action.payload.uploadItemKey].bytesUploaded +
              action.payload.bytesUploaded,
          },
        },
      };
    }

    case UploadFileActionTypes.ToggleCancelDialog: {
      return {
        ...state,
        isCancelDialogOpen: !state.isCancelDialogOpen,
      };
    }

    case UploadFileActionTypes.UploadItemBegin: {
      const item = { ...state.uploadItems[action.payload.itemId] };
      item.isUploadStarted = true;

      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.itemId]: item,
        },
      };
    }

    case UploadFileActionTypes.UploadItemBeginCancellation: {
      const item = { ...state.uploadItems[action.payload.itemId] };
      item.isCancelling = true;

      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.itemId]: item,
        },
      };
    }

    case UploadFileActionTypes.UploadItemCancelled: {
      const item = { ...state.uploadItems[action.payload.itemId] };
      item.isCancelled = true;

      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.itemId]: item,
        },
      };
    }

    case UploadFileActionTypes.UploadItemComplete: {
      const item = { ...state.uploadItems[action.payload.itemId] };
      item.isUploadCompleted = true;

      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.itemId]: item,
        },
      };
    }

    case UploadFileActionTypes.UploadItemError: {
      const item = { ...state.uploadItems[action.payload.itemId] };
      item.uploadError = action.payload.error;

      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.itemId]: item,
        },
      };
    }

    case UploadFileActionTypes.UploadItemFirstBytes: {
      const item = { ...state.uploadItems[action.payload.itemId] };

      // start off with 2%
      item.bytesUploaded = (item.totalBytes / 100) * 2;

      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.itemId]: item,
        },
      };
    }

    case UploadFileActionTypes.UploadItemProgress: {
      const item = { ...state.uploadItems[action.payload.itemId] };
      item.bytesUploaded += action.payload.bytesUploaded;

      return {
        ...state,
        uploadItems: {
          ...state.uploadItems,
          [action.payload.itemId]: item,
        },
      };
    }
  }
};
