import { Link, Typography } from "@mui/material";
import * as React from "react";

export const ImageGuidance: React.FC = () => {
  return (
    <Typography variant="body2" component={"span"}>
      Edit this field to specify your action&apos;s associated container image.{" "}
      <br />
      <br />
      An action can be associated with an image stored in a public image
      registry, such as Docker Hub (e.g.,{" "}
      <pre style={{ display: "inline" }}>ros:noetic-ros-core</pre>), or in a
      private image registry (e.g.,{" "}
      <pre style={{ display: "inline" }}>
        &lt;registry&gt;/&lt;repository&gt;:&lt;tag&gt;
      </pre>
      ). <br />
      <br />
      Roboto currently only supports using its own private image registry. For
      example, an image stored in Roboto&apos;s private image registry would be
      specified as: <br />
      <br />
      <pre style={{ display: "inline" }}>
        &lt;roboto_aws_account_id&gt;.dkr.ecr.us-west-2.amazonaws.com/&lt;org_id&gt;/image-name:1.2.3
      </pre>
      <br />
      <br />
      You can push an image to Roboto&apos;s private image registry using the{" "}
      <Link
        target="_blank"
        href="https://docs.roboto.ai/reference/cli.html#roboto-images-push"
      >
        Roboto CLI
      </Link>{" "}
      as follows:
      <br />
      <br />
      <pre>$ roboto images push {"<image-name:1.2.3>"}</pre>
    </Typography>
  );
};
