export {
  type ImageCommandPayloadMap,
  type ImageCommandResponsePayloadMap,
  ImageCommand,
  ImageCommandResponse,
  isInitCommand,
  isLoadImagesCommand,
} from "./commands";

export { type ImageEventPayloadMap, ImageEvent, isErrorEvent } from "./events";

export const SOURCE = "roboto-image-panel";
