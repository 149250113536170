import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo, useEffect } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";

import {
  LoadingScreen,
  RobotoStyledTab,
  RobotoStyledTabs,
  RobotoTabPanel,
} from "@/components";
import { ActionRecord, ActionReference } from "@/domain/actions";
import { Invocations } from "@/features/actions/components";
import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import { APIResponse, actionEndpoint } from "@/types";

import { ActionDetails, ActionLink } from "../components/action";

/**
 * Details page for a single Action.
 *
 * The action to detail is determined from the page's URL.
 * The URL can contain the action's name, and optionally the action's owner/org,
 * and a digest for a particular version of an action.
 * The reference to action is parsed from the URL, and used to fetch the action record from the roboto_service.
 */
export function ActionPage() {
  // "actionRef" are the route parameters (action name, and optionally action owner/org)
  const { actionRef, "*": splat } = useParams<{
    actionRef: string | undefined;
    "*": string | undefined;
  }>();

  const [searchParams, setSearchParams] = useSearchParams();

  // "digest" is a query parameter
  const paramDigest = searchParams.get("digest");

  const tabSearchParam = searchParams.get("tab");
  const tabValue = tabSearchParam !== null ? parseInt(tabSearchParam) : 0;

  const theme = useTheme();
  const { currentOrganization } = useAuth();

  // Prevent recomputation of the `actionReference` object by turning route parameters into a primitive value.
  let actionRefString: string = actionRef ?? "";

  if (splat) {
    actionRefString = `${actionRefString}/${splat}`;
  }

  // This is the reference to the action to detail parsed from the URL.
  // It may or may not be resolvable to an existing action record.
  const actionReference = useMemo(() => {
    if (!actionRefString) {
      return null;
    }

    const parts = actionRefString.split("/");
    const reference: ActionReference = {} as ActionReference;

    if (parts.length === 2) {
      // owner is explicitly specified
      // example: "some-org/an-action[?digest=digest]"
      reference["owner"] = parts[0];
      reference["name"] = parts[1];
    } else if (parts.length === 1) {
      // owner is not specified
      // example: "an-action[?digest=digest]"
      reference["name"] = parts[0];
    }

    const digest = paramDigest;
    if (digest && !Array.isArray(digest)) {
      reference["digest"] = digest;
    }

    return reference;
  }, [actionRefString, paramDigest]);

  const {
    data: response,
    error,
    loading,
    initiateRequest,
    updateCache,
  } = useLazyAPICall<APIResponse<ActionRecord>>();

  useEffect(() => {
    async function fetchAction() {
      if (!actionReference || !currentOrganization?.org_id) {
        return;
      }

      const queryParams = actionReference?.digest
        ? new URLSearchParams({ digest: actionReference.digest })
        : undefined;

      const headers = actionReference?.owner
        ? { "X-Roboto-Resource-Owner-Id": actionReference.owner }
        : undefined;

      await initiateRequest({
        endpoint: actionEndpoint,
        method: "GET",
        orgId: currentOrganization.org_id,
        pathParams: { name: actionReference.name },
        queryParams,
        headers,
      });
    }

    void fetchAction();
  }, [currentOrganization?.org_id, actionReference, initiateRequest]);

  const actionRecord = response?.data;

  if (loading || (!actionRecord && !error)) {
    return <LoadingScreen />;
  }

  const editable =
    // Action belongs to current org...
    actionRecord?.org_id === currentOrganization?.org_id &&
    // ...and action being viewed is the latest version
    // N.b.: we don't have a good way of knowing whether an action is the latest version,
    // so we just check whether the digest is undefined, which guarantees that it's the latest version.
    actionReference?.digest === undefined;

  const breadcrumbs = [
    <Link key="list" to="/actions?tab=0">
      Actions
    </Link>,
    <ActionLink
      key="latest"
      action={actionReference}
      linkSx={{
        fontSize: "1.125rem",
        fontWeight: "500",
        color: theme.palette.text.primary,
      }}
    />,
  ];
  if (actionReference?.digest) {
    breadcrumbs.push(
      <Typography
        key="digest"
        sx={{ fontSize: "1.125rem", fontWeight: "500" }}
        color="text.secondary"
      >
        {actionReference.digest}
      </Typography>,
    );
  }

  let errorContent = null;
  let pageContent = null;
  if (error) {
    errorContent = (
      <Box
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(1),
        }}
      >
        <Alert severity="error">{error.message}</Alert>
      </Box>
    );
  } else if (!actionRecord) {
    errorContent = (
      <Box
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(1),
        }}
      >
        <Alert severity="error">
          Failed to load action &apos;{actionReference?.name}&apos;.
          <br />
          Try refreshing the page.
        </Alert>
      </Box>
    );
  } else {
    pageContent = (
      <Box
        sx={{
          padding: theme.spacing(1, 0),
          paddingBottom: theme.spacing(1),
        }}
      >
        <RobotoTabPanel index={0} value={tabValue}>
          <ActionDetails
            action={actionRecord}
            currentOrg={currentOrganization}
            onUpdate={editable ? updateCache : undefined}
          />
        </RobotoTabPanel>

        <RobotoTabPanel
          index={1}
          value={tabValue}
          sx={{
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Invocations
            action={actionRecord ?? undefined}
            currentOrg={currentOrganization}
          />
        </RobotoTabPanel>
      </Box>
    );
  }

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      {errorContent}

      {!error && (
        <Box
          sx={{
            paddingTop: theme.spacing(3),
          }}
        >
          <RobotoStyledTabs
            value={tabValue}
            onChange={(_event, newValue) => {
              setSearchParams({ tab: String(newValue) });
            }}
            aria-label="actions tabs"
            variant="scrollable"
          >
            <RobotoStyledTab label="Details" />
            <RobotoStyledTab label="Invocations" />
          </RobotoStyledTabs>

          <Divider
            sx={{
              borderColor: theme.border.color,
            }}
          />
        </Box>
      )}

      {pageContent}
    </>
  );
}
