import { NavigationPageWithRedirectProtection } from "@/components";
import { ActionsPage } from "@/features/actions";

export default function Actions() {
  return (
    <NavigationPageWithRedirectProtection title={"Roboto - Actions"}>
      <ActionsPage />
    </NavigationPageWithRedirectProtection>
  );
}
