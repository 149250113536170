import { IconButton, IconButtonProps, TooltipProps } from "@mui/material";

import { RobotoTooltip } from "./RobotoTooltip";

export function TooltipIconButton(props: IconButtonProps & TooltipProps) {
  const { title, ...other } = props;
  return (
    <RobotoTooltip title={title || ""}>
      <span>
        <IconButton {...other} />
      </span>
    </RobotoTooltip>
  );
}
