import * as React from "react";

import { RobotoSwitch, RobotoTooltip } from "@/components";
import { DomainServicesContext } from "@/providers/DomainServices";

interface TokenSwitchProps {
  enabled: boolean;
  tokenId: string;
  setErrorText: (arg: string) => void;
}

export const TokenSwitch: React.FC<TokenSwitchProps> = ({
  enabled,
  tokenId,
  setErrorText,
}) => {
  const { tokens } = React.useContext(DomainServicesContext);

  const [switchState, setSwitchState] = React.useState<boolean>(enabled);

  const updateToken = () => {
    setErrorText("");
    if (switchState === true) {
      tokens
        .disableToken(tokenId)
        .then(() => {
          setSwitchState(!switchState);
        })
        .catch((err) => {
          const error =
            err instanceof Error
              ? err
              : new Error("Failed to disable token", {
                  cause: err,
                });
          setErrorText(error.message);
        });
    } else {
      tokens
        .enableToken(tokenId)
        .then(() => {
          setSwitchState(!switchState);
        })
        .catch((err) => {
          const error =
            err instanceof Error
              ? err
              : new Error("Failed to enable token", {
                  cause: err,
                });
          setErrorText(error.message);
        });
    }
  };

  const handleToggle = () => {
    void updateToken();
  };

  return (
    <RobotoTooltip title={switchState ? "Disable Token" : "Enable Token"}>
      <RobotoSwitch
        checked={switchState}
        onChange={handleToggle}
        inputProps={{ "aria-label": "token toggle" }}
        color="success"
      />
    </RobotoTooltip>
  );
};
