import { FormControl, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { RobotoTypography } from "../RobotoTypography";

import { EventSourceType, EventScope, EventDataEntityType } from "./scope";

interface EventScopeFormProps {
  sourceOptions: Array<EventScope["source"]>;
}

const dataEntityOptions = [
  EventDataEntityType.AllDatasets,
  EventDataEntityType.AllFiles,
  EventDataEntityType.AllTopics,
  EventDataEntityType.AllMessagePaths,
];

export function EventScopeForm({ sourceOptions }: EventScopeFormProps) {
  const form = useFormContext<{ scope: EventScope }>();
  return (
    <section>
      <RobotoTypography
        component={"h3"}
        sx={{ fontWeight: 500, fontSize: "1rem", marginBottom: "0.5rem" }}
      >
        Scope event to
      </RobotoTypography>
      <div style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
        <FormControl size="small">
          <Controller
            name="scope.dataEntity.type"
            control={form.control}
            render={({ field }) => {
              return (
                <Select {...field} id="dataEntity">
                  {dataEntityOptions.map((dataEntity) => (
                    <MenuItem key={dataEntity} value={dataEntity}>
                      {dataEntity}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          ></Controller>
        </FormControl>
        in
        <FormControl size="small">
          <Controller
            name="scope.source"
            control={form.control}
            render={({ field }) => {
              return (
                <Select
                  id="source"
                  onChange={(event) => {
                    // Note(Pratik) We're searching for the source in the source options because we
                    // want to use the entire source object instead of just the source type.
                    const source = sourceOptions.find(
                      (source) =>
                        source.type === (event.target.value as EventSourceType),
                    );

                    if (!source) {
                      // This shouldn't ever happen because the only selectable inputs are the source options
                      throw new Error(
                        "Selected source is not in available source options",
                      );
                    }

                    field.onChange(source);
                  }}
                  value={field.value.type}
                >
                  {sourceOptions.map((source) => (
                    <MenuItem key={source.type} value={source.type}>
                      {source.type}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          ></Controller>
        </FormControl>
      </div>
    </section>
  );
}
