import { HttpClient } from "../../http";
import { OrgInviteRecord, OrgRecord } from "../orgs/OrgRecord";

interface Options {
  abortSignal: AbortSignal;
}

export class UserService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getInvitesForCurrentUser(
    options?: Partial<Options>,
  ): Promise<OrgInviteRecord[]> {
    const requestUrl = this.#httpClient.constructUrl(`v1/users/invites`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
    });
    return await response.json<OrgInviteRecord[]>();
  }

  public async getOrgsForCurrentUser(
    options?: Partial<Options>,
  ): Promise<OrgRecord[]> {
    const requestUrl = this.#httpClient.constructUrl(`v1/users/orgs`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
    });
    return await response.json<OrgRecord[]>();
  }
}
