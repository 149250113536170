import { Box, CircularProgress, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { RobotoButton, RobotoTypography } from "@/components";
import { useAuth } from "@/providers";

export const ResendCodeButton: React.FC<{ emailAddress: string }> = ({
  emailAddress,
}) => {
  //
  const { resendVerificationCode } = useAuth();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  const onClick = async () => {
    setErrMsg(null);
    setIsLoading(true);
    const errMsg = await resendVerificationCode(emailAddress);
    setErrMsg(errMsg);
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading ? (
        <CircularProgress size="1.75rem" />
      ) : (
        <RobotoButton
          disabled={emailAddress.length < 3}
          onClick={() => {
            void onClick();
          }}
          eventName={"ResendVerificationCodeClicked"}
        >
          Resend Code
        </RobotoButton>
      )}
      <RobotoTypography
        variant={"caption"}
        sx={{ color: theme.palette.error.main }}
      >
        {errMsg}
      </RobotoTypography>
    </Box>
  );
};
