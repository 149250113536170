import { Container, Paper, useTheme } from "@mui/material";
import { useEffect } from "react";
import * as React from "react";

import { Footer } from "./Footer";
import { MainContentWithNavigation } from "./MainContent";
import { RobotoNavigationComponent } from "./RobotoNavigation";

export interface NavigationPageProps {
  title?: string;
  children: React.ReactNode;
  isNotOnPaper?: boolean;
}

export const NavigationPage: React.FC<NavigationPageProps> = ({
  title,
  children,
  isNotOnPaper = false,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      document.title = "Roboto";
    }
  }, [title]);

  let innerContent = (
    <Container sx={{ marginTop: theme.spacing(3) }} maxWidth={false}>
      <Paper
        sx={{ padding: "20px", backgroundColor: theme.palette.paper.main }}
        elevation={1}
      >
        {children}
      </Paper>
      <Footer />
    </Container>
  );

  if (isNotOnPaper) {
    innerContent = <>{children}</>;
  }

  return (
    <MainContentWithNavigation
      sideBarWidth={theme.navSideBarWidth}
      topBarHeight={theme.navTopBarHeight}
    >
      <RobotoNavigationComponent
        sideBarWidth={theme.navSideBarWidth}
        topBarHeight={theme.navTopBarHeight}
      />

      {innerContent}
    </MainContentWithNavigation>
  );
};
