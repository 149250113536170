import { Alert, Box, useTheme } from "@mui/material";

import { OrgRecord } from "@/domain/orgs";

import { CollectionsView } from "./CollectionsView";
import { ColView } from "./types";

interface CollectionsProps {
  currentOrg: OrgRecord | null;
}

/**
 * Lists Collections accessible within the current org.
 */
export default function Collections({ currentOrg }: CollectionsProps) {
  const theme = useTheme();
  const verticalSpacing = theme.spacing(3);

  return (
    <>
      <Box marginBottom={verticalSpacing}>
        <Alert severity="info">
          Collections are used to group datasets or files together. For example,
          you could create a collection of images for regression testing an
          algorithm.
        </Alert>
      </Box>
      <CollectionsView defaultView={ColView.Table} org={currentOrg} />
    </>
  );
}
