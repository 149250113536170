import { ErrorDetail, MessagePayload } from "@/webworker";

export enum LogEvent {
  AllLogsLoaded = "all-logs-loaded",
  Error = "error",
  Initialized = "initialized",
  LoadingStateChange = "loading-state-change",
  NumLogsChanged = "num-logs-changed",
}

export interface LogEventPayloadMap {
  [LogEvent.AllLogsLoaded]: {
    timestamps: ArrayBuffer;
  };
  [LogEvent.Error]: ErrorDetail | Error;
  [LogEvent.Initialized]: undefined;
  [LogEvent.LoadingStateChange]: { isLoading: boolean };
  [LogEvent.NumLogsChanged]: { numLogs: number };
}

export function isAllLogsLoadedEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.AllLogsLoaded]>
> {
  return event.data.type === LogEvent.AllLogsLoaded;
}

export function isErrorEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.Error]>
> {
  return event.data.type === LogEvent.Error;
}

export function isInitializedEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.Initialized]>
> {
  return event.data.type === LogEvent.Initialized;
}

export function isLoadingStateChangeEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.LoadingStateChange]>
> {
  return event.data.type === LogEvent.LoadingStateChange;
}
