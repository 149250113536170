import * as React from "react";

import styles from "./ConfigSection.module.css";

interface ConfigSectionProps {
  title: string;
  children: React.ReactNode;
  controls?: React.ReactNode;
}

/**
 * Group of configuration/controls for a plot series.
 */
export function ConfigSection({
  title,
  children,
  controls,
}: React.PropsWithChildren<ConfigSectionProps>) {
  return (
    <section>
      <div className={styles.header}>
        <h4 className={styles.title}>{`${title}`}</h4>
        <div className={styles.controls}>{controls}</div>
      </div>
      {children}
    </section>
  );
}
